import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import CModal from "../../../../widget/CModal/CModal";
import { customApi, baseUrl } from "../../../../widget/apis";
import BtnLoader from "../../../../widget/Loading/BtnLoader";
import { PickupOrderApi } from "../../../../widget/apis/orders";

export declare type ModalPickupOrdersProps = {
    order_id: any,
    visible: boolean,
    setVisible: (bol: boolean) => void;
}

const ModalPickupOrders = ({ setVisible, visible, order_id }: ModalPickupOrdersProps) => {
    const formRef: any = useRef();
    const [displayBtn, setDisplayBtn] = useState<boolean>(false)

    const handelPickupRequest = async (e: any) => {
        e.preventDefault()
        setDisplayBtn(true)
        try {
            const res: any = await PickupOrderApi(order_id, formRef)
            if (res.status == true) {
                toast(res.message, { type: "success" })
                setDisplayBtn(false)
                setVisible(false)
            }
            else{
                toast(res.message, { type: "error" })
                setDisplayBtn(false)
            }
        }
        catch {
            setDisplayBtn(false)
        }
    }

    return (
        <>
            <CModal visible={visible} setVisible={setVisible} uId="pickup-order" radius={'30px'} >
                <>
                    <div className=" px-3">
                        <form onSubmit={handelPickupRequest} ref={formRef} className=" w-full flex flex-col">
                            <div className={`w-full flex flex-col mt-5 `}>
                                <span>Pickup Date <span className="text-red-600">*</span></span>
                                <input name='pickup_date' type="date" className="mt-2 w-full h-[40px] rounded-md bg-transparent border px-2 focus:outline-none" required={true} />
                            </div>
                            <div className={`w-full flex flex-col mt-4 `}>
                                <span>Pickup Time <span className="text-red-600">*</span></span>
                                <input name='pickup_time' type="time" className="mt-2 w-full h-[40px] rounded-md bg-transparent border px-2 focus:outline-none" required={true} />
                            </div>
                            <div className="w-full flex items-center mt-4">
                                {displayBtn ?
                                    <button type="button" className=" w-[120px] h-10 m-3  bg-gr-300 flex items-center justify-center rounded-[30px] relative">
                                        <span><BtnLoader /></span>
                                    </button>
                                    :
                                    <button type="submit" className=" w-[120px] h-10 m-3 bg-gr-300 flex items-center justify-center rounded-[30px] text-white">
                                        <span>Send</span>
                                    </button>
                                }
                                <button type="button" onClick={() => { setVisible(false) }} className=" w-[120px] h-10 m-3  border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                    <span>Cancel</span>
                                </button>
                            </div>
                        </form>

                    </div>
                </>
            </CModal>
        </>
    )
}

export default ModalPickupOrders;