import { customApi, baseUrl } from ".."



export const SaleDashboardReport = async (type: string , start?:string , end?:string) => {
    let generateUrl = null ;
    if(type == 'custom'){
        generateUrl = `${baseUrl}${localStorage.getItem("shopId")}/dashboard/sell?type=${type}&start=${start}&end=${end}`
    }
    else {
        generateUrl = `${baseUrl}${localStorage.getItem("shopId")}/dashboard/sell?type=${type}`
    }
    const data = await customApi({ url: generateUrl }, { method: "GET", token: true })
    return data;
}

