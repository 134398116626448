import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Base from './components/widget/layout/base'
import 'antd/dist/antd.css';
import 'leaflet/dist/leaflet.css';
import ContextMain from './components/widget/context/ContextMain'
import Auth from './components/page/auth/Auth';
import { ToastContainer } from 'react-toastify';
import { ProtectedRoute } from './components/widget/apis/PrivateRoute'


function App() {

  return (
      <ContextMain>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/panel/*" element={<ProtectedRoute ><Base /></ProtectedRoute>} />
          </Routes>
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          hideProgressBar={false} newestOnTop
          closeOnClick theme='light'
          pauseOnFocusLoss
          draggable pauseOnHover
        />
      </ContextMain>
  );
}

export default App;
