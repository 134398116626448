import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';

function ShopList() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [lastPage, setLastPage] = useState(0);



  const changePage = (status: any) => {
    if (status) {
      if (lastPage >= currentPage + 1) {
        getData(currentPage + 1)
      }
    }
    else {
      if (currentPage > 1) {
        getData(currentPage - 1)
      }
    }
  }


  const getData = async (current: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}shops` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListData(req.data.data)
      setCurrentPage(req.data.meta.current_page)
      setLastPage(req.data.meta.last_page)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("List Shops")
    getData(currentPage)
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full  flex flex-col   ">
        <div className=' w-full py-[20px]   flex flex-wrap'>
          {listData.map((item: any, index: any) =>
            <div className=' lg:w-3/12 sm:w-4/12 w-full p-[10px]  flex flex-col items-center relative cursor-pointer transition-all hover:mt-[-3px] overflow-hidden'>
              <div className=' w-[80px] h-[80px] ct-bg-1 z-10 rounded-full flex justify-center items-center p-[2px] mb-[-40px]'>
                <div className=' w-full h-full bg-white rounded-full p-[2px]'>
                  <img src={item.logo} alt='logo' className='w-full h-full rounded-full' />
                </div>
              </div>
              <div className='w-full pt-[50px] pb-[20px] bg-white shadow rounded-xl flex flex-col items-center overflow-hidden border-t-2 border-[#ffc804]'>
                <div className=' w-full flex items-center justify-center'>
                  <svg x="0px" y="0px" viewBox="0 0 489.4 489.4" className={` w-5 h-5 fill-gray-500 transition-all `}>
                    <use xlinkHref="/assets/svg/shop.svg#shop" />
                  </svg>
                  <span className=' text-gray-500 ml-[10px] text-lg'>{item.name}</span>
                </div>
                <Link key={index} to={`/panel/shop/detail/${item.id}`}>
                  <button className='mt-[20px] h-[40px] border rounded-md border-[#ffc804] text-[#ffc804] px-[10px]'>View Product</button>
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className=' w-full  flex items-center justify-center'>
          {currentPage != 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(false)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
              </svg>
            </div>
          }
          <div className="table-counter-main-item">{currentPage} </div>
          {lastPage >= currentPage + 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(true)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
              </svg>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default ShopList;
