import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../../widget/context/ContextMain'
import CModal from '../../../../widget/CModal/CModal';
import { customApi, baseUrl } from '../../../../widget/apis';
import LoadingPage from '../../../../widget/Loading/LoadingPage';
import ImgCrop from 'antd-img-crop';
import { Upload, message, Popconfirm, Popover } from 'antd';
import { toast } from "react-toastify";
import type { UploadProps } from 'antd';
import UploadWP from '../../../../widget/uploadWithProgress';
import BtnLoader from '../../../../widget/Loading/BtnLoader';
import InputColor from 'react-input-color';
import Item from 'antd/lib/list/Item';
import CFileUpload from '../../../../widget/uploadFile';

type IListOfColorFromInput = {
  'green': any,
  'red': any,
  'blue': any,
}

function ProductEditWidget(props: any) {

  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<any>([])
  const [termOfChild, setTermOfChild] = useState<any>([])
  const [visibleColor, setVisibleColor] = useState<boolean>(false)
  const [visibleEditChildStock, setVisibleEditChildStock] = useState<boolean>(false)
  const [selectedEditChildStock, setSelectedEditChildStock] = useState<any>()
  const [visibleColorCustom, setVisibleColorCustom] = useState<boolean>(false)
  const [selectChildForColor, setSelectChildForColor] = useState<number>(-1)
  const [listColor, setListColor] = useState<any>([])
  const [listColorCustom, setListColorCustom] = useState<any>([])
  const [fileList, setFileList] = useState([]);
  const [displayBtn, setDisplayBtn] = useState<any>([])
  const [colorNameToAdd, setColorNameToAdd] = useState<string>("")
  const [isAddNewColor, setIsAddNewColor] = useState<boolean>(false)
  const [selectImageOrColor, setSelectImageOrColor] = useState<number>(0)
  const [listOfColorFromInput, setListOfColorFromInput] = useState<IListOfColorFromInput[]>([{ blue: "", green: "", red: "" }])
  const [selectedImages, setSelectedImages] = useState<{
    colorImg: { path: any, url: string },
  } | any>()

  const [stockInputKeeper, setStockInputKeeper] = useState({
    'stock': 0,
    'frame': 0,
  });

  // Function For Upload Handel


  const antdUploadProps: UploadProps = {
    name: 'image',
    listType: "picture-card",
    action: `${baseUrl}${localStorage.getItem("shopId")}/products/image`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 4,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onRemove(file) {
      // setFileListLenght(0)
    },
  };


  const onChangeSide = async (info: any, index: any, type: any) => {
    if (info.file.status === 'uploading') {
      message.info(`Wait to upload file ...`);
    }
    if (info.file.status === 'done') {
      const data = { ...props.singleProduct };
      for (let i in data.children) {
        if (i == index) {
          data.children[i][type] = {
            "path": info.file.response.data.path,
            "url": info.file.response.data.url
          }
          if (!data.children[i].isNotInServer) {
            data.children[i]["isChildStructureChanged"] = true
          }
        }
      }
      props.setSingleProduct(data)
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }

  };


  const removeImageSide = (type: string, id: number) => {
    const data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == id) {
        data.children[i][type] = {
          "path": null,
          "url": null
        };
        if (!data.children[i].isNotInServer) {
          data.children[i]["isChildStructureChanged"] = true
        }
      }
    }
    props.setSingleProduct(data)
  }

  const onChangeChildToColorInput = (index: any, e: any) => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr[index] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    }
    setListOfColorFromInput([...lastArr])
  }

  const clearData = () => {
    setVisibleColor(false)
  }

  const openModalColor = (id: any) => {
    setSelectChildForColor(id);
    setVisibleColor(true)
  }

  const openModalCustomColor = (id: any) => {
    setSelectChildForColor(id);
    setVisibleColorCustom(true)
  }

  const handelChangeStockAndFrmaeInput = (e: any, type: any) => {
    let data: any = { ...stockInputKeeper }
    data[type] = +e.target.value;
    setStockInputKeeper(data)
  }

  const handelChangeEditStockChild = (e: any, type: any) => {
    let data: any = { ...selectedEditChildStock }
    data[type] = +e.target.value;
    setSelectedEditChildStock(data)
  }

  const handelAddStockItem = (childId: number) => {
    if (stockInputKeeper?.frame == 0) return toast("Frame value is invalid", { type: "error" })
    let data: any = [...props.stockList];
    data.push({
      'uid': Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
      'index': childId,
      'stock': stockInputKeeper.stock,
      'frame': stockInputKeeper.frame,
      'isNewAdd': true,
    })
    props.setStockList(data)
  }

  const handelRemoveStockItem = (uid: number) => {
    let data: any = [...props.stockList];
    for (let i in data) {
      if (data[i].uid == uid) {
        data.splice(i, 1)
      }
    }
    props.setStockList(data)
  }

  const addChild = () => {
    const data = { ...props.singleProduct };
    data.children.push({
      'color': null,
      'color_id': 0,
      'stock': 0,
      'price': 0,
      'variant_id': null,
      'discount': 0,
      'description': null,
      'suggestion_price': 0,
      'min_price': 0,
      'images': [],
      'gltf_dir': null,
      "top": {
        "path": null,
        "url": null
      },
      "left": {
        "path": null,
        "url": null
      },
      "right": {
        "path": null,
        "url": null,
      },
      'lens_color': null,
      'lens_type': null,
      'color_code': null,
      'can_edit': true,
      'isNotInServer': true,
      'stock_type': null
    })
    props.setSingleProduct(data)
  }


  const removeChild = (id: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (i == id) {
        data.children.splice(+i, 1)
      }
    }
    props.setSingleProduct(data)
  }



  const selectedColor = (color: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == selectChildForColor) {
        data.children[i].color = color.image;
        data.children[i].color_id = color.id;
        if (!data.children[i].isNotInServer) {
          data.children[i].isChildStructureChanged = true;
        }
      }
    }
    props.setSingleProduct(data)
    setVisibleColor(false)
    setVisibleColorCustom(false)
  }

  const changeInputChild = (type: string, index: any, value: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        if (type === "discount") {
          if (value <= 100) data.children[i][type] = value;
        } else data.children[i][type] = value;
        if (!data.children[i].isNotInServer) {
          data.children[i]["isChildStructureChanged"] = true;
        }
        if (type === "price") {
          if (props.SelectedCat == 1) {
            if (props.brandSetting?.MSPS_x) data.children[i].min_price = (value * props.brandSetting.MSPS_x).toFixed(2);
            if (props.brandSetting?.RSPS_x) data.children[i].suggestion_price = (value * props.brandSetting.RSPS_x).toFixed(2);
          } else if (props.SelectedCat == 2) {
            if (props.brandSetting?.MSPE_x) data.children[i].min_price = (value * props.brandSetting.MSPE_x).toFixed(2);
            if (props.brandSetting?.RSPE_x) data.children[i].suggestion_price = (value * props.brandSetting.RSPE_x).toFixed(2);
          }
        }
      }
    }
    props.setSingleProduct(data)
    setVisibleColor(false)
    props.handelAddItem(type, value, 'children')
  }



  const getColors = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown` }, { method: "GET", token: true })
    const reqCustom = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown?own=true` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListColor(req.data)
      setListColorCustom(reqCustom.data)
    }
  }

  const confirmDeleteColor = async (item: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/${item.id}` }, { method: "DELETE", token: true })
    if (req.status == true) {
      setDisplayPage(false)
      getColors()
    } else setDisplayPage(false)
  };

  const onFinishAddColor = async () => {

    if (selectImageOrColor == 0) return toast("select image or color ", { type: "warning" })
    if (colorNameToAdd.length === 0) return toast("insert name for color", { type: "warning" })
    if (selectImageOrColor == 1 && !selectedImages?.colorImg?.path) return toast("upload an image ", { type: "warning" })
    if (selectImageOrColor == 2 && !listOfColorFromInput.length) return toast("select an color ", { type: "warning" })
    setDisplayPage(true)
    let postData: any = {
      'name': colorNameToAdd,
    }
    if (selectImageOrColor == 1) postData['image'] = selectedImages?.colorImg.path;
    if (selectImageOrColor == 2) postData['hexes'] = listOfColorFromInput;

    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status) {
      setColorNameToAdd("")
      setSelectedImages(null)
      getColors()
      toast(req.message, { type: "success" })
    }
    else {
      toast(req.message, { type: "error" })
    }
  }

  const onChangeImage = async (response: any, name: string) => {
    let data: any = { ...selectedImages };
    data[name] = response;
    setSelectedImages(data)
  };

  useEffect(() => {
    setTermOfChild([...props.singleProduct.children])
  }, [props.forceKeyToUpdateTerm])

  useEffect(() => {
    getColors()
  }, [])

  const onChangeGalleryImage = async (response: any, name: string, index: any) => {

    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        var fArr = [...data.children[i]?.images]
        fArr.push(response)
        data.children[i]['images'] = [...fArr]
        if (!data.children[i].isNotInServer) {
          data.children[i]['isChildStructureChanged'] = true
        }
      }
    }
    props.setSingleProduct(data)
  }

  function removeServerImg(itemIndex: any, imgIndex: any) {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == itemIndex) {
        for (var x in data.children[i].images) {
          if (+x == imgIndex)
            data.children[i].images.splice(x, 1)
        }
        if (!data.children[i].isNotInServer) {
          data.children[i]['isChildStructureChanged'] = true
        }
      }
    }
    props.setSingleProduct(data)
  }

  const onChangeColor = (e: any) => {
    let data: any = { ...selectedImages };
    data['hex'] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    };
    setSelectedImages(data)
  }

  const onChangeUpload = async (response: any, name: string, index: any) => {
    if (!response) return
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        if (name === "gltf_dir") {
          data.children[i][name] = response
          if (!data.children[i].isNotInServer) {
            data.children[i]["isChildStructureChanged"] = true
            data.children[i]["new_gltf"] = true
          }
        }
        else {
          data.children[i][name].push(response.path)
        }
      }
    }
    props.setSingleProduct(data)
  };

  const onRemoveModel = async (name: string, index: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        data.children[i][name] = null
      }
    }
    props.setSingleProduct(data)
  };

  const deleteColorfromInput = (item: any) => {
    let lastArr = [...listOfColorFromInput]
    if (lastArr.length === 1) return
    let findIndex = lastArr.findIndex(e => item.green == e.green && item.red == e.red)
    lastArr.splice(findIndex, 1)
    setListOfColorFromInput([...lastArr])
  }

  const deleteChildren = async (params: any, stockList: any, thisStockList: any) => {
    let stockListArr = [...stockList]
    let FindIndexStock = stockListArr.findIndex((e: any) => e.variant_id == params.variant_id)
    if (thisStockList.length <= 1) return toast(" This child must be have one stock at least", { type: "warning" })
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/${params.variant_id}` }, { method: "DELETE", token: true })
    if (req.status == true) {
      setDisplayPage(false)
      stockListArr.splice(FindIndexStock, 1)
      props.setStockList([...stockListArr])
      // props.updateData()
    } else {
      toast(req.message, { type: "error" })
      setDisplayPage(false)
    }
  }

  const deleteMultiChildren = async (stockList: any) => {
    // if (stockList.length <= 1) return toast(" This child must be have one stock at least", { type: "warning" })
    var variantsIdList = []
    for (var t in stockList) {
      variantsIdList.push(stockList[t].variant_id)
    }
    const variants = variantsIdList
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/remove_multi` }, { method: "POST", body: JSON.stringify({ variants: variants }), token: true })
    if (req.status == true) {
      setDisplayPage(false)
      props.updateData()
    } else {
      toast(req.message, { type: "error" })
      setDisplayPage(false)
    }
  }


  const onFinishAddChildren = async (index: any, children: any, childStock: any) => {
    if (!children.isNotInServer) {
      if (children.isChildStructureChanged) return toast("You should update child and then save new stock", { type: "warning" })
    }
    if (children['lens_type'] == "") return toast(" select lens type is required", { type: "warning" })
    if (stockInputKeeper?.frame == 0) return toast("Frame value is invalid", { type: "warning" })
    if (!children.right?.path) return toast(" select image angle view is required", { type: "warning" })
    children["stock"] = stockInputKeeper.stock
    children["frame"] = stockInputKeeper?.frame
    children["right"] = children.right.path
    if (children.left?.path) children["left"] = children.left.path
    else delete children.left
    if (children.top?.path) children["top"] = children.top.path
    else delete children.top

    if (children.ar_front?.path) children["ar_front"] = children.ar_front.path
    else delete children.ar_front
    if (children.ar_left?.path) children["ar_left"] = children.ar_left.path
    else delete children.ar_left
    if (children.ar_right?.path) children["ar_right"] = children.ar_right.path
    else delete children.ar_right

    var imgsList = []
    for (var t in children.images) {
      imgsList.push(children.images[t].path)
    }
    children["images"] = imgsList
    // children["top"] = children.top.path
    const postBody = {
      "product_id": props.singleProduct.id,
      ...children
    }
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}` }, { method: "POST", body: JSON.stringify(postBody), token: true })
    if (req.status) {
      await props.updateData()
      setTermOfChild([...props.singleProduct.children])
    } else {
      await props.updateData()
      toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
  }

  const addNewChildToColorInput = () => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr.push({
      'green': "",
      'red': "",
      'blue': "",
    })
    setListOfColorFromInput([...lastArr])
  }

  const returnDefPrice: string | any = (item: any, type: any) => {
    if (props.SelectedCat == 1 && props.brandSetting?.[`${type}S_x`]) {
      return props.brandSetting?.[`${type}S_x`] * item.price
    } else if (props.SelectedCat == 2 && props.brandSetting?.[`${type}E_x`]) {
      return props.brandSetting?.[`${type}E_x`] * item.price
    }
  }

  const cancelUpdateInformation = (item: any) => {
    let lastTerm: any = [...termOfChild]
    let childData = { ...props.singleProduct };
    var LTI = lastTerm.findIndex((e: any) => e.frame_sizes[0].variant_id == item.frame_sizes[0].variant_id)
    var CDI = childData.children.findIndex((e: any) => e.frame_sizes[0].variant_id == item.frame_sizes[0].variant_id)
    if (childData.children[CDI]['new_gltf'] == true) {
      delete childData.children[CDI]['gltf_dir']
    }
    childData.children[CDI] = { ...lastTerm[LTI], isChildStructureChanged: false }
    props.setSingleProduct({ ...childData })
  }

  const updateMulti = async (children: any, stockList: any) => {

    if (!children.isChildStructureChanged) return toast("isChildStructureChanged state has problem", { type: "error" })
    if (!children.right?.path) return toast(" select image angle view is required", { type: "warning" })

    children["right"] = children.right.path
    if (children?.left && children.left.path != null) children["left"] = children.left.path
    else delete children.left
    if (children?.top && children.top.path != null) children["top"] = children.top.path
    else delete children.top
    var imgsList = []
    for (var t in children.images) {
      imgsList.push(children.images[t].path)
    }
    var variantsIdList = []
    for (var t in stockList) {
      variantsIdList.push(stockList[t].variant_id)
    }
    children["images"] = imgsList
    children["variants_id"] = [...variantsIdList]
    children["ar_front"] = children.ar_front?.path
    children["ar_right"] = children.ar_right?.path
    children["ar_left"] = children.ar_left?.path
    const postBody = children
    console.log(children);

    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/update_multi` }, { method: "POST", body: JSON.stringify(postBody), token: true })
    if (req.status) {
      await props.updateData()
    } else {
      toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
  }

  const openChildStockEdit = (item: any) => {
    setSelectedEditChildStock(item)
    setVisibleEditChildStock(true)
  }

  const onFinishEditChildStock = async () => {
    const payload = {
      "stock": selectedEditChildStock.stock
    }
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/${selectedEditChildStock.variant_id}` }, { method: "POST", body: JSON.stringify(payload), token: true })
    if (req.status) {
      await props.updateData()
      setVisibleEditChildStock(false)
    } else {
      toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
  }

  return (
    <>
      {displayPage && <LoadingPage />}
      <h4 className=" text-glasses-text-50 mt-8"> Color , image , price and stock </h4>
      {props.singleProduct.children && props.singleProduct.children.map((item: any, index: any) =>
        <div key={index} className={` w-full flex flex-col border border-dashed relative ${item.isNotInServer ? " border-red-300" : " border-gray-300"}  rounded mt-6 p-2 pt-1 `}>
          {item.isNotInServer &&
            <div className=' flex justify-center items-center bg-red-900 text-white px-3 rounded-lg absolute top-[-10px] left-3'>
              Is not save in sever yet
            </div>
          }
          {!item.can_edit &&
            <div className=' rounded-xl bg-red-100 p-3 py-1 text-sm m-1 my-4'>
              The product cannot be edited as some retailers have already purchased it.
            </div>
          }
          {item.can_edit &&
            <Popconfirm
              placement="top" title={`Are you sure to delete ?`}
              onConfirm={() => {
                if (item.isNotInServer) {
                  removeChild(index)
                } else {
                  deleteMultiChildren(props.stockList.filter((e: any) => e.index == index).map((value: any, key: number) => value))
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              <div className=" w-[30px] h-[30px] absolute top-[-20px] right-[-25px] rounded-full bg-gray-300 flex justify-center items-center text-white text-sm cursor-pointer mr-[10px]">
                x
              </div>
            </Popconfirm>
          }

          <div className=" w-full mt-5 flex flex-wrap">


            <div className=" w-full px-1 my-2">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Stock Type</span>
                <select value={item.stock_type} defaultValue={item.stock_type} onChange={(e: any) => changeInputChild('stock_type', index, e.target.value)} name={'stock_type'} className=" w-full h-[40px] rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                  <option value={''} selected disabled hidden>select stock type</option>
                  <option value={"in_stock"}>In Stock</option>
                  <option value={"out_stock"}>Out Of Stock</option>
                  <option value={"by_stock"}>By Stock</option>
                </select>
              </div>
            </div>

            <div className=" w-6/12 px-1 mb-2 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50 flex  items-center  ">Frame Color {item.color && <span className=" text-glasses-text-50 text-[10px] ml-2"><img src={item.color} className="w-[15px] h-[15px] rounded-full" /></span>}</span>
                <div className=' flex w-full items-center justify-center'>
                  <div onClick={() => {
                    item.can_edit && openModalCustomColor(index)
                  }} className=" w-full flex-col h-[40px] text-xs  bg-white shadow rounded  mt-1 flex items-center justify-center text-gray-400 cursor-pointer ">
                    Custom
                  </div>
                  <div onClick={() => {
                    item.can_edit && openModalColor(index)
                  }} className=" w-full flex-col h-[40px] text-xs  bg-white shadow rounded  mt-1 flex items-center justify-center text-gray-400 cursor-pointer ">
                    Standard
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 mb-3 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Price</span>
                <div className=" w-full h-[40px] mt-1">
                  <input value={item.price} onChange={(e) => { changeInputChild('price', index, e.target.value) }} type="number" placeholder={'Price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 mb-3 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Discount</span>
                <div className=" w-full h-[40px] mt-1">
                  <input required value={item.discount} onChange={(e) => { changeInputChild('discount', index, e.target.value) }} max={100} type="number" placeholder={'discount'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 mb-3 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Suggested retail price</span>
                <div className=" w-full h-[40px] mt-1">
                  <input
                    defaultValue={returnDefPrice(item, "RSP")}
                    value={item.suggestion_price}
                    onChange={(e) => { changeInputChild('suggestion_price', index, +e.target.value) }}
                    type="number" placeholder={returnDefPrice(item, "RSP")}
                    className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none"
                  />
                </div>
              </div>
            </div>
            <div className=" w-6/12 px-1 mb-3 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Minimum selling price</span>
                <div className=" w-full h-[40px] mt-1">
                  <input value={item.min_price} onChange={(e) => { changeInputChild('min_price', index, +e.target.value) }} type=" number" placeholder={'Minimum selling price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-6/12 mb-3 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Color code</span>
                <input maxLength={8} disabled={!item.can_edit} value={item.color_code} defaultValue={item.color_code} onChange={(e: any) => changeInputChild('color_code', index, e.target.value)} placeholder='Max 8 character' name={'color_code'} type="text" className=" w-full h-[40px] rounded text-center bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
              </div>
            </div>

            <div className=" w-full flex flex-col mt-5">
              <span className=" text-glasses-text-50 text-sm pl-4">Description</span>
              <textarea disabled={!item.can_edit}
                onChange={(e: any) => changeInputChild('description', index, e.target.value)}
                name={'description'} placeholder="Insert description"
                className=" w-full h-[80px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50"
                required defaultValue={item.description} value={item.description}
              />
            </div>


            <div className=" w-full flex flex-col mt-5">
              <span className=" text-glasses-text-50 text-sm pl-4">Meta Title</span>
              <input disabled={!item.can_edit}
                onChange={(e: any) => changeInputChild('meta_title', index, e.target.value)}
                name={'meta_title'} placeholder="Insert meta title"
                className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50"
                required defaultValue={item.meta_title} value={item.meta_title}
              />
            </div>

            <div className=" w-full flex flex-col mt-5">
              <span className=" text-glasses-text-50 text-sm pl-4">Meta Description</span>
              <textarea disabled={!item.can_edit}
                onChange={(e: any) => changeInputChild('meta_description', index, e.target.value)}
                name={'meta_description'} placeholder="Insert meta description"
                className=" w-full h-[80px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50"
                required defaultValue={item.meta_description} value={item.meta_description}
              />
            </div>


            {/* ------------------ */}
            {props?.childrenDropDown &&
              <>
                <div className=" w-full px-1 py-2 mt-2">
                  <div className=" w-full flex flex-col items-center">
                    <span className=" text-glasses-text-50">Lens Color</span>
                    <select disabled={!item.can_edit} title={item.lens_color} value={item.lens_color} defaultValue={item.lens_color} onChange={(e: any) => changeInputChild('lens_color', index, e.target.value)} name={'lens_color'} className=" w-full h-[40px] rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                      <option value={item.lens_color} selected disabled hidden>{item.lens_color ? item.lens_color : "select lens color"}</option>
                      {props.childrenDropDown?.lens_color.map((itemCat: any, index: any) =>
                        <option key={index} value={itemCat}>{itemCat}</option>
                      )}
                      <option key={-1} value={-1}>+ Add custom item</option>
                    </select>
                  </div>
                </div>

                <div className=" w-full px-1 py-2 mt-2">
                  <div className=" w-full flex flex-col items-center">
                    <span className=" text-glasses-text-50">Lens Type </span>
                    <select disabled={!item.can_edit} title={item.lens_type} value={item.lens_type} defaultValue={item.lens_type} onChange={(e: any) => changeInputChild('lens_type', index, e.target.value)} name={'lens_type'} className=" w-full h-[40px] rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                      <option value={item.lens_type} selected disabled hidden>{item.lens_type ? item.lens_type : "select lens type"}</option>
                      {props.childrenDropDown?.lens_type.map((itemCat: any, index: any) =>
                        <option key={index} value={itemCat}>{itemCat}</option>
                      )}
                    </select>
                  </div>
                </div>
              </>
            }


          </div>

          <div className=" w-full  flex mt-3 items-center">

            {['top', 'right', 'left'].map((itemCover: any, indexCover: any) =>
              <div className=" w-4/12 px-1 mb-3 ">
                <div className=" w-full relative overflow-hidden">
                  {item[itemCover]?.url == null ?
                    < ImgCrop aspect={2 / 1} >
                      <Upload
                        className=" w-full flex"
                        accept=".png , .jpg , .jpeg , .pdf "
                        onChange={(e) => onChangeSide(e, index, itemCover)}
                        onRemove={() => { }}
                        {...antdUploadProps}
                      >

                        {item[itemCover]?.url == null &&
                          <div className=" w-full bg-white shadow rounded h-[100px] p-[5px] flex items-center justify-center text-gray-400 cursor-pointe">
                            <div className=" w-full h-full border border-dashed flex flex-col justify-center items-center">
                              <h3 className="text-gray-500">
                                {itemCover == 'top' && 'left view'}
                                {itemCover == 'left' && 'Side view'}
                                {itemCover == 'right' && 'Angle view'}
                              </h3>
                              <svg x="0px" y="0px" viewBox="0 0 218.166 218.166" className={` w-6 h-6  fill-gray-500 transition-all `}>
                                <use xlinkHref="/assets/svg/photo.svg#photo" />
                              </svg>
                            </div>
                          </div>

                        }
                      </Upload>
                    </ImgCrop>
                    :
                    <div className=" overflow-hidden relative">
                      {item.can_edit &&
                        <div onClick={() => removeImageSide(itemCover, index)} className=" w-[20px] h-[20px] top-2 left-2 bg-rose-500 rounded-full text-white flex justify-center items-center cursor-pointer absolute">x</div>
                      }
                      <img className=" rounded" src={item[itemCover].url} alt="cover" />
                    </div>
                  }
                </div>
              </div>
            )}
          </div>

          <div className=" w-full flex flex-col mt-5 ">
            {item.images?.length > 0 &&
              <>
                <span className=" text-glasses-text-50 text-sm pl-4 mb-2">Gallery Images</span>
                <div className=' flex w-full items-center  border border-glasses-head-50 border-dashed p-3 flex-wrap'>
                  {item.images.map((imgesI: any, imagesIndex: number) =>
                    <div key={imgesI.url} className=' flex relative '>
                      <img className=' w-[110px] mr-2 mb-2 ' src={imgesI.url} />
                      {item.can_edit &&
                        <span onClick={() => { removeServerImg(index, imagesIndex) }} className=' bg-red-500 rounded-lg absolute top-2 h-7 w-7 flex justify-center items-center right-3 text-white'>
                          <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-5 h-5 fill-white transition-all cursor-pointer   `}>
                            <use xlinkHref="/assets/svg/trash.svg#trash" />
                          </svg>
                        </span>
                      }
                    </div>
                  )}
                </div>
              </>
            }
            {item.can_edit &&
              <>
                <span className=" text-glasses-text-50 text-sm pl-4 mt-4 mb-2">Upload More Gallery Images</span>
                <div className=' flex w-full items-center  border border-glasses-head-50 border-dashed p-3 flex-wrap'>
                  <UploadWP showCart={false} customText='Upload Images' imageRatio={2} currentIMG={""} setResponse={(res: any) => onChangeGalleryImage(res, "images", index)}
                    url={`${localStorage.getItem("shopId")}/products/image`} fileCount={20} />
                </div>
              </>
            }
          </div>


          <div className=" w-full flex flex-col mt-5 ">
            <span className=" text-glasses-text-50 text-sm pl-4">Upload 3D Models</span>
            {item.gltf_dir &&
              <div className=' w-full flex px-2 py-2 text-xs pl-4 items-center  '>
                Current model: <span className=' bg-blue-200 rounded-lg p-1 ml-2'>{item.gltf_dir}</span>
              </div>
            }
            <div className=' flex w-full items-center  border-dashed py-3 flex-wrap'>
              <CFileUpload
                showUploadButtonDuringUpload
                keyPayload='zip'
                listType='picture'
                customText=' Click to upload models'
                url={`${localStorage.getItem("shopId")}/products/zip`}
                fileCount={1}
                setResponse={(res: any) => onChangeUpload(res, "gltf_dir", index)}
                onRemove={() => onRemoveModel("model", index)}
              />
            </div>
          </div>

          <div className=" w-full  flex mt-3 items-center">
            {['ar_front', 'ar_right', 'ar_left'].map((itemCover: any, indexCover: any) =>
              <div className=" w-4/12 px-1 mb-3 ">
                <div className=" w-full relative overflow-hidden">

                  {item[itemCover]?.path == null ?
                    <Upload
                      className=" w-full flex"
                      accept=".png , .jpg , .jpeg , .pdf "
                      onChange={(e) => onChangeSide(e, index, itemCover)}
                      onRemove={() => { }}
                      {...antdUploadProps}
                    >
                      {item[itemCover]?.path == null &&
                        <div className=" w-full bg-white shadow rounded h-[100px] p-[5px] flex items-center justify-center text-gray-400 cursor-pointe">
                          <div className=" w-full h-full border border-dashed flex flex-col justify-center items-center">
                            <h3 className="text-gray-500">
                              {itemCover == 'ar_front' && 'Front AR View'}
                              {itemCover == 'ar_right' && 'Right AR View'}
                              {itemCover == 'ar_left' && 'Left AR View'}
                            </h3>
                            <svg x="0px" y="0px" viewBox="0 0 218.166 218.166" className={` w-6 h-6  fill-gray-500 transition-all `}>
                              <use xlinkHref="/assets/svg/photo.svg#photo" />
                            </svg>
                          </div>
                        </div>

                      }
                    </Upload>
                    :
                    <div className=" overflow-hidden relative">
                      {item.can_edit &&
                        <div onClick={() => removeImageSide(itemCover, index)} className=" w-[20px] h-[20px] top-2 left-2 bg-rose-500 rounded-full text-white flex justify-center items-center cursor-pointer absolute">x</div>
                      }
                      <img className=" rounded" src={item[itemCover].url} alt="cover" />
                    </div>
                  }
                </div>
              </div>
            )}
          </div>

          {!item.isNotInServer &&
            <>
              <div onClick={() => updateMulti(item, props.stockList.filter((e: any) => e.index == index).map((value: any, key: number) => value))}
                className={` ${!item.isChildStructureChanged ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-glasses-head-50 cursor-pointer "} transition-all duration-500
             text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                Update information
              </div>
              <div onClick={() => cancelUpdateInformation(item)}
                className={` mb-4 ${!item.isChildStructureChanged ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-red-400 cursor-pointer "} transition-all duration-500
             text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                Cancel and fallback
              </div>
            </>
          }


          <div className=" w-full  mt-2 flex">
            <div className=" w-5/12 pr-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Stock</span>
                <div className=" w-full h-[40px] ">
                  <input type="number" onChange={(e: any) => handelChangeStockAndFrmaeInput(e, 'stock')} value={stockInputKeeper.stock} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-5/12 pl-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Frame Size</span>
                <div className=" w-full h-[40px] ">
                  <input type="number" onChange={(e: any) => handelChangeStockAndFrmaeInput(e, 'frame')} value={stockInputKeeper.frame} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-2/12 pl-1 flex justify-end items-center ">
              <div onClick={() => onFinishAddChildren(index, item, props.stockList.filter((e: any) => e.index == index))}
                className={` w-full  ${item.isChildStructureChanged ? " bg-gray-300 cursor-not-allowed " : " bg-glasses-head-50 cursor-pointer"} h-[40px] text-white flex justify-center items-center rounded mt-[22px]`}
              >
                Save
              </div>
            </div>
          </div>

          <div className=" w-full  mt-2 flex flex-col">
            {props.stockList.map((value: any, key: number) =>
              <>
                {value.index == index &&
                  <div key={key} className='w-full h-[35px] px-3 rounded-lg border border-dashed border-glasses-text-50 flex items-center justify-between mb-1'>
                    <div className='flex items-center'>
                      <span className=' w-32' >Stock: {value.stock}</span>
                      <span>Frame Size: {value.frame}</span>
                    </div>
                    <div className=' flex justify-center items-center'>

                      {/* {item.can_edit && */}
                      <svg onClick={() => openChildStockEdit(value)} x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-glasses-text-50  cursor-pointer  mr-4`}>
                        <use xlinkHref="/assets/svg/edit.svg#edit" />
                      </svg>
                      {/* } */}
                      {value.can_edit &&
                        <Popconfirm placement="top" title={`Are you sure to delete ?`} onConfirm={() => deleteChildren(value, props.stockList, props.stockList.filter((e: any) => e.index == index))} okText="Yes" cancelText="No">
                          <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-glasses-text-50  cursor-pointer  `}>
                            <use xlinkHref="/assets/svg/trash.svg#trash" />
                          </svg>
                        </Popconfirm>
                      }
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </div>
      )}
      <div onClick={addChild} className="w-full h-[40px] border border-dashed border-glasses-text-50 rounded mt-4 flex justify-center items-center cursor-pointer">
        <span className=" text-glasses-text-50 " >+ Add new color , image , price and stock </span>
      </div>

      <CModal onScap={clearData} visible={visibleColorCustom} setVisible={setVisibleColorCustom} title="AddColor" uId='AddColorCustom'>
        <div className=" w-full flex flex-wrap">
          <div className='  max-h-[40vh] overflow-auto flex w-full flex-wrap '>
            {listColorCustom.map((item: any, index: any) =>
              <div key={index} className=" w-6/12 p-1">
                <div className=" w-full h-[40px] border rounded flex justify-between overflow-hidden items-center px-2 cursor-pointer">
                  <div className=' flex items-center justify-between w-full ' onClick={() => selectedColor(item)}>
                    <span className="">{item.name}</span>
                    <img src={`${item.image}`} className=" w-[25px] h-[25px] rounded-full" alt="color" />
                  </div>
                  <Popconfirm placement="top" title={`Are you sure to delete ${item.name} ?`} onConfirm={() => confirmDeleteColor(item)} okText="Yes" cancelText="No">
                    <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-6 h-6 ml-3 fill-glasses-head-50 transition-all cursor-pointer   `}>
                      <use xlinkHref="/assets/svg/trash.svg#trash" />
                    </svg>
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>
          {visibleColorCustom &&
            <>
              <div className=' w-full flex items-center justify-between pb-2 '>
                <span className=' mt-4  '>Add New One </span>
                <div className='  mt-4 p-1 rounded-md bg-blue-100 flex justify-center items-center  drop-shadow-xl  '>
                  <span onClick={() => setIsAddNewColor(!isAddNewColor)} className=' cursor-pointer text-xs rounded-xl text-gray-700'><img className={` w-4 ${isAddNewColor ? " rotate-[90deg]" : " rotate-[-90deg]"} `} src='/assets/svg/arrow-left.svg' /></span>
                </div>
              </div>
              <div className=' w-full mb-4 border-b ' />
              <div className={` w-full duration-700 transition-all bg-gray-100 rounded-xl p-2 flex flex-col items-center justify-center 
              ${isAddNewColor ? " max-h-[480px] opacity-100 visible " : " max-h-0 opacity-0 invisible "}
              `}>
                <input value={colorNameToAdd} onChange={(e) => { setColorNameToAdd(e.target.value); }} type="text" className=" w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Insert Color Name"} required />
                <select defaultValue='' onChange={(e: any) => { setSelectImageOrColor(e.target.value) }} className=' w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none' required>
                  <option value='' disabled >Select image or color</option>
                  <option value={1}>Select image</option>
                  <option value={2}>Select color</option>
                </select>
                {selectImageOrColor == 1 &&
                  <div className='flex flex-col mb-[30px] items-center justify-center'>
                    <span className='mb-1 mt-2'>Select image</span>
                    <UploadWP listType='text' customText='Upload Color Img' imageRatio={1} currentIMG={selectedImages?.colorImg?.url} setResponse={(res: any) => onChangeImage(res, "colorImg")}
                      url={`${localStorage.getItem("shopId")}/colors/image`} fileCount={1} />
                  </div>
                }
                {selectImageOrColor == 2 &&
                  <div className='flex flex-col mb-[30px] w-full '>
                    <span className=' mb-1 mt-2'>Select list of color</span>
                    <div className=' flex flex-wrap items-center '>
                      {listOfColorFromInput.map((item, index: number) =>
                        <div key={index} className=' flex items-center mr-3 relative mt-2 '>
                          <span className=' p-1 rounded text-xs mr-1 border '>{index + 1}</span>
                          <InputColor initialValue="#fff" onChange={(e: any) => onChangeChildToColorInput(index, e)} placement="right" />
                          {listOfColorFromInput.length !== 1 &&
                            <svg onClick={() => deleteColorfromInput(item)} x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 ml-1 fill-glasses-head-50 transition-all cursor-pointer   `}>
                              <use xlinkHref="/assets/svg/trash.svg#trash" />
                            </svg>
                          }
                        </div>
                      )}
                      <div onClick={addNewChildToColorInput}
                        className=' w-[50px] h-[25px] rounded mt-2 flex justify-center items-center border border-dashed border-[#201e1e] cursor-pointer   '>
                        +
                      </div>
                    </div>
                  </div>
                }
                {displayBtn == false ?
                  <div onClick={() => onFinishAddColor()} className=" w-full h-10 bg-glasses-head-50 text-white rounded cursor-pointer flex justify-center items-center" >Add </div>
                  :
                  <div className='  flex justify-center items-center' ><BtnLoader /></div>
                }
              </div>
            </>
          }
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleColor} setVisible={setVisibleColor} title="AddColor" uId='AddColor'>
        <div className=" max-h-[40vh] overflow-auto flex w-full flex-wrap">
          {listColor.map((item: any, index: any) =>
            <div key={index} onClick={() => selectedColor(item)} className=" w-6/12 p-1">
              <div className=" w-full h-[40px] border rounded flex justify-between overflow-hidden items-center px-2 cursor-pointer">
                <span className="">{item.name}</span>
                <img src={`${item.image}`} className=" w-[25px] h-[25px] rounded-full" alt="color" />
              </div>
            </div>
          )}
        </div>
      </CModal>

      <CModal onScap={() => { }} visible={visibleEditChildStock} setVisible={setVisibleEditChildStock} title="Edit stock" uId='Edit_stock'>
        {selectedEditChildStock &&
          <div className=" w-full  mt-2 flex">
            <div className=" w-5/12 pr-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Stock</span>
                <div className=" w-full h-[40px] ">
                  <input type="number" onChange={(e: any) => handelChangeEditStockChild(e, 'stock')} value={selectedEditChildStock.stock} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-5/12 pl-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Frame Size</span>
                <div className=" w-full h-[40px] ">
                  <input disabled type="number" onChange={(e: any) => handelChangeEditStockChild(e, 'frame')} value={selectedEditChildStock.frame} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-2/12 pl-1 flex justify-end items-center ">
              <div onClick={() => onFinishEditChildStock()}
                className={` w-full bg-glasses-head-50 cursor-pointer h-[40px] text-white flex justify-center items-center rounded mt-[21px] shadow`}
              >
                Save
              </div>
            </div>
          </div>
        }
      </CModal>
    </>
  );
}

export default ProductEditWidget;
