import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

function ShopDetail() {
  const Ctx = useContext(ContextState);
  let params = useParams();
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [detailShop, setDetailShop] = useState<any>(null);




  const changePage = (status: any) => {
    if (status) {
      if (lastPage >= currentPage + 1) {
        getProducts(currentPage + 1)
      }
    }
    else {
      if (currentPage > 1) {
        getProducts(currentPage - 1)
      }
    }
  }

  const getShop = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}shops/${params.id}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      Ctx.setHeadTitle(req.data.name)
      setDetailShop(req.data)
    }
  }

  const getProducts = async (current: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}shops/${params.id}/products` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListData(req.data.data)
      setCurrentPage(req.data.meta.current_page)
      setLastPage(req.data.meta.last_page)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("Shops")
    getProducts(currentPage)
    getShop()
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full  flex flex-col   ">
        {detailShop != null &&
          <div className=' w-full   my-[20px] flex flex-wrap items-center'>
            <div className=' w-full md:w-4/12   relative flex justify-center'>
              <img src={detailShop.cover} alt="cover" className=' w-full rounded-md shadow' />
              <img src={detailShop.logo} alt="logo" className=' w-[70px] h-[70px]  rounded-full absolute bottom-[-35px] ' />
            </div>
            <div className=' w-full md:w-8/12 flex flex-col md:pl-[10px] pl-0 '>
              <div className=' w-full flex flex-col bg-white  rounded-lg shadow p-[10px] mt-[50px] md:mt-[0] '>
                <div className='flex items-center mb-[10px]'>
                  <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                    <svg x="0px" y="0px" viewBox="0 0 489.4 489.4" className={` w-5 h-5 fill-gray-500 transition-all `}>
                      <use xlinkHref="/assets/svg/shop.svg#shop" />
                    </svg>
                  </button>
                  <span className='ml-[10px] text-glasses-text-50 font-bold'>{detailShop.name}</span>

                </div>
                <div className='flex items-center mb-[10px]'>
                  <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                    <svg x="0px" y="0px" viewBox="0 0 19 32" className={` w-5 h-5 fill-gray-500  `}>
                      <use xlinkHref="/assets/svg/phone.svg#phone" />
                    </svg>
                  </button>
                  <span className='ml-[10px] text-glasses-text-50 font-bold'>{detailShop.address.length > 0 && detailShop.address[0].mobile}</span>
                </div>
                <div className='flex items-center mb-[10px]'>
                  <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                    <svg x="0px" y="0px" viewBox="0 0 368.666 368.666" className={`  w-5 h-5 fill-gray-500   `}>
                      <use xlinkHref="/assets/svg/location2.svg#XMLID_2_" />
                    </svg>
                  </button>
                  <span className='ml-[10px] text-glasses-text-50 font-bold'>{detailShop.address.length > 0 && detailShop.address[0].address}</span>
                </div>
                <div className='flex items-center mb-[10px]'>
                  <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                    <svg x="0px" y="0px" viewBox="0 0 490.1 490.1" className={`w-5 h-5 fill-gray-500 `}>
                      <use xlinkHref="/assets/svg/user.svg#user" />
                    </svg>
                  </button>
                  <span className='ml-[10px] text-glasses-text-50 font-bold'>{detailShop.address.length > 0 && detailShop.address[0].name}</span>
                </div>

              </div>
            </div>
          </div>
        }
        <div className=' w-full h-[50px] mt-[30px] md:mt-[70px] border-b flex items-center pl-[10px]'><h3 className=' text-xl'>Products</h3></div>
        <div className=' w-full py-[20px]   flex flex-wrap'>
          {listData.map((item: any, index: any) =>
            <div className=' lg:w-3/12 sm:w-4/12 w-full p-[10px]  flex flex-col items-center relative cursor-pointer overflow-hidden'>
              <div className=' w-full  bg-white rounded-lg shadow overflow-hidden'>
                <img src={item.children[0].view.top} alt=" product" className=' w-full rounded-lg' />
                <div className=' w-full bg-white flex flex-col items-center justify-center p-[10px]' >
                  <span className=' text-glasses-text-50 text-xl'>{item.title}</span>
                  <Link to={`/panel/shop/product/${item.slug}`}>
                    <button className='mt-[10px] h-[40px] border rounded-md border-[#ffc804] text-[#ffc804] px-[10px]'>View Detail</button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className=' w-full  flex items-center justify-center'>
          {currentPage != 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(false)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
              </svg>
            </div>
          }
          <div className="table-counter-main-item">{currentPage} </div>
          {lastPage >= currentPage + 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(true)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
              </svg>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default ShopDetail;
