import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import CModal from "../../../../widget/CModal/CModal";
import { customApi, baseUrl } from "../../../../widget/apis";

export declare type ModalSendOrdersProps = {
    visible: boolean,
    setVisible: (text: boolean) => void;
    detailData: any,
    setDetailData: (text: any) => void;
    setDisplayPage: (text: boolean) => void;
    viewDetail: () => void;
}

const ModalSendOrders = ({ setDetailData, viewDetail, detailData, setVisible, visible, setDisplayPage }: ModalSendOrdersProps) => {
    const inputRef: any = useRef();
    const [totalQuantity, setTotalQuantity] = useState<number>(0)

    const sendOrder = async () => {
        var newPost: any[] = []
        for (var i in detailData.products) {
            newPost.push({ count: detailData.products[i].count, variant_id: detailData.products[i].variant_id, status: detailData.products[i].status })
        }
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/${detailData.id}` }, { method: "PUT", token: true, body: JSON.stringify({ 'variants': newPost }) })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            viewDetail()
            setVisible(false)
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const handelOnChangeCount = (value: any, index: number) => {
        if (value < 0) return
        let data = { ...detailData };
        for (let i in data.products) {
            if (+i == index) {
                data['products'][i]['count'] = value;
                data['products'][i]['total'] = data['products'][i]['price'] * data['products'][i]['count'];
            }
        }
        setDetailData(data)
        handelCalculateTotal()
        handelQuantity()
    }

    const handelOnChangeStatus = (value: any, index: number) => {
        let data = { ...detailData };
        for (let i in data.products) {
            if (+i == index) {
                data['products'][i]['status'] = value;
            }
        }
        setDetailData(data)
        handelCalculateTotal()
        handelQuantity()
    }

    const handelCalculateTotal = () => {
        let data = { ...detailData };
        let total = 0;
        for (let i of data.products) {
            if (i.status == 'Confirm') {
                total = total + i.total;
            }
        }
        data['total_payment'] = total;
        setDetailData(data)
    }

    const handelQuantity = () => {
        let quantity = 0;
        for (let i of detailData.products) {
            if (i.status == 'Confirm') {
                quantity = +quantity + +i.count;
            }
        }
        setTotalQuantity(quantity)
    }

    useEffect(() => {
        if (detailData) {
            let data = { ...detailData };
            for (let i of data.products) {
                if (!i['status']) {
                    i['status'] = 'Confirm';
                }
                i['total'] = i.count * i.price;
            }
            setDetailData(data)
            handelQuantity()
        }
        handelCalculateTotal()
    }, [detailData.products])

    return (
        <>
            <CModal visible={visible} setVisible={setVisible} uId="Detail-order" radius={'30px'} >
                <>
                    <div className=" px-3">
                        <div className=" w-full  p-3 flex items-center justify-end">
                            <div onClick={() => { setVisible(false) }} className="  text-gray-500 rounded cursor-pointer flex items-center justify-center ">X</div>
                        </div>
                        {detailData.products?.map((item: any, index: number) =>
                            <div ref={inputRef} key={index} className={`  border-l-0 w-full p-3 flex items-center justify-between bg-gray-50 border-b duration-300 transition-all`}>
                                <span>{index + 1}</span>
                                <div className="flex items-center">
                                    <img src={item.image} alt="image" className=" w-[80px] rounded" />
                                    <div className="border flex flex-col justify-center items-center text-center px-2 ml-2 rounded-xl text-[11px] bg-white">
                                        <small className="">Frame Model:</small>
                                        <>{item.glass_name}</>
                                    </div>
                                    <div className=" flex ml-2 justify-center items-center" >
                                        {item.color_image ?
                                            <img className="w-4 h-4 bg-red-500 rounded-full" src={item.color_image} />
                                            :
                                            <div className="w-[30px] flex flex-col items-center justify-center">
                                                <span className="text-[10px] ">sph</span>
                                                <span>{item.sph}</span>
                                            </div>
                                        }
                                        <span className=" ml-1 text-xs">x</span>
                                        <input onChange={(e: any) => handelOnChangeCount(e.target.value, index)} defaultValue={item.count} type={"number"} min='0' className=" w-[60px] mx-1 rounded-xl border text-center text-xs p-1 " />
                                    </div>
                                </div>
                                <div className=" flex justify-center items-center flex-col">
                                    {(item.discount && item.discount != 0) ?
                                        <>
                                            <s>{item.price}<span className=" text-[10px] ml-1">AED </span></s>
                                            <span className=" text-lg">{(+item.price - (+item.price * +item.discount / 100))}<span className=" text-[10px] ml-1">AED </span></span>
                                        </>
                                        :
                                        <>
                                            <span className=" text-lg">{item.price}<span className=" text-[10px] ml-1">AED </span></span>
                                        </>
                                    }

                                </div>
                                <div className=" h-10 border-r" />
                                <div className=" flex justify-center items-center">
                                    <select defaultValue={item.status} onChange={(e: any) => handelOnChangeStatus(e.target.value, index)} className=" text-xs bg-[#ddd] h-[30px] px-1 rounded-xl focus:outline-none">
                                        {['Confirm', 'Cancel', 'Pending'].map((item) => <option className=' ' value={item}>{item}</option>)}
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className=" w-full flex  ">
                            <div className="flex flex-col w-4/12">
                                <div className=" w-full flex flex-col  p-3">
                                    <span className=" text-gray-600" > Subtotal :</span>
                                    <span className="text-lg flex">AED {detailData.total_payment}</span>
                                </div>
                                {detailData.discount_code ?
                                    <div className=" w-full flex flex-col  p-3">
                                        <span className=" text-gray-600" >Discount :</span>
                                        <span className="text-lg flex">AED {(detailData.total_payment * (detailData.discount_code.discount / 100)).toFixed(2)}</span>
                                    </div>
                                    : ""
                                }
                                <div className=" w-full flex flex-col  p-3">
                                    <span className=" text-gray-600" >Vat :</span>
                                    <span className="text-lg flex">AED {detailData.discount_code ?
                                        ((detailData.total_payment - (detailData.total_payment * (detailData.discount_code.discount / 100))) * 0.05).toFixed(2)
                                        :
                                        (detailData.total_payment * 0.05).toFixed(2)}</span>
                                </div>
                                <div className=" w-full flex flex-col  p-3">
                                    <span className=" text-gray-600" >Total :</span>
                                    <span className="text-lg flex">AED {detailData.discount_code ?
                                        ((detailData.total_payment - (detailData.total_payment * (detailData.discount_code.discount / 100))) + ((detailData.total_payment - (detailData.total_payment * (detailData.discount_code.discount / 100))) * 0.05)).toFixed(2)
                                        :
                                        (detailData.total_payment + (detailData.total_payment * 0.05)).toFixed(2)}
                                    </span>
                                </div>
                            </div>
                            <div className="  flex flex-col  w-4/12 p-3">
                                <span className=" text-gray-600">Total quantity :</span>
                                <span className="text-lg">{totalQuantity}</span>
                            </div>
                        </div>
                        <div className=" w-full flex items-center">
                            <button onClick={sendOrder} className=" w-[120px] h-10 m-3 mt-[60px] bg-gr-300 flex items-center justify-center rounded-[30px] text-white">
                                <span>Send</span>
                            </button>
                            <button onClick={() => { setVisible(false) }} className=" w-[120px] h-10 m-3 mt-[60px] border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                <span>Cancel</span>
                            </button>
                        </div>

                    </div>
                </>
            </CModal>
        </>
    )
}

export default ModalSendOrders;