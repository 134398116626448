import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../../../widget/context/ContextMain'
import CModal from '../../../../../widget/CModal/CModal';
import { customApi, baseUrl } from '../../../../../widget/apis';
import LoadingPage from '../../../../../widget/Loading/LoadingPage';
import ImgCrop from 'antd-img-crop';
import { Upload, message, Popconfirm, Popover } from 'antd';
import { toast } from "react-toastify";
import type { UploadProps } from 'antd';
import UploadWP from '../../../../../widget/uploadWithProgress';
import BtnLoader from '../../../../../widget/Loading/BtnLoader';
import InputColor from 'react-input-color';
import CFileUpload from '../../../../../widget/uploadFile';
import GenerateCustomVariantsEditComp from './GenerateCustomVariantsEdit';

type IListOfColorFromInput = {
  'green': any,
  'red': any,
  'blue': any,
}

function ProductEditContactLensWidget(props: any) {

  const [displayPage, setDisplayPage] = useState<any>([])
  const [termOfChild, setTermOfChild] = useState<any>([])
  const [visibleColor, setVisibleColor] = useState<boolean>(false)
  const [visibleEditChildStock, setVisibleEditChildStock] = useState<boolean>(false)
  const [selectedEditChildStock, setSelectedEditChildStock] = useState<any>()
  const [visibleColorCustom, setVisibleColorCustom] = useState<boolean>(false)
  const [selectChildForColor, setSelectChildForColor] = useState<number>(-1)
  const [listColor, setListColor] = useState<any>([])
  const [listColorCustom, setListColorCustom] = useState<any>([])
  const [fileList, setFileList] = useState([]);
  const [displayBtn, setDisplayBtn] = useState<any>([])
  const [colorNameToAdd, setColorNameToAdd] = useState<string>("")
  const [isAddNewColor, setIsAddNewColor] = useState<boolean>(false)
  const [selectImageOrColor, setSelectImageOrColor] = useState<number>(0)
  const [listOfColorFromInput, setListOfColorFromInput] = useState<IListOfColorFromInput[]>([{ blue: "", green: "", red: "" }])
  const [selectedImages, setSelectedImages] = useState<{
    colorImg: { path: any, url: string },
  } | any>()

  const [stockInputKeeper, setStockInputKeeper] = useState({
    'stock': 0,
    'frame': 0,
  });

  // Function For Upload Handel


  const antdUploadProps: UploadProps = {
    name: 'image',
    listType: "picture-card",
    action: `${baseUrl}${localStorage.getItem("shopId")}/products/image`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 4,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onRemove(file) {
      // setFileListLenght(0)
    },
  };


  const onChangeSide = async (info: any, index: any, type: any) => {
    if (info.file.status === 'uploading') {
      message.info(`Wait to upload file ...`);
    }
    if (info.file.status === 'done') {
      const data = { ...props.singleProduct };
      for (let i in data.children) {
        if (i == index) {
          data.children[i][type] = {
            "path": info.file.response.data.path,
            "url": info.file.response.data.url
          }
          if (!data.children[i].isNotInServer) {
            data.children[i]["isChildStructureChanged"] = true
          }
        }
      }
      props.setSingleProduct(data)
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }

  };


  const removeImageSide = (type: string, id: number) => {
    const data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == id) {
        data.children[i][type] = {
          "path": null,
          "url": null
        };
        if (!data.children[i].isNotInServer) {
          data.children[i]["isChildStructureChanged"] = true
        }
      }
    }
    props.setSingleProduct(data)
  }

  const onChangeChildToColorInput = (index: any, e: any) => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr[index] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    }
    setListOfColorFromInput([...lastArr])
  }

  const clearData = () => {
    setVisibleColor(false)
  }

  const openModalColor = (id: any) => {
    setSelectChildForColor(id);
    setVisibleColor(true)
  }

  const openModalCustomColor = (id: any) => {
    setSelectChildForColor(id);
    setVisibleColorCustom(true)
  }

  const handelChangeStockAndFrmaeInput = (e: any, type: any) => {
    let data: any = { ...stockInputKeeper }
    data[type] = +e.target.value;
    setStockInputKeeper(data)
  }

  const handelChangeEditStockChild = (e: any, type: any) => {
    let data: any = { ...selectedEditChildStock }
    data[type] = +e.target.value;
    setSelectedEditChildStock(data)
  }

  const handelAddStockItem = (childId: number) => {
    if (stockInputKeeper?.frame == 0) return toast("Frame value is invalid", { type: "error" })
    let data: any = [...props.stockList];
    data.push({
      'uid': Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
      'index': childId,
      'stock': stockInputKeeper.stock,
      'frame': stockInputKeeper.frame,
      'isNewAdd': true,
    })
    props.setStockList(data)
  }

  const handelRemoveStockItem = (uid: number) => {
    let data: any = [...props.stockList];
    for (let i in data) {
      if (data[i].uid == uid) {
        data.splice(i, 1)
      }
    }
    props.setStockList(data)
  }

  const addChild = () => {
    const data = { ...props.singleProduct };
    data.children.push({
      'color': null,
      'color_id': 0,
      'stock': 0,
      'price': 0,
      'variant_id': null,
      'discount': 0,
      'description': null,
      'suggestion_price': 0,
      'min_price': 0,
      'images': [],
      'gltf_dir': null,
      "top": {
        "path": null,
        "url": null
      },
      "left": {
        "path": null,
        "url": null
      },
      "right": {
        "path": null,
        "url": null,
      },
      'lens_color': null,
      'lens_type': null,
      'color_code': null,
      'can_edit': true,
      'isNotInServer': true
    })
    props.setSingleProduct(data)
  }


  const removeChild = (id: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (i == id) {
        data.children.splice(+i, 1)
      }
    }
    props.setSingleProduct(data)
  }



  const selectedColor = (color: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == selectChildForColor) {
        data.children[i].color = color.image;
        data.children[i].color_id = color.id;
        if (!data.children[i].isNotInServer) {
          data.children[i].isChildStructureChanged = true;
        }
      }
    }
    props.setSingleProduct(data)
    setVisibleColor(false)
    setVisibleColorCustom(false)
  }

  const changeInputChild = (type: string, index: any, value: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        if (type === "discount") {
          if (value <= 100) data.children[i][type] = value;
        } else data.children[i][type] = value;
        if (!data.children[i].isNotInServer) {
          data.children[i]["isChildStructureChanged"] = true;
        }
        if (type === "price") {
          if (props.SelectedCat == 1) {
            if (props.brandSetting?.MSPS_x) data.children[i].min_price = (value * props.brandSetting.MSPS_x).toFixed(2);
            if (props.brandSetting?.RSPS_x) data.children[i].suggestion_price = (value * props.brandSetting.RSPS_x).toFixed(2);
          } else if (props.SelectedCat == 2) {
            if (props.brandSetting?.MSPE_x) data.children[i].min_price = (value * props.brandSetting.MSPE_x).toFixed(2);
            if (props.brandSetting?.RSPE_x) data.children[i].suggestion_price = (value * props.brandSetting.RSPE_x).toFixed(2);
          }
        }
      }
    }
    props.setSingleProduct(data)
    setVisibleColor(false)
    props.handelAddItem(type, value, 'children')
  }



  const getColors = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown` }, { method: "GET", token: true })
    const reqCustom = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown?own=true` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListColor(req.data)
      setListColorCustom(reqCustom.data)
    }
  }

  const confirmDeleteColor = async (item: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/${item.id}` }, { method: "DELETE", token: true })
    if (req.status == true) {
      setDisplayPage(false)
      getColors()
    } else setDisplayPage(false)
  };

  const onFinishAddColor = async () => {

    if (selectImageOrColor == 0) return toast("select image or color ", { type: "warning" })
    if (colorNameToAdd.length === 0) return toast("insert name for color", { type: "warning" })
    if (selectImageOrColor == 1 && !selectedImages?.colorImg?.path) return toast("upload an image ", { type: "warning" })
    if (selectImageOrColor == 2 && !listOfColorFromInput.length) return toast("select an color ", { type: "warning" })
    setDisplayPage(true)
    let postData: any = {
      'name': colorNameToAdd,
    }
    if (selectImageOrColor == 1) postData['image'] = selectedImages?.colorImg.path;
    if (selectImageOrColor == 2) postData['hexes'] = listOfColorFromInput;

    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status) {
      setColorNameToAdd("")
      setSelectedImages(null)
      getColors()
      toast(req.message, { type: "success" })
    }
    else {
      toast(req.message, { type: "error" })
    }
  }

  const onChangeImage = async (response: any, name: string) => {
    let data: any = { ...selectedImages };
    data[name] = response;
    setSelectedImages(data)
  };

  useEffect(() => {
    setTermOfChild([...props.singleProduct.children])
  }, [props.forceKeyToUpdateTerm])

  useEffect(() => {
    getColors()
  }, [])

  const onChangeGalleryImage = async (response: any, name: string, index: any) => {

    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        var fArr = [...data.children[i]?.images]
        fArr.push(response)
        data.children[i]['images'] = [...fArr]
        if (!data.children[i].isNotInServer) {
          data.children[i]['isChildStructureChanged'] = true
        }
      }
    }
    props.setSingleProduct(data)
  }

  function removeServerImg(itemIndex: any, imgIndex: any) {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == itemIndex) {
        for (var x in data.children[i].images) {
          if (+x == imgIndex)
            data.children[i].images.splice(x, 1)
        }
        if (!data.children[i].isNotInServer) {
          data.children[i]['isChildStructureChanged'] = true
        }
      }
    }
    props.setSingleProduct(data)
  }

  const onChangeColor = (e: any) => {
    let data: any = { ...selectedImages };
    data['hex'] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    };
    setSelectedImages(data)
  }

  const onChangeUpload = async (response: any, name: string, index: any) => {
    if (!response) return
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        if (name === "gltf_dir") data.children[i][name] = response
        else data.children[i][name].push(response.path)
      }
    }
    props.setSingleProduct(data)
  };

  const onRemoveModel = async (name: string, index: any) => {
    let data = { ...props.singleProduct };
    for (let i in data.children) {
      if (+i == index) {
        data.children[i][name] = null
      }
    }
    props.setSingleProduct(data)
  };

  const deleteColorfromInput = (item: any) => {
    let lastArr = [...listOfColorFromInput]
    if (lastArr.length === 1) return
    let findIndex = lastArr.findIndex(e => item.green == e.green && item.red == e.red)
    lastArr.splice(findIndex, 1)
    setListOfColorFromInput([...lastArr])
  }

  const deleteChildren = async (params: any, stockList: any, thisStockList: any) => {
    let stockListArr = [...stockList]
    let FindIndexStock = stockListArr.findIndex((e: any) => e.variant_id == params.variant_id)
    if (thisStockList.length <= 1) return toast(" This child must be have one stock at least", { type: "warning" })
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/${params.variant_id}` }, { method: "DELETE", token: true })
    if (req.status == true) {
      setDisplayPage(false)
      stockListArr.splice(FindIndexStock, 1)
      props.setStockList([...stockListArr])
      // props.updateData()
    } else {
      toast(req.message, { type: "error" })
      setDisplayPage(false)
    }
  }

  const deleteMultiChildren = async (stockList: any) => {
    // if (stockList.length <= 1) return toast(" This child must be have one stock at least", { type: "warning" })
    var variantsIdList = []
    for (var t in stockList) {
      variantsIdList.push(stockList[t].variant_id)
    }
    const variants = variantsIdList
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/remove_multi` }, { method: "POST", body: JSON.stringify({ variants: variants }), token: true })
    if (req.status == true) {
      setDisplayPage(false)
      props.updateData()
    } else {
      toast(req.message, { type: "error" })
      setDisplayPage(false)
    }
  }


  const onFinishAddChildren = async (index: any, children: any, childStock: any) => {
    if (!children.isNotInServer) {
      if (children.isChildStructureChanged) return toast("You should update child and then save new stock", { type: "warning" })
    }
    if (stockInputKeeper?.frame == 0) return toast("Frame value is invalid", { type: "warning" })
    if (!children.right?.path) return toast(" select image angle view is required", { type: "warning" })
    children["stock"] = stockInputKeeper.stock
    children["frame"] = stockInputKeeper?.frame
    children["right"] = children.right.path
    if (children.left) children["left"] = children.left.path
    if (children.top) children["top"] = children.top.path
    var imgsList = []
    for (var t in children.images) {
      imgsList.push(children.images[t].path)
    }
    children["images"] = imgsList
    // children["top"] = children.top.path
    const postBody = {
      "product_id": props.singleProduct.id,
      ...children
    }
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}` }, { method: "POST", body: JSON.stringify(postBody), token: true })
    if (req.status) {
      await props.updateData()
      setTermOfChild([...props.singleProduct.children])
    } else {
      toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
  }

  const addNewChildToColorInput = () => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr.push({
      'green': "",
      'red': "",
      'blue': "",
    })
    setListOfColorFromInput([...lastArr])
  }

  const returnDefPrice: string | any = (item: any, type: any) => {
    if (props.SelectedCat == 1 && props.brandSetting?.[`${type}S_x`]) {
      return props.brandSetting?.[`${type}S_x`] * item.price
    } else if (props.SelectedCat == 2 && props.brandSetting?.[`${type}E_x`]) {
      return props.brandSetting?.[`${type}E_x`] * item.price
    }
  }

  const cancelUpdateInformation = (item: any) => {
    let lastTerm: any = [...termOfChild]
    let childData = { ...props.singleProduct };
    var LTI = lastTerm.findIndex((e: any) => e.frame_sizes[0].variant_id == item.frame_sizes[0].variant_id)
    var CDI = childData.children.findIndex((e: any) => e.frame_sizes[0].variant_id == item.frame_sizes[0].variant_id)
    childData.children[CDI] = { ...lastTerm[LTI], isChildStructureChanged: false }
    props.setSingleProduct({ ...childData })
  }

  const updateMulti = async (children: any, stockList: any) => {

    if (!children.isChildStructureChanged) return toast("isChildStructureChanged state has problem", { type: "error" })
    if (!children.right?.path) return toast(" select image angle view is required", { type: "warning" })

    children["right"] = children.right.path
    if (children?.left && children.left.path != null) children["left"] = children.left.path
    else delete children.left
    if (children?.top && children.top.path != null) children["top"] = children.top.path
    else delete children.top
    var imgsList = []
    for (var t in children.images) {
      imgsList.push(children.images[t].path)
    }
    var variantsIdList = []
    for (var t in stockList) {
      variantsIdList.push(stockList[t].variant_id)
    }
    children["images"] = imgsList
    children["variants_id"] = [...variantsIdList]
    const postBody = children
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/update_multi` }, { method: "POST", body: JSON.stringify(postBody), token: true })
    if (req.status) {
      await props.updateData()
    } else {
      toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
  }

  const openChildStockEdit = (item: any) => {
    setSelectedEditChildStock(item)
    setVisibleEditChildStock(true)
  }

  const onFinishEditChildStock = async () => {
    const payload = {
      "stock": selectedEditChildStock.stock
    }
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${props.currentCategory}/${selectedEditChildStock.variant_id}` }, { method: "POST", body: JSON.stringify(payload), token: true })
    if (req.status) {
      await props.updateData()
      setVisibleEditChildStock(false)
    } else {
      toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
  }

  return (
    <>
      {displayPage && <LoadingPage />}
      <h4 className=" text-glasses-text-50 text-[25px] mt-8">Variants Mangement (Sph, price, stock ...) </h4>
      <GenerateCustomVariantsEditComp
        SingleProduct={props.singleProduct}
        setSingleProduct={props.setSingleProduct}
        isSelectedBoxGenerate={true}
        setIsSelectedBoxGenerate={() => { }}
        onFinishForm={props.onFinishAdd}
        setDisplayPage={setDisplayPage}
        displayPage={displayPage}
        currentCategory={props.currentCategory}
        updateData={props.updateData}
      />
      {/* {props.singleProduct.children && props.singleProduct.children.map((item: any, index: any) =>
        <div key={index} className={` w-full flex flex-col border border-dashed relative ${item.isNotInServer ? " border-red-300" : " border-gray-300"}  rounded mt-6 p-2 pt-1 `}>
          {item.isNotInServer &&
            <div className=' flex justify-center items-center bg-red-900 text-white px-3 rounded-lg absolute top-[-10px] left-3'>
              Is not save in sever yet
            </div>
          }
          {!item.can_edit &&
            <div className=' rounded-xl bg-red-100 p-3 py-1 text-sm m-1 my-4'>
              The product cannot be edited as some retailers have already purchased it.
            </div>
          }
          {item.can_edit &&
            <Popconfirm
              placement="top" title={`Are you sure to delete ?`}
              onConfirm={() => {
                if (item.isNotInServer) {
                  removeChild(index)
                } else {
                  deleteMultiChildren(props.stockList.filter((e: any) => e.index == index).map((value: any, key: number) => value))
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              <div className=" w-[30px] h-[30px] absolute top-[-20px] right-[-25px] rounded-full bg-gray-300 flex justify-center items-center text-white text-sm cursor-pointer mr-[10px]">
                x
              </div>
            </Popconfirm>
          }

          <div className=" w-full mt-5 flex flex-wrap">

            {displayPage && <LoadingPage />}

            <div className=" w-full flex flex-col mt-5">
              <span className=" text-glasses-text-50 text-sm pl-4">SPH</span>
              <textarea disabled={!item.can_edit}
                onChange={(e: any) => changeInputChild('sph', index, e.target.value)}
                name={'sph'} placeholder="Insert sph"
                className=" w-full h-[80px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50"
                required defaultValue={item.sph} value={item.sph}
              />
            </div>

          </div>


          {!item.isNotInServer &&
            <>
              <div onClick={() => updateMulti(item, props.stockList.filter((e: any) => e.index == index).map((value: any, key: number) => value))}
                className={` ${!item.isChildStructureChanged ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-glasses-head-50 cursor-pointer "} transition-all duration-500
             text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                Update information
              </div>
              <div onClick={() => cancelUpdateInformation(item)}
                className={` mb-4 ${!item.isChildStructureChanged ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-red-400 cursor-pointer "} transition-all duration-500
             text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                Cancel and fallback
              </div>
            </>
          }


          {item.can_edit &&
            <div className=" w-full  mt-2 flex">
              <div className=" w-5/12 pr-1 ">
                <div className=" w-full flex flex-col items-center">
                  <span className=" text-glasses-text-50">Stock</span>
                  <div className=" w-full h-[40px] ">
                    <input type="number" onChange={(e: any) => handelChangeStockAndFrmaeInput(e, 'stock')} value={stockInputKeeper.stock} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                  </div>
                </div>
              </div>
              <div className=" w-5/12 pl-1 ">
                <div className=" w-full flex flex-col items-center">
                  <span className=" text-glasses-text-50">Frame Size</span>
                  <div className=" w-full h-[40px] ">
                    <input type="number" onChange={(e: any) => handelChangeStockAndFrmaeInput(e, 'frame')} value={stockInputKeeper.frame} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                  </div>
                </div>
              </div>
              <div className=" w-2/12 pl-1 flex justify-end items-center ">
                <div onClick={() => onFinishAddChildren(index, item, props.stockList.filter((e: any) => e.index == index))}
                  className={` w-full  ${item.isChildStructureChanged ? " bg-gray-300 cursor-not-allowed " : " bg-glasses-head-50 cursor-pointer"} h-[40px] text-white flex justify-center items-center rounded mt-[22px]`}
                >
                  Save
                </div>
              </div>
            </div>
          }

          <div className=" w-full  mt-2 flex flex-col">
            {props.stockList.map((value: any, key: number) =>
              <>
                {value.index == index &&
                  <div key={key} className='w-full h-[35px] px-3 rounded-lg border border-dashed border-glasses-text-50 flex items-center justify-between mb-1'>
                    <div className='flex items-center'>
                      <span className=' w-32' >Stock: {value.stock}</span>

                      <span>Frame Size: {value.frame}</span>
                    </div>
                    <div className=' flex justify-center items-center'>

                      {item.can_edit &&
                        <svg onClick={() => openChildStockEdit(value)} x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-glasses-text-50  cursor-pointer  mr-4`}>
                          <use xlinkHref="/assets/svg/edit.svg#edit" />
                        </svg>
                      }
                      {item.can_edit &&
                        <Popconfirm placement="top" title={`Are you sure to delete ?`} onConfirm={() => deleteChildren(value, props.stockList, props.stockList.filter((e: any) => e.index == index))} okText="Yes" cancelText="No">
                          <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-glasses-text-50  cursor-pointer  `}>
                            <use xlinkHref="/assets/svg/trash.svg#trash" />
                          </svg>
                        </Popconfirm>
                      }
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </div>
      )} */}
      {/* <div onClick={addChild} className="w-full h-[40px] border border-dashed border-glasses-text-50 rounded mt-4 flex justify-center items-center cursor-pointer">
        <span className=" text-glasses-text-50 " >+ Add new color , image , price and stock </span>
      </div> */}

      <CModal onScap={clearData} visible={visibleColorCustom} setVisible={setVisibleColorCustom} title="AddColor" uId='AddColorCustom'>
        <div className=" w-full flex flex-wrap">
          <div className='  max-h-[40vh] overflow-auto flex w-full flex-wrap '>
            {listColorCustom.map((item: any, index: any) =>
              <div key={index} className=" w-6/12 p-1">
                <div className=" w-full h-[40px] border rounded flex justify-between overflow-hidden items-center px-2 cursor-pointer">
                  <div className=' flex items-center justify-between w-full ' onClick={() => selectedColor(item)}>
                    <span className="">{item.name}</span>
                    <img src={`${item.image}`} className=" w-[25px] h-[25px] rounded-full" alt="color" />
                  </div>
                  <Popconfirm placement="top" title={`Are you sure to delete ${item.name} ?`} onConfirm={() => confirmDeleteColor(item)} okText="Yes" cancelText="No">
                    <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-6 h-6 ml-3 fill-glasses-head-50 transition-all cursor-pointer   `}>
                      <use xlinkHref="/assets/svg/trash.svg#trash" />
                    </svg>
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>
          {visibleColorCustom &&
            <>
              <div className=' w-full flex items-center justify-between pb-2 '>
                <span className=' mt-4  '>Add New One </span>
                <div className='  mt-4 p-1 rounded-md bg-blue-100 flex justify-center items-center  drop-shadow-xl  '>
                  <span onClick={() => setIsAddNewColor(!isAddNewColor)} className=' cursor-pointer text-xs rounded-xl text-gray-700'><img className={` w-4 ${isAddNewColor ? " rotate-[90deg]" : " rotate-[-90deg]"} `} src='/assets/svg/arrow-left.svg' /></span>
                </div>
              </div>
              <div className=' w-full mb-4 border-b ' />
              <div className={` w-full duration-700 transition-all bg-gray-100 rounded-xl p-2 flex flex-col items-center justify-center 
              ${isAddNewColor ? " max-h-[480px] opacity-100 visible " : " max-h-0 opacity-0 invisible "}
              `}>
                <input value={colorNameToAdd} onChange={(e) => { setColorNameToAdd(e.target.value); }} type="text" className=" w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Insert Color Name"} required />
                <select defaultValue='' onChange={(e: any) => { setSelectImageOrColor(e.target.value) }} className=' w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none' required>
                  <option value='' disabled >Select image or color</option>
                  <option value={1}>Select image</option>
                  <option value={2}>Select color</option>
                </select>
                {selectImageOrColor == 1 &&
                  <div className='flex flex-col mb-[30px] items-center justify-center'>
                    <span className='mb-1 mt-2'>Select image</span>
                    <UploadWP listType='text' customText='Upload Color Img' imageRatio={1} currentIMG={selectedImages?.colorImg?.url} setResponse={(res: any) => onChangeImage(res, "colorImg")}
                      url={`${localStorage.getItem("shopId")}/colors/image`} fileCount={1} />
                  </div>
                }
                {selectImageOrColor == 2 &&
                  <div className='flex flex-col mb-[30px] w-full '>
                    <span className=' mb-1 mt-2'>Select list of color</span>
                    <div className=' flex flex-wrap items-center '>
                      {listOfColorFromInput.map((item, index: number) =>
                        <div key={index} className=' flex items-center mr-3 relative mt-2 '>
                          <span className=' p-1 rounded text-xs mr-1 border '>{index + 1}</span>
                          <InputColor initialValue="#fff" onChange={(e: any) => onChangeChildToColorInput(index, e)} placement="right" />
                          {listOfColorFromInput.length !== 1 &&
                            <svg onClick={() => deleteColorfromInput(item)} x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 ml-1 fill-glasses-head-50 transition-all cursor-pointer   `}>
                              <use xlinkHref="/assets/svg/trash.svg#trash" />
                            </svg>
                          }
                        </div>
                      )}
                      <div onClick={addNewChildToColorInput}
                        className=' w-[50px] h-[25px] rounded mt-2 flex justify-center items-center border border-dashed border-[#201e1e] cursor-pointer   '>
                        +
                      </div>
                    </div>
                  </div>
                }
                {displayBtn == false ?
                  <div onClick={() => onFinishAddColor()} className=" w-full h-10 bg-glasses-head-50 text-white rounded cursor-pointer flex justify-center items-center" >Add </div>
                  :
                  <div className='  flex justify-center items-center' ><BtnLoader /></div>
                }
              </div>
            </>
          }
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleColor} setVisible={setVisibleColor} title="AddColor" uId='AddColor'>
        <div className=" max-h-[40vh] overflow-auto flex w-full flex-wrap">
          {listColor.map((item: any, index: any) =>
            <div key={index} onClick={() => selectedColor(item)} className=" w-6/12 p-1">
              <div className=" w-full h-[40px] border rounded flex justify-between overflow-hidden items-center px-2 cursor-pointer">
                <span className="">{item.name}</span>
                <img src={`${item.image}`} className=" w-[25px] h-[25px] rounded-full" alt="color" />
              </div>
            </div>
          )}
        </div>
      </CModal>

      <CModal onScap={() => { }} visible={visibleEditChildStock} setVisible={setVisibleEditChildStock} title="Edit stock" uId='Edit_stock'>
        {selectedEditChildStock &&
          <div className=" w-full  mt-2 flex">
            <div className=" w-5/12 pr-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Stock</span>
                <div className=" w-full h-[40px] ">
                  <input type="number" onChange={(e: any) => handelChangeEditStockChild(e, 'stock')} value={selectedEditChildStock.stock} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-5/12 pl-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Frame Size</span>
                <div className=" w-full h-[40px] ">
                  <input disabled type="number" onChange={(e: any) => handelChangeEditStockChild(e, 'frame')} value={selectedEditChildStock.frame} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-2/12 pl-1 flex justify-end items-center ">
              <div onClick={() => onFinishEditChildStock()}
                className={` w-full bg-glasses-head-50 cursor-pointer h-[40px] text-white flex justify-center items-center rounded mt-[21px] shadow`}
              >
                Save
              </div>
            </div>
          </div>
        }
      </CModal>
    </>
  );
}

export default ProductEditContactLensWidget;
