import { Select } from 'antd';
import React from 'react';
import CModal from '../../widget/CModal/CModal';
import { checkisBrand } from '../../widget/layout/Nav';

interface IFilterInventoryModalProps {
    setIsFilterModal: CallableFunction
    isFilterModal: boolean
    filterInModal: CallableFunction | any
    setSelectedBrand: CallableFunction
    listBrand: any
    setSelectedCat: CallableFunction
    listCategory: any
    isUseFilterStatus: boolean,
    setIsUseFilterStatus: CallableFunction
}

const FilterInventoryModal: React.FunctionComponent<IFilterInventoryModalProps> = ({ isFilterModal, setIsFilterModal, filterInModal, setSelectedBrand, listBrand, listCategory, setSelectedCat,isUseFilterStatus, setIsUseFilterStatus }) => {

    return (
        <>
            <CModal title='filter options' setVisible={setIsFilterModal} visible={isFilterModal} uId='filterProductModal' >
                <>
                    <form id="filterProductFormIN" name='filterMOdal' onSubmit={filterInModal} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>

                        <div className=" w-10/12 flex flex-col my-5">
                            <span className=" text-glasses-text-50 text-sm">Product Name</span>
                            <input type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                        </div>

                        <div className=" w-10/12 flex flex-col my-5">
                            <span className=" text-glasses-text-50 text-sm">Color Code</span>
                            <input type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                        </div>

                        {!checkisBrand() &&
                            <div className={` w-10/12 flex flex-col my-5 add-product-as-shop-form ${localStorage.getItem('role') == '2' && 'opacity-0 invisible w-0 h-0 overflow-hidden my-0'}`}>
                                <span className=" text-glasses-text-50 text-sm mb-1">Brand</span>
                                <Select
                                    className=' w-full rounded'
                                    showSearch
                                    allowClear
                                    placeholder="Select a brand"
                                    optionFilterProp="children"
                                    onChange={(value) => { setSelectedBrand(value) }}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {listBrand.map((item: any, index: any) =>
                                        <Select.Option key={index} value={item.key}>{item.value}</Select.Option>
                                    )}
                                </Select>
                            </div>
                        }


                        <div className=" w-10/12 flex flex-col my-5 add-product-as-shop-form">
                            <span className=" text-glasses-text-50 text-sm mb-1">Category</span>
                            <Select
                                className=' w-full rounded'
                                showSearch
                                allowClear
                                placeholder="Select a category"
                                optionFilterProp="children"
                                onChange={(value) => { setSelectedCat(value) }}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {listCategory.map((item: any, index: any) =>
                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className=" w-10/12 flex my-5 ">
                            <div onClick={() => setIsUseFilterStatus(!isUseFilterStatus)}
                                 className=" border border-glasbg-glasses-head-50 w-[15px] h-[15px] flex justify-center cursor-pointer items-center mr-2 rounded-sm">
                                {isUseFilterStatus ? <div className=" bg-glasses-head-50 w-[10px] h-[10px] rounded-sm"/> : ""}
                            </div>
                            <span className=" text-glasses-text-50 text-sm">Use filter by status</span>
                        </div>
                        {isUseFilterStatus &&
                            <div className=" w-10/12 flex flex-col my-5">
                                <span className=" text-glasses-text-50 text-sm">Status</span>
                                <label className="table-toggle">
                                    <>
                                        <input type="checkbox" defaultChecked/>
                                        <div className="table-toggle-main"/>
                                    </>
                                </label>
                            </div>
                        }

                        <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Filter </button>
                    </form>
                </>
            </CModal>
        </>
    );
};

export default FilterInventoryModal;
