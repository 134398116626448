import React, { useEffect, useState } from 'react'

export const ContextState = React.createContext<any>({})


export default function ContextMain(props: any) {
    const [headTitle, setHeadTitle] = useState<any>('')
    const [shopLogo, setShopLogo] = useState<any>(null)
    const [isPanelReady, setIsPanelReady] = useState<boolean>(false)

    // برای باز و بسته شدن صفحه چت
    const [displayChat, setDisplayChat] = useState<boolean>(false)

    // آی دی صفحه چتی که باز است را نگه میدارد
    const [pageDetailChatIsOpen, setPageDetailChatIsOpen] = useState<any>(null)

    // زمانی که صفحه چتی باز نیست و لیست صفحه چت ها باز است و پیامی می اید با تغییر این استیت در دیپنسیس باعث میشود ریکوئست جدید به سرور زده شود و لیست چت جدید گرفته شود
    const [updateChatList, setUpdateChatList] = useState<boolean>(false)

    // لیست پیام های یک صفحه چت را نگه میدارد
    const [chatDetailListData, setChatDetailListData] = useState<any>([])

    // منو باز را نگه میدارد
    const [activeItemNav, setActiveItemNav] = useState<any>({ 'menu': -1, 'subMenu': -1 })

    // لیست اکانت های یوزر لاگین کرده
    const [accountsList, setAccountsList] = useState<any>([])


    return (
        <ContextState.Provider value={{
            headTitle,
            setHeadTitle,
            displayChat,
            setDisplayChat,
            pageDetailChatIsOpen,
            setPageDetailChatIsOpen,
            updateChatList,
            setUpdateChatList,
            chatDetailListData,
            setChatDetailListData,
            shopLogo,
            setShopLogo,
            activeItemNav,
            setActiveItemNav,
            isPanelReady,
            setIsPanelReady ,
            accountsList ,
            setAccountsList
        }}>
            {props.children}
        </ContextState.Provider>
    )
}
