import { Alert } from 'antd';
import * as React from 'react';
import CModal from '../../widget/CModal/CModal';

interface IEditLIsteInventoryModalProps {
    setVisibleStock: CallableFunction
    visibleStock: boolean
    onFinish: CallableFunction | any
    stockData: any
    setNumForEditList: CallableFunction
    numForEditList: number
    editListOfPricing: CallableFunction
    suggestedPrice: number
    minimumPrice: number
    setSuggestedPrice: CallableFunction
    setMinimumPrice: CallableFunction
}

const EditLIstInventoryModal: React.FunctionComponent<IEditLIsteInventoryModalProps> = ({ minimumPrice, suggestedPrice, setMinimumPrice, setSuggestedPrice, onFinish, setVisibleStock, stockData, visibleStock, numForEditList, setNumForEditList, editListOfPricing }) => {


    return (
        <>
            <CModal mt={50} title={`Edit Pricing `} maxWidth={"680px"} width={"95vw"} setVisible={setVisibleStock} visible={visibleStock} uId='editStockModal' >
                <>
                    {stockData.filter((e: any) => e.isSelected).length !== 0 &&
                        <form onSubmit={onFinish} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>
                            <Alert
                                className=' w-full'
                                description="Insert optional inputs and then click adjust"
                                type="info"
                                showIcon
                            />

                            <div className=" my-5 mb-5 w-full max-w-md mx-auto">
                                <div className=' flex items-center mb-4 justify-between w-full '>
                                    <div className=' flex justify-center items-center flex-wrap  w-[150px] '>
                                        <span className=' w-full flex items-center text-xs justify-center text-gray-400 mb-2 '>Insert minimum price</span>
                                        <input value={minimumPrice} onChange={(e) => { setMinimumPrice(+e.target.value) }} defaultValue={minimumPrice} type="number" className=" w-[calc(100%-70px)] text-center max-w-[150px] h-[30px] rounded-xl bg-white shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                                        <span className=' text-gray-500 ml-2 text-xs'> x Price</span>
                                    </div>
                                    <div className=' flex justify-center items-center flex-wrap  w-[150px] '>
                                        <span className=' w-full flex items-center text-xs justify-center text-gray-400 mb-2 '>Insert suggested price</span>
                                        <input value={suggestedPrice} onChange={(e) => { setSuggestedPrice(+e.target.value) }} defaultValue={suggestedPrice} type="number" className=" w-[calc(100%-70px)] text-center max-w-[150px] h-[30px] rounded-xl bg-white shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                                        <span className=' text-gray-500 ml-2 text-xs'> x Price</span>
                                    </div>
                                    <div className=' flex justify-center items-center flex-wrap  w-[120px] '>
                                        <span className=' w-full flex items-center text-xs justify-center text-gray-400 mb-2 '>Click to apply</span>
                                        <span onClick={() => editListOfPricing()} className={`  rounded w-fit px-4 py-1 flex justify-center items-center cursor-pointer bg-glasses-head-50 text-white`}> Adjust </span>
                                    </div>
                                </div>
                            </div>
                            <div className=" w-full  flex flex-col max-h-[40vh] overflow-auto">
                                {stockData.filter((e: any) => e.isSelected)?.map((item: any, index: any) =>
                                    <div key={index} className={` ${item.isSelected ? "border-l-4 border-l-green-500 " : " border-l-0 "} w-full p-3 flex items-center justify-between bg-gray-50 border-b duration-300 transition-all`}>
                                        <span>{index + 1}</span>
                                        <img className=' rounded-lg w-16 ml-3' src={item.image} alt={item.name}/>
                                        {/* <div className=' w-[180px] bg-gray-400 rounded-lg h-[40px] ml-6'/> */}
                                        <div className={`flex items-center w-fit mx-6 justify-around `}>
                                            {item.name}
                                        </div>
                                        <div className="border border-blue-300 flex flex-col justify-center text-center w-[200px] items-center px-2 ml-2 rounded-xl text-xs bg-white mr-5">
                                            <small className=" text-blue-400">Price</small>
                                           <>{item.price}</>
                                        </div>
                                        <div className="border flex flex-col justify-center text-center w-[200px] items-center px-2 ml-2 rounded-xl text-xs bg-white">
                                            <small className="">Minimum price</small>
                                            <>{item.min_price ? item.min_price : "not set"}</>
                                        </div>

                                        <div className="border flex flex-col justify-center text-center w-[200px] items-center px-2 ml-2 rounded-xl text-xs bg-white">
                                            <small className="">Suggested Price</small>
                                            <>{item.suggested_price ? item.suggested_price : "not set"}</>
                                        </div>

                                        <div className=" flex justify-center items-center flex-col">
                                            {item.suggestion_price ?
                                                <div className=" flex flex-col justify-center items-center bg-white border px-2 py-1  rounded-xl">
                                                    <span className=" text-[8px]">Suggested retail price</span>
                                                    <span className=" text-xs">{item.suggestion_price}<span className=" text-[10px] ml-1">AED</span></span>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>


                            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold "> Update </button>

                        </form>
                    }
                </>
            </CModal>

        </>
    );
};

export default EditLIstInventoryModal;
