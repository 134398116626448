import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';

function OrderBuyList() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [lastPage, setLastPage] = useState(0);


  const col = [
    {
      title: "Id",
      render: (i: any) => (<>{i.order_id != null && i.order_id}</>)
    },
    {
      title: "Shop",
      render: (i: any) => (<>{i.shop.name != null && i.shop.name}</>)
    },
    {
      title: "Status",
      render: (i: any) => (<span className=" text-glasses-head-50">{i.status != null && i.status}</span>)
    },
    {
      title: "Detail",
      render: (i: any) => (
        <Link to={`/panel/order-buy/detail/${i.id}`}>
          <div className=" h-[35px] px-3 border border-glasses-head-50 flex items-center justify-center rounded">
            <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
              <use xlinkHref="/assets/svg/eye.svg#eye" />
            </svg>
          </div>
        </Link>
      )
    },

  ]




  const changePage = (status: any) => {
    if (status) {
      if (lastPage >= currentPage + 1) {
        getData(currentPage + 1)
      }
    }
    else {
      if (currentPage > 1) {
        getData(currentPage - 1)
      }
    }
  }


  const getData = async (current: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}orders_buy?page=${current}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListData(req.data.data)
      setCurrentPage(req.data.meta.current_page)
      setLastPage(req.data.meta.last_page)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("Orders Buy List")
    getData(currentPage)
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full  flex flex-col   ">
        {/* <div className=" w-full h-[60px] flex items-center">
          <Link to="/panel/product/add">
            <button className=" px-[10px] h-[40px] bg-glasses-head-50 rounded-xl text-white"> + Add New Product</button>
          </Link>
        </div> */}
        <TablePage columns={col} data={listData}>
          {currentPage != 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(false)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
              </svg>
            </div>
          }
          <div className="table-counter-main-item">{currentPage} </div>
          {lastPage >= currentPage + 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(true)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
              </svg>
            </div>
          }
        </TablePage>
      </div>


    </>
  );
}

export default OrderBuyList;
