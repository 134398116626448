import { Alert } from 'antd';
import * as React from 'react';
import CModal from '../../widget/CModal/CModal';

interface IEditLIsteInventoryModalProps {
    setVisibleStock: CallableFunction
    visibleStock: boolean
    onFinish: CallableFunction | any
    stockData: any
    setSelectModeForEditList: CallableFunction
    setNumForEditList: CallableFunction
    numForEditList: number
    selectModeForEditList: "add" | "set"
    editListOfStock: CallableFunction
}

const EditLIstInventoryModal: React.FunctionComponent<IEditLIsteInventoryModalProps> = ({ setSelectModeForEditList, onFinish, setVisibleStock, stockData, visibleStock, numForEditList, setNumForEditList, selectModeForEditList, editListOfStock }) => {


    return (
        <>
            <CModal title={`Edit stock `} setVisible={setVisibleStock} visible={visibleStock} uId='editStockModal ' mt={50}>
                <>
                    {stockData.filter((e: any) => e.isSelected).length !== 0 &&
                        <form onSubmit={onFinish} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>
                            <Alert
                                className=' w-full'
                                description="Follow bottom step to edit selected list."
                                type="info"
                                showIcon
                            />
                            <div className=" my-5 mb-5 w-full">
                                <div className=' flex items-center mb-4 justify-between w-full '>
                                    <div className=' flex justify-center items-center flex-wrap  w-[150px] '>
                                        <span className=' w-full flex items-center text-center text-xs text-gray-400 mb-2 '><span className=' border border-glasses-head-50 text-xs w-[20px] h-[20px] flex justify-center items-center rounded-full mr-2'>1</span>select action type</span>
                                        <span onClick={() => setSelectModeForEditList("add")} className={` w-[calc(50%-1rem)]  rounded px-4 py-1 flex justify-center items-center ${selectModeForEditList === "add" ? " bg-glasses-head-50 text-white" : " bg-gray-50 text-gray-800 "} mx-2 cursor-pointer`}> Add</span>

                                        <span onClick={() => setSelectModeForEditList("set")} className={` w-[calc(50%-1rem)]  rounded px-4 py-1 flex justify-center items-center ${selectModeForEditList === "set" ? " bg-glasses-head-50 text-white" : " bg-gray-50 text-gray-800 "} mx-2 cursor-pointer`}> Set</span>
                                    </div>
                                    <div className=' flex justify-center items-center flex-wrap  w-[150px] '>
                                        <span className=' w-full flex items-center text-center text-xs text-gray-400 mb-2 '> <span className=' border border-glasses-head-50 text-xs w-[20px] h-[20px] flex justify-center items-center rounded-full mr-2'>2</span>insert quantity</span>
                                        <div onClick={() => { setNumForEditList(numForEditList + 1) }} className='  w-[20px] mx-2 rounded-xl text-lg bg-white shadow flex justify-center items-center cursor-pointer '> + </div>
                                        <input value={numForEditList} onChange={(e) => { setNumForEditList(+e.target.value) }} defaultValue={numForEditList} type="number" className=" w-[calc(100%-70px)] text-center max-w-[50px] h-[30px] rounded-xl bg-white shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                                        <div onClick={() => { setNumForEditList(numForEditList - 1) }} className='  w-[20px] mx-2 rounded-xl text-2xl bg-white shadow-xl flex justify-center items-center cursor-pointer '> - </div>
                                    </div>
                                    <div className=' flex justify-center items-center flex-wrap  w-[120px] '>
                                        <span className=' w-full flex items-center text-center text-xs text-gray-400 mb-2 '> <span className=' border border-glasses-head-50 text-xs w-[20px] h-[20px] flex justify-center items-center rounded-full mr-2'>3</span>click to apply</span>
                                        <span onClick={() => editListOfStock()} className={`  rounded w-fit px-4 py-1 flex justify-center items-center cursor-pointer bg-glasses-head-50 text-white`}> Adjust </span>
                                    </div>
                                </div>
                            </div>
                            <div className=" w-full  flex flex-col max-h-[40vh] overflow-auto ">
                                {stockData.filter((e: any) => e.isSelected)?.map((item: any, index: any) =>
                                    <div key={index} className={` ${item.isSelected ? "border-l-4 border-l-green-500 " : " border-l-0 "} w-full p-3 flex items-center justify-between bg-gray-50 border-b duration-300 transition-all`}>
                                        <span>{index + 1}</span>
                                        <img className=' rounded-lg w-16 ml-3' src={item.image} alt={item.name} />
                                        <div className={`flex items-center w-fit mx-6 justify-around `}>
                                            {item.name}
                                        </div>
                                        <div className="border flex flex-col justify-center text-center w-[100px] items-center px-2 ml-2 rounded-xl text-[11px] bg-white">
                                            <small className="">Orginal stock</small>
                                            {item.stock && <>{item.stock}</>}
                                        </div>
                                        <div className=' w-[100px]'>
                                            ----{">"}
                                        </div>
                                        <div className="border border-[#000] flex flex-col justify-center text-center w-[100px] items-center px-2 ml-2 rounded-xl text-[11px] bg-white">
                                            <small className="">Current stock</small>
                                            {item?.currentStock && <>{item?.currentStock}</>}
                                        </div>

                                        <div className=" flex justify-center items-center flex-col">
                                            {item.suggestion_price ?
                                                <div className=" flex flex-col justify-center items-center bg-white border px-2 py-1  rounded-xl">
                                                    <span className=" text-[8px]">Suggested retail price</span>
                                                    <span className=" text-xs">{item.suggestion_price}<span className=" text-[10px] ml-1">AED</span></span>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>


                            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold "> Update </button>

                        </form>
                    }
                </>
            </CModal>

        </>
    );
};

export default EditLIstInventoryModal;
