import React, { useState, useEffect, useRef } from 'react';
import { Popconfirm, Popover, TreeSelect } from 'antd';
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../../../../widget/apis';
import CModal from '../../../../../widget/CModal/CModal';
import { fallBackInformation, formInputs, updateMulti } from "./methods"
import { type } from 'os';

interface IGenerateCustomVariantsProps {
    setIsSelectedBoxGenerate: CallableFunction,
    isSelectedBoxGenerate: boolean,
    SingleProduct: any,
    setSingleProduct: CallableFunction,
    onFinishForm: CallableFunction,
    setDisplayPage: CallableFunction,
    updateData: CallableFunction,
    displayPage: boolean,
    currentCategory: any
}

type IFormData = {
    'stock': any,
    'price'?: any,
    'discount'?: any,
    'suggestion_price'?: any,
    'min_price'?: any,
    'price_zero'?: any,
    'discount_zero'?: any,
    'suggestion_price_zero'?: any,
    'min_price_zero'?: any,
    'sph'?: any,
    'isSelected': false,
    'stock_type': "",
    'stock_type_zero': "",
}

const GenerateCustomVariantsEditComp: React.FunctionComponent<IGenerateCustomVariantsProps> = ({
    isSelectedBoxGenerate,
    setIsSelectedBoxGenerate,
    SingleProduct,
    setSingleProduct,
    onFinishForm,
    displayPage,
    setDisplayPage,
    currentCategory,
    updateData
}) => {

    const positiveArr = new Array(48).fill(1).map((val, index) => ((index + 1) * 0.25))
    const negativeArr = new Array(48).fill(1).map((val, index) => (-((index + 1) * 0.25)))
    const [visibleEditChildStock, setVisibleEditChildStock] = useState<boolean>(false)
    const [selectedEditChildStock, setSelectedEditChildStock] = useState<any>()
    const [treeDataInputValue, setTreeDataInputValue] = useState<any[]>([]);
    const [isVariantTypeStatus, setIsVariantsTypeStatus] = useState<{
        "zero-power": boolean,
        "positive-power": boolean,
        "negative-power": boolean,
    }>({ "zero-power": false, "positive-power": false, "negative-power": false });

    const [isParentChanged, setIsParentChanged] = useState<{
        "zero-parent": boolean,
        "usual-parent": boolean,
    }>({ "zero-parent": false, "usual-parent": false });

    const [formData, setFormData] = useState<IFormData>({
        'stock': 0,
        'price': 0,
        'discount': 0,
        'suggestion_price': 0,
        'min_price': 0,
        'price_zero': 0,
        'discount_zero': 0,
        'suggestion_price_zero': 0,
        'min_price_zero': 0,
        'sph': [],
        'isSelected': false,
        'stock_type': "",
        'stock_type_zero': "",
    });
    const [formDataTerm, setFormDataTerm] = useState<IFormData>({
        'stock': 0,
        'price': 0,
        'discount': 0,
        'suggestion_price': 0,
        'min_price': 0,
        'price_zero': 0,
        'discount_zero': 0,
        'suggestion_price_zero': 0,
        'min_price_zero': 0,
        'sph': [],
        'isSelected': false,
        'stock_type': "",
        'stock_type_zero': "",
    });

    const { SHOW_PARENT } = TreeSelect;
    const formChildRef: any = useRef()

    const treeData: any = (): any[] => {
        const finalArr: any[] = [
            {
                title: 'Positive Powers',
                value: 'positive-power',
                key: 'positive-power',
                children: []
            },
            {
                title: 'Zero Power',
                value: 'zero-power',
                key: 'zero-power',
                children: []
            },
            {
                title: 'Negative Powers',
                value: 'negative-power',
                key: 'negative-power',
                children: []
            },
        ]
        for (var i in positiveArr) {
            finalArr[0].children?.push({
                title: positiveArr[i].toFixed(2),
                value: positiveArr[i].toFixed(2),
                sph: positiveArr[i],
                key: positiveArr[i],
                stock: 0,
                variant_id: '',
                isAvailable: false,
                can_edit: true,
            })
        }
        for (var i in negativeArr) {
            finalArr[2].children?.push({
                title: negativeArr[i].toFixed(2),
                value: negativeArr[i].toFixed(2),
                sph: negativeArr[i],
                key: negativeArr[i],
                stock: 0,
                variant_id: '',
                isAvailable: false,
                can_edit: true,
            })
        }
        finalArr[1].children?.push({
            title: "zero",
            value: 0.00,
            sph: 0,
            key: 0,
            stock: 0,
            variant_id: '',
            isAvailable: false,
            can_edit: true,
        })
        return finalArr
    }



    const checkValidationList = (items: any[]): { message: any, status: boolean } => {
        let lastForm: any = { ...formData }
        const itemsCheckRes: any = items.map((item): { message: any, status: boolean } => {
            if (item == "discount" || item == "stock" || item == "discount_zero" || item == "stock_zero") {
                if (lastForm[item] == null || lastForm[item] == undefined) return { message: `the ${item} input in variants must be inserted`, status: false }
            } else {
                if (item.includes("zero")) {
                    if (isVariantTypeStatus['zero-power']) {
                        if (lastForm[item] == 0) return { message: `the ${item} input must be more than zero`, status: false }

                    }
                } else if (lastForm[item] == 0) return { message: `the ${item} input must be more than zero`, status: false }
            }
            return { message: "", status: true }
        })
        for (var u in itemsCheckRes) {
            if (!itemsCheckRes[u].status) {
                return itemsCheckRes[u]
            }
        }
        return { message: "", status: true }
    }

    const onFinishAddItem = async (e: any) => {
        let lastSingleProduct: any = { ...SingleProduct }
        let listToCheckValidation: any[] = ['stock', 'price', 'discount', 'suggestion_price', 'min_price', 'price_zero', 'discount_zero', 'suggestion_price_zero', 'min_price_zero',]
        let lastArr = [...treeDataInputValue]
        if (!checkValidationList(listToCheckValidation).status) {
            return toast(checkValidationList(listToCheckValidation).message, { type: "warning" })
        }
        var iniArr: any = []
        for (var i in lastArr) {
            if (lastArr[i].value === "zero-power") {
                for (var t in lastArr[i].children) {
                    lastArr[i].children[t].isAvailable && iniArr.push({
                        'stock': lastArr[i].children[t].stock,
                        'price': formData?.price_zero,
                        'discount': formData?.discount_zero,
                        'suggestion_price': formData?.suggestion_price_zero,
                        'min_price': formData?.min_price_zero,
                        'stock_type': formData?.stock_type,
                        'sph': lastArr[i].children[t].value,
                        'isSelected': true
                    })
                }
            } else {
                for (var t in lastArr[i].children) {
                    lastArr[i].children[t].isAvailable && iniArr.push({
                        'stock': lastArr[i].children[t].stock,
                        'price': formData?.price,
                        'discount': formData?.discount,
                        'suggestion_price': formData?.suggestion_price,
                        'min_price': formData?.min_price,
                        'stock_type': formData?.stock_type,
                        'sph': lastArr[i].children[t].value,
                        'isSelected': true
                    })
                }
            }
        }
        lastSingleProduct["children"] = [...iniArr]
    }

    const onChangeTreeData = (e: any, item: any, inItem: any, inputType: "checkBox" | "number" | "positive-power" | "negative-power" | "zero-power") => {
        let lastArr = [...treeDataInputValue]
        let lastVariantsStatus = { ...isVariantTypeStatus }
        if (inputType === "checkBox" || inputType === "number") {
            var findIndexParent = lastArr.findIndex((e: any) => e.value === item.value)
            var findIndexChild = lastArr[findIndexParent].children.findIndex((e: any) => e.value === inItem.value)
            if (inputType === "checkBox") lastArr[findIndexParent].children[findIndexChild].isAvailable = !lastArr[findIndexParent].children[findIndexChild].isAvailable
            else if (inputType === "number") lastArr[findIndexParent].children[findIndexChild].stock = +e
        }
        else if (inputType === "positive-power") {
            lastVariantsStatus[inputType] = e
            for (var t in lastArr) {
                if (lastArr[t].value === inputType) {
                    for (var u in lastArr[t].children) {
                        lastArr[t].children[u].isAvailable = e
                    }
                }
            }
        }
        else if (inputType === "negative-power") {
            lastVariantsStatus[inputType] = e
            for (var t in lastArr) {
                if (lastArr[t].value === inputType) {
                    for (var u in lastArr[t].children) {
                        lastArr[t].children[u].isAvailable = e
                    }
                }
            }
        }
        else if (inputType === "zero-power") {
            lastVariantsStatus[inputType] = e
            for (var t in lastArr) {
                if (lastArr[t].value === inputType) {
                    for (var u in lastArr[t].children) {
                        lastArr[t].children[u].isAvailable = e
                    }
                }
            }
        }
        setTreeDataInputValue([...lastArr])
        setIsVariantsTypeStatus({ ...lastVariantsStatus })
    }

    const takeSingleProductData = (singleProductIni: any) => {
        let data: any = { ...formData };
        let lastVariantsStatus = { ...isVariantTypeStatus }
        let iniEmptyData = [...treeData()]
        let iniSingleProductData = [...singleProductIni.children]
        for (let q in iniSingleProductData) {
            if (+iniSingleProductData[q].sph > 0) {
                for (let w in iniEmptyData[0].children) {
                    if (iniEmptyData[0].children[w].sph == +iniSingleProductData[q].sph) {
                        iniEmptyData[0].children[w].isAvailable = true
                        iniEmptyData[0].children[w].variant_id = +iniSingleProductData[q].variant_id
                        iniEmptyData[0].children[w].can_edit = iniSingleProductData[q].can_edit
                        iniEmptyData[0].children[w].stock = +iniSingleProductData[q].stock
                    }
                }
                data["discount"] = +iniSingleProductData[q].discount;
                data["price"] = +iniSingleProductData[q].price;
                data["min_price"] = +iniSingleProductData[q].min_price;
                data["stock_type"] = iniSingleProductData[q].stock_type;
                data["suggestion_price"] = +iniSingleProductData[q].suggestion_price;
                lastVariantsStatus['positive-power'] = true
            } else if (+iniSingleProductData[q].sph < 0) {
                for (let w in iniEmptyData[2].children) {
                    if (iniEmptyData[2].children[w].sph == +iniSingleProductData[q].sph) {
                        iniEmptyData[2].children[w].isAvailable = true
                        iniEmptyData[2].children[w].variant_id = +iniSingleProductData[q].variant_id
                        iniEmptyData[2].children[w].can_edit = iniSingleProductData[q].can_edit
                        iniEmptyData[2].children[w].stock = +iniSingleProductData[q].stock
                    }
                }
                lastVariantsStatus['negative-power'] = true
            } else if (+iniSingleProductData[q].sph === 0) {
                for (let w in iniEmptyData[1].children) {
                    if (iniEmptyData[1].children[w].sph == +iniSingleProductData[q].sph) {
                        iniEmptyData[1].children[w].isAvailable = true
                        iniEmptyData[1].children[w].variant_id = +iniSingleProductData[q].variant_id
                        iniEmptyData[1].children[w].can_edit = iniSingleProductData[q].can_edit
                        iniEmptyData[1].children[w].stock = +iniSingleProductData[q].stock
                    }
                    data["discount_zero"] = +iniSingleProductData[q].discount;
                    data["price_zero"] = +iniSingleProductData[q].price;
                    data["min_price_zero"] = +iniSingleProductData[q].min_price;
                    data["stock_type_zero"] = iniSingleProductData[q].stock_type;
                    data["suggestion_price_zero"] = +iniSingleProductData[q].suggestion_price;
                }
                lastVariantsStatus['zero-power'] = true
            }
        }
        setTreeDataInputValue([...iniEmptyData])
        setIsVariantsTypeStatus({ ...lastVariantsStatus })
        setFormData({ ...data })
        setFormDataTerm({ ...data })
    }

    useEffect(() => {
        takeSingleProductData(SingleProduct)
    }, [SingleProduct.children])

    const deleteChildren = async (ParentItem: any, childItem: any) => {
        if (!childItem.can_edit) return toast(" You have not access to remove :(", { type: "warning", position: "top-center", draggable: true })
        let lastArr = [...treeDataInputValue]
        var findIndexParent = lastArr.findIndex((e: any) => e.value === ParentItem.value)
        var findIndexChild = lastArr[findIndexParent].children.findIndex((e: any) => e.value === childItem.value)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${currentCategory}/${childItem.variant_id}` }, { method: "DELETE", token: true })
        if (req.status == true) {
            setDisplayPage(false)
            // lastArr[findIndexParent].children[findIndexChild].isAvailable = false
            // setTreeDataInputValue([...lastArr])
            updateData()
        } else {
            toast(req.message, { type: "error" })
            setDisplayPage(false)
        }
    }

    const onFinishAddChildren = async (ParentItem: any, childItem: any) => {
        let lastArr = [...treeDataInputValue]
        var findIndexParent = lastArr.findIndex((e: any) => e.value === ParentItem.value)
        var findIndexChild = lastArr[findIndexParent].children.findIndex((e: any) => e.value === childItem.value)
        const postBody = {
            "product_id": SingleProduct.id,
            ...lastArr[findIndexParent].children[findIndexChild]
        }
        const checkingZero: boolean = lastArr[findIndexParent].value == "zero-power"
        postBody["discount"] = checkingZero ? formData.discount_zero : formData.discount
        postBody["min_price"] = checkingZero ? formData.min_price_zero : formData.min_price
        postBody["suggestion_price"] = checkingZero ? formData.suggestion_price_zero : formData.suggestion_price
        postBody["price"] = checkingZero ? formData.price_zero : formData.price
        postBody["stock_type"] = checkingZero ? formData.stock_type_zero ? formData.stock_type_zero : "by_stock" : formData.stock_type ? formData.stock_type : "by_stock"
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${currentCategory}` }, { method: "POST", body: JSON.stringify(postBody), token: true })
        if (req.status) {
            await updateData()
        } else {
            toast(req.message, { type: "error" })
        }
        setDisplayPage(false)
    }

    const handelChangeEditStockChild = (e: any, type: any) => {
        let data: any = { ...selectedEditChildStock }
        data[type] = +e.target.value;
        setSelectedEditChildStock(data)
    }

    const onFinishEditChildStock = async () => {
        const payload = {
            "stock": selectedEditChildStock.stock,
        }
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${currentCategory}/${selectedEditChildStock.variant_id}` }, { method: "POST", body: JSON.stringify(payload), token: true })
        if (req.status) {
            await updateData()
            setVisibleEditChildStock(false)
        } else {
            toast(req.message, { type: "error" })
        }
        setDisplayPage(false)
    }

    const getUsualList = () => {
        return [
            ...treeDataInputValue.filter(e => e.value !== "zero-power").map((item: any) => item)[0].children,
            ...treeDataInputValue.filter(e => e.value !== "zero-power").map((item: any) => item)[1].children
        ]
    }
    const getZeroList = () => {
        return [...treeDataInputValue.filter(e => e.value === "zero-power").map((item: any) => item)[0].children]
    }

    return (
        <>
            <div className={` flex flex-col overflow-hidden duration-700 transition-all border border-t-4 ${isSelectedBoxGenerate ? " border-t-glasses-head-50" : "border-t-gray-300"} p-4`}>
                {/* <div className=' flex w-full justify-between items-center'>
                    <span> Generate custom group variants</span>
                    <span onClick={() => setIsSelectedBoxGenerate(!isSelectedBoxGenerate)} className={`  cursor-pointer text-xs rounded-xl p-2 ${isSelectedBoxGenerate ? "bg-glasses-head-50 " : " bg-gray-300"} text-gray-700  duration-700 transition-all  `}>
                        <img className={` w-4 ${isSelectedBoxGenerate ? " rotate-[90deg]" : " rotate-[-90deg]"} `} src='/assets/svg/arrow-left.svg' />
                    </span>
                </div> */}
                <div className={` ${isSelectedBoxGenerate ? " max-h-[3000px] opacity-100 visible mt-4 " : " max-h-0  opacity-0 invisible "} duration-700 transition-all`}>
                    {/* <div className=' w-full flex items-center'>
                        <div className=" w-full flex items-center">
                            <Popover placement='top' content={"Does positive variants power available?"} className={" mr-1"}>
                                <input checked={isVariantTypeStatus['positive-power']} onChange={(e) => onChangeTreeData(e.target.checked, "", "", "positive-power")} type={"checkbox"} />
                            </Popover>
                            <span>Positive Variants</span>
                        </div>
                        <div className=" w-full flex items-center">
                            <Popover placement='top' content={"Does negative variants power available?"} className={" mr-1"}>
                                <input checked={isVariantTypeStatus['negative-power']} onChange={(e) => onChangeTreeData(e.target.checked, "", "", "negative-power")} type={"checkbox"} />
                            </Popover>
                            <span>Negative Variants</span>
                        </div>
                        <div className=" w-full flex items-center">
                            <Popover placement='top' content={"Does zero variant power available?"} className={" mr-1"}>
                                <input checked={isVariantTypeStatus['zero-power']} onChange={(e) => onChangeTreeData(e.target.checked, "", "", "zero-power")} type={"checkbox"} />
                            </Popover>
                            <span>Zero Variant</span>
                        </div>
                    </div> */}
                    <div className=" w-full mt-3 flex flex-wrap">
                        <div className=' w-full text-xl mb-2'>Positive & Negative Powers stock</div>

                        <div className=" w-6/12 px-1 mb-2 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Price</span>
                                <div className=" w-full h-[40px]  mt-1">
                                    <input disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                        value={formData?.price} onChange={(e) => { formInputs('price', +e.target.value, "usual-parent", isParentChanged, setIsParentChanged, formData, setFormData) }} type=" number" placeholder={'Price'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>

                        <div className=" w-6/12 px-1 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Discount %</span>
                                <div className=" w-full h-[40px]  mt-1">
                                    <input disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                        value={formData?.discount} onChange={(e) => { formInputs('discount', +e.target.value, "usual-parent", isParentChanged, setIsParentChanged, formData, setFormData) }} max={100} type="number" placeholder={'Discount'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>

                        <div className=" w-6/12 px-1 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Suggested retail price</span>
                                <div className=" w-full h-[40px] mt-1">
                                    <input disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                        value={formData?.suggestion_price} onChange={(e) => { formInputs('suggestion_price', +e.target.value, "usual-parent", isParentChanged, setIsParentChanged, formData, setFormData) }} type=" number" placeholder={'suggestion price'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>
                        <div className=" w-6/12 px-1 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Minimum selling price</span>
                                <div className=" w-full h-[40px] mt-1">
                                    <input disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                        value={formData?.min_price} onChange={(e) => { formInputs('min_price', +e.target.value, "usual-parent", isParentChanged, setIsParentChanged, formData, setFormData) }} type=" number" placeholder={'Minimum selling price'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>
                        <div className=" w-full px-1 my-2">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Stock Type</span>
                                <select disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                    value={formData?.stock_type} defaultValue={"by_stock"} onChange={(e: any) => formInputs('stock_type', e.target.value, "usual-parent", isParentChanged, setIsParentChanged, formData, setFormData)} className=" w-full h-[40px] rounded mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                                    <option value={''} selected disabled hidden>select stock type</option>
                                    <option value={"in_stock"}>In Stock</option>
                                    <option value={"out_stock"}>Out Of Stock</option>
                                    <option value={"by_stock"}>By Stock</option>
                                </select>
                            </div>
                        </div>

                        <>
                            <div onClick={() => updateMulti(formData, isParentChanged, "usual-parent", setDisplayPage, SingleProduct, updateData, currentCategory, getUsualList(), isParentChanged, (e: any) => setIsParentChanged(e))}
                                className={` ${!isParentChanged['usual-parent'] ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-glasses-head-50 cursor-pointer "} transition-all duration-500
                                text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                                Update information
                            </div>
                            <div onClick={() => fallBackInformation(formData, setFormData, formDataTerm, setFormDataTerm, "usual-parent", isParentChanged, setIsParentChanged)}
                                className={` mb-4 ${!isParentChanged['usual-parent'] ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-red-400 cursor-pointer "} transition-all duration-500
                                text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                                Cancel and fallback
                            </div>
                        </>

                        <div className=" w-full flex flex-col px-1 mb-2 mt-3 ">
                            {treeDataInputValue.length !== 0 && treeDataInputValue.filter(e => e.value !== "zero-power").map((item: any, index: number) =>
                                <div key={index} className=' w-full flex flex-col mb-4'>
                                    <div className=' flex flex-wrap w-full items-center'>
                                        {item.children.map((inItem: any, index: number) =>
                                            <div key={index} className=" flex flex-col items-center w-[calc(33%-0.5rem)] lg:w-[calc(25%-0.5rem)] mx-1 mb-2">
                                                <span className={` text-glasses-text-50 ${!inItem.isAvailable ? " line-through text-gray-400 " : " "}`}>SPH ({inItem.title})</span>
                                                <div className=" w-full mt-1 flex items-center">
                                                    {inItem.isAvailable ?
                                                        <>
                                                            <Popconfirm placement="top" title={`Are you sure to remove ?`} onConfirm={() => !isParentChanged['usual-parent'] && deleteChildren(item, inItem)} okText="Yes" cancelText="No">
                                                                <Popover placement='top' content={"Does it power available?"} className={" mr-1"}>
                                                                    <input disabled={isParentChanged['usual-parent']} type={"checkbox"} readOnly checked={inItem.isAvailable} />
                                                                </Popover>
                                                            </Popconfirm>
                                                        </>
                                                        :
                                                        <>
                                                            <Popover placement='top' content={"Does it power available?"} className={" mr-1"}>
                                                                <input disabled={isParentChanged['usual-parent']} onClick={() => !isParentChanged['usual-parent'] && onFinishAddChildren(item, inItem)} type={"checkbox"} checked={inItem.isAvailable} />
                                                            </Popover>
                                                        </>
                                                    }
                                                    <Popover placement='top' content={"Click to edit stock"} className={" mr-1"}>
                                                        <input value={inItem.stock}
                                                            // onChange={(e) => onChangeTreeData(e.target.value, item, inItem, "number")} 
                                                            onClick={() => {
                                                                if (isParentChanged['usual-parent']) return
                                                                setSelectedEditChildStock(inItem)
                                                                setVisibleEditChildStock(true)
                                                            }}
                                                            disabled={!inItem.isAvailable} defaultValue={inItem.stock} type="number"
                                                            placeholder={'Insert stock'}
                                                            className=" w-full mr-2 cursor-pointer text-glasses-text-50 h-[40px] shadow rounded text-center focus:outline-none"
                                                        />
                                                    </Popover>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=" w-full mt-3 flex flex-wrap">
                            <div className=' w-full text-xl mb-2'>Zero Power stock</div>

                            <div className=" w-6/12 px-1 mb-2 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Price</span>
                                    <div className=" w-full h-[40px]  mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.price_zero}
                                            onChange={(e) => { formInputs('price_zero', +e.target.value, "zero-parent", isParentChanged, setIsParentChanged, formData, setFormData) }}
                                            type=" number"
                                            placeholder={'Price'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>

                            <div className=" w-6/12 px-1 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Discount %</span>
                                    <div className=" w-full h-[40px]  mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.discount_zero}
                                            onChange={(e) => { formInputs('discount_zero', +e.target.value, "zero-parent", isParentChanged, setIsParentChanged, formData, setFormData) }}
                                            max={100}
                                            type="number" placeholder={'Discount'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>

                            <div className=" w-6/12 px-1 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Suggested retail price</span>
                                    <div className=" w-full h-[40px] mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.suggestion_price_zero}
                                            onChange={(e) => { formInputs('suggestion_price_zero', +e.target.value, "zero-parent", isParentChanged, setIsParentChanged, formData, setFormData) }}
                                            type=" number"
                                            placeholder={'suggestion price'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>
                            <div className=" w-6/12 px-1 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Minimum selling price</span>
                                    <div className=" w-full h-[40px] mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.min_price_zero}
                                            onChange={(e) => { formInputs('min_price_zero', +e.target.value, "zero-parent", isParentChanged, setIsParentChanged, formData, setFormData) }}
                                            type=" number"
                                            placeholder={'Minimum selling price'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>

                            <div className=" w-full px-1 my-2">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Stock Type</span>
                                    <select disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                        value={formData?.stock_type_zero} defaultValue={"by_stock"} onChange={(e: any) => formInputs('stock_type_zero', e.target.value, "zero-parent", isParentChanged, setIsParentChanged, formData, setFormData)} className=" w-full h-[40px] rounded mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                                        <option value={''} selected disabled hidden>select stock type</option>
                                        <option value={"in_stock"}>In Stock</option>
                                        <option value={"out_stock"}>Out Of Stock</option>
                                        <option value={"by_stock"}>By Stock</option>
                                    </select>
                                </div>
                            </div>

                            <>
                                <div onClick={() => updateMulti(formData, isParentChanged, "zero-parent", setDisplayPage, SingleProduct, updateData, currentCategory, getZeroList(), isParentChanged, (e: any) => setIsParentChanged(e))}
                                    className={` ${!isParentChanged['zero-parent'] ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-glasses-head-50 cursor-pointer "} transition-all duration-500
                                text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                                    Update information
                                </div>
                                <div onClick={() => fallBackInformation(formData, setFormData, formDataTerm, setFormDataTerm, "zero-parent", isParentChanged, setIsParentChanged)}
                                    className={` mb-4 ${!isParentChanged['zero-parent'] ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-red-400 cursor-pointer "} transition-all duration-500
                                text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
                                    Cancel and fallback
                                </div>
                            </>
                            <div className=" w-full flex flex-col px-1 mb-2 mt-3 ">
                                {treeDataInputValue.length !== 0 && treeDataInputValue.filter(e => e.value === "zero-power").map((item: any, index: number) =>
                                    <div key={index} className=' w-full flex flex-col mb-4'>
                                        <div className=' flex flex-wrap w-full items-center'>
                                            {item.children.map((inItem: any) =>
                                                <div className=" flex flex-col items-center w-[calc(33%-0.5rem)] lg:w-[calc(25%-0.5rem)] mx-1 mb-2">
                                                    <span className={` text-glasses-text-50 ${!inItem.isAvailable ? " line-through text-gray-400 " : " "}`}>SPH ({inItem.title})</span>
                                                    <div className=" w-full mt-1 flex items-center">
                                                        {inItem.isAvailable ?
                                                            <>
                                                                <Popconfirm placement="top" title={`Are you sure to remove ?`} onConfirm={() => !isParentChanged['zero-parent'] && deleteChildren(item, inItem)} okText="Yes" cancelText="No">
                                                                    <Popover placement='top' content={"Does it power available?"} className={" mr-1"}>
                                                                        <input disabled={isParentChanged['zero-parent']} type={"checkbox"} readOnly checked={inItem.isAvailable} />
                                                                    </Popover>
                                                                </Popconfirm>
                                                            </>
                                                            :
                                                            <>
                                                                <Popover placement='top' content={"Does it power available?"} className={" mr-1"}>
                                                                    <input disabled={isParentChanged['zero-parent']} onClick={() => !isParentChanged['zero-parent'] && onFinishAddChildren(item, inItem)} readOnly type={"checkbox"} checked={inItem.isAvailable} />
                                                                </Popover>
                                                            </>
                                                        }
                                                        <Popover placement='top' content={"Click to edit stock"} className={" mr-1"}>
                                                            <input value={inItem.stock}
                                                                // onChange={(e) => onChangeTreeData(e.target.value, item, inItem, "number")} 
                                                                onClick={() => {
                                                                    if (isParentChanged['zero-parent']) return
                                                                    setSelectedEditChildStock(inItem)
                                                                    setVisibleEditChildStock(true)
                                                                }}
                                                                disabled={!inItem.isAvailable} defaultValue={inItem.stock} type="number"
                                                                placeholder={'Insert stock'}
                                                                className=" w-full mr-2 cursor-pointer text-glasses-text-50 h-[40px] shadow rounded text-center focus:outline-none"
                                                            />
                                                        </Popover>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>


                </div>
            </div>

            <CModal onScap={() => { }} visible={visibleEditChildStock} setVisible={setVisibleEditChildStock} title="Edit stock" uId='Edit_stock'>
                {selectedEditChildStock &&
                    <div className=" w-full  mt-2 flex">
                        <div className=" w-5/12 pr-1 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">SPH</span>
                                <div className=" w-full h-[40px] ">
                                    <input disabled type="number" value={selectedEditChildStock.sph} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>
                        <div className=" w-5/12 pl-1  ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Stock</span>
                                <div className=" w-full h-[40px] ">
                                    <input type="number" onChange={(e: any) => handelChangeEditStockChild(e, 'stock')} value={selectedEditChildStock.stock} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>
                        <div className=" w-2/12 pl-1 flex justify-end items-center ">
                            <div onClick={() => onFinishEditChildStock()}
                                className={` w-full bg-glasses-head-50 cursor-pointer h-[40px] text-white flex justify-center items-center rounded mt-[21px] shadow`}
                            >
                                Save
                            </div>
                        </div>
                    </div>
                }
            </CModal>
        </>
    );
};

export default GenerateCustomVariantsEditComp;
