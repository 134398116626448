import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { baseUrl, customApi } from '../../widget/apis';
import CModal from '../../widget/CModal/CModal';
import { ContextState } from '../../widget/context/ContextMain';
import LoadingPage from '../../widget/Loading/LoadingPage';
import TablePage from '../../widget/Table/TablePage';
import DetailsModal from './details';


interface IStatementProps {

}

const Statement: React.FunctionComponent<IStatementProps> = ({ }) => {

    const Ctx = useContext(ContextState);
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [listData, setListData] = useState<any[]>([]);
    const [lastPage, setLastPage] = useState(0);
    const [isAllowReq, setIsAllowReq] = React.useState<boolean>(true);
    const [isShowDetailModal, setIsShowDetailModal] = React.useState<boolean>(false);
    const [selectedItem, setSelectedItem] = React.useState<any>({});
    const [filterItems, setFilterItems] = React.useState<{ name?: string, price?: any }>({});

    function selectItem(item: any) {
        setSelectedItem(item)
        setIsShowDetailModal(true)
    }

    const col = [
        {
            title: "Order Id",
            render: (i: any) => (<div>#{i.order_id && i.order_id}</div>)
        },

        {
            title: "Details",
            render: (i: any) => (
                <div onClick={() => selectItem(i)}>
                    <svg x="0px" y="0px" viewBox="0 0 512 512" className={` cursor-pointer w-6 fill-glasses-head-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/eye.svg#eye" />
                    </svg>
                </div>
            )
        }
    ]



    const handelActive = async (id: number) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}coupons/${id}/change_status` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            getData(currentPage)
        }
        else {
            toast(req.message, { type: "error" })
        }
    }



    const changePage = (status: any) => {
        if (status) {
            if (lastPage >= currentPage + 1) {
                getData(currentPage + 1)
            }
        }
        else {
            if (currentPage > 1) {
                getData(currentPage - 1)
            }
        }
    }


    const getData = async (current: any, searchKey?: any, name?: any, price?: any) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/statements?page=${current}${searchKey ? `&search=${searchKey}` : ""}${name ? `&name=${name}` : ""}${price ? `&price=${price}` : ""}` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status) {
            setListData(req.data.data)
            setCurrentPage(req.data.meta.current_page)
            setLastPage(req.data.meta.last_page)
            setIsShowDetailModal(false)

        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("Statement")
        getData(currentPage)
    }, [])

    async function searchWithDelay() {
        if (isAllowReq) {
            const Input: any = document.getElementById(`searchInputProduct`)
            setIsAllowReq(false)
            setTimeout(async () => {
                // if (!stockInput.value) return setIsAllowReq(true)
                getData(currentPage, Input.value)
                setIsAllowReq(true)
            }, 1500)
        }
    }



    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full  flex flex-col   ">

                <div className=' w-full flex flex-wrap pt-4 justify-center md:justify-between items-center'>
                    <div className=' flex items-center justify-center bg-gray-200 pr-4 overflow-hidden rounded-lg group'>
                        <input id='searchInputProduct' className='bg-gray-200 px-4 rounded-tl-lg rounded-bl-lg h-12' placeholder='Search' type={"text"}
                            onChange={(e: any) => {
                                searchWithDelay()
                            }} />
                        <svg x="0px" y="0px" viewBox="0 0 53.627 53.627" className={` w-5 fill-gray-500 h-12 bg-gray-200 rounded-tr-lg rounded-br-lg transition-all group-hover:fill-glasses-head-50 `}>
                            <use xlinkHref="/assets/svg/search.svg#search" />
                        </svg>
                    </div>

                    {/* <div className=' flex '>
                        {filterItems.name &&
                            <div onClick={() => filterCancel({ name: true })} className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600'>
                                <span className=' cursor-pointer mr-2'>x</span>  {filterItems.name}
                            </div>
                        }
                        {filterItems.price &&
                            <div onClick={() => filterCancel({ price: true })} className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600'>
                                <span className=' cursor-pointer mr-2'>x</span> {filterItems.price}
                            </div>
                        }
                        <div onClick={() => setIsShowDetailModal(!isShowDetailModal)} className=' flex justify-center items-center px-8 py-2 text-sm rounded-lg bg-blue-400 hover:bg-blue-600 cursor-pointer text-white'>
                            Filter
                        </div>
                    </div> */}
                </div>
                <TablePage columns={col} data={listData}>
                    {currentPage != 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(false)} >
                            <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
                            </svg>
                        </div>
                    }
                    <div className="table-counter-main-item">{currentPage} </div>
                    {lastPage >= currentPage + 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(true)} >
                            <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
                            </svg>
                        </div>
                    }
                </TablePage>
            </div>

            <CModal title={`Details of #${selectedItem.order_id}`} setVisible={setIsShowDetailModal} visible={isShowDetailModal} uId='statementmodal' width='100%' maxWidth='1280px'  >
                <span onClick={()=>{ setIsShowDetailModal(false)}} className=' absolute top-3 right-4 text-xl cursor-pointer '>x</span>
                <DetailsModal item={Boolean(Object.keys(selectedItem).length) && selectedItem} />
            </CModal>
        </>
    );
}

export default Statement;
