import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../widget/apis';
import { ContextState } from '../../widget/context/ContextMain';
import LoadingPage from '../../widget/Loading/LoadingPage';


type Props = {

}

const MyWallet = (props: Props) => {
    const Ctx = useContext(ContextState);
    const [displayPage, setDisplayPage] = useState<boolean>(false)
    const [walletData, setWalletDataData] = useState<any>({})


    const getData = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/checkouts` }, { method: "GET", token: true })
        if (req.status) {
            setDisplayPage(false)
            setWalletDataData(req.data)

        } else {
            toast(req.message)
        }
    }


    useEffect(() => {
        Ctx.setHeadTitle("My Wallet")
        Ctx.setActiveItemNav({ 'menu': 'wallet', 'subMenu': 0 })
        getData()
    }, [])

    return (

        <div className=' w-full flex flex-col'>
            {displayPage && <LoadingPage />}
            <div className=' w-full flex mt-6 flex-wrap '>
                <div className=' w-[200px] rounded-[15px] bg-white p-4 flex justify-center items-center flex-col mx-4'>
                    <small className=' text-gray-500 text-sm mb-2 font-bold'>
                        Total receivable
                    </small>
                    <h2 className=' text-2xl font-bold'>
                        $ {walletData.total_checkout}
                    </h2>
                </div>

                <div className=' w-[200px] rounded-[15px] bg-white p-4 flex justify-center items-center flex-col mx-4'>
                    <small className=' text-gray-500 text-sm mb-2 font-bold'>
                        Last CheckOut
                    </small>
                    <h2 className=' text-2xl font-bold'>
                        {walletData.last_checkout}
                    </h2>
                </div>

                <div className=' w-[200px] rounded-[15px] bg-white p-4 flex justify-center items-center flex-col mx-4'>
                    <small className=' text-gray-500 text-sm mb-2 font-bold'>
                        Pending receivable
                    </small>
                    <h2 className=' text-2xl font-bold text-orange-500'>
                        $  {parseInt(walletData.total_income) - parseInt(walletData.total_checkout)}
                    </h2>
                </div>

                <div className=' w-[200px] rounded-[15px] bg-white p-4 flex justify-center items-center flex-col mx-4'>
                    <small className=' text-gray-500 text-sm mb-2 font-bold '>
                        Total amount received
                    </small>
                    <h2 className=' text-2xl font-bold text-blue-500'>
                        $   {walletData.total_income}
                    </h2>
                </div>
            </div>

            <h3 className=' text-xl font-bold mt-10 mb-5'>Check Out History</h3>

            {walletData?.history?.map((item: any, index: number) =>
                <div key={index} className=' flex flex-col w-full max-w-[350px] bg-white rounded-[15px] p-4 mx-4'>
                    <div className=' flex w-full justify-between items-center my-3'>
                        <img className=' w-6' alt='person' src='/assets/svg/person.svg' />
                        <p className=' m-0 font-bold'>Amount</p>
                        <p className=' m-0 font-bold'>$ {item.amount}</p>
                    </div>
                    <div className=' flex w-full justify-between items-center my-3'>
                        <img className=' w-6' alt='person' src='/assets/svg/clock.svg' />
                        <p className=' m-0 font-bold'>Checkout Data</p>
                        <p className=' m-0 font-bold'>{item.pay_time}</p>
                    </div>
                </div>
            )}


        </div>

    )
}

export default MyWallet