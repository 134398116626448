import React, { useContext, useEffect, useRef, useState } from 'react';
import { ContextState } from '../../../../widget/context/ContextMain'
import { checkPermission, checkisBrand } from '../../../../widget/layout/Nav';
import { ProductsPermissions } from '../../../../widget/rolePermissions';
import LoadingPage from '../../../../widget/Loading/LoadingPage';
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../../../widget/apis';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CModal from '../../../../widget/CModal/CModal';
import BtnLoader from '../../../../widget/Loading/BtnLoader';
import ProductAddContactLensWidget from './widget/ProductAddWidgetContactLens';
import UploadWP from '../../../../widget/uploadWithProgress';
import { stringify } from 'querystring';
import { Popover } from 'antd';


interface IProductAddContactLensProps {

}

const ProductAddContactLens: React.FunctionComponent<IProductAddContactLensProps> = ({ }) => {
    const Ctx = useContext(ContextState);
    useEffect(() => {
        Ctx.setHeadTitle("Add Product (contact lens)")
        Ctx.setActiveItemNav({ 'menu': 'products', 'subMenu': 0 })
    }, [])

    const formRef: any = useRef()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [displayBtn, setDisplayBtn] = useState<any>([])
    const [displayPage, setDisplayPage] = useState<any>([])
    const [visibleAddItem, setVisibleAddItem] = useState<boolean>(false)
    const [listData, setListData] = useState<any>(null)
    const [galleryImages, setGalleryImages] = useState<any>([])
    const [selectedId, setSelectedId] = useState<any>(null)
    const [keepType, setKeepType] = useState<any>(null)
    const [keepBaseOrChild, setKeepBaseOrChild] = useState<any>(null)
    const [stockList, setStockList] = useState<any>([]);
    const [selectedImages, setSelectedImages] = useState<any>()
    const [SelectedCat, setSelectedCat] = useState<any>(null);
    const [settingData, setSettingData] = useState<any>(null)
    const [childList, setChildList] = useState<any[]>([])

    const onChangeCat = (category: any) => {
        setSelectedCat(+category)
    }

    useEffect(() => {
        if (searchParams.get('currentCategory')) getData(searchParams.get('currentCategory'))
    }, [searchParams.get('currentCategory')])

    useEffect(() => {
        if (listData) {
            if (searchParams.get('currentCategory')) {
                onChangeCat(searchParams.get('currentCategory'))
            }
        }
    }, [searchParams.get('currentCategory'), listData])

    const clearData = async () => {
        setKeepType(null);
        setVisibleAddItem(false)
        const form: any = document.getElementById("formNewItem")
        form?.reset()
        let allSelect: any = document.getElementsByClassName("add-form-sel")
        for (let i of allSelect) {
            if (i.value == -1) {
                i.value = ''
            }
        }
    }

    type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';

    const checkValidation = (): { status: boolean, message: string, messType: TypeOptions } => {
        if (!selectedImages?.default_image) return { status: false, message: "please select an default image", messType: "info" }
        if (childList.length == 0) return { status: false, message: "please add one variant at least ", messType: "info" }
        else {
            for (let i of childList) {
                if (!i.min_price) return { status: false, message: " Min Price is Required", messType: "info" }
                if (i.sph == null || i.sph == undefined) return { status: false, message: " SPH is Required", messType: "info" }
                if (!i.price) return { status: false, message: " Price is Required", messType: "info" }
                if (i.discount == undefined) return { status: false, message: " Discount is Required", messType: "info" }
                if (i.stock == undefined || i.stock == null) return { status: false, message: " Stock is Required", messType: "info" }
            }
            return { status: true, message: " Validation is Passed ", messType: "success" }
        }
    }

    const returnCatName = (catNum: number) => {
        if (+catNum === 1) return "sunglasses"
        if (+catNum === 2) return "eyeglasses"
        if (+catNum === 3) return "contact_lenses"
    }

    const onFinishAdd = async (e: any) => {
        e.preventDefault()
        if (!checkValidation().status) return toast(checkValidation().message, { type: checkValidation().messType })
        var imgList = []
        for (var t in galleryImages) {
            imgList.push(galleryImages[t].path)
        }
        let postData: any = {
            "title": formRef.current['title'].value,
            "description": formRef.current['description'].value,
            "default_image": selectedImages.default_image.path,
            "images": imgList,
            "diameter": formRef.current['diameter'].value,
            "base_curve": formRef.current['base_curve'].value,
            "expiry": formRef.current['expiry'].value,
            "delivery_day": formRef.current['delivery_day'].value,
            "weight": formRef.current['weight'].value,
            "variants": childList
        }

        setDisplayPage(true)
        if (formRef.current['color']) postData["color"] = formRef.current['color'].value
        if (formRef.current['made']) postData["made"] = formRef.current['made'].value
        if (formRef.current['packaging']) postData["packaging"] = formRef.current['packaging'].value
        if (formRef.current['water_content']) postData["water_content"] = formRef.current['water_content'].value
        if (formRef.current['weight_group']) postData["weight_group"] = formRef.current['weight_group'].value
        if (formRef.current['contact_lens_material']) postData["contact_lens_material"] = formRef.current['contact_lens_material'].value
        if (formRef.current['contact_lens_type']) postData["contact_lens_type"] = formRef.current['contact_lens_type'].value
        await sessionStorage.setItem('draft', JSON.stringify(postData))
        await sessionStorage.setItem('stockList', JSON.stringify(stockList))
        await sessionStorage.setItem('currentCategory', '3')
        return

        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${returnCatName(SelectedCat)}` }, { method: "POST", body: JSON.stringify(postData), token: true })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            if (searchParams.get('page') != null) {
                navigate(`/panel/product/list?page=${searchParams.get('page')}`);
            }
            else {
                navigate('/panel/product/list');
            }
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const handelAddItem = async (type: string, value: any, baseOrchild: string) => {
        if (value == -1) {
            setKeepType(type)
            setKeepBaseOrChild(baseOrchild)
            setVisibleAddItem(true)
            await setSelectedId(`select${type}`)
        }
    }

    const onFinishAddItem = async (e: any) => {
        e.preventDefault()
        setDisplayPage(true)
        let postData = {
            'name': e.target[0].value,
            'group': keepType,
        }
        let data: any = { ...listData };
        data[keepBaseOrChild][keepType].push(e.target[0].value)
        const req = await customApi({ url: `${baseUrl}filters` }, { method: "POST", body: JSON.stringify(postData), token: true })
        setDisplayPage(false)
        if (req.status) {
            setListData(data)
            toast(req.message, { type: "success" })
        }
        else {
            toast(req.message, { type: "error" })
        }
        clearData()
    }

    // const getCategory = async () => {
    //   const req = await customApi({ url: `${baseUrl}categories/dropdown` }, { method: "GET", token: true })
    //   if (req.status == true) {
    //     setListCategory(req.data)
    //   }
    // }


    const getData = async (currentCat: any) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}filters/${returnCatName(+currentCat)}/dropdown` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            // setChildList(req.data.children)
            setListData(req.data)
        }
    }

    const getSettingData = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/settings` }, { method: "GET", token: true })
        if (req.status) {
            setDisplayPage(false)
            setSettingData(req.data)
        } else {
            toast(req.message, { type: "error" })
        }
    }

    useEffect(() => {
        if (!checkPermission(ProductsPermissions.add) || !checkisBrand()) {
            navigate('/panel/product/list');
        }
        checkisBrand() && getSettingData()
    }, [])

    const onChangeImage = async (response: any, name: string, isList?: boolean) => {
        let data: any = { ...selectedImages };
        if (isList) {
            let arr = [...galleryImages]
            arr.push(response);
            setGalleryImages([...arr])
        } else {
            data[name] = response;
        }
        setSelectedImages(data)
    };

    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full md:w-6/12 lg:w-6/12 xl:w-5/12  flex flex-col   ">
                <form ref={formRef} onSubmit={onFinishAdd} className=" w-full">

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Title</span>
                        <input name={'title'} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Description</span>
                        <textarea
                            name={'description'} placeholder="Insert description"
                            className=" w-full min-h-[80px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50"
                            required
                        />
                    </div>

                    <div className=" w-full flex flex-col mt-5 mx-1">
                        <span className=" text-glasses-text-50 text-sm mb-2">Defult Image</span>
                        <UploadWP imageRatio={1.8} currentIMG={""} setResponse={(res: any) => onChangeImage(res, "default_image")}
                            url={`${localStorage.getItem("shopId")}/products/image`} fileCount={1} />
                    </div>

                    <div className=" w-full flex flex-col mt-5 ">
                        <span className=" text-glasses-text-50 text-sm pl-4 mb-2">Upload Gallery Images</span>
                        <div className=' flex w-full items-center  border border-glasses-head-50 border-dashed p-3 flex-wrap'>
                            <UploadWP customText='Upload Images' imageRatio={2} currentIMG={""} setResponse={(res: any) => onChangeImage(res, "images", true)}
                                url={`${localStorage.getItem("shopId")}/products/image`} fileCount={20} />
                        </div>
                    </div>

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Diameter</span>
                        <input name={'diameter'} placeholder={" insert diameter"} type="number" step={0.01} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>
                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Base Curve</span>
                        <input name={'base_curve'} placeholder={" insert base curve"} type="number" step={0.01} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>
                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Expiry</span>
                        <input name={'expiry'} placeholder={" insert expiry"} type="text" step={0.01} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                    </div>
                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">delivery_day</span>
                        <input name={'delivery_day'} placeholder={" insert delivery day"} type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">weight (g)</span>
                        <input name={'weight'} defaultValue={500} type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>


                    {listData != null &&
                        <>
                            {Object.entries(listData.base).map((PItem: any, PIndex) =>
                                <div key={PIndex} className=" w-full flex flex-col mt-5">
                                    <span className=" text-glasses-text-50 text-sm">{PItem[0].split("_").join(" ")}</span>
                                    <select
                                        name={PItem[0]} id={`select${PItem[0]}`}
                                        onChange={(e) => handelAddItem(PItem[0], e.target.value, 'base')}
                                        className=" add-form-sel w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50"
                                        required={PItem[0] !== "contact_lens_material"}
                                    >
                                        <option value={''} className=" text-gray-400" selected disabled hidden>{`select ${PItem[0]}`}</option>
                                        {PItem[1].map((item: any, index: number) =>
                                            <option key={index} value={item}>{item}</option>
                                        )}
                                        <option key={-1} value={-1}>+ Add custom item</option>
                                    </select>
                                </div>
                            )}
                        </>
                    }

                    <ProductAddContactLensWidget
                        brandSetting={settingData}
                        childrenDropDown={listData?.children ? listData?.children : null}
                        childList={childList}
                        setChildList={setChildList}
                        stockList={stockList}
                        setStockList={setStockList}
                        handelAddItem={handelAddItem}
                        SelectedCat={SelectedCat}
                        onFinishForm={onFinishAdd}
                    />

                    <div className='w-full flex items-center mt-8'>
                        {childList.length !== 0 ?
                            <Popover open content={" Click to add"} placement="topLeft">
                                <button type='submit' className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Add Product</button>
                            </Popover>
                            :
                            <Popover content={" You should generate variants and then can add product"} placement="topLeft">
                                <button type='button' className=" text-white rounded-lg bg-gray-300 w-[120px] h-[40px] mt-9 font-bold ">Add Product</button>
                            </Popover>
                        }
                        <Link to={searchParams.get('page') != null ? `/panel/product/list?page=${searchParams.get('page')}` : `/panel/product/list`} >
                            <button type='button' className=" text-white rounded-lg border border-glasses-head-50 text-glasses-head-50 ml-2 w-[120px] h-[40px] mt-9 font-bold ">Cancel</button>
                        </Link>
                    </div>

                </form>


                <CModal onScap={clearData} visible={visibleAddItem} setVisible={setVisibleAddItem} title="AddIstem" uId='AddIstem'>
                    <form onSubmit={onFinishAddItem} id="formNewItem">
                        <input type="text" className=" w-full h-10 border rounded my-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Your custom item"} required />
                        {displayBtn == false ?
                            <button type="submit" className=" w-full h-10 bg-glasses-head-50 text-white rounded" >save</button>
                            :
                            <button type="button" disabled><BtnLoader /></button>
                        }
                    </form>
                </CModal>

            </div>
        </>
    );
};

export default ProductAddContactLens;
