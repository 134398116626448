export const ReplaceProductFromDraftMethod = async (
    formRef: any,
    setChildList: (obj: any) => void,
    setStockList: (obj: any) => void,
) => {
    let draft = JSON.parse(String(sessionStorage.getItem('draft')));
    console.log(draft);
    for (let i of Object.entries(draft)) {
        if (i[0] != 'variants') {
            formRef.current[`${i[0]}`].value = i?.[1]
        }
    }
    if (draft?.variants && Array.isArray(draft?.variants)) {
        setChildList(draft?.variants)
    }
    setStockList(JSON.parse(String(sessionStorage.getItem('stockList'))))
}