import {toast} from "react-toastify";
// import LocalForage from "localforage"
import {ApiInputOptional, ApiInputRequired, GetCustomizedOrdersOptions} from "../types/api.types";

console.log(window.location.host);

export const baseUrl = typeof window != 'undefined' && window.location.hostname == 'my.optics4less.com' ? 'https://api.optics4less.com/api/my/v2/' : 'https://api.optics4less.com/api/my/v2/'
export const ImageUrl = `http://192.168.1.103:8000/public/storage/`


export async function getToken(): Promise<string | null> {
    // return await localStorage.getItem("token");
    return `Bearer ${await localStorage.getItem("token")}`;

}


export async function customApi(required: Required<ApiInputRequired>, optional: Partial<ApiInputOptional>) {
    const init: RequestInit = {}
    const headers: HeadersInit = {}
    // if (optional.token) headers['token'] = await getToken() ?? ""
    // headers['Accept'] = "application/json"

    if (optional.token) headers['Authorization'] = await getToken() ?? ""
    if (optional.method !== "GET") init.body = optional.body
    if (optional.formData) {
        // headers['Content-Type'] = "multipart/form-data"
    } else {
        headers['Content-Type'] = "application/json"
        headers['Accept'] = "application/json"
    }
    init.headers = headers
    init.method = optional.method ?? "GET"
    // if(!navigator.onLine) {
    //     toast( "Internet Connection Lost", {type: "error"})
    //     setTimeout(()=>{
    //         alert("Internet Connection Lost")
    //         return window.location.reload();
    //     },1000)
    // }
    const request = await fetch(required.url, init)
    const response = request.json();
    return response.then((res) => {
        if (res.status) {
            toast(optional.successMes, optional.messageConfig = {type: "success"})
            return res
        } else {
            toast(res.msg, {type: "error"})
            toast(optional.errorMes, optional.messageConfig = {type: "error"})
            return res
        }
    })
        .catch((err) => {
            alert(`staus error : ${err}`)
            toast(err, {type: "error", autoClose: false})
            return err
        })
}


function DownloadInvoiceApi(id: any) {
    return fetch(`${baseUrl}${localStorage.getItem("shopId")}/orders/${id}/dl_invoice`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    })
        .then(res => res.blob())
        .then((res) => {
            return URL.createObjectURL(res);
        })
        .catch((er) => {
            return er;
        })
}

function DownloadPDFApi(id: any) {
    return fetch(`${baseUrl}${localStorage.getItem("shopId")}/orders/${id}/dl_fly_pdf`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    })
        .then(res => res.blob())
        .then((res) => {
            return URL.createObjectURL(res);
        })
        .catch((er) => {
            return er;
        })
}

export {DownloadInvoiceApi, DownloadPDFApi};


