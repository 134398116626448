import * as React from 'react';
import CModal from '../../widget/CModal/CModal';

interface IEditSingleInventoryModalProps {
    setVisible: CallableFunction
    visible: boolean
    selectedInv: any
    onFinish: CallableFunction | any
    changeStock: CallableFunction
    stockData: any
    selectedInvIndex: any
}

const EditSingleInventoryModal: React.FunctionComponent<IEditSingleInventoryModalProps> = ({changeStock, onFinish, selectedInv, setVisible, stockData, visible, selectedInvIndex }) => {

    return (
        <>
            <CModal title='Edit Invetory' setVisible={setVisible} visible={visible} uId='editInventoryModal' >
                <>
                    {selectedInv &&
                        <form onSubmit={onFinish} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>
                            <div className=" w-10/12 flex flex-col my-5 mb-0">
                                <span className=" text-glasses-text-50  mb-2 text-lg">Adjust By </span>
                                <div className=' flex items-center'>
                                    <div onClick={() => changeStock("add", selectedInv)} className='  w-[25px] mx-2 rounded-xl text-lg bg-white shadow flex justify-center items-center cursor-pointer '> + </div>
                                    <input value={selectedInv.stock - stockData[selectedInvIndex].stock} type="number" className=" w-[calc(100%-70px)] text-center max-w-[50px] h-[40px] rounded-xl bg-white shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                                    <div onClick={() => changeStock("minus", selectedInv)} className='  w-[25px] mx-2 rounded-xl text-2xl bg-white shadow-xl flex justify-center items-center cursor-pointer '> - </div>
                                </div>
                                <p className=' mt-2 bg-gray-100 rounded-lg w-fit shadow-md px-2 py-1'> Current stock : {selectedInv.stock} <span className=' mx-5'>{"<"}----</span> Orginal stock : {stockData[selectedInvIndex].stock} </p>
                            </div>

                            <div className=" w-10/12 flex flex-col mt-4">
                                <span className=" text-glasses-text-50  mb-2 text-lg">Reason </span>
                                <select onChange={(e: any) => { }} name={'lens_color'} className=" w-full h-[40px] rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                                    <option value={"correctoin"}>{"Correctoin (defult)"}</option>
                                    <option value={"count"}>{"Count"}</option>
                                    <option value={"received"}>{"Received"}</option>
                                    <option value={"return restock"}>{"Return restock"}</option>
                                    <option value={"damaged"}>{"Damaged"}</option>
                                    <option value={"theft or loss"}>{"Theft or loss"}</option>
                                    <option value={"promotion or donation"}>{"Promotion or donation"}</option>
                                </select>
                            </div>


                            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold "> Update </button>

                        </form>
                    }
                </>
            </CModal>

        </>
    );
};

export default EditSingleInventoryModal;
