import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { baseUrl } from '../../widget/apis';
import { GetBrandAPi, UpdateBrandAPi } from '../../widget/apis/brand';
import LoadingPage from '../../widget/Loading/LoadingPage';
import UploadWP from '../../widget/uploadWithProgress';
import { ContextState } from '../../widget/context/ContextMain';


interface IBrandProps {
}

const Brand: React.FunctionComponent<IBrandProps> = (props) => {
    const [displayPage, setDisplayPage] = useState<boolean>(false)
    const [brandData, setBrandData] = useState<any>({})
    const [selectedImages, setSelectedImages] = useState<{ image: { path: any, url: string } }>()
    const Ctx = useContext(ContextState);


    const getData = async () => {
        try {
            const res = await GetBrandAPi()
            if (res.status) {
                setBrandData(res.data)
                setSelectedImages({
                    image: {
                        path: res.data.image ? res.data.image : "",
                        url: res.data.image_url ? res.data.image_url : ""
                    }
                })

            }
            setDisplayPage(false)
        }
        catch {
            setDisplayPage(false)
        }
    }

    useEffect(() => {
        getData()
        Ctx.setHeadTitle("Brand")        
        Ctx.setActiveItemNav({ 'menu': 'brand', 'subMenu': 0 })
    }, [])


    const updateSetting = async (e: any) => {
        e.preventDefault()
        if (displayPage) return
        setDisplayPage(true)

        try {
            const req = await UpdateBrandAPi(e, selectedImages?.image.path)
            if (req.status) {
                toast(req.message, { type: "success" })
                setDisplayPage(false)
                getData()
                const form: any = document.getElementById("orderDescForm")
                form?.reset()
            } else {
                setDisplayPage(false)
                toast(req.message)
            }
        }
        catch {
            setDisplayPage(false)
        }
    }

    const onChangeImage = async (response: any, name: string) => {
        let data: any = { ...selectedImages };
        data[name] = response;
        setSelectedImages(data)
    };

    return (
        <>
            {displayPage && <LoadingPage />}

            <div className=" w-full md:w-6/12 lg:w-4/12  flex flex-col ">
                <form id='orderDescForm' onSubmit={updateSetting} className=" w-full">
                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Name</span>
                        <input defaultValue={brandData?.name && brandData?.name} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>

                    <div className=' flex w-full'>
                        <div className=" w-full flex flex-col mt-5 mx-1">
                            <span className=" text-glasses-text-50 text-sm mb-2">Logo</span>
                            <UploadWP imageRatio={1.608} currentIMG={selectedImages?.image.url} setResponse={(res: any) => onChangeImage(res, "image")}
                                url={`25/settings/logo_brand`} fileCount={1} />
                        </div>
                    </div>

                    <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Update Profile </button>
                </form>

            </div>
        </>
    );
};

export default Brand;
