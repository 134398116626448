import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../../../widget/context/ContextMain'
import CModal from '../../../../../widget/CModal/CModal';
import BtnLoader from '../../../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../../../widget/apis';
import LoadingPage from '../../../../../widget/Loading/LoadingPage';
import ImgCrop from 'antd-img-crop';
import { Upload, message, Popconfirm } from 'antd';
import { toast } from "react-toastify";
import type { UploadProps } from 'antd';
import UploadWP from '../../../../../widget/uploadWithProgress';
import InputColor from 'react-input-color';
import CFileUpload from '../../../../../widget/uploadFile';
import ManageSelectedVariantsInputComp from './ManageSelectedVariantsIntputs';
import GenerateCustomVariantsComp from './GenerateCustomVariants';
import SpecialSelectionComp from './SpecialSelection';

type IListOfColorFromInput = {
  'green': any,
  'red': any,
  'blue': any,
}

interface IProductAddContactLensWidgetProps {
}

const ProductAddContactLensWidget: React.FunctionComponent<any> = (props: any) => {

  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<any>([])
  const [visibleColor, setVisibleColor] = useState<boolean>(false)
  const [visibleColorCustom, setVisibleColorCustom] = useState<boolean>(false)
  const [selectChildForColor, setSelectChildForColor] = useState<number>(-1)
  const [listColor, setListColor] = useState<any>([])
  const [listColorCustom, setListColorCustom] = useState<any>([])
  const [displayBtn, setDisplayBtn] = useState<any>([])
  const [colorNameToAdd, setColorNameToAdd] = useState<string>("")
  const [isAddNewColor, setIsAddNewColor] = useState<boolean>(false)
  const [isSelectedBoxManage, setIsSelectedBoxManage] = useState<boolean>(false)
  const [isSelectedBoxSpecialSelection, setIsSelectedBoxSpecialSelection] = useState<boolean>(false)
  const [isSelectedBoxGenerate, setIsSelectedBoxGenerate] = useState<boolean>(true)
  const [selectImageOrColor, setSelectImageOrColor] = useState<number>(0)
  const [selectedImages, setSelectedImages] = useState<{
    colorImg: { path: any, url: string },
  } | any>()
  const [listOfColorFromInput, setListOfColorFromInput] = useState<IListOfColorFromInput[]>([{ blue: "", green: "", red: "" }])

  const onFinishAddColor = async () => {
    if (selectImageOrColor == 0) return toast("select image or color ", { type: "warning" })
    if (colorNameToAdd.length === 0) return toast("insert name for color", { type: "warning" })
    if (selectImageOrColor == 1 && !selectedImages?.colorImg?.path) return toast("upload an image ", { type: "warning" })
    if (selectImageOrColor == 2 && !listOfColorFromInput.length) return toast("select an color ", { type: "warning" })
    setDisplayPage(true)
    let postData: any = {
      'name': colorNameToAdd,
    }
    if (selectImageOrColor == 1) postData['image'] = selectedImages?.colorImg.path;
    if (selectImageOrColor == 2) postData['hexes'] = listOfColorFromInput;

    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status) {
      setColorNameToAdd("")
      setSelectedImages(null)
      getColors()
      toast(req.message, { type: "success" })
    }
    else {
      toast(req.message, { type: "error" })
    }
  }




  const clearData = () => {
    setVisibleColor(false)
  }

  const addChild = () => {
    const data = [...props.childList];
    data.push({
      'stock': 0,
      'price': 0,
      'discount': 0,
      'suggestion_price': 0,
      'min_price': 0,
      'sph': 0,
      'isSelected': false
    })
    props.setChildList(data)
  }


  const removeChild = (id: any) => {
    const data = [...props.childList];
    data.splice(id, 1)
    props.setChildList(data)
  }

  const selectedColor = (color_id: any, color: string) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == selectChildForColor) {
        data[i].color_id = color_id;
        data[i].color = color;
      }
    }
    props.setChildList(data)
    setVisibleColor(false)
    setVisibleColorCustom(false)
  }

  const changeInputChild = (type: string, index: any, value: any) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        if (type === "discount") {
          if (value <= 100) data[i][type] = value;
        } else data[i][type] = value;
        if (type === "price") {
          if (props.SelectedCat == 1) {
            if (props.brandSetting?.MSPS_x) data[i].min_price = (value * props.brandSetting.MSPS_x).toFixed(2);
            if (props.brandSetting?.RSPS_x) data[i].suggestion_price = (value * props.brandSetting.RSPS_x).toFixed(2);
          } else if (props.SelectedCat == 2) {
            if (props.brandSetting?.MSPE_x) data[i].min_price = (value * props.brandSetting.MSPE_x).toFixed(2);
            if (props.brandSetting?.RSPE_x) data[i].suggestion_price = (value * props.brandSetting.RSPE_x).toFixed(2);
          }
        }
      }
    }
    props.setChildList(data)
    setVisibleColor(false)
    props.handelAddItem(type, value, 'children')
  }


  const getColors = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown` }, { method: "GET", token: true })
    const reqCustom = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown?own=true` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListColor(req.data)
      setListColorCustom(reqCustom.data)
    }
  }

  const confirmDeleteColor = async (item: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/${item.id}` }, { method: "DELETE", token: true })
    if (req.status == true) {
      setDisplayPage(false)
      getColors()
    } else setDisplayPage(false)
  };

  useEffect(() => {
    getColors()
  }, [])


  const onChangeImage = async (response: any, name: string) => {
    let data: any = { ...selectedImages };
    data[name] = response;
    setSelectedImages(data)
  };

  const onChangeUpload = async (response: any, name: string, index: any) => {
    if (!response) return
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        if (name === "gltf_dir") data[i][name] = response
        else data[i][name].push(response.path)
      }
    }
    props.setChildList(data)
  };


  const onChangeColor = (e: any) => {
    let data: any = { ...selectedImages };
    data['hexes'] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    };
    setSelectedImages(data)
  }


  const onRemoveModel = async (name: string, index: any) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        data[i][name] = null
      }
    }
    props.setChildList(data)
  };

  const addNewChildToColorInput = () => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr.push({
      'green': "",
      'red': "",
      'blue': "",
    })
    setListOfColorFromInput([...lastArr])
  }

  const onChangeChildToColorInput = (index: any, e: any) => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr[index] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    }
    setListOfColorFromInput([...lastArr])
  }

  const deleteColorfromInput = (item: any) => {
    let lastArr = [...listOfColorFromInput]
    if (lastArr.length === 1) return
    let findIndex = lastArr.findIndex(e => item.green == e.green && item.red == e.red)
    lastArr.splice(findIndex, 1)
    setListOfColorFromInput([...lastArr])
  }

  const selectChildren = (item: any, index: any) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        data[i]["isSelected"] = !data[i].isSelected
      }
    }
    props.setChildList(data)
  }


  return (

    <>
      {displayPage && <LoadingPage />}
      <h4 className=" text-glasses-text-50 text-[25px] mt-8">Variants Mangement (Sph, price, stock ...) </h4>
      <GenerateCustomVariantsComp
        childList={props.childList}
        setChildList={props.setChildList}
        isSelectedBoxGenerate={isSelectedBoxGenerate}
        setIsSelectedBoxGenerate={setIsSelectedBoxGenerate}
        onFinishForm={props.onFinishAdd}
      />
      {/* <ManageSelectedVariantsInputComp
        childList={props.childList}
        setChildList={props.setChildList}
        isSelectedBoxManage={isSelectedBoxManage}
        setIsSelectedBoxManage={setIsSelectedBoxManage}
      />
      <SpecialSelectionComp
         childList={props.childList}
         setChildList={props.setChildList}
         isSelectedBoxSpecialSelection={isSelectedBoxSpecialSelection}
         setIsSelectedBoxSpecialSelection={setIsSelectedBoxSpecialSelection}
      /> */}

      {/* {props.childList.map((item: any, index: any) =>
        <div key={index} className="w-full flex flex-col border border-dashed border-gray-300 rounded mt-2 p-2">
          <div className=" w-full h-9 flex items-center justify-between ">

            <div onClick={() => { selectChildren(item, index) }}
              className={` w-[20px] h-[20px] flex justify-center items-center border rounded-md cursor-pointer ${item.isSelected ? "border-glasses-head-50" : "border-gray-500"} hover:border-glasses-head-50 `}>
              {item.isSelected && <span className=" bg-glasses-head-50 w-[16px] h-[16px] rounded-md" />}
            </div>
              <div onClick={() => removeChild(index)} className=" w-[20px] h-[20px] rounded-full bg-gray-300 flex justify-center items-center text-white text-xs cursor-pointer mr-[10px]">x</div>
          </div>

          <div className=" w-full mt-3 flex flex-wrap">

            <div className=" w-6/12 px-1 mb-2 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Price</span>
                <div className=" w-full h-[40px]  mt-1">
                  <input value={item.price} onChange={(e) => { changeInputChild('price', index, e.target.value) }} type=" number" placeholder={'Price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Discount %</span>
                <div className=" w-full h-[40px]  mt-1">
                  <input required value={item.discount} onChange={(e) => { changeInputChild('discount', index, e.target.value) }} max={100} type="number" placeholder={'Discount'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Suggested retail price</span>
                <div className=" w-full h-[40px] mt-1">
                  <input value={item.suggestion_price} onChange={(e) => { changeInputChild('suggestion_price', index, e.target.value) }} type=" number" placeholder={'suggestion price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-6/12 px-1 mt-2">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Minimum selling price</span>
                <div className=" w-full h-[40px] mt-1">
                  <input value={item.min_price} onChange={(e) => { changeInputChild('min_price', index, +e.target.value) }} type=" number" placeholder={'Minimum selling price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 mb-2 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Stock</span>
                <div className=" w-full h-[40px]  mt-1">
                  <input value={item.stock} onChange={(e) => { changeInputChild('stock', index, e.target.value) }} type="number" placeholder={'Stock'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-6/12 px-1 mb-2 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">SPH</span>
                <div className=" w-full h-[40px]  mt-1">
                  <input value={item.sph} onChange={(e) => { changeInputChild('sph', index, e.target.value) }} type="number" placeholder={'Insert SPH'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

          </div>

        </div>
      )}

      <div onClick={addChild} className="w-full h-[40px] border border-dashed border-glasses-text-50 rounded mt-4 flex justify-center items-center cursor-pointer">
        <span className=" text-glasses-text-50 " >+ Add new SPH , price and stock </span>
      </div> */}

    </>
  );
};

export default ProductAddContactLensWidget;
