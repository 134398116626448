import { useEffect, useState } from "react";
import CModal from "../../../widget/CModal/CModal";
import { TrackOrderApi } from "../../../widget/apis/orders";



const ModalTrackOrders = ({ setVisible, visible, selecedOrder, setDisplay, setSelectedOrder }: any) => {
    const [detailData, setDetailData] = useState<any>(null)

    const handelClose = () => {
        setVisible(false)
        setSelectedOrder(null)
        setDetailData(null)
    }

    const getData = async () => {
        setDisplay(true)
        try {
            const res = await TrackOrderApi(selecedOrder?.pickup_request_tracking?.properties?.pickup_request_no)
            setDetailData(res)
            setDisplay(false)
        }
        catch {
            setDisplay(false)
        }
    }


    useEffect(() => {
        if (visible) {
            getData()
        }
    }, [visible])

    return (
        <>
            <CModal onScap={handelClose} visible={visible} setVisible={setVisible} uId="track-order" radius={'30px'} >
                <>
                    {detailData != null &&
                        <>
                            <span className="mx-3 font-bold text-[18px] mb-1">Detail</span>
                            <div className="flex  px-3">
                                <div className="w-full flex flex-col">
                                    <div className="w-full h-[50px] border flex items-center justify-center text-center font-bold">Origin</div>
                                    <div className="w-full min-h-[50px] text-gray-500 px-2 border flex items-center justify-center text-center">{detailData.Origin}</div>
                                </div>
                                <div className="w-full flex flex-col">
                                    <div className="w-full h-[50px] border flex items-center justify-center text-center font-bold">Destination</div>
                                    <div className="w-full min-h-[50px] text-gray-500 px-2 border flex items-center justify-center text-center">{detailData.Destination}</div>
                                </div>
                                <div className="w-full flex flex-col">
                                    <div className="w-full h-[50px] border flex items-center justify-center text-center font-bold">Refrence</div>
                                    <div className="w-full min-h-[50px] text-gray-500 px-2 border flex items-center justify-center text-center">{detailData.ShipperReference}</div>
                                </div>
                            </div>


                            <div className="flex flex-col px-3 mt-5">
                                {Array.isArray(detailData.TrackingLogDetails) && detailData.TrackingLogDetails?.map((item: any, index: number) =>
                                    <div key={index} className="w-full flex flex-col mb-5">
                                        <span className="mx-3 font-bold text-[18px]  mb-1">Step {index + 1}</span>
                                        <div className="flex ">
                                            <div className="w-full flex flex-col">
                                                <div className="w-full h-[50px] border flex items-center justify-center text-center font-bold">Date</div>
                                                <div className="w-full min-h-[50px] text-gray-500 border flex items-center justify-center text-center">{item.ActivityDate}</div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <div className="w-full h-[50px] border flex items-center justify-center text-center font-bold">Time</div>
                                                <div className="w-full min-h-[50px] text-gray-500 border flex items-center justify-center text-center">{item.ActivityTime}</div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <div className="w-full h-[50px] border flex items-center justify-center text-center font-bold">Status</div>
                                                <div className="w-full min-h-[50px] text-gray-500 border flex items-center justify-center text-center">{item.Status}</div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <div className="w-full h-[50px] border flex items-center justify-center text-center font-bold">Remark</div>
                                                <div className="w-full min-h-[50px] text-gray-500 border flex items-center justify-center text-center">{item.Remarks}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="flex ">
                                    <button onClick={() => { setVisible(false) }} type={'button'} className=" w-[120px] h-10 border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                        <span>Close</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                </>
            </CModal>
        </>
    )
}

export default ModalTrackOrders;