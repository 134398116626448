import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { ContextState } from '../context/ContextMain'
import { customApi, baseUrl } from '../apis';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from "react-router-dom";
import ChatDetailMessage from './ChatDetailMessage';
import ChatProduct from './ChatProduct';
import CSkeleton from '../CSkeleton/CSkeleton';


function ChatDetail() {
  const [displayBtn, setDisplayBtn] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [skeltonImage, setSkeltonImage] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [MoreData, setMoreData] = React.useState<boolean>(true);
  const Ctx = useContext(ContextState);
  const chatBoxRef: any = useRef()
  const formRef: any = useRef()
  let params = useParams();

  const observer: any = useRef()
  //@ts-ignore
  const lastElement: any = useCallback((node: any) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (MoreData) {
          getData(currentPage + 1)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, MoreData])

  const sendImage = async (e: any) => {
    setSkeltonImage(true)
    setDisplayBtn(true)
    setTimeout(() => chatBoxRef.current?.scrollTo({ top: chatBoxRef.current.scrollHeight, behavior: "smooth" }), 1000)
    let fd = new FormData();
    fd.append('file', e.target.files[0])
    fd.append('type', 'file')
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/chats/${Ctx.pageDetailChatIsOpen.id}/messages` }, { method: "POST", body: fd, formData: true, token: true })
    setSkeltonImage(false)
    setDisplayBtn(false)
    setTimeout(() => chatBoxRef.current?.scrollTo({ top: chatBoxRef.current.scrollHeight, behavior: "smooth" }), 1000)
    getData(1)
  }

  const sendMessage = async (e: any) => {
    e.preventDefault()
    setDisplayBtn(true)
    if (!e.target[1].value.trim()) return
    let postData = JSON.stringify({
      'type': 'message',
      'content': e.target[1].value
    })
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/chats/${Ctx.pageDetailChatIsOpen.id}/messages` }, { method: "POST", body: postData, token: true })
    setDisplayBtn(false)
    e.target.reset();
    setTimeout(() => chatBoxRef.current?.scrollTo({ top: chatBoxRef.current.scrollHeight, behavior: "smooth" }), 1000)
    getData(1)
  }

  const getData = async (current: any) => {
    setLoading(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/chats/${Ctx.pageDetailChatIsOpen.id}/messages?page=${current}` }, { method: "GET", token: true })
    setLoading(false)
    if (req.status == true) {
      if (current == 1) {
        // chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight );
        setTimeout(() => chatBoxRef.current?.scrollTo({ top: chatBoxRef.current.scrollHeight, behavior: "smooth" }), 1000)
        Ctx.setChatDetailListData(req.data.data)
        setMoreData(true)
      }
      else {
        Ctx.setChatDetailListData(Ctx.chatDetailListData.concat(req.data.data))
      }
      setCurrentPage(req.data.meta.current_page)
      setLastPage(req.data.meta.last_page)
      if (req.data.meta.current_page == req.data.meta.last_page) setMoreData(false)
    }
  }

  useEffect(() => {
    let input = document.getElementById('input-chat-btn')
    let btn = document.getElementById('submit-chat-btn')
    input?.addEventListener('keypress', (e) => {
      e.preventDefault();
      btn!.click();
    })
  }, [])

  useEffect(() => {
    if (Ctx.pageDetailChatIsOpen != null) {
      getData(1)
    }
  }, [Ctx.updateChatList])

  return (
    <>
      <div ref={chatBoxRef} className="w-full h-[calc(100%-100px)] py-[4px] flex flex-col overflow-y-scroll bg-gray-100 ">
        <div className=' w-full  flex flex-col-reverse  px-[10px] relative '>
          {/* {loading &&
            <div className=' w-full h-[25px] flex justify-center absolute  left-0 right-0 top-0 z-30'>
              <div className=' w-[120px] h-[25px] rounded-[30px] bg-white shadow text-center flex justify-center items-center text-sm'>
                <span>please wait ...</span>
              </div>
            </div>
          } */}
          {Ctx.chatDetailListData && Ctx.chatDetailListData.map((item: any, index: number) =>
            <>
              {Ctx.chatDetailListData.length == index + 1 ?
                <div ref={lastElement}>
                  <ChatDetailMessage index={index} item={item} skeltonImage={skeltonImage} />
                </div>
                :
                <div>
                  <ChatDetailMessage index={index} item={item} skeltonImage={skeltonImage} />
                </div>
              }
            </>
          )}
          {skeltonImage &&
            <div className=" w-full flex justify-end">
              <div className=" w-fit max-w-[80%] shadow p-[5px]  bg-white mt-[10px] rounded-l-[10px] rounded-br-[10px] flex flex-col justify-center items-center">
                <div className=" w-[100px] h-[100px] rounded-r-[10px] rounded-bl-[10px] relative overflow-hidden" >
                  <CSkeleton />
                </div>
              </div>
            </div>
          }
        </div>

      </div>
      <form onSubmit={sendMessage} className=" w-full h-[50px] bg-white shadow flex items-center relative">
        <label className=" w-[40px] flex justify-center items-center cursor-pointer  ">
          <div className=" w-[30px] h-[30px]  rounded flex justify-center items-center cursor-pointers">
            <svg x="0px" y="0px" viewBox="0 0 218.166 218.166" className={` w-5 h-5  fill-gray-600 transition-all   `}>
              <use xlinkHref="/assets/svg/photo.svg#photo" />
            </svg>
          </div>
          <input onChange={(e: any) => sendImage(e)} type={'file'} className="hidden" />
        </label>
        <div className=" w-[calc(100%-80px)]  bg-red-400">
          <input name="message" type="text" className=" w-full h-[50px] pl-[15px] pr-[10px]" placeholder="typeing message ..." />
        </div>
        <div className=" w-[40px] flex justify-center items-center  ">
          {displayBtn == false ?
            <button id=" submit-chat-btn" className=" w-[30px] h-[30px] bg-blue-600 rounded-full flex justify-center items-center cursor-pointers">
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-5 h-5  fill-white transition-all   `}>
                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
              </svg>
            </button>
            :
            <button type='button' className=" w-[30px] h-[30px] bg-blue-600 rounded-full flex justify-center items-center cursor-pointers cursor-not-allowed" disabled>

            </button>
          }
        </div>
      </form>
    </>
  );
}

export default ChatDetail;
