import Cleave from 'cleave.js/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddCouponAPi } from '../../../widget/apis/coupon';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { ContextState } from '../../../widget/context/ContextMain'
import { CouponsPermissions } from '../../../widget/rolePermissions';
import { checkPermission } from '../../../widget/layout/Nav';
import { Button, Modal, Space } from 'antd';

interface IAddNewCouponProps {
}

const AddNewCoupon: React.FunctionComponent<IAddNewCouponProps> = ({ }) => {
    const navigate = useNavigate();
    const Ctx = useContext(ContextState);

    const [displayPage, setDisplayPage] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState(false);
    const [isBrand, setIsBrand] = useState(false);
    const [formInfo, setFormInfo] = useState<any>();

    async function addCoupon(e: any) {
        e.preventDefault()
        setFormInfo(e)
        if(Ctx.accountsList.length > 1){
            return setOpenModal(true);
        }
        else {
            return finishCreateCoupon(e, false)
        }
    }

    const finishCreateCoupon = async (e: any, isAll: boolean) => {
        if (displayPage) return
        setDisplayPage(true)
        const form: any = document.getElementById("AddCouponForm")
        try {
            const req = await AddCouponAPi(e, isAll)
            if (req.status) {
                toast(req.message, { type: "success" })
                setDisplayPage(false)
                form?.reset()
                navigate('/panel/coupon');
            } else {
                setDisplayPage(false)
                setOpenModal(false)
                toast(req.message)
            }
        }
        catch {
            setDisplayPage(false)
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("Add New Coupon")
        Ctx.setActiveItemNav({ 'menu': 'coupon', 'subMenu': 0 })
    }, [])

    useEffect(() => {
        if (!checkPermission(CouponsPermissions.add)) {
            navigate('/panel/coupon')
        }
    }, [])

    const okAllShop = () => {
        finishCreateCoupon(formInfo, true)
    };

    const noJustMyShop = () => {
        finishCreateCoupon(formInfo, false)
    };

    useEffect(()=>{
        if(localStorage.getItem('isBrand')){
            setIsBrand(Boolean(localStorage.getItem('isBrand')))
        }
    },[])

    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full md:w-6/12 lg:w-4/12  flex flex-col   ">
                <form id="AddCouponForm" name='AddCouponModal' onSubmit={addCoupon} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>
                    <div className=" w-10/12 flex flex-col my-5">
                        <span className=" text-glasses-text-50 text-sm">Discount Code</span>
                        <input type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>
                    <div className=" w-10/12 flex flex-col my-5">
                        <span className=" text-glasses-text-50 text-sm">Discount</span>
                        <Cleave placeholder="Insert the discount" required
                            className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50"
                            options={{
                                prefix: '%',
                                uppercase: true
                            }}
                        />
                    </div>
                    <div className=" w-10/12 flex flex-col my-5">
                        <span className=" text-glasses-text-50 text-sm">Count</span>
                        <input required type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                    </div>
                    <div className=" w-10/12 flex flex-col my-5">
                        <span className=" text-glasses-text-50 text-sm">Start Date</span>
                        <input required type={"date"} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                    </div>
                    <div className=" w-10/12 flex flex-col my-5">
                        <span className=" text-glasses-text-50 text-sm">Expire Date</span>
                        <input required type={"date"} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                    </div>

                    <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Create </button>
                </form>
            </div>

            <Modal
                visible={openModal}
                footer={false}
                onCancel={() => { setOpenModal(false) }}
            >
                <p className=' text-lg pr-5'>
                    {`Do you want this coupon to be applied to all of your ${isBrand ? 'brands' : 'shops'}  ?`}
                </p>
                <div className=' w-full items-center mt-3'>
                    <button onClick={okAllShop} className=" text-white rounded-lg bg-glasses-head-50 mr-2 w-[120px] h-[40px]  font-bold ">Yes </button>
                    <button onClick={noJustMyShop} className=" text-white rounded-lg bg-glasses-head-50 mr-2 w-[120px] h-[40px]  font-bold ">No </button>
                </div>

            </Modal>
        </>
    );
};

export default AddNewCoupon;
