import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd'
import { baseUrl } from '../apis';
import { UploadListType } from 'antd/lib/upload/interface';
import { toast } from 'react-toastify';

interface ICFileUploadProps {
    url: string,
    fileCount: number,
    setResponse: CallableFunction,
    customText?: string,
    listType?: UploadListType,
    keyPayload: string,
    onRemove?: CallableFunction,
    showUploadButtonDuringUpload?: boolean | any
}

const CFileUpload: React.FunctionComponent<ICFileUploadProps> = ({ fileCount, setResponse, url, customText = "Click to upload", listType = "text", keyPayload = "file", onRemove, showUploadButtonDuringUpload = null }) => {
    const [fileListLength, setFileListLength] = useState<number>(0)

    const props: UploadProps = {
        name: keyPayload,
        action: `${baseUrl}${url}`,
        headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        listType: listType,
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 4,
            format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        onChange(info) {

            if (info.file.status === 'uploading') {
                if (showUploadButtonDuringUpload !== null) {
                    if (showUploadButtonDuringUpload) {
                        if (info.fileList.length !== 0) {
                            setFileListLength(fileCount)
                        } else {
                            setFileListLength(0)
                        }
                    }
                }
            }
            if (info.file.status === 'done') {
                if (!info.file.response.status) {
                    setFileListLength(0)
                    return toast(info.file.response.message, { type: "error", autoClose: false, position: "bottom-center" })
                } else if (info.fileList.length !== 0) {
                    setFileListLength(info.fileList.length);
                    setResponse(info.file.response.data)
                    message.success(`${info.file.name} file uploaded successfully`);
                }

            } else if (info.file.status === 'error') {
                setFileListLength(0)
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: () => {
            setFileListLength(0)
            onRemove?.()
        }
    };


    return (
        <div className=' w-full'>
            <Upload {...props}>
                {fileListLength < fileCount &&
                    <>
                        <Button icon={<UploadOutlined />}>{customText}</Button>
                    </>
                }
            </Upload>
        </div>
    );
};

export default CFileUpload;
