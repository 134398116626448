import React, { useState, useContext, useEffect, useRef } from 'react';
import ProfitChart from '../../widget/chartjs/chart-profit';
import ProfitChartCalender from '../../widget/chartjs/chart-profit-calender';
import { ContextState } from '../../widget/context/ContextMain'


function Dashboard() {
  const Ctx = useContext(ContextState);
  const qrRef: any = useRef()

  const downloadQr = () => {
    const source = qrRef.current;
    const fileName: any = localStorage.getItem('slug')
    var el = document.createElement("a");
    el.setAttribute("href", source.src);
    el.setAttribute("download", fileName);
    el.setAttribute("target", '_blank');
    document.body.appendChild(el);
    el.click();
    el.remove();
  }

  useEffect(() => {
    Ctx.setHeadTitle("Dashboard")
    Ctx.setActiveItemNav({ 'menu': 'dashboard', 'subMenu': 0 })
  }, [Ctx.shopLogo])

  return (
    <>
      {
        Ctx.shopLogo != null &&
        <div className=" w-full flex flex-col pt-5 ">
          <div className=' w-full flex flex-wrap'>
            <div className=' md:w-6/12  w-full overfolow-hidden  p-2'>
              <div className=' w-full bg-white p-2 rounded-lg'>
                <ProfitChart />
              </div>
            </div>
            <div className=' md:w-6/12  w-full overfolow-hidden  p-2 '>
              <div className=' w-full bg-white p-2 rounded-lg'>
                <ProfitChartCalender />
              </div>
            </div>
          </div>
          {localStorage.getItem('slug') &&
            <div className='w-full  flex items-center p-2 sm:pr-4 pr-2'>
              <div className=' md:w-6/12  w-full overfolow-hidden flex sm:flex-row flex-col bg-white rounded-lg p-5'>
                <img ref={qrRef} className='w-[180px] h-[180px] sm:m-0 m-auto rounded-lg' src={`https://api.qrserver.com/v1/create-qr-code/?size=180x180&data=https://optics4less.com/s/${localStorage.getItem('slug')}`} alt="" />
                <div className=' w-full  pl-2 flex flex-col justify-center items-center sm:mt-0 mt-3 '>
                  <span className='text-center text-lg'>
                    This is your store barcode that was generated for you, and you can print it or share it with your customers
                  </span>
                  <div className=' w-full flex justify-center items-center mt-6 '>
                      <button onClick={downloadQr} className=' bg-glasses-head-50 text-lg rounded-[30px] w-[90%] max-w-[140px] h-[50px] mx-3 text-white'>Download</button>
                    <a href={`https://optics4less.com/s/${localStorage.getItem('slug')}`} target='_blank'>
                      <div
                        className=" h-[50px] w-[50px] px-1  border-glasses-head-50 flex items-center justify-center rounded mx-3">
                        <svg x="0px" y="0px" viewBox="0 0 24 24" 
                          className={` stroke-glasses-head-50 w-10 h-10 fill-white transition-all   `}>
                          <use xlinkHref="/assets/svg/link-external.svg#link-external" />
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}

export default Dashboard;
