import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../../widget/context/ContextMain'
import BtnLoader from '../../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../../widget/apis';
import LoadingPage from '../../../../widget/Loading/LoadingPage';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { Select } from 'antd';

function ProductDuplicateWidget(props: any) {
  const { Option } = Select;
  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<any>([])
  const [visibleColor, setVisibleColor] = useState<boolean>(false)
  const [selectChildForColor, setSelectChildForColor] = useState<number>(-1)
  const [listColor, setListColor] = useState<any>([])
  const [listBrand, setListBrand] = useState<any>([])
  const [listProduct, setListProduct] = useState<any>([])



  // Function For Upload Handel

  const changeInputChild = (type: string, index: any, value: any) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        data[i][type] = value;
      }
    }
    props.setChildList(data)
    setVisibleColor(false)
  }

  const changeStockCount = (e: any, index: number, id: number) => {    
    let data = [...props.childList];
    for (let i of data[index].frame_sizes) {
      if (i.uid == id) {
        i.stock = +e.target.value;
      }
    }
    props.setChildList(data)
  }

  const changeProductSelect = (name: string) => {
    let data = [...listProduct];
    let listChild = [];
    for (let i of data) {
      if (i.slug == name) {
        props.setProductSelected(i.id)
        for (let j of i.products) {
          let newItem: any = {
            'color': j.color_image,
            'color_id': j.color_id,
            'price': 0,
            'discount': 0,
            'frame_sizes': []
          }
          for (let frame of j.frame_sizes) {
            newItem['frame_sizes'].push({
              'uid': Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
              'stock': 0,
              'frame': frame,
            })
          }
          listChild.push(newItem)
        }

      }
    }
    props.setChildList(listChild)
  }

  const changeBrand = async (e: any, type?: string) => {
    const req = await customApi({ url: `${baseUrl}glasses/dropdown?brand_id=${e}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListProduct(req.data)
      if (type && type != 'first') {
        props.setChildList([])
      }
    }
  }

  const getData = async () => {
    const req = await customApi({ url: `${baseUrl}brands/dropdown` }, { method: "GET", token: true })
    if (req.status == true) {
      setListBrand(req.data)
    }
  }


  const startFetchData = async () => {
    setDisplayPage(true)
    await getData()
    await changeBrand(props.productDetail.brand.id, 'first')
  }

  useEffect(() => {
    if (props.productDetail) {
      startFetchData()
    }
  }, [props.productDetail])


  return (

    <>
      {displayPage && <LoadingPage />}


      {props.productDetail &&
        <div className=" w-full flex flex-col mt-5 add-product-as-shop-form">
          <span className=" text-glasses-text-50 text-sm mb-2">Brand</span>
          <Select
            className=' w-full rounded'
            showSearch
            placeholder="Select a brand"
            optionFilterProp="children"
            onChange={(e: any) => changeBrand(e, 'sec')}
            defaultValue={props.productDetail.brand.id}
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {listBrand.map((item: any, index: any) =>
              <Option key={index} value={item.key}>{item.value}</Option>
            )}
          </Select>
        </div>
      }

      {listProduct.length > 0 &&
        <div className=" w-full flex flex-col mt-5">
          <span className=" text-glasses-text-50 text-sm">Delivery Day</span>
          <input type="number" name='delivery_day' defaultValue={props.productDetail.delivery_day} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
        </div>
      }

      {listProduct.length > 0 && props.productDetail &&
        <div className=" w-full flex flex-col mt-5 add-product-as-shop-form">
          <span className=" text-glasses-text-50 text-sm mb-2">Product</span>
          <Select
            className=' w-full rounded'
            showSearch
            placeholder="Select a brand"
            optionFilterProp="children"
            defaultValue={props.productDetail.glass_slug}
            onChange={changeProductSelect}
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {listProduct.map((item: any, index: any) =>
              <Option key={index} value={item.slug}>{item.name}</Option>
            )}
          </Select>
        </div>
      }

      {/* <h4 className=" text-glasses-text-50 mt-8"> Stock , price and discount </h4> */}
      {props.childList.map((item: any, index: any) =>
        <div key={index} className="w-full flex flex-col border border-dashed border-gray-300 rounded mt-2 p-2">

          {item.frame_sizes.map((value: any, key: number) =>
            <div key={key} className=" w-full  mt-2 flex">
              <div className=" w-6/12 pr-1 ">
                <div className=" w-full flex flex-col items-center">
                  <span className=" text-glasses-text-50">Frame</span>
                  <div className=" w-full h-[40px] ">
                    <input type="number" value={value.frame} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none cursor-not-allowed" disabled />
                  </div>
                </div>
              </div>
              <div className=" w-6/12 pl-1 ">
                <div className=" w-full flex flex-col items-center">
                  <span className=" text-glasses-text-50">Stock</span>
                  <div className=" w-full h-[40px] ">
                    <input type="number" onChange={(e: any) => changeStockCount(e, index, value.uid)} value={value.stock} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className=" w-full mt-2 flex">
            <div className=" w-4/12 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Color</span>
                <div className=" w-full h-[40px]  bg-white shadow rounded flex items-center justify-center text-gray-400 cursor-pointer text-sm">
                  <img src={item.color} alt="color" className=' w-[25px] h-[25px] rounded-full' />
                </div>
              </div>
            </div>

            <div className=" w-4/12 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Price</span>
                <div className=" w-full h-[40px] ">
                  <input value={item.price} onChange={(e) => { changeInputChild('price', index, e.target.value) }} type=" number" placeholder={'Price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-4/12 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Discount</span>
                <div className=" w-full h-[40px] ">
                  <input value={item.discount} onChange={(e) => { changeInputChild('discount', index, e.target.value) }} type=" number" placeholder={'Discount'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

          </div>
        </div>
      )}

    </>
  );
}

export default ProductDuplicateWidget;
