import React, { useContext, useEffect, useState } from 'react';

function ChatDetailMessage(props: any) {


  return (
    <>
      {props.item && props.item.type === "message" && props.item.is_admin === false &&
        <div key={props.index} className=" w-fit max-w-[80%] shadow p-[5px] bg-blue-600 text-white mt-[10px] rounded-r-[10px] rounded-bl-[10px]">
          <span>{props.item.content} </span>
        </div>
      }
      {props.item && props.item.type === "file"  && props.item.is_admin === false &&
        <div key={props.index} className=" w-fit max-w-[80%] shadow p-[5px] bg-blue-600 text-white mt-[10px] rounded-r-[10px] rounded-bl-[10px] flex flex-col justify-center items-center">
          <img src={props.item.content} alt="img" className=" w-[100px] rounded-r-[10px] rounded-bl-[10px]" />
        </div>
      }
      {props.item && props.item.type === "message"  && props.item.is_admin === true &&
        <div className=" w-full flex justify-end">
          <div key={props.index} className=" w-fit max-w-[80%] shadow p-[5px] bg-white mt-[10px] rounded-l-[10px] rounded-br-[10px]">
            <span>{props.item.content} </span>
          </div>
        </div>
      }
      {props.item && props.item.type === "file"  && props.item.is_admin === true &&
        <div className=" w-full flex justify-end">
          <div key={props.index} className=" w-fit max-w-[80%] shadow p-[5px]  bg-white mt-[10px] rounded-l-[10px] rounded-br-[10px] flex flex-col justify-center items-center">
            <img src={props.item.content} alt="img" className=" w-[100px] rounded-r-[10px] rounded-bl-[10px]" />
          </div>
        </div>
      }
    </>
  );
}

export default ChatDetailMessage;
