import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../widget/apis';
import { ContextState } from '../../widget/context/ContextMain';
import LoadingPage from '../../widget/Loading/LoadingPage';


type Props = {

}

const NotificationPage = (props: Props) => {
    const Ctx = useContext(ContextState);
    const [displayPage, setDisplayPage] = useState<boolean>(false)
    const [walletData, setWalletDataData] = useState<any>({})


    const getData = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/notifications` }, { method: "GET", token: true })
        if (req.status) {
            setDisplayPage(false)
            // setWalletDataData(req.data)            
        } else {
            toast(req.message)
        }
    }


    useEffect(() => {
        Ctx.setHeadTitle("Notifications")
        Ctx.setActiveItemNav({ 'menu': 'notification', 'subMenu': 0 })
        getData()
    }, [])

    return (

        <div className=' w-full flex flex-col'>
            {displayPage && <LoadingPage />}
           
           notifs

        </div>

    )
}

export default NotificationPage