import React, { useEffect, useState } from 'react'


export default function LoadingPage() {




  return (
    <div className="LoadingPage backdrop-blur-sm">
      <div className="LoadingPage-cont">
        <div className="spinner-4" />
        <span>Please wait ...</span>
      </div>
    </div>
  )
}