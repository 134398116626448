import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import { customApi, baseUrl, ImageUrl } from '../../widget/apis';
import LoadingPage from '../../widget/Loading/LoadingPage';
import TablePage from '../../widget/Table/TablePage';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CModal from '../../widget/CModal/CModal';
import { Select } from 'antd';
import Brand from '../brand';
import { checkisBrand, checkPermission } from '../../widget/layout/Nav'
import { ProductsPermissions } from '../../widget/rolePermissions';
import { ModalRefundChangeStatus, ModalRefundDetail } from './index';

type IFilterP = {
    name?: string,
    min_price?: any,
    max_price?: any,
    brand?: string,
    category?: any,
    status?: any,
}

export function Refund() {
    const Ctx = useContext(ContextState);
    const navigator = useNavigate()
    const { Option } = Select;
    const [searchParams, setSearchParams] = useSearchParams();
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [listData, setListData] = useState<any[]>([]);
    const [lastPage, setLastPage] = useState(0);
    const [visibleStatus, setVisibleStatus] = useState<boolean>(false)
    const [visibleDetail, setVisibleDetail] = useState<boolean>(false)
    const [selecedOrder, setSelectedOrder] = useState<any>(null)

    const col = [
        {
            title: "Name",
            render: (i: any) => (<>{i.receive_name != null && i.receive_name}</>)
        },
        {
            title: "Receive city",
            render: (i: any) => (<>{i.receive_city != null && i.receive_city}</>)
        },
        // {
        //     title: "Address ",
        //     render: (i: any) => (<>{i.receive_address != null && i.receive_address}</>)
        // },
        {
            title: "Phone ",
            render: (i: any) => (<>{i.receive_phone != null && i.receive_phone}</>)
        },
        // {
        //     title: "Post codez ",
        //     render: (i: any) => (<>{i.receive_postcodez != null && i.receive_postcodez}</>)
        // },
        {
            title: "Total payment",
            render: (i: any) => (<>{i.total_payment != null && i.total_payment}</>)
        },
        // {
        //     title: "Reason",
        //     render: (i: any) => (<>{i?.refund_orders?.reason != null && i?.refund_orders?.reason}</>)
        // },
        // {
        //     title: "Description",
        //     render: (i: any) => (<>{i?.refund_orders?.description != null && i?.refund_orders?.description}</>)
        // },
        {
            title: "Status",
            render: (i: any) => (
                <>
                    {i?.refund_orders?.status == 2 && <span className=" text-[#FFA500] font-bold">Under review </span>}
                    {i?.refund_orders?.status == 1 && <span className=" text-[#16BB83] font-bold">Approved </span>}
                    {i?.refund_orders?.status == 3 && <span className=" text-[#FF3A44] font-bold">Declined </span>}

                </>
            )
        },
        {
            title: "Change Status",
            render: (i: any) => (
                <>
                    {i?.refund_orders?.status != 1 &&
                        <button
                            onClick={() => handelStartChangeStatus(i)}
                            className=" h-[35px] px-3 text-white rounded-xl bg-glasses-head-50 flex items-center justify-center">
                            <svg x="0px" y="0px" viewBox="0 0 469.331 469.331"
                                className={` w-3 h-3 fill-white transition-all  `}>
                                <use xlinkHref="/assets/svg/edit.svg#edit" />
                            </svg>
                        </button>
                    }
                </>
            )
        },
        {
            title: "View Detail",
            render: (i: any) => (
                <>
                    <div
                        onClick={()=>{setVisibleDetail(true);setSelectedOrder(i)}}
                        className=" h-[35px] w-[35px] cursor-pointer px-1 border border-glasses-head-50 flex items-center justify-center rounded">
                        <svg x="0px" y="0px" viewBox="0 0 512 512"
                            className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
                            <use xlinkHref="/assets/svg/eye.svg#eye" />
                        </svg>
                    </div>
                </>
            )
        },
        // {
        //     title: "Edit",
        //     display: checkPermission(ProductsPermissions.update) ? true : false,
        //     render: (i: any) => (
        //         <>
        //             <button
        //                 className=" h-[35px] px-3 text-white rounded-xl bg-glasses-head-50 flex items-center justify-center">
        //                 <svg x="0px" y="0px" viewBox="0 0 469.331 469.331"
        //                     className={` w-3 h-3 fill-white transition-all mr-2  `}>
        //                     <use xlinkHref="/assets/svg/edit.svg#edit" />
        //                 </svg>
        //                 <span>Edit</span>
        //             </button>
        //         </>
        //     )
        // },
        // {
        //     title: "View",
        //     display: checkisBrand() ? false : true,
        //     render: (i: any) => (
        //         <>
        //             {i.id != null && i.status == 2 &&
        //                 <a href={`https://optics4less.com${i.uri}`} target='_blank'>
        //                     <div
        //                         className=" h-[35px] w-[35px] px-1 border border-glasses-head-50 flex items-center justify-center rounded">
        //                         <svg x="0px" y="0px" viewBox="0 0 512 512"
        //                             className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
        //                             <use xlinkHref="/assets/svg/eye.svg#eye" />
        //                         </svg>
        //                     </div>
        //                 </a>
        //             }
        //         </>
        //     )
        // },
    ]

    const handelStartChangeStatus = (order: any) => {
        setSelectedOrder(order)
        setVisibleStatus(true)
    }


    const changePage = (status: any) => {
        if (status) {
            if (lastPage >= currentPage + 1) {
                getData(currentPage + 1)
            }
        } else {
            if (currentPage > 1) {
                getData(currentPage - 1)
            }
        }
    }

    const getData = async (current: any) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/refund_order/list` }, {
            method: "GET",
            token: true
        })
        setDisplayPage(false)
        if (req.status == true) {
            setListData([...req.data])
            // setCurrentPage(req.data.meta.current_page)
            // setLastPage(req.data.meta.last_page)
            // setSelectedBrand(null)
            // setSelectedCat(null)
            if (searchParams.get('page')) {
                searchParams.delete('page');
                setSearchParams(searchParams);
            }
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("List Refunds")
        Ctx.setActiveItemNav({ 'menu': 'products', 'subMenu': 0 })
        if (searchParams.get('page') != null) {
            getData(searchParams.get('page'))
        } else {
            getData(currentPage)
        }
    }, [])



    useEffect(() => {
        if (!checkPermission(ProductsPermissions.view)) {
            navigator('/panel/dashboard')
        }
    }, [])


    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full  flex flex-col   ">


                {/* --------------------------- Table --------------------------- */}
                <TablePage columns={col} data={listData}>
                    {currentPage != 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(false)}>
                            <svg x="0px" y="0px" viewBox="0 0 24 24"
                                className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
                            </svg>
                        </div>
                    }

                    {new Array(lastPage).fill("any").map((item: any, index: number) =>
                        <div key={index} onClick={() => getData(index + 1)}
                            className={`table-counter-main-item ${index + 1 == currentPage && 'table-counter-main-item-active'}`}>{index + 1} </div>
                    )}
                    {lastPage >= currentPage + 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(true)}>
                            <svg x="0px" y="0px" viewBox="0 0 24 24"
                                className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
                            </svg>
                        </div>
                    }
                </TablePage>
            </div>

            <ModalRefundChangeStatus
                visible={visibleStatus}
                setVisible={setVisibleStatus}
                selecedOrder={selecedOrder}
                setSelectedOrder={setSelectedOrder}
                currentPage={currentPage}
                getData={getData}
            />

            <ModalRefundDetail
                visible={visibleDetail}
                setVisible={setVisibleDetail}
                selecedOrder={selecedOrder}
            />

        </>
    );
}

