import React, { useState, useEffect, useRef } from 'react';
import { Popover, TreeSelect } from 'antd';
import { toast } from 'react-toastify';


interface IGenerateCustomVariantsProps {
    setIsSelectedBoxGenerate: CallableFunction,
    isSelectedBoxGenerate: boolean,
    childList: any[],
    setChildList: CallableFunction,
    onFinishForm: CallableFunction
}

const GenerateCustomVariantsComp: React.FunctionComponent<IGenerateCustomVariantsProps> = ({
    isSelectedBoxGenerate,
    setIsSelectedBoxGenerate,
    childList,
    setChildList,
    onFinishForm
}) => {
    const positiveArr = new Array(48).fill(1).map((val, index) => ((index + 1) * 0.25))
    const negativeArr = new Array(48).fill(1).map((val, index) => (-((index + 1) * 0.25)))
    const [treeDataInputValue, setTreeDataInputValue] = useState<any[]>([]);
    const [isVariantTypeStatus, setIsVariantsTypeStatus] = useState<{
        "zero-power": boolean,
        "positive-power": boolean,
        "negative-power": boolean,
    }>({ "zero-power": true, "positive-power": true, "negative-power": true });
    const [formData, setFormData] = useState<{
        'stock': any,
        'price'?: any,
        'discount'?: any,
        'suggestion_price'?: any,
        'min_price'?: any,
        'price_zero'?: any,
        'discount_zero'?: any,
        'suggestion_price_zero'?: any,
        'min_price_zero'?: any,
        'sph'?: any,
        'isSelected': false,
        'stock_type': "",
        'stock_type_zero': "",
    }>({
        'stock': 0,
        'price': 0,
        'discount': 0,
        'suggestion_price': 0,
        'min_price': 0,
        'price_zero': 0,
        'discount_zero': 0,
        'suggestion_price_zero': 0,
        'min_price_zero': 0,
        'sph': [],
        'isSelected': false,
        'stock_type': "",
        'stock_type_zero': "",
    });

    const { SHOW_PARENT } = TreeSelect;
    const formChildRef: any = useRef()


    const treeData: any = () => {
        const finalArr: any = [
            {
                title: 'Positive Powers',
                value: 'positive-power',
                key: 'positive-power',
                children: []
            },
            {
                title: 'Zero Power',
                value: 'zero-power',
                key: 'zero-power',
                children: []
            },
            {
                title: 'Negative Powers',
                value: 'negative-power',
                key: 'negative-power',
                children: []
            },
        ]
        for (var i in positiveArr) {
            finalArr[0].children?.push({
                title: positiveArr[i].toFixed(2),
                value: positiveArr[i].toFixed(2),
                key: positiveArr[i],
                stock: 0,
                isAvailable: true
            })
        }
        for (var i in negativeArr) {
            finalArr[2].children?.push({
                title: negativeArr[i].toFixed(2),
                value: negativeArr[i].toFixed(2),
                key: negativeArr[i],
                stock: 0,
                isAvailable: true
            })
        }
        finalArr[1].children?.push({
            title: "zero",
            value: 0.00,
            key: 0,
            stock: 0,
            isAvailable: true
        })
        return finalArr
    }

    useEffect(() => {
        setTreeDataInputValue(treeData())
    }, [])

    const formInputs = (inputName: string, value: any) => {
        let data: any = { ...formData };
        if (inputName === "discount" || inputName === "discount_zero") {
            if (value <= 100) data[inputName] = value;
        } else data[inputName] = value
        setFormData({ ...data })
        setChildList([])
    }

    const checkValidationList = (items: any[]): { message: any, status: boolean } => {
        let lastForm: any = { ...formData }
        const itemsCheckRes: any = items.map((item): { message: any, status: boolean } => {
            if (item == "discount" || item == "stock" || item == "discount_zero" || item == "stock_zero") {
                if (lastForm[item] == null || lastForm[item] == undefined) return { message: `the ${item} input in variants must be inserted`, status: false }
            } else {
                if (item.includes("zero")) {
                    if (isVariantTypeStatus['zero-power']) {
                        if (lastForm[item] == 0) return { message: `the ${item} input must be more than zero`, status: false }
                    }
                } else if (lastForm[item] == 0){
                    if (isVariantTypeStatus['negative-power'] && isVariantTypeStatus['positive-power']) {
                        return { message: `the ${item} input must be more than zero`, status: false }
                    }
                }
            }
            return { message: "", status: true }
        })
        for (var u in itemsCheckRes) {
            if (!itemsCheckRes[u].status) {
                return itemsCheckRes[u]
            }
        }
        return { message: "", status: true }
    }

    const onFinishAddItem = async (e: any) => {
        let listToCheckValidation: any[] = ['stock', 'price', 'discount', 'suggestion_price', 'min_price', 'price_zero', 'discount_zero', 'suggestion_price_zero', 'min_price_zero']
        let lastArr = [...treeDataInputValue]
        if (!checkValidationList(listToCheckValidation).status) {
            return toast(checkValidationList(listToCheckValidation).message, { type: "warning" })
        }
        var iniArr: any = []
        for (var i in lastArr) {
            if (lastArr[i].value === "zero-power") {
                for (var t in lastArr[i].children) {
                    lastArr[i].children[t].isAvailable && iniArr.push({
                        'stock': lastArr[i].children[t].stock,
                        'price': formData?.price_zero,
                        'discount': formData?.discount_zero,
                        'suggestion_price': formData?.suggestion_price_zero,
                        'min_price': formData?.min_price_zero,
                        'stock_type': formData?.stock_type,
                        'sph': lastArr[i].children[t].value,
                        'isSelected': true
                    })
                }
            } else {
                for (var t in lastArr[i].children) {
                    lastArr[i].children[t].isAvailable && iniArr.push({
                        'stock': lastArr[i].children[t].stock,
                        'price': formData?.price,
                        'discount': formData?.discount,
                        'suggestion_price': formData?.suggestion_price,
                        'min_price': formData?.min_price,
                        'stock_type': formData?.stock_type,
                        'sph': lastArr[i].children[t].value,
                        'isSelected': true
                    })
                }
            }
        }

        setChildList([...iniArr])
    }

    const onChangeTreeData = (e: any, item: any, inItem: any, inputType: "checkBox" | "number" | "positive-power" | "negative-power" | "zero-power") => {
        let lastArr = [...treeDataInputValue]
        let lastVariantsStatus = { ...isVariantTypeStatus }
        if (inputType === "checkBox" || inputType === "number") {
            var findIndexParent = lastArr.findIndex((e: any) => e.value === item.value)
            var findIndexChild = lastArr[findIndexParent].children.findIndex((e: any) => e.value === inItem.value)
            if (inputType === "checkBox") {
                lastArr[findIndexParent].children[findIndexChild].isAvailable = !lastArr[findIndexParent].children[findIndexChild].isAvailable
                if (inItem.title.includes("zero")) lastVariantsStatus["zero-power"] = !lastArr[findIndexParent].children[findIndexChild].isAvailable
            }
            else if (inputType === "number") lastArr[findIndexParent].children[findIndexChild].stock = +e
        }
        else if (inputType === "positive-power") {
            lastVariantsStatus[inputType] = e
            for (var t in lastArr) {
                if (lastArr[t].value === inputType) {
                    for (var u in lastArr[t].children) {
                        lastArr[t].children[u].isAvailable = e
                    }
                }
            }
        }
        else if (inputType === "negative-power") {
            lastVariantsStatus[inputType] = e
            for (var t in lastArr) {
                if (lastArr[t].value === inputType) {
                    for (var u in lastArr[t].children) {
                        lastArr[t].children[u].isAvailable = e
                    }
                }
            }
        }
        else if (inputType === "zero-power") {
            lastVariantsStatus[inputType] = e
            for (var t in lastArr) {
                if (lastArr[t].value === inputType) {
                    for (var u in lastArr[t].children) {
                        lastArr[t].children[u].isAvailable = e
                    }
                }
            }
        }
        setTreeDataInputValue(lastArr)
        setIsVariantsTypeStatus({ ...lastVariantsStatus })
        setChildList([])
    }

    return (
        <>
            <div className={` flex flex-col overflow-hidden duration-700 transition-all border border-t-4 ${isSelectedBoxGenerate ? " border-t-glasses-head-50" : "border-t-gray-300"} p-4`}>
                {/* <div className=' flex w-full justify-between items-center'>
                    <span> Generate custom group variants</span>
                    <span onClick={() => setIsSelectedBoxGenerate(!isSelectedBoxGenerate)} className={`  cursor-pointer text-xs rounded-xl p-2 ${isSelectedBoxGenerate ? "bg-glasses-head-50 " : " bg-gray-300"} text-gray-700  duration-700 transition-all  `}>
                        <img className={` w-4 ${isSelectedBoxGenerate ? " rotate-[90deg]" : " rotate-[-90deg]"} `} src='/assets/svg/arrow-left.svg' />
                    </span>
                </div> */}
                <div className={` ${isSelectedBoxGenerate ? " max-h-[3000px] opacity-100 visible mt-4 " : " max-h-0  opacity-0 invisible "} duration-700 transition-all`}>
                    <div className=' w-full flex items-center'>
                        <div className=" w-full flex items-center">
                            <Popover placement='top' content={"Does positive variants power available?"} className={" mr-1"}>
                                <input defaultChecked onChange={(e) => onChangeTreeData(e.target.checked, "", "", "positive-power")} type={"checkbox"} />
                            </Popover>
                            <span>Positive Variants</span>
                        </div>
                        <div className=" w-full flex items-center">
                            <Popover placement='top' content={"Does negative variants power available?"} className={" mr-1"}>
                                <input defaultChecked onChange={(e) => onChangeTreeData(e.target.checked, "", "", "negative-power")} type={"checkbox"} />
                            </Popover>
                            <span>Negative Variants</span>
                        </div>
                        <div className=" w-full flex items-center">
                            <Popover placement='top' content={"Does zero variant power available?"} className={" mr-1"}>
                                <input defaultChecked onChange={(e) => onChangeTreeData(e.target.checked, "", "", "zero-power")} type={"checkbox"} />
                            </Popover>
                            <span>Zero Variant</span>
                        </div>
                    </div>


                    <div className=" w-full mt-3 flex flex-wrap">
                        <div className=' w-full text-xl mb-2'>Positive & Negative Powers stock</div>

                        <div className=" w-6/12 px-1 mb-2 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Price</span>
                                <div className=" w-full h-[40px]  mt-1">
                                    <input  disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                     value={formData?.price} onChange={(e) => { formInputs('price', +e.target.value) }} type=" number" placeholder={'Price'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>

                        <div className=" w-6/12 px-1 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Discount %</span>
                                <div className=" w-full h-[40px]  mt-1">
                                    <input  disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                     value={formData?.discount} onChange={(e) => { formInputs('discount', +e.target.value) }} max={100} type="number" placeholder={'Discount'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>

                        <div className=" w-6/12 px-1 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Suggested retail price</span>
                                <div className=" w-full h-[40px] mt-1">
                                    <input  disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                     value={formData?.suggestion_price} onChange={(e) => { formInputs('suggestion_price', +e.target.value) }} type=" number" placeholder={'suggestion price'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>
                        <div className=" w-6/12 px-1 ">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Minimum selling price</span>
                                <div className=" w-full h-[40px] mt-1">
                                    <input  disabled={!isVariantTypeStatus["negative-power"] && !isVariantTypeStatus["positive-power"]}
                                     value={formData?.min_price} onChange={(e) => { formInputs('min_price', +e.target.value) }} type=" number" placeholder={'Minimum selling price'} className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                </div>
                            </div>
                        </div>
                        <div className=" w-full px-1 my-2">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Stock Type</span>
                                <select  disabled={!isVariantTypeStatus["negative-power"]}
                                 value={formData?.stock_type} onChange={(e: any) => formInputs('stock_type', e.target.value)} className=" w-full h-[40px] rounded mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                                    <option value={''} selected disabled hidden>select stock type</option>
                                    <option value={"in_stock"}>In Stock</option>
                                    <option value={"out_stock"}>Out Of Stock</option>
                                    <option value={"by_stock"}>By Stock</option>
                                </select>
                            </div>
                        </div>

                        <div className=" w-full flex flex-col px-1 mb-2 mt-3 ">
                            {treeDataInputValue.length !== 0 && treeDataInputValue.filter(e => e.value !== "zero-power").map((item: any, index: number) =>
                                <div key={index} className=' w-full flex flex-col mb-4'>
                                    <div className=' flex flex-wrap w-full items-center'>
                                        {item.children.map((inItem: any) =>
                                            <div className=" flex flex-col items-center w-[calc(33%-0.5rem)] lg:w-[calc(25%-0.5rem)] mx-1 mb-2">
                                                <span className={` text-glasses-text-50 ${!inItem.isAvailable ? " line-through text-gray-400 " : " "}`}>SPH ({inItem.title})</span>
                                                <div className=" w-full mt-1 flex items-center">
                                                    <Popover placement='top' content={"Does it power available?"} className={" mr-1"}>
                                                        <input onChange={(e) => onChangeTreeData(e.target.checked, item, inItem, "checkBox")} type={"checkbox"} checked={inItem.isAvailable} />
                                                    </Popover>
                                                    <Popover placement='top' content={"insert stock"} className={" mr-1"}>
                                                        <input onChange={(e) => onChangeTreeData(e.target.value, item, inItem, "number")} disabled={!inItem.isAvailable} defaultValue={inItem.stock} type="number" placeholder={'Insert stock'} className=" w-full mr-2 text-glasses-text-50 h-[40px] shadow rounded text-center focus:outline-none" />
                                                    </Popover>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=" w-full mt-3 flex flex-wrap">
                            <div className=' w-full text-xl mb-2'>Zero Power stock</div>

                            <div className=" w-6/12 px-1 mb-2 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Price</span>
                                    <div className=" w-full h-[40px]  mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.price_zero}
                                            onChange={(e) => { formInputs('price_zero', +e.target.value) }}
                                            type=" number"
                                            placeholder={'Price'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>

                            <div className=" w-6/12 px-1 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Discount %</span>
                                    <div className=" w-full h-[40px]  mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.discount_zero}
                                            onChange={(e) => { formInputs('discount_zero', +e.target.value) }}
                                            max={100}
                                            type="number" placeholder={'Discount'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>

                            <div className=" w-6/12 px-1 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Suggested retail price</span>
                                    <div className=" w-full h-[40px] mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.suggestion_price_zero}
                                            onChange={(e) => { formInputs('suggestion_price_zero', +e.target.value) }}
                                            type=" number"
                                            placeholder={'suggestion price'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>
                            <div className=" w-6/12 px-1 ">
                                <div className=" w-full flex flex-col items-center">
                                    <span className=" text-glasses-text-50">Minimum selling price</span>
                                    <div className=" w-full h-[40px] mt-1">
                                        <input
                                            disabled={!treeDataInputValue.filter(e => e.value === "zero-power")[0]?.children[0].isAvailable}
                                            value={formData?.min_price_zero}
                                            onChange={(e) => { formInputs('min_price_zero', +e.target.value) }}
                                            type=" number"
                                            placeholder={'Minimum selling price'}
                                            className=" w-full h-full text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                                    </div>
                                </div>
                            </div>
                            <div className=" w-full px-1 my-2">
                            <div className=" w-full flex flex-col items-center">
                                <span className=" text-glasses-text-50">Stock Type</span>
                                <select  disabled={!isVariantTypeStatus["negative-power"]}
                                 value={formData?.stock_type_zero} onChange={(e: any) => formInputs('stock_type_zero', e.target.value)} className=" w-full h-[40px] rounded mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                                    <option value={''} selected disabled hidden>select stock type</option>
                                    <option value={"in_stock"}>In Stock</option>
                                    <option value={"out_stock"}>Out Of Stock</option>
                                    <option value={"by_stock"}>By Stock</option>
                                </select>
                            </div>
                        </div>
                            <div className=" w-full flex flex-col px-1 mb-2 mt-3 ">
                                {treeDataInputValue.length !== 0 && treeDataInputValue.filter(e => e.value === "zero-power").map((item: any, index: number) =>
                                    <div key={index} className=' w-full flex flex-col mb-4'>
                                        <div className=' flex flex-wrap w-full items-center'>
                                            {item.children.map((inItem: any) =>
                                                <div className=" flex flex-col items-center w-[calc(33%-0.5rem)] lg:w-[calc(25%-0.5rem)] mx-1 mb-2">
                                                    <span className={` text-glasses-text-50 ${!inItem.isAvailable ? " line-through text-gray-400 " : " "}`}>SPH ({inItem.title})</span>
                                                    <div className=" w-full mt-1 flex items-center">
                                                        <Popover placement='top' content={"Does it power available?"} className={" mr-1"}>
                                                            <input onChange={(e) => onChangeTreeData(e.target.checked, item, inItem, "checkBox")} type={"checkbox"} checked={inItem.isAvailable} />
                                                        </Popover>
                                                        <Popover placement='top' content={"insert stock"} className={" mr-1"}>
                                                            <input onChange={(e) => onChangeTreeData(e.target.value, item, inItem, "number")} disabled={!inItem.isAvailable} defaultValue={inItem.stock} type="number" placeholder={'Insert stock'} className=" w-full mr-2 text-glasses-text-50 h-[40px] shadow rounded text-center focus:outline-none" />
                                                        </Popover>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div onClick={onFinishAddItem}
                        className=" text-white w-full flex justify-center items-center cursor-pointer rounded-lg bg-glasses-head-50 h-[30px] mt-2 font-bold mx-auto ">
                        Generate
                    </div>


                </div>
            </div>
        </>
    );
};

export default GenerateCustomVariantsComp;
