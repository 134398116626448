import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import CModal from '../../../widget/CModal/CModal';
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

function OrderBuyDetail() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [detailData, setDetailData] = useState<any>(null)
  const [visiblePay, setvisiblePay] = useState<boolean>(false)
  const [selectPayMethod, setSelectPayMethod] = useState<any>(null)
  const [listMethodPay, setListMethodPay] = useState<any>([])
  const [selectOrder, setSelectOrder] = useState<any>(null)
  let params = useParams();


  const getPaymentMethod = async (id: number, status: string) => {
    setDisplayPage(true)
    const res = await customApi({ url: `${baseUrl}orders_buy/services` }, { method: 'GET', token: true })
    setDisplayPage(false)
    if (res.status == true) {
      setSelectOrder({
        'id': id,
        'status': status
      })
      setListMethodPay(res.data)
      setvisiblePay(true)
    }
  }

  const resendPayment = async () => {
    if (selectPayMethod == null) { return toast("select pay method", { type: "info" }) }
    if (selectOrder != null && selectOrder.status == 'waiting_payment') {
      setDisplayBtn(true)
      const res = await customApi({ url: `${baseUrl}orders_buy/${selectOrder.id}/pay?service_id=${selectPayMethod.id}` }, { method: 'GET', token: true })
      setDisplayBtn(false)
      if (res.status == true) {
        window.location.href = res.data
      }
    }
    else {
      toast("false", { type: "info" })
    }

  }

  const cancelOrder = async (id: any) => {
    setDisplayPage(true)
    const res = await customApi({ url: `${baseUrl}orders_buy/${id}/cancel` }, { method: 'GET', token: true })
    setDisplayPage(false)
    if (res.status == true) {
      setDisplayPage(false)
      toast(res.message, { type: "info" })
      viewDetail()
    }
    else {
      toast("not found", { type: "info" })
    }
  }
  const viewDetail = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}orders_buy/${params.id}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setDetailData(req.data);
      Ctx.setHeadTitle(`Order ${req.data.name}`)
    }
  }



  useEffect(() => {
    Ctx.setHeadTitle("Order")
    viewDetail()
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      {detailData != null &&
        <div className=" w-full  flex flex-col   ">
          <div className=" w-full h-[60px] flex items-center justify-between">
            <div className="  px-[10px] h-[40px] text-center bg-glasses-head-50 rounded-xl text-white flex justify-center items-center">{detailData.status}</div>
            <div className='flex items-center'>
              {detailData.status == 'waiting_payment' &&
                <div onClick={() => getPaymentMethod(detailData.id, detailData.status)} className="  px-[50px] h-[40px] cursor-pointer  text-center bg-green-600 rounded-xl text-white flex justify-center items-center">Pay</div>
              }
              {!(detailData.status == 'waiting_payment' || detailData.status == 'come_back') &&
                <div onClick={() => cancelOrder(detailData.id)} className="  px-[50px] h-[40px] cursor-pointer  text-center bg-[#FF3A44] rounded-xl text-white flex justify-center items-center">Return</div>
              }
            </div>

          </div>
          <div className=" w-full flex flex-wrap mt-[20px]">
            <div className=" w-full md:w-6/12  flex flex-col">

              <div className=" w-full flex flex-col ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold"> information</h3>
                <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                  <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Shop Name</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.shop.name}</span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Receive Name</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.address.receive_name} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Receive Phone</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.address.receive_phone} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Receive Address </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.address.receive_address} </span>
                  </div>
                </div>
              </div>

              <div className=" w-full flex flex-col md:mt-[50px] mt-[30px] ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Payment information</h3>
                {detailData.payments && detailData.payments.map((item: any, index: any) =>
                  <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                    <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                      <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                        <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                          <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                        </svg>
                      </button>
                      <span className="mx-[10px] text-[#836c77] font-bold">Price</span>
                      <span className="mx-[10px] text-glasses-text-50 font-bold">{item.price} </span>
                    </div>
                    <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                      <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                        <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                          <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                        </svg>
                      </button>
                      <span className="mx-[10px] text-[#836c77] font-bold">Servise</span>
                      <span className="mx-[10px] text-glasses-text-50 font-bold">{item.pay_service_name} </span>
                    </div>
                  </div>
                )}
              </div>

            </div>
            <div className=" w-full md:w-6/12 mt-[30px] md:mt-0  flex justify-end">
              <div className=' w-full lg:w-8/12 md:w-11/12  flex flex-col'>
                <div className='w-full flex flex-col'>
                  <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Items</h3>
                  {detailData.products && detailData.products.map((item: any, index: any) =>
                    <div className=" w-full p-[10px] sm:p-[20px] mb-[10px] bg-white rounded-xl shadow flex items-center ">
                      <img src={item.image} alt="cover" className=' w-[120px]  rounded' />
                      <div className=' w-[calc(100%-120px)] pl-[10px]  flex flex-col'>
                        <span className="text-glasses-text-50 font-bold">{item.name}</span>
                        <div className=' flex items-center mt-[5px]'>
                          <span className=" text-[#836c77]  text-lg">{item.count} X</span>
                          <img src={item.color} alt="cover" className=' ml-[15px] w-[30px] h-[30px] rounded-full' />
                        </div>
                        <span className='text-[#FFA500] text-xl mt-[10px]'>$ {item.price}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>

        </div>
      }


      <CModal visible={visiblePay} setVisible={setvisiblePay} title="PayMethod" uId='PayMethod'>
        {listMethodPay.map((item: any, index: any) =>
          <div key={index} onClick={() => { setSelectPayMethod(item) }} className={` w-full h-20 rounded border mt-2 mb-4 flex items-center cursor-pointer ${selectPayMethod != null && selectPayMethod.id == item.id ? 'border-green-600' : 'border-gray-300'}`}>
            <div className=" w-8 h-full flex items-center justify-center">
              <span className={` w-4 h-4 rounded-full border flex justify-center items-center ${selectPayMethod != null && selectPayMethod.id == item.id ? 'border-green-600 ' : 'border-gray-400 '} `}>
                {selectPayMethod != null && selectPayMethod.id == item.id &&
                  <span className=" w-2 h-2 rounded-full bg-green-600"></span>
                }
              </span>
            </div>
            <img src={item.icon} alt="icon" className=" w-[80px] rounded" />
          </div>
        )}
        <div className="CModel-Bottom">
          {selectPayMethod == null &&
            <button className=" cursor-not-allowed w-[100px] h-[35px] rounded bg-gray-600" disabled={true}>Ok </button>
          }
          {selectPayMethod != null && displayBtn == false &&
            <button onClick={() => resendPayment()} className="CModel-Bottom-btn-ok" >Ok </button>
          }
          {selectPayMethod != null && displayBtn == true &&
            <button type='button' className="CModel-Bottom-btn-ok" disabled><BtnLoader /></button>
          }
          <button className="CModel-Bottom-btn-cancel" onClick={() => { setvisiblePay(false) }}>Cansel</button>
        </div>
      </CModal>


    </>
  );
}

export default OrderBuyDetail;
