import React, { useState, useEffect, useContext } from 'react';
import { ContextState } from '../../widget/context/ContextMain';
import Nav, { checkisBrand, checkPermission } from './Nav';
import Count from './Count';
import Chat from '../chat/Chat';
import FireBaseConfig from '../Firebase/FireBaseConfig';
import { ChatsPermissions } from '../rolePermissions';


function Base() {
  const [displayNav, setDisplayNav] = useState(true);
  const [displayNavMobile, setDisplayNavMobile] = useState(false);
  const Ctx = useContext(ContextState);

  useEffect(() => {
    if (checkisBrand()) {
      document.documentElement.style.setProperty('--glasses--head--', "#4de58a")
    }
  }, [])

  useEffect(() => {
    const broadcast = new BroadcastChannel('background-message');
    broadcast.onmessage = (event) => {      
      Ctx.setUpdateChatList(!Ctx.updateChatList)
    };
  }, [])


  return (
    <div className=" w-full flex ">
      <>
        <Nav displayNav={displayNav} setDisplayNav={setDisplayNav} displayNavMobile={displayNavMobile} setDisplayNavMobile={setDisplayNavMobile} />
        <Count displayNav={displayNav} setDisplayNav={setDisplayNav} displayNavMobile={displayNavMobile} setDisplayNavMobile={setDisplayNavMobile} />
        <FireBaseConfig />
        {checkPermission(ChatsPermissions.view) &&
          <Chat />
        }
      </>
    </div>
  );
}

export default Base;
