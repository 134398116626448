import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import CModal from '../../../widget/CModal/CModal';
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import { DeleteCart, PlusItem, DeleteProduct, Minusetem  , TotalCard } from '../../../widget/card/ManageCard';
import { useNavigate } from 'react-router-dom';


function CardList() {
  const [cart, setCart] = useState<any>([]);
  const [display, setDisplay] = useState<boolean>(false)
  const [selectCart, setSelectCart] = useState<any>(null)
  const [listMethodPay, setListMethodPay] = useState<any>([])
  const [selectPatMethod, setSelectPayMethod] = useState<any>(null)
  const [visibleAddAddress, setVisibleAddAddress] = useState<boolean>(false)
  const navigate = useNavigate();
  const Ctx = useContext(ContextState);

  const clearData = () => {
    const formAdd: any = document.getElementById("add-address-form");
    formAdd?.reset()
  }

  const onFinishPay = async (e: any) => {
    e.preventDefault()
    if (selectPatMethod == null) { return toast("select payment method", { type: "error" }) }
    if (selectCart == null) { return toast("select card", { type: "error" }) }
    let sortCart: any = {};
    selectCart.shopCart.map((item: any) => {
      sortCart[item.product_slug] = item.count;
    })
    let postData = {
      'products': sortCart,
      'receive_name': e.target[0].value,
      'service_id': selectPatMethod.id,
      'receive_phone': e.target[1].value,
      'receive_address': e.target[2].value,
      'total_payment': selectCart.total + +selectCart.shop.shipping_price
    }
    if (display == true) {
      toast("Please wait", { type: "info" })
      return
    }
    setDisplay(true)
    if (selectCart != null) {
      const res = await customApi({ url: `${baseUrl}orders_buy` }, { method: 'POST', body: JSON.stringify(postData), token: true })
      setDisplay(false)
      if (res.status) {
        document.location.href = `${res.data}`
      }
      else {
        toast(res.message, { type: "error" })
      }
    }
  }

  const openAddressModal = () => {
    if (selectPatMethod == null) { return toast("select payment method", { type: "error" }) }
    if (selectCart == null) { return toast("select card", { type: "error" }) }
    setVisibleAddAddress(true)
  }

  const getPaymentMethod = async () => {
    setDisplay(true)
    const res = await customApi({ url: `${baseUrl}orders_buy/services` }, { method: 'GET', token: true })
    setDisplay(false)
    if (res.status == true) {
      setListMethodPay(res.data)
    }
  }

  const ContinueShoping = async (value: any) => {
    await setSelectCart(value)
    navigate('/panel/card/list/#finallCart');
  }

  const pluseProductToCart = async (shopId: any, product_slug: any) => {
    setSelectCart(null)
    let newCard = await PlusItem(shopId, product_slug)
    setCart(newCard[0])
  }

  const deleteProductFromCart = async (shopId: any, product_slug: any) => {
    setSelectCart(null)
    let newCard = await DeleteProduct(shopId, product_slug)
    setCart(newCard[0])
  }

  const minuseProductFromCart = async (shopId: any, product_slug: any) => {
    setSelectCart(null)
    let newCard = await Minusetem(shopId, product_slug)
    setCart(newCard[0])
  }

  const deleteCart = async (shopId: any) => {
    setSelectCart(null)
    let newCard = await DeleteCart(shopId)
    setCart(newCard[0])
  }

  const updateCardShop = async (cards: any) => {
    setDisplay(true)
    for (let card of cards) {
        for (let product of card.shopCart) {
            const res = await customApi({ url: `${baseUrl}shops/products/${product.product_parent_slug}` }, { method: "GET" , token: true })
            if (res.status == true) {
                card.shop.shipping_price = res.data.shop.shipping_price;
                card.shop.feathers = res.data.shop.feathers;
                card.shop.benefits = res.data.shop.benefits;
                for(let child of res.data.children){                                                                 
                    if(child.slug == product.product_slug){            
                        product.price = child.price ;
                        product.show_price = child.show_price ;
                    }
                }
            }
        }
    }                
    let calucator_total = await TotalCard(cards)
    localStorage.setItem('cart', JSON.stringify(calucator_total))
    setCart(calucator_total)
    setDisplay(false)
}

  useEffect(() => {
    Ctx.setHeadTitle("List Card")
  }, [])


  useEffect(() => {
    if (localStorage.getItem('cart')) {
      let cart_string = localStorage.getItem('cart');
      if (typeof cart_string === 'string') {
        setCart(JSON.parse(cart_string))
        updateCardShop(JSON.parse(cart_string))
      }
    }
    getPaymentMethod()
  }, [])


  if (cart.length == 0) return <>
    <div className=' w-full m-0 flex justify-center my-[50px] flex flex-col items-center '>
      <img src='/assets/svg/empty-cart.svg' className=' w-[400px]' alt="icon" />
      <span className=' text-gray-400 text-lg mt-[50px]'>Your cart empty</span>
      <Link to={'/panel/shop/list'} >
        <button className=' px-[50px] h-[40px] mt-[20px] border rounded bg-glasses-head-50 text-white'>Buy Product</button>
      </Link>

    </div>
  </>



  return (
    <>
      {display && <LoadingPage />}
      <div className=" w-full flex flex-col lg:flex-row justify-between mt-[20px]">
        <div className=' w-full lg:w-[62%] '>
          <div className="flex flex-col  ">
            {cart.length > 0 && cart.map((item: any, index: any) =>
              <>
                {item.shopCart && item.shopCart.length > 0 &&
                  <div key={index} className='w-full flex flex-col bg-white shadow rounded-[20px] overflow-hidden mb-[20px] '>
                    <div className=' w-full  flex items-center px-[20px] py-[5px] border-b'>
                      <img src={item.shop.logo} alt="logo" className=' w-[40px] h-[40px] rounded-full' />
                      <span className=" ml-4 text-glasses-text-50">{item.shop.name}</span>
                    </div>
                    <div className=' w-full p-[15px]'>
                      {item.shopCart.map((product: any, indexPro: any) =>
                        <div key={indexPro} className=' w-full p-[20px] border-b mb-[10px] flex flex-col md:flex-row justify-center md:justify-start items-center'>
                          <img className=" h-[120px] rounded-[10px]" src={product.image} alt="product" />
                          <div className=" w-full md:w-[calc(100%-345px)] pl-[10px] md:mt-0 mt-2  flex flex-col items-center md:items-start">
                            <span className=" text-[#836c77] text-sm  md:text-lg">{product.title}</span>
                            <span className="text-[#FFA500] text-sm md:text-xl mt-[10px]">{product.price * product.count} د.إ</span>
                          </div>
                          <div className=' h-[35px] flex items-center mt-[10px] md:mt-[0]  border rounded-lg'>
                            {product.count != 1 ?
                              <div onClick={() => minuseProductFromCart(item.shop.id, product.product_slug)} className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-xl  cursor-pointer'>-</div>
                              :
                              <div onClick={() => deleteProductFromCart(item.shop.id, product.product_slug)} className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-xl  cursor-pointer'>
                                <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                                  <use xlinkHref="/assets/svg/trash.svg#trash" />
                                </svg>
                              </div>
                            }
                            <div className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-bold text-lg'>
                              {product.count}
                            </div>
                            <div onClick={() => pluseProductToCart(item.shop.id, product.product_slug)} className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-xl cursor-pointer'>+</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=" w-full h-[60px]  flex justify-end items-center" >
                      <button onClick={() => { ContinueShoping(item) }} className=" px-[10px] rounded-[20px] h-11 bg-glasses-head-50  text-white mx-2">Pay This Card</button>
                      {/* <button onClick={() => deleteCart(item.shop.id)} className="  w-[50%] rounded-lg h-11 bg-[#FFA500] text-white mx-2">Continue shopping</button> */}
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </div>

        <div id="finallCart" className=' w-full lg:w-[35%]  '>
          <div className="  shadow rounded-[20px] flex flex-col overflow-hidden ">
            <div className=" w-full bg-white   flex flex-col p-6">
              {selectCart != null &&
                <>
                  <div className=" w-full flex flex-col">
                    <h3 className="text-center text-gray-500">Select Pay Method </h3>
                    <div className=" w-full  py-[10px] flex justify-center items-center my-[20px]  ">
                      {listMethodPay.map((val: any, indexVal: any) =>
                        <div onClick={() => { setSelectPayMethod(val) }} key={indexVal} className={` w-[150px] h-[90px] relative p-[10px] rounded border mx-[10px] flex flex-col justify-center items-center cursor-pointer ${selectPatMethod != null && selectPatMethod.id == val.id ? ' border-[#FFA500]' : 'border-gray-300'}`}>
                          <img src={val.icon} alt="icon" className=" w-[100px]" />
                          {selectPatMethod != null && selectPatMethod.id == val.id &&
                            <svg x="0px" y="0px" viewBox="0 0 330 330" className={` absolute w-5 h-5 top-[-7px] right-[-7px] bg-white rounded-full fill-[#FFA500]  `}>
                              <use xlinkHref="/assets/svg/check.svg#check" />
                            </svg>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                  {selectPatMethod != null &&
                    <>
                      <h3 className="text-center text-gray-500">Summary </h3>
                      <div className=" w-full flex justify-between my-3 text-sm text-gray-700">
                        <span className="">Number </span>
                        <span>{selectCart.shopCart.length}</span>
                      </div>
                      <div className=" w-full flex justify-between my-3 border-b border-dashed border-gray-400 text-sm text-gray-700 pb-4">
                        <span className="">Shipping (د.إ)</span>
                        <span>{selectCart.shop.shipping_price}</span>
                      </div>
                      <div className=" w-auto flex justify-between my-3 text-sm text-gray-700 ">
                        <span className="font-semibold">Total {selectPatMethod.id == 1 ? "($)" : "(د.إ)"}</span>
                        <span className=" font-semibold">
                          <span>{((selectCart.total + +selectCart.shop.shipping_price) / selectPatMethod.total_calculate).toFixed(2)}</span>
                        </span>
                      </div>
                    </>
                  }
                  {display == false ?
                    <button onClick={openAddressModal} className=" w-full h-10 mt-2  text-white bg-[#FFA500] focus:outline-none">Payment</button>
                    :
                    <button type="button" className=" w-full h-10 mt-2 relative flex justify-center items-center  text-white bg-[#FFA500] focus:outline-none" disabled><BtnLoader /></button>
                  }
                </>
              }
              <div className=" w-full flex  my-3 items-center">
                <img className=" w-10 border border-gray-500  p-1" src="/assets/svg/hourglass.svg" alt="calender" />
                <div className=" flex flex-col ml-2">
                  <span className=" font-semibold text-xs">7-Day Exchange & Return</span>
                  <span className=" text-xs text-gray-700 mt-2">Enjoy 7 days to try our glasses. Send back for a free exchange or return. </span>
                </div>
              </div>
              <div className=" w-full flex  mt-3 items-center">
                <img className=" w-10 border border-gray-500  p-1" src="/assets/svg/calender.svg" alt="calender" />
                <div className=" flex flex-col ml-2">
                  <span className=" font-semibold text-xs">365-Day Frame Guarantee</span>
                  <span className=" text-xs text-gray-700 mt-2">Customers are eligible for a one-time frame replacement within 365 days.  </span>
                </div>
              </div>
            </div>
            <div className=" w-full h-[50px]  flex items-center justify-around">
              <img className=" h-16" src='/assets/svg/paypal.svg' alt="paypal" />
              <img className=" h-9 mt-1 ml-2" src='/assets/svg/hyper.svg' alt="hyper" />
            </div>
          </div>
        </div>
      </div>
      <CModal onScap={clearData} visible={visibleAddAddress} setVisible={setVisibleAddAddress} uId="Your-Address" title='Your Address'>
        <form onSubmit={onFinishPay} id="add-address-form">
          <div className=" flex flex-col w-full">
            <span className=" text-gray-500">Name :</span>
            <input type="text" className=" w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Name"} required />
          </div>
          <div className=" flex flex-col w-full">
            <span className=" text-gray-500">Phone :</span>
            <input type="text" className=" w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Phone"} required />
          </div>
          <div className=" flex flex-col w-full">
            <span className=" text-gray-500">Address :</span>
            <input type="text" className=" w-full h-[80px] border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Address"} required />
          </div>
          {display == false ?
            <button type="submit" className=" w-full h-10 bg-glasses-head-50 text-white rounded" >save</button>
            :
            <button type="button" className=" w-full h-10 bg-glasses-head-50 flex justify-center items-center text-white rounded relative" disabled><BtnLoader /></button>
          }
        </form>
      </CModal>
    </>
  );
}

export default CardList;
