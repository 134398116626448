import React, { useState, useEffect, useContext } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import { baseUrl, customApi } from '../../widget/apis';
import LoadingPage from '../../widget/Loading/LoadingPage';
import TablePage from '../../widget/Table/TablePage';
import CModal from '../../widget/CModal/CModal';
import { toast } from 'react-toastify';
import { checkPermission } from '../../widget/layout/Nav';
import { OperatorsPermissions } from '../../widget/rolePermissions';
import AddOperatorsExist from './operatorAddComponent/AddOperatorsExist';
import AddOperatorsNew from './operatorAddComponent/AddOperatorsNew';
import { Popconfirm } from 'antd';

interface EpratorProps {
}

const Eprator: React.FunctionComponent<EpratorProps> = ({ }) => {
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [listData, setListData] = useState<any[]>([]);
    const [rolesData, setRolesData] = useState<any[]>([]);
    const [selectedOperator, setSelectedOperator] = useState<any>({})
    const [selectedPermission, setSelectedPermission] = useState<any[]>([])
    const [isUserPermissionsModal, setIsUserPermissionsModal] = useState<boolean>(false);
    const [isAddUserModal, setIsAddUserModal] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<any>("");
    const [listShops, setListShops] = useState<any[]>([])
    const [tab, setTab] = useState<boolean>(true);

    const Ctx = useContext(ContextState);

    const [col, setCol] = useState([
        {
            title: "Name",
            render: (i: any) => (<>{i.name != null && <>{i.name}</>}</>)
        },
        {
            title: "Email",
            render: (i: any) => (<>{i.email != null && i.email}</>)
        },
        {
            title: "Phone",
            render: (i: any) => (<>{i.phone != null && i.phone}</>)
        },

        {
            title: "Role",
            render: (i: any) => (<>{i.role != null && i.role}</>)
        },
        {
            title: "Permissions",
            render: (i: any) => (
                <div onClick={() => {
                    getUserInfo(i.role_id)
                    setSelectedOperator(i)
                }} className=" h-[35px] px-3 border border-glasses-head-50 flex items-center justify-center rounded cursor-pointer relative group">
                    <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/eye.svg#eye" />
                    </svg>

                </div>
            )
        },

    ])

    const getData = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/users` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            setListData(req.data)
            setIsAddUserModal(false)
            setIsUserPermissionsModal(false)
        }
    }

    useEffect(() => {

        if (checkPermission(OperatorsPermissions.remove)) {
            let colData = [...col];
            colData.push(
                {
                    title: "Delete",
                    render: (i: any) => (
                        <Popconfirm placement="top" title={`Are you sure to delete ?`} onConfirm={() => deleteUser(i.id)} okText="Yes" cancelText="No">
                            <div className=" h-[35px] px-3 border border-glasses-head-50 flex items-center justify-center rounded cursor-pointer">
                                <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
                                    <use xlinkHref="/assets/svg/trash.svg#trash" />
                                </svg>
                            </div>
                        </Popconfirm>
                    )
                },
            )
            setCol(colData)
        }
    }, [])

    const getRoles = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/users/roles` }, { method: "GET", token: true })
        if (req.status == true) {
            setDisplayPage(false)
            const l = req.data.map((item: any) => ({ ...item, isSelected: false }))
            setRolesData([...l])
        } else setDisplayPage(false)
    }

    const getUserInfo = async (operatorId: string) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/users/roles/${operatorId}` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            setSelectedPermission(req.data.permissions)
            setIsUserPermissionsModal(true)
        }
    }

    const deleteUser = async (operatorId: string) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/users/${operatorId}` }, { method: "DELETE", token: true })
        if (req.status == true) {
            setDisplayPage(false)
            setSelectedPermission(req.data.permissions)
            getData()
        } else setDisplayPage(false)
    }

    const addUser = async (e: any) => {
        e.preventDefault()
        // if (checkImageRequired() == false) return
        if (displayPage) return
        if (!selectedRole) return toast("choose one role", { type: "warning" })
        setDisplayPage(true)
        let formData = new FormData()
        formData.append("email", e.target[0].value);
        formData.append("role_id", selectedRole.id);

        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/users` }, { method: "POST", body: formData, formData: true, token: true })
        if (req.status) {
            toast(req.message, { type: "success" })
            setDisplayPage(false)
            const form: any = document.getElementById("addUserForm")
            getData()
            form?.reset()
            setIsAddUserModal(false)
        } else {
            setDisplayPage(false)
            toast(req.message, { type: "warning" })
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("Operators")
        Ctx.setActiveItemNav({ 'menu': 'operators', 'subMenu': 0 })
        getData()
        getRoles()
    }, [])

    async function adduser(e: any) {
        e.preventDefault()
        if (displayPage) return
        setDisplayPage(true)

    }

    function selectPermission(item: any) {
        setSelectedRole(item)
        const newL = rolesData.map((itemsIn) => {
            if (itemsIn.id == item.id) return { ...itemsIn, isSelected: true }
            else return { ...itemsIn, isSelected: false }
        })
        setRolesData(newL)
    }

    const getShops = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}accounts` }, { method: "GET", token: true })
        if (req.status == true) {
            let list: any = [];
            for (let i of req.data) {
                i['label'] = i['value']
                i['value'] = i['key']
                list.push(i)
            }
            setListShops(list)
        }
    }

    useEffect(() => {
        getShops()
    }, [])

    return (
        <>
            {checkPermission(OperatorsPermissions.add) &&
                <div onClick={() => { setIsAddUserModal(true) }} className=" w-full h-[60px] flex items-center my-4">
                    <a className=" px-[10px] py-3 bg-glasses-head-50 hover:text-white rounded-xl text-white"> + Add New User</a>
                </div>
            }
            <div className=" w-full  flex flex-col   ">
                {displayPage && <LoadingPage />}
                <TablePage columns={col} data={listData} />
            </div>

            <CModal title={`${selectedOperator.name} Permissions`} setVisible={setIsUserPermissionsModal} visible={isUserPermissionsModal} uId='permissionOfUserModal' >
                <>
                    <div className={` flex flex-wrap w-full bg-gray-200 px-2 py-2 rounded-lg `}>
                        {Boolean(selectedPermission?.length) && selectedPermission?.map((item: any, index: number) =>
                            <span key={index} className=' px-2 py-1 bg-blue-100 text-xs mx-1 mb-2 rounded-lg shadow-md'>{item.display_name}</span>
                        )}
                    </div>
                </>
            </CModal>

            <CModal title={`Add User`} setVisible={setIsAddUserModal} visible={isAddUserModal} uId='addUserModal' >
                <>
                    <div className='w-[95%] m-auto my-[20px] h-[40px] rounded-xl border border-glasses-head-50 flex items-center relative p-1'>
                        <div onClick={() => { setTab(true) }} className={`w-[50%] h-[30px]  rounded-xl flex items-center justify-center cursor-pointer ${tab ? 'bg-glasses-head-50 text-white' : 'text-glasses-head-50'}`}>New user</div>
                        <div onClick={() => { setTab(false) }} className={`w-[50%] h-[30px]  rounded-xl flex items-center justify-center  cursor-pointer ${!tab ? 'bg-glasses-head-50 text-white' : 'text-glasses-head-50'}`}>Exist user</div>
                    </div>
                    {tab ?
                        <AddOperatorsNew
                            addUser={addUser}
                            rolesData={rolesData}
                            selectPermission={selectPermission}
                            selectedRole={selectedRole}
                            displayPage={displayPage}
                            setDisplayPage={setDisplayPage}
                            setIsAddUserModal={setIsAddUserModal}
                            getData={getData}
                            listShops={listShops}
                        />
                        :
                        <AddOperatorsExist
                            addUser={addUser}
                            rolesData={rolesData}
                            selectPermission={selectPermission}
                            selectedRole={selectedRole}
                            displayPage={displayPage}
                            setDisplayPage={setDisplayPage}
                            setIsAddUserModal={setIsAddUserModal}
                            getData={getData}
                            listShops={listShops}
                        />
                    }
                </>
            </CModal>

        </>
    );
};

export default Eprator;
