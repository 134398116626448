import CModal from "../../../widget/CModal/CModal";
import React , {useState} from "react"
import { baseUrl, customApi } from "../../../widget/apis";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { checkPermission } from "../../../widget/layout/Nav";
import { PricePermissions } from "../../../widget/rolePermissions";
import { Popconfirm, Popover } from "antd";

export declare type ModalDetailOrdersProps = {
    visible: boolean,
    setVisible: (text: boolean) => void;
    orderDetails: any,
    setDisplayPage: CallableFunction,
    changeStatus?: () => void,
    updateData?: CallableFunction
}

const ModalDetailOrders = ({ orderDetails, setVisible, visible, setDisplayPage, changeStatus, updateData }: any) => {
    const [selectedItems, setSelectedItems] = useState<any[]>([])
    const [allType, setAllType] = useState<"not_received" | "for_shop" | "for_online">()
    const [totalQuantity, setTotalQuantity] = useState<number>(0)

    function insertInList(item: any) {
        var last = [...selectedItems]
        for (var i in last) {
            if (last[i].id === item.id) {
                last[i]["isSelected"] = !item.isSelected
            }
        }
        setSelectedItems([...last])
        checkProductSelectedQuantity()
    }

    const handelSelectIsSelect = (e: any, id: any) => {
        var ini = [...orderDetails.data?.products];
        for (let i of ini) {
            if (i.id == id) {
                i['isSelected'] = e.target.value
            }
        }
        setSelectedItems(ini)
        checkProductSelectedQuantity()
    }

    function selectAll(allType: "not_received" | "for_shop" | "for_online") {
        setAllType(allType)
        var last = [...selectedItems]
        for (var i in last) {
            last[i]["isSelected"] = allType
        }
        setSelectedItems([...last])
        checkProductSelectedQuantity()
    }

    useEffect(() => {
        if (orderDetails?.data?.products) {
            var ini = orderDetails.data?.products
            for (var i in ini) {
                ini[i]["isSelected"] = 'not_received'
                ini[i]["countToC"] = +ini[i].min_price
                ini[i]["x"] = 2
            }
            setSelectedItems(ini)
        }
    }, [orderDetails?.data?.products.length, visible])

    function changeCountToC(e: any, item: any) {
        var last = [...selectedItems]
        let num = e.target.value;
        for (var i in last) {
            if (last[i].id === item.id) {
                if (Number.parseFloat(num) % 1 == 0 || num == "") {
                    last[i]["countToC"] = Number.parseFloat(num)
                }
                else {
                    const converted = num.toString();
                    if (converted.includes('.')) {
                        if (converted.split('.')[1].length == 1) {
                            last[i]["countToC"] = Number.parseFloat(num).toFixed(1)
                        }
                        else {
                            last[i]["countToC"] = Number.parseFloat(num).toFixed(2)
                        }
                    };
                }
            }
        }
        setSelectedItems([...last])
    }

    function zarbCount(percentage: number, item?: any) {
        if (!percentage) return
        var last = [...selectedItems]
        if (!item) {
            for (var i in last) {
                if (last[i].isSelected == 'for_online') {
                    last[i]["countToC"] = (+last[i].min_price + (+last[i].min_price * (percentage / 100))).toFixed(2)
                    last[i]["x"] = (percentage / 100)
                }
            }
        } else {
            for (var i in last) {
                if (last[i].isSelected == 'for_online') {
                    if (last[i].id === item.id) {
                        last[i]["x"] = (percentage / 100)
                        last[i]["countToC"] = (+last[i].min_price + (+last[i].min_price * (percentage / 100))).toFixed(2)
                    }
                }
            }
        }
        setSelectedItems([...last])
    }

    async function createProduct() {
        const postBody: any = {
            variants: [],
            received_ids: [],
        }
        var last = [...selectedItems]
        for (var i in last) {
            if (last[i].isSelected == 'for_online' || last[i].isSelected == 'for_shop') {
                if (+last[i].countToC < +last[i].min_price) return toast("The price can't be less than minimum selling price", { type: 'warning' })
                if (last[i].isSelected == 'for_online') {
                    postBody.variants.push({
                        id: last[i].id,
                        price: parseInt(last[i].countToC),
                        count: last[i].count,
                    })
                }
                if (last[i].isSelected == 'for_shop') {
                    postBody.received_ids.push(last[i].id)
                }
            }
        }
        // if (!postBody.variants.length) return toast("No selected item", { type: 'warning' })
        setDisplayPage(true)
        const res = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/my_orders/${orderDetails.itemId}/add_product` }, { method: "POST", body: JSON.stringify(postBody), token: true })
        if (res.status) {
            toast(res.message, { type: "success" })
            setDisplayPage(false)
            setSelectedItems([])
            setVisible(false)
            updateData()
        } else {
            toast(res.message, { type: "error" })
            setDisplayPage(false)
        }
    }

    const checkProductSelectedQuantity = () => {
        let quantity = 0;
        if (orderDetails?.data.status != 1) {
            for (let i of selectedItems) {
                if (i.isSelected == 'for_online' || i.isSelected == 'for_shop') {
                    quantity = quantity + i.count;
                }
            }
        }
        else {
            for (let i of orderDetails.data?.products) {
                quantity = quantity + i.count;
            }
        }
        setTotalQuantity(quantity)
    }

    useEffect(() => {
        if (orderDetails) {
            checkProductSelectedQuantity()
        }

    }, [orderDetails])

    const onCloseModal = () => {
        selectAll("not_received")
    }

    return (
        <>
            <CModal onScap={() => onCloseModal()} visible={visible} setVisible={setVisible} width={'650px'} uId="Detail-order" radius={'30px'} >
                <>
                    {orderDetails?.data != null &&
                        <div className=" px-3">
                            <div className=" w-full  p-3 flex items-center justify-between">
                                <div className=" flex items-center w-full justify-between ">
                                    <div className=" flex items-center ">
                                        <img src={orderDetails?.data.shop.logo} alt="logo" className=" w-12 h-12 rounded-full shadow" />
                                        <span className=" ml-3 text-gray-600 ">{orderDetails?.data.shop.name}</span>
                                    </div>
                                    {orderDetails?.data.status == 8 ?
                                        <div className='border border-glasses-head-50 rounded mr-8 p-1 text-xs text-glasses-head-50'>Pending order</div>
                                        :
                                        orderDetails?.data.status == -1 &&
                                        <div className='border border-red-600 rounded mr-8 p-1 text-xs text-red-600'>Order has been canceled</div>
                                    }
                                </div>
                                <div onClick={() => { setVisible(false) }} className="  text-gray-500 rounded cursor-pointer flex items-center justify-center ">X</div>
                            </div>
                            <div className='w-full flex items-center justify-between mb-4'>
                                <span className=" text-[20px]">Order Number : <strong>#{orderDetails?.data.name}</strong></span>
                                {orderDetails?.data.status == 8 &&
                                    <Popconfirm placement="top" title={`Are you sure to delete ?`} onConfirm={changeStatus} okText="Yes" cancelText="No">
                                        <button className='border bg-red-500 rounded p-1 text-white'>Cancel order</button>
                                    </Popconfirm>
                                }
                            </div>
                            <div className=" w-full flex flex-col p-3 bg-gray-50 mb-2">
                                <span>Addess :</span>
                                <span className=" my-1 text-gray-600 pb-2 text-xs"> {orderDetails?.data.address.receive_address}</span>
                                <span>Phone :</span>
                                <span className=" my-1 text-gray-600 text-xs">{orderDetails?.data.address.receive_phone}</span>
                            </div>
                            {orderDetails?.data.status != 1 && orderDetails?.data.status !== 8 && orderDetails?.data.status !== -1 &&
                                <div className=" bg-blue-500 rounded-xl shadow-lg p-2 text-white text-xs mb-4"> - Click to select and confirm the items</div>
                            }
                            {orderDetails?.data.status != 1 && orderDetails?.data.status !== 8 && orderDetails?.data.status !== -1 &&
                                <div className=" w-full flex flex-wrap my-2  justify-between">
                                    <div className={` w-full flex items-center justify-center mb-2 `}>
                                        <button onClick={() => selectAll("not_received")} className={` text-[12px] mx-2 bg-gray-100 rounded-lg px-2 ${allType === "not_received" ? " border border-blue-400 " : " border-none "}`}>Select All Not Received</button>
                                        <button onClick={() => selectAll("for_online")} className={` text-[12px] mx-2 bg-gray-100 rounded-lg px-2 ${allType === "for_online" ? " border border-blue-400 " : " border-none "}`}>Select All For Online</button>
                                        <button onClick={() => selectAll("for_shop")} className={` text-[12px] mx-2 bg-gray-100 rounded-lg px-2 ${allType === "for_shop" ? " border border-blue-400 " : " border-none "}`}>Select All For Shop</button>
                                    </div>
                                    <span />
                                    <div className=" flex w-full justify-center ">
                                        {[20, 30, 40, 50, 100].map((percentage) =>
                                            <button onClick={() => zarbCount(percentage)} className=" text-[12px] bg-gray-100 rounded-lg px-2 mx-1 ">+{percentage}%</button>
                                        )}
                                    </div>
                                </div>
                            }
                            {selectedItems.length > 0 && selectedItems?.map((item: any, index: any) =>
                                <div key={index} className={` ${item.isSelected == 'for_online' && "border-l-green-500 "} ${item.isSelected == 'for_shop' && "border-l-blue-500 "} border-l-4 w-full p-3 flex items-center justify-between bg-gray-50 border-b duration-300 transition-all`}>
                                    <span>{index + 1}</span>
                                    <div className={`flex items-center ${orderDetails?.data.status == 1 && 'w-full justify-around'}`}>
                                        {/* {orderDetails?.data.status != 1 && orderDetails?.data.status !== 8 && orderDetails?.data.status !== -1 &&
                                            <div onClick={() => insertInList(item)} className=" border border-green-400 w-[15px] h-[15px] flex justify-center cursor-pointer items-center mr-2 rounded-sm">
                                                {item.isSelected ? <div className=" bg-green-400 w-[10px] h-[10px] rounded-sm" /> : ""}
                                            </div>
                                        } */}
                                        <img src={item.image ? item.image : "/assets/media/reopen.png"} alt="image" className=" w-[80px] rounded" />
                                        <div className="border flex flex-col justify-center items-center px-2 ml-2 rounded-xl text-[11px] bg-white">
                                            <small className="">Price </small>
                                            {item.price_with_discount ?
                                                <div className=" flex flex-col justify-center items-center  ">
                                                    <span className=" text-[10px] line-through ">
                                                        {item.price}
                                                    </span>
                                                    <span className=" text-sm">
                                                        {item.price_with_discount}
                                                    </span>
                                                </div>
                                                :
                                                <span className=" text-sm">
                                                    {item.price}
                                                </span>
                                            }
                                        </div>
                                        <div className=" flex ml-2 justify-center items-center max-w-[150px] mr-2" >
                                            {item.type === "contact_lens" ?
                                                <div className=' flex w-full flex-col justify-center items-center'>
                                                    <div className=' w-full flex justify-between px-2 items-center bg-gray-200 mb-2 rounded'>
                                                        <span className=" text-xs  mr-6"> SPH</span>
                                                        <span className=' text-xs '>{item.sph}</span>
                                                    </div>
                                                    <div className=' w-full flex justify-between px-2 items-center bg-gray-200 rounded'>
                                                        <span className=" text-xs  mr-6"> Count</span>
                                                        <span className=' text-xs '>{item.count}</span>
                                                    </div>
                                                </div>
                                                :
                                                <div className=' w-full flex items-center justify-around'>
                                                    <img className=" w-[20px] h-[20px] rounded-full bg-red-100"
                                                        src={item.color?.image ? item.color?.image : "/assets/media/reopen.png"} />
                                                    <span className=" text-[11px] px-2"> X</span>
                                                    <span>{item.count}</span>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                    <div className=" flex w-[50px] justify-center items-center flex-col">
                                        {item.suggestion_price ?
                                            <div className=" flex flex-col justify-center items-center bg-white border px-2 py-1  rounded-xl">
                                                <span className=" text-[8px] text-center">Suggested retail price</span>
                                                <span className=" text-xs text-center">{item.suggestion_price}<span className=" text-[10px] ml-1">AED</span></span>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                    <div className=" h-10 border-r" />
                                    {orderDetails?.data.status != 1 && orderDetails?.data.status !== 8 && orderDetails?.data.status !== -1 &&
                                        <div className="   flex justify-center items-center flex-col ">
                                            <select onChange={(e: any) => handelSelectIsSelect(e, item.id)} value={item.isSelected} className=" w-[90px] h-[20px] rounded-2xl text-[11px] px-[2px] text-center">
                                                <option value={'not_received'}>{'Not received'}</option>
                                                <option value={'for_online'}>{'For online'}</option>
                                                <option value={'for_shop'}>{'For shop'}</option>
                                            </select>
                                        </div>
                                    }
                                    {orderDetails?.data.status != 1 && <div className=" h-10 border-r" />}
                                    {(orderDetails?.data.status != 1 && orderDetails?.data.status !== 8 && orderDetails?.data.status !== -1 && item.isSelected == 'for_online') ?
                                        <div className=" flex justify-center items-center">
                                            <select onChange={(e: any) => zarbCount(e.target.value, item)} className=" text-xs text-center bg-[#ddd] rounded-xl focus:outline-none">
                                                <option value={''} className=" text-gray-400" selected disabled hidden>{`?%`}</option>
                                                {[20, 30, 40, 50, 100].map((item) => <option className=' ' value={item}>+{item}%</option>)}
                                            </select>
                                            <>
                                                <Popover title={"Solve Error"} placement="top" color="#edd1d1" content={`This item cannot be sold less than the minimum selling price / Minimum Price is ${item.min_price} AED`} visible={item.countToC < item.min_price}>
                                                    <input
                                                        disabled={orderDetails?.data.status === 8}
                                                        defaultValue={item.min_price}
                                                        value={item.countToC}
                                                        onChange={(e) => { changeCountToC(e, item) }}
                                                        type={"number"}
                                                        className={` w-[70px] mx-1 rounded-xl border text-center text-xs p-1  ${item.countToC < item.min_price ? " border border-red-400 " : " border border-green-400 "}`}
                                                    />
                                                    <span className=" text-[10px]">AED</span>
                                                </Popover>
                                            </>
                                        </div>
                                        :
                                        <div className="w-[150px]" />
                                    }
                                </div>
                            )}
                            <div className=" w-full flex items-center justify-center">
                                {checkPermission(PricePermissions) &&
                                    <div className=" w-full flex flex-col p-3">
                                        <span className=" text-gray-600">Subtotal :</span>
                                        <span className="text-lg">AED {orderDetails?.data.total_payment}</span>
                                    </div>
                                }
                                <div className=" w-full flex flex-col p-3">
                                    <span className=" text-gray-600">Total quantity :</span>
                                    <span className="text-lg">{totalQuantity}</span>
                                </div>
                                <div />
                            </div>
                            <div className=" w-full flex items-center">

                                {orderDetails?.data.status != 1 && orderDetails?.data.status !== 8 && orderDetails?.data.status !== -1 &&
                                    <button onClick={() => { createProduct() }} className=" w-[120px] h-10 m-3 mt-[60px] bg-gr-300 flex items-center justify-center rounded-[30px] text-white">
                                        <span>Done</span>
                                    </button>
                                }
                            </div>

                        </div>
                    }
                </>
            </CModal>
        </>
    )
}

export default ModalDetailOrders;