import React, {useState} from 'react';
import TablePage from '../../../widget/Table/TablePage';

interface IDetailsModalProps {
    item: any
}

const DetailsModal: React.FunctionComponent<IDetailsModalProps> = ({ item }) => {

    const col = [
        {
            title: "Image",
            render: (i: any) => <div className='flex justify-center p-2 '><img className='w-10/12 ' src={i.image} /></div>
        },
        {
            title: "Name",
            render: (i: any) => <div>{i.name && i.name}</div>
        },
        {
            title: "Unit Price",
            render: (i: any) => <div>{i.unit_price && i.unit_price}</div>
        },
        {
            title: "Count",
            render: (i: any) => <div>{i.count && i.count}</div>
        },

        {
            title: "Discount",
            render: (i: any) => <div>{i.discount && i.discount}</div>
        },
        // {
        //     title: "Price",
        //     render: (i: any) => <div className='text-green-600 '> {i.price && i.price.toFixed(2)} AED</div>
        // },
        {
            title: "Vat (+5%)",
            render: (i: any) => <div className='text-red-600 '> {i.fee && i.fee} AED</div>
        },
        {
            title: "Hyperpay commission (-%2.6)",
            render: (i: any) => <div className='text-red-600 '> {i.bank && i.bank} AED</div>
        },
        {
            title: "Per transaction cost (-1 AED)",
            render: (i: any) => <div className='text-red-600 '> {i.aed && i.aed} AED</div>
        },
        {
            title: "Platform commission (-%10)",
            render: (i: any) => <div className='text-red-600 '> {i.optic && i.optic} AED</div>
        },
        {
            title: "Delivery fee",
            render: (i: any) => <div className={`${i.delivery_fee < 0 ? 'text-red-600' : 'text-gray-700' }  `}> {i.delivery_fee && i.delivery_fee.toFixed(2)} AED</div>
        },
        {
            title: "Final Price",
            render: (i: any) => <div className='text-lg text-green-700 '> {i.final_price && i.final_price.toFixed(2)} AED</div>
        },
    ]


    return (
        <div className='px-4 '>
            <TablePage columns={col} data={item.products} />
            <div className='flex justify-end w-full '>
                <div className='flex items-center justify-center p-8 pt-0 text-2xl w-fit'>
                    Total : <span className='px-2 py-1 ml-2 font-bold text-green-700 bg-green-100 rounded-xl'> {item.total_price} AED</span>
                </div>
            </div>
        </div>
    );
};

export default DetailsModal;
