import { Select } from 'antd';
import { useState, useEffect, useContext } from 'react';
import { SaleDashboardReport } from '../../apis/dashboard-api';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SectionLoading from '../../Loading/SectionLoading';
import { ContextState } from '../../../widget/context/ContextMain'


const ProfitChart = () => {
    const { Option } = Select;
    const [displayLoading, setDisplayLoading] = useState(true)
    const [chartValue, setChartValue] = useState([])
    const [chartCategory, setChartCategory] = useState([])
    const [chartTime, setChartTime] = useState(null)

    const Ctx = useContext(ContextState);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,

                },
            },
            y: {
                ticks: {
                    // forces step size to be 50 units
                    stepSize: 20,
                    offset: true
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Profit chart',
            },
        },
    };

    const labels = chartCategory;

    const data = {
        labels,
        datasets: [
            {
                data: chartValue,
                backgroundColor: '#4AAAC5',
                borderWidth: 0,
                borderRadius: 15,
                // borderSkipped: false
            },
        ],
    };

    async function getData(type: string) {
        try {
            const res = await SaleDashboardReport(type)
            setDisplayLoading(false)
            let chartCat: any = [];
            let chartVal: any = [];
            setChartTime(res.data.type)
            Object.entries(res.data.values).map((item: any, index) => {
                chartCat.push(item[0])
                chartVal.push(item[1])
            })
            setChartCategory(chartCat)
            setChartValue(chartVal)
        }
        catch {

        }
    }

    useEffect(() => {
        getData('week')
    }, [Ctx.shopLogo])

    return (
        <div className=" w-full flex flex-col relative">
            {displayLoading && <SectionLoading />}
            <div className=' w-full flex justify-between items-center px-2 my-3'>
                <div className='  text-lg text-glasses-text-50'>Sale</div>
                <span className='text-lg text-glasses-text-50'>{chartTime != null &&  chartTime}</span>
                <Select onChange={getData} defaultValue={'week'} className=' chart-sale-select'>
                    <Option value="week" >This week</Option>
                    <Option value="month">This month</Option>
                    <Option value="year">This year</Option>
                </Select>
            </div>
            <Bar options={options} data={data} />
        </div>
    )
}
export default ProfitChart