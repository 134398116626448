import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import CModal from '../../../widget/CModal/CModal';
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import ProductEditWidget from './widget/ProductEditWidget'
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";
import { checkPermission, checkisBrand } from '../../../widget/layout/Nav';
import { ProductsPermissions } from '../../../widget/rolePermissions';


function ProductEdit() {
  const Ctx = useContext(ContextState);
  let params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [displayBtn, setDisplayBtn] = useState<any>([])
  const [displayPage, setDisplayPage] = useState<any>([])
  const [visibleAddItem, setVisibleAddItem] = useState<boolean>(false)
  const [listData, setListData] = useState<any>(null)
  const [lensFieldKeeper, setLensFieldKeeper] = useState<any>()
  const [forceKeyToUpdateTerm, setForceKeyToUpdateTerm] = useState<number>(0)
  const [listCategory, setListCategory] = useState<any>([])
  const [singleProduct, setSingleProduct] = useState<any>(null)
  const [keepType, setKeepType] = useState<any>(null)
  const [keepBaseOrChild, setKeepBaseOrChild] = useState<any>(null)
  const [childList, setChildList] = useState<any>([])
  const [SelectedCat, setSelectedCat] = useState<any>(null);
  const [stockList, setStockList] = useState<any>([]);
  const [settingData, setSettingData] = useState<any>(null)


  const clearData = () => {
    setKeepType(null);
    setVisibleAddItem(false)
    const form: any = document.getElementById("formNewItem")
    form?.reset()
    let allSelect: any = document.getElementsByClassName("edit-form-sel")
    for (let i of allSelect) {
      if (i.value == -1) {
        i.value = ''
      }
    }
  }

  const onChangeCat = (category: any) => {
    setSelectedCat(category)
    if (category == 3) {
      if (!listData.base['contact_lens_type']) {
        let data = { ...listData }
        data.base['contact_lens_type'] = lensFieldKeeper.contact_lens_type;
        setListData({ ...data })
      }
    }
    else {
      if (listData.base['contact_lens_type']) {
        let data = { ...listData }
        delete data.base['contact_lens_type']
        setListData({ ...data })
      }
    }
    if (category != 2) {
      if (!listData.base['lens_material']) {
        let data = { ...listData }
        data.base['lens_material'] = lensFieldKeeper.lens_material;
        data['children'] = lensFieldKeeper.children;
        setListData({ ...data })
      }
    }
    else {
      if (listData.base['lens_material']) {
        let data = { ...listData }
        delete data.base['lens_material']
        delete data['children']
        setListData({ ...data })
      }
    }
  }

  type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';

  const checkValidation = (): { status: boolean, message: string, messType: TypeOptions } => {
    // return false
    if (singleProduct.children.length == 0) return { status: false, message: "please select child", messType: "info" }
    else {
      for (let i of singleProduct.children) {
        if (!i.min_price) return { status: false, message: " Min Price is Required", messType: "info" }
        if (!i.price) return { status: false, message: " Price is Required", messType: "info" }
        if (!i.color_code) return { status: false, message: "Color code is Required", messType: "info" }
        if (!i.color_id) return { status: false, message: " Frame color is Required", messType: "info" }
        if (!i.right?.path) return { status: false, message: " select image angle view is required", messType: "info" }
        if (i.left?.path == null) {
          delete i.left
        }
        if (i.top?.path == null) {
          delete i.top
          // toast("select image front view is required", { type: "info" })
          // return false
        }
        if (i.color_id == null || i.color_id == 0) return { status: false, message: " fill all child color input ", messType: "info" }
      }
      return { status: true, message: " Validation is Passed ", messType: "success" }
    }
  }

  const addStockToListANdCheckValid = () => {
    let allChild: any = [...singleProduct.children]
    if (stockList.length == 0) {
      toast("It is necessary to select the stock and frame for each pair of glasses", { type: "info" })
      return false
    }
    for (let [i, child] of allChild.entries()) {
      child['frame_sizes'] = [];
      for (let j of stockList) {
        if (j.index == i) {
          child['frame_sizes'].push(j)
        }
      }
    }
    for (let i of childList) {
      if (i.frame_sizes?.length == 0) {
        toast("It is necessary to select the stock and frame for each pair of glasses", { type: "info" })
        return false
      }
    }
    return true
  }

  const onFinishAdd = async (e: any) => {
    e.preventDefault()
    // if (!checkValidation().status) return toast(checkValidation().message, { type: checkValidation().messType })
    // if (addStockToListANdCheckValid() == false) {
    //   return
    // }
    let data = { ...singleProduct }

    data.name = data.title;
    // for (let i of data.children) {
    //   if (i?.top?.path) i.top = i.top.path;
    //   else delete i["top"]
    //   if (i?.left?.path) i.left = i.left.path;
    //   if (i?.right?.path) i.right = i.right.path
    //   else delete i["right"]
    //   if (i.color_id != null) i.color_id = i.color_id
    //   if (i.lens_color != null) i.lens_color = i.lens_color
    //   if (i.lens_type != null) i.lens_type = i.lens_type
    //   if (i.top == null) {
    //     delete i.top
    //   }
    //   if (i.left == null) {
    //     delete i.left
    //   }
    //   if (i.right == null) {
    //     delete i.right
    //   }
    // }
    setDisplayPage(true)

    // if (!listData?.children) {
    //   for (var i in data.children) {
    //     delete data.children[i]["lens_color"]
    //     delete data.children[i]["lens_type"]
    //   }
    //   delete data["lens_material"]
    // }

    // var newChildPostData: any[] = []
    // for (var i in data.children) {
    //   var imgsList = []
    //   for (var t in data.children[i].images) {
    //     imgsList.push(data.children[i].images[t].path)
    //   }
    //   for (var x in data.children[i].frame_sizes) {
    //     var NewO = { ...data.children[i], ...data.children[i].frame_sizes[x], images: imgsList }
    //     if (data.children[i].frame_sizes[x]?.isNewAdd) {
    //       NewO.variant_id = null
    //     }
    //     delete NewO.frame_sizes
    //     newChildPostData.push(NewO)
    //   }
    // }
    // data.variants = newChildPostData
    // delete data.description
    // delete data.children

    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${returnCatName(searchParams.get('categoryId'))}/${params.id}` }, { method: "POST", body: JSON.stringify(data), token: true })
    setDisplayPage(false)
    if (req.status == true) {
      await getData(searchParams.get('categoryId'))
      toast("Updated successfully", { type: "success" })
      // if (searchParams.get('page') != null) {
      //   navigate(`/panel/product/list?page=${searchParams.get('page')}`);
      // }
      // else {
      //   navigate('/panel/product/list');
      // }
    }
    else {
      toast(req.message, { type: "error" })
    }
  }


  const handelAddItem = (type: string, value: any, baseOrchild: string) => {
    if (value == -1) {
      setKeepType(type)
      setKeepBaseOrChild(baseOrchild)
      setVisibleAddItem(true)
    }
    else {
      let data = { ...singleProduct };
      data[type] = value;
      setSingleProduct(data)
    }
  }

  const onFinishAddItem = async (e: any) => {
    e.preventDefault()
    setDisplayPage(true)
    let postData = {
      'name': e.target[0].value,
      'group': keepType,
    }
    let data = { ...listData };
    data[keepBaseOrChild][keepType].push(e.target[0].value)
    const req = await customApi({ url: `${baseUrl}filters` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status) {
      setListData(data)
      toast(req.message, { type: "success" })
    }
    else {
      toast(req.message, { type: "error" })
    }
    clearData()
  }


  const getSingleData = async (dropDownList: any) => {

    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${returnCatName(searchParams.get('categoryId'))}/${params.id}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      let listStock = [];
      Object.entries(dropDownList.base).map((item: any, index) => {
        if (item[1].indexOf(req.data[item[0]]) == -1) {
          item[1].push(req.data[item[0]])
        }
      })

      var newChildren: any = []
      var serverNewStructurChildren: any[] = Object.values(req.data.children)
      for (var i in serverNewStructurChildren) {
        var childStocks: any[] = []
        var canEdit: boolean = true
        for (var x in serverNewStructurChildren[i]) {
          if (!serverNewStructurChildren[i][x].can_edit) canEdit = false
          childStocks.push({
            frame: serverNewStructurChildren[i][x].frame,
            stock: serverNewStructurChildren[i][x].stock,
            variant_id: serverNewStructurChildren[i][x].variant_id,
            can_edit: serverNewStructurChildren[i][x].can_edit
          })
        }
        var u = serverNewStructurChildren[i][0]
        delete u.variant_id
        newChildren.push({ ...u, frame_sizes: childStocks, can_edit: canEdit })
      }
      setSingleProduct({ ...req.data, children: newChildren })

      for (let [i, child] of newChildren.entries()) {
        for (let j of child.frame_sizes) {
          listStock.push({
            'uid': Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
            'index': i,
            'stock': +j.stock,
            'frame': +j.frame,
            'lens_color': child.lens_color,
            'lens_type': child.lens_type,
            'variant_id': j.variant_id,
            'can_edit': j.can_edit
          })
        }
      }
      setForceKeyToUpdateTerm(forceKeyToUpdateTerm + 1)
      setSelectedCat(req.data.category_id)
      if (req.data.category_id != 3) {
        delete dropDownList.base['contact_lens_type']
      }
      if (req.data.category_id == 2) {
        delete dropDownList.base['lens_material']
        delete dropDownList['children']
      }
      setStockList(listStock)
      setListData({ ...dropDownList })
    }
  }

  const getCategory = async () => {
    const req = await customApi({ url: `${baseUrl}categories/dropdown` }, { method: "GET", token: true })
    if (req.status == true) {
      setListCategory(req.data)
    }
  }
  const returnCatName = (catNum: any) => {
    if (+catNum === 1) return "sunglasses"
    if (+catNum === 2) return "eyeglasses"
    if (+catNum === 3) return "contact_lenses"
  }

  const getData = async (currentCat: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}filters/${returnCatName(+currentCat)}/dropdown` }, { method: "GET", token: true })
    if (req.status == true) {
      setLensFieldKeeper({
        contact_lens_type: req.data.base.contact_lens_type,
        lens_material: req.data.base.lens_material,
        children: req.data.children
      })
      // delete req.data.base["contact_lens_type"]
      // delete req.data.base["lens_material"]
      // delete req.data["children"]
      getSingleData(req.data)
      return req.data
    }
    else {
      setDisplayPage(false)
      toast(req.message, { type: "error" })
    }
  }

  const getSettingData = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/settings` }, { method: "GET", token: true })
    if (req.status) {
      setSettingData(req.data)
    } else {
      toast(req.message, { type: "error" })
    }
  }

  useEffect(() => {
    Ctx.setActiveItemNav({ 'menu': 'products', 'subMenu': 0 })
    getCategory()
    checkisBrand() && getSettingData()
  }, [])

  useEffect(() => {
    const cC: any = searchParams.get('categoryId')
    Ctx.setHeadTitle(
      <div className='flex items-center '>
        <Link to={searchParams.get('page') != null ? `/panel/product/list?page=${searchParams.get('page')}` : `/panel/product/list`} >
          <span className='mr-6 text-base cursor-pointer flex items-center text-glasses-head-50 '>
            <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-6 h-6 fill-glasses-head-50 transition-all rotate-180 `}>
              <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
            </svg>back
          </span>
        </Link>
        Edit Product ({returnCatName(+cC)}, PI: {params.id})
      </div>
    )
    if (+cC) getData(+cC)
    if (listData) {
      if (+cC) {
        onChangeCat(+cC)
      }
    }
  }, [searchParams.get('categoryId')])

  useEffect(() => {
    if (!checkPermission(ProductsPermissions.update) || !checkisBrand()) {
      navigate('/panel/product/list');
    }
  }, [])

  return (
    <>
      {displayPage && <LoadingPage />}
      <div className="  w-full md:w-6/12 lg:w-6/12 xl:w-4/12  flex flex-col  ">
        <form onSubmit={onFinishAdd} className=" w-full">

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">Name</span>
            <input value={singleProduct != null && singleProduct['title']} onChange={(e) => handelAddItem('title', e.target.value, 'base')} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
          </div>
          {/* 
          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">category</span>
            <select value={singleProduct != null && singleProduct['category_id']} onChange={(e) => { handelAddItem('category_id', e.target.value, 'base'); onChangeCat(e.target.value) }} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required >
              <option value={''} selected disabled hidden>select category</option>
              {listCategory.map((itemCat: any, index: any) =>
                <option key={index} value={itemCat.id}>{itemCat.name}</option>
              )}
            </select>
          </div> */}

          {listData != null &&
            <>
              {Object.entries(listData?.base ? listData?.base : {}).map((Pitem: any, Pindex) =>
                <div key={Pindex} className=" w-full flex flex-col mt-5">
                  <span className=" text-glasses-text-50 text-sm">{Pitem[0]}</span>
                  <select value={singleProduct != null && singleProduct[Pitem[0]]} id={`select${Pitem[0]}`} onChange={(e) => handelAddItem(Pitem[0], e.target.value, 'base')} className="edit-form-sel w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50"  >
                    <option value={''} className=" text-gray-400" selected disabled hidden>{`select ${Pitem[0]}`}</option>
                    {Pitem[1] && Pitem[1].map((item: any, index: number) =>
                      <option key={index} value={item}>{item}</option>
                    )}
                    <option key={-1} value={-1}>+ Add custom item</option>
                  </select>
                </div>
              )}
            </>
          }

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">frame width</span>
            <input value={singleProduct != null ? singleProduct['frame_width'] : ""} onChange={(e) => handelAddItem('frame_width', e.target.value, 'base')} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50" />
          </div>

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">lens width</span>
            <input value={singleProduct != null ? singleProduct['lens_width'] : ""} onChange={(e) => handelAddItem('lens_width', e.target.value, 'base')} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
          </div>

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">Delivery Day</span>
            <input type="number" value={singleProduct != null ? singleProduct['delivery_day'] : ""} onChange={(e) => handelAddItem('delivery_day', e.target.value, 'base')} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
          </div>

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">weight (g)</span>
            <input name={'weight'} value={singleProduct != null ? singleProduct['weight'] : ""} onChange={(e) => handelAddItem('weight', e.target.value, 'base')} type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
          </div>

          <div className='w-full flex items-center'>
            <button type='submit' className=" text-white rounded-lg bg-glasses-head-50 w-full h-[40px] mt-3 mb-8 font-bold ">Update Product</button>
          </div>


        </form>

        {singleProduct != null &&
          <ProductEditWidget
            updateData={() => getData(searchParams.get('categoryId'))}
            brandSetting={settingData}
            childrenDropDown={listData?.children}
            singleProduct={singleProduct}
            setSingleProduct={setSingleProduct}
            stockList={stockList}
            setStockList={setStockList}
            handelAddItem={handelAddItem}
            SelectedCat={SelectedCat}
            currentCategory={returnCatName(searchParams.get('categoryId'))}
            forceKeyToUpdateTerm={forceKeyToUpdateTerm}
          />
        }

        <CModal onScap={clearData} visible={visibleAddItem} setVisible={setVisibleAddItem} title="AddIstem" uId='AddIstem'>
          <form onSubmit={onFinishAddItem} id="formNewItem">
            <input type="text" className=" w-full h-10 border rounded my-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Your custom item"} required />
            {displayBtn == false ?
              <button type="submit" className=" w-full h-10 bg-glasses-head-50 text-white rounded" >save</button>
              :
              <button type="button" disabled><BtnLoader /></button>
            }
          </form>
        </CModal>


      </div>
    </>
  );
}

export default ProductEdit;
