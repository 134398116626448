import React, { useState, useContext, useEffect, useRef } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ProductDuplicateWidget from './widget/ProductDuplicateWidget';
import { checkPermission } from '../../../widget/layout/Nav';
import { ProductsPermissions } from '../../../widget/rolePermissions';

function ProductDuplicate() {
  const Ctx = useContext(ContextState);
  const formRef: any = useRef()
  const params = useParams()
  const navigate = useNavigate();
  const [displayPage, setDisplayPage] = useState<boolean>(false)
  const [childList, setChildList] = useState<any>([])
  const [productSelected, setProductSelected] = useState<number>(0)
  const [productDetail, setProductDetail] = useState<any>(0)

  const checkValidation = async () => {
    if (childList.length == 0) {
      toast("please select child", { type: "info" })
      return false
    }
    else {
      for (let i of childList) {
        if (i.price == 0 || i.stock == 0) {
          toast("Price and stock should not be zero", { type: "info" })
          return false
        }
      }
      return true
    }
  }

  const getProductDetail = async () => {
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${params.id}` }, { method: "GET", token: true })
    if (req.status == true) {
      let data: any = req.data.children;
      setProductSelected(req.data.glass_id)
      setProductDetail(req.data)
      for (let i of data) {
        for (let j of i.frame_sizes) {
          j['uid'] = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        }
      }
      setChildList(data)
    }
  }

  const onFinishAdd = async (e: any) => {
    e.preventDefault()
    if (await checkValidation() == false) {
      return
    }
    setDisplayPage(true)
    for (let i of childList) {
      delete i.top;
      delete i.left;
      delete i.right;
    }
    let postData = {
      "glass_id": productSelected,
      "children": childList,
      "delivery_day": formRef.current['delivery_day'].value,
    }
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status == true) {
      toast(req.message, { type: "success" })
      navigate('/panel/product/list');
    }
    else {
      toast(req.message, { type: "error" })
    }
  }


  useEffect(() => {
    Ctx.setHeadTitle("Add Product")
    Ctx.setActiveItemNav({ 'menu': 'products', 'subMenu': 0 })
    getProductDetail()

  }, [])

  useEffect(() => {
    if (!checkPermission(ProductsPermissions.add)) {
      navigate('/panel/product/list');
    }
  }, [])

  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full md:w-6/12 lg:w-4/12  flex flex-col   ">
        <form ref={formRef} onSubmit={onFinishAdd} className=" w-full">


          <ProductDuplicateWidget
            setProductDetail={setProductDetail}
            productDetail={productDetail}
            childList={childList}
            setChildList={setChildList}
            setProductSelected={setProductSelected}
          />

          <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Add Product</button>

        </form>

      </div>
    </>
  );
}

export default ProductDuplicate;
