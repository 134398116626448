import React, { useState, useContext, useEffect } from 'react';
import CModal from '../../../widget/CModal/CModal';
import { toast } from "react-toastify";


function EditProfileAddress(props: any) {
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<number>(-1)
  const [formValue, setFormValue] = useState<any>({
    'name': null,
    'mobile': null,
    'detail': null,
    'address': null
  })

  const clearData = () => {
    setFormValue({
      'name': null,
      'mobile': null,
      'detail': null,
      'address': null
    })
  }

  const onFinishDelete = () => {
    let data = { ...props.detailData };
    for (let i in data.addresses) {
      if (data.addresses[i].id == deleteItem) {
        data.addresses.splice(i, 1)
      }
    }
    setVisibleDelete(false)
    clearData()
    props.setDetailData(data)
  }

  const changeInput = (value: any, type: string) => {
    let data = { ...formValue };
    data[type] = value;
    setFormValue(data)
  }

  const onFinishAdd = () => {    
    if (formValue.name != null && formValue.name != "" && formValue.detail != null && formValue.detail != "" && formValue.mobile != null && formValue.mobile != "" && formValue.address != null && formValue.address != "") {
      let data = { ...props.detailData };
      formValue['id'] = Math.floor(100000000 + Math.random() * 900000000);
      formValue['latLng'] = { 'lat': 0, 'lng': 0 };
      data.addresses.push(formValue)      
      setVisibleAdd(false)
      clearData()
      props.setDetailData(data)
      clearData()
    }
    else {
      toast("Please fill in all the fields", { type: "error" })
    }
  }

  const onFinishEdit = () => {
    if (formValue.name != null && formValue.name != "" && formValue.detail != null && formValue.detail != "" && formValue.mobile != null && formValue.mobile != "" && formValue.address != null && formValue.address != "") {
      let data = { ...props.detailData };
      for (let i of props.detailData.addresses) {
        if (i.id == formValue.id) {
          i.name = formValue.name;
          i.mobile = formValue.mobile;
          i.detail = formValue.detail;
          i.address = formValue.address;

        }
      }
      props.setDetailData(data)
      setVisibleEdit(false)
      clearData()
    }
    else {
      toast("Please fill in all the fields", { type: "error" })
    }
  }

  return (

    <>
      <div className=' w-full flex flex-col mt-5'>
        <div className='flex justify-between mb-2'>
          <span className=' ml-3 text-glasses-text-50 text-xl font-bold'> Address</span>
          <button onClick={() => { setVisibleAdd(true) }} type="button" className=' px-[30px] border border-[#FFA500] h-[35px] rounded text-[#FFA500]' >+ new address</button>
        </div>
        {props.detailData.addresses && props.detailData.addresses.map((item: any, index: any) =>

          <div className='w-full p-[10px] sm:p-[20px] mb-2 bg-white rounded-xl shadow flex flex-col'>
            <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
              <button type='button' className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                <svg x="0px" y="0px" viewBox="0 0 490.1 490.1" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                  <use xlinkHref="/assets/svg/user.svg#user" />
                </svg>
              </button>
              <span className="mx-[10px] text-[#836c77] font-bold">Address Name</span>
              <span className="mx-[10px] text-glasses-text-50 font-bold">{item.name} </span>
            </div>
            <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
              <button type='button' className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                <svg x="0px" y="0px" viewBox="0 0 19 32" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                  <use xlinkHref="/assets/svg/phone.svg#phone" />
                </svg>
              </button>
              <span className="mx-[10px] text-[#836c77] font-bold">Mobile </span>
              <span className="mx-[10px] text-glasses-text-50 font-bold">{item.mobile} </span>
            </div>
            <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
              <button type='button' className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                <svg x="0px" y="0px" viewBox="0 0 368.666 368.666" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                  <use xlinkHref="/assets/svg/location2.svg#XMLID_2_" />
                </svg>
              </button>
              <span className="mx-[10px] text-[#836c77] font-bold">Address </span>
              <span className="mx-[10px] text-glasses-text-50 font-bold">{item.address} </span>
            </div>
            <div className=" w-full  flex items-center border-t pt-[10px]">
              <button onClick={() => { setVisibleDelete(true); setDeleteItem(item.id) }} type='button' className=" w-[35px] h-[35px] flex justify-center items-center rounded border border-[#FFA500]">
                <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                  <use xlinkHref="/assets/svg/trash.svg#trash" />
                </svg>
              </button>
              <button onClick={() => { setFormValue(item); setVisibleEdit(true) }} type='button' className=" w-[35px] h-[35px] ml-[5px] flex justify-center items-center rounded border border-[#FFA500]">
                <svg x="0px" y="0px" viewBox="0 0 469.331 469.331" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                  <use xlinkHref="/assets/svg/edit.svg#edit" />
                </svg>
              </button>
            </div>
          </div>

        )}
      </div>

      <CModal onScap={clearData} visible={visibleAdd} setVisible={setVisibleAdd} title="AddAddress" uId='AddAddress'>
        <div className=" w-full flex flex-col">
          <div className=" w-full flex flex-col mt-[10px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Name</span>
            <input value={formValue.name} onChange={(e) => changeInput(e.target.value, 'name')} type="text" className=" border h-[35px] w-full rounded px-2" placeholder="Name" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Mobile</span>
            <input value={formValue.mobile} onChange={(e) => changeInput(e.target.value, 'mobile')} type="number" className=" border h-[35px] w-full rounded px-2" placeholder="Mobile" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Detail</span>
            <input value={formValue.detail} onChange={(e) => changeInput(e.target.value, 'detail')} type="text" className=" border h-[35px] w-full rounded px-2" placeholder="Detail" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Address</span>
            <textarea value={formValue.address} onChange={(e) => changeInput(e.target.value, 'address')} className=" border h-[80px] w-full rounded px-2" placeholder="Address" />
          </div>
          <button onClick={onFinishAdd} type='button' className=" w-full h-[35px] border text-white bg-glasses-head-50 rounded mt-[15px]">Add </button>
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleDelete} setVisible={setVisibleDelete} title="DeleteAddress" uId='DeleteAddress'>
        <p>Are youe shoure delete this item ?</p>
        <div className="CModel-Bottom">
          <button type='button' onClick={onFinishDelete} className="CModel-Bottom-btn-ok" >Ok </button>
          <button type='button' className="CModel-Bottom-btn-cancel" onClick={() => { setVisibleDelete(false) }}>Cansel</button>
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleEdit} setVisible={setVisibleEdit} title="EditAddress" uId='EditAddress'>
        <div className=" w-full flex flex-col">
          <div className=" w-full flex flex-col mt-[10px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Name</span>
            <input value={formValue.name} onChange={(e) => changeInput(e.target.value, 'name')} type="text" className=" border h-[35px] w-full rounded px-2" placeholder="Name" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Mobile</span>
            <input value={formValue.mobile} onChange={(e) => changeInput(e.target.value, 'mobile')} type="number" className=" border h-[35px] w-full rounded px-2" placeholder="Mobile" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Detail</span>
            <input value={formValue.detail} onChange={(e) => changeInput(e.target.value, 'detail')} type="text" className=" border h-[35px] w-full rounded px-2" placeholder="Detail" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Address</span>
            <textarea value={formValue.address} onChange={(e) => changeInput(e.target.value, 'address')} className=" border h-[80px] w-full rounded px-2" placeholder="Address" />
          </div>
          <button onClick={onFinishEdit} type='button' className=" w-full h-[35px] border text-white bg-glasses-head-50 rounded mt-[15px]">Update </button>
        </div>
      </CModal>

    </>
  );
}

export default EditProfileAddress;
