import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContextState } from '../context/ContextMain'
import { customApi, baseUrl } from '../apis';
import InfiniteScroll from 'react-infinite-scroll-component';


function ChatList(props: any) {
  const [loading, setLoading] = useState<boolean>(false)
  const [listData, setListData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [MoreData, setMoreData] = React.useState<boolean>(true);
  const Ctx = useContext(ContextState);


  const observer: any = useRef()
  //@ts-ignore
  const lastElement: any = useCallback((node: any) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (MoreData) {
          getData(currentPage + 1)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, MoreData])



  const selectChat = async (item: any) => {
    Ctx.setPageDetailChatIsOpen(item)
  }

  const getData = async (current: any) => {
    setLoading(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/chats?page=${current}` }, { method: "GET", token: true })
    setLoading(false)
    if (req.status == true) {
      if (current == 1) {
        setListData(req.data.data)
        setMoreData(true)
      }
      else {
        setListData(listData.concat(req.data.data))
      }
      setCurrentPage(req.data.meta.current_page)
      setLastPage(req.data.meta.last_page)
      if (req.data.meta.current_page == req.data.meta.last_page) setMoreData(false)
    }
  }

  useEffect(() => {
    if (Ctx.displayChat == true) {
      getData(1)
    }
  }, [Ctx.displayChat, Ctx.updateChatList])


  return (
    <div className="w-full h-[calc(100%-50px)] flex flex-col overflow-y-scroll ">
      <div className=' w-full  flex flex-col  '>
        {listData.map((item: any, index: number) =>
          <>
            {listData.length === index + 1 ?
              <div ref={lastElement} key={index} onClick={() => selectChat(item)}>
                <ChatItem item={item} />
              </div>
              :
              <div key={index} onClick={() => selectChat(item)}>
                <ChatItem item={item} />
              </div>
            }
          </>
        )}

        {lastPage != currentPage &&
          <div className=" w-full h-[35px]  bg-blue-400 text-white flex justify-center items-center justify-center cursor-pointer ">Load more ... </div>
        }
      </div>
    </div>
  );
}

export default ChatList;




export const ChatItem = (props: any) => {
  return (
    <div className=' w-full h-[70px] px-[10px] border-b border-gray-50 flex items-center cursor-pointer transition-all hover:pl-[5px] hover:bg-gray-100'>
      <div className='  w-[35px] h-[35px] border rounded-full flex justify-center items-center'>
        <svg x="0px" y="0px" viewBox="0 0 490.1 490.1" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
          <use xlinkHref="/assets/svg/user.svg#user" />
        </svg>
      </div>
      <div className=' w-[calc(100%-35px)] flex flex-col pl-[10px]'>
        <div className='w-full flex-col flex items-center mb-[8px] '>
          <div className='w-full flex items-center justify-between'>
            <span>{props.item.display_name}</span>
            <div className='text-xs bg-gray-200 rounded-2xl flex items-center justify-center px-1'>{props.item.last_message_time != null && props.item.last_message_time.substring(0, 10)}</div>
          </div>
          <div className=' w-full  flex items-center text-xs'>{props.item.email }</div>
        </div>
        <span className=' text-xs text-gray-400'>{props.item.last_message != null && props.item.last_message.substring(0, 40)}</span>
      </div>
    </div>
  )
}