import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { AddToCart, PlusItem, DeleteProduct, Minusetem } from '../../../widget/card/ManageCard';

function ShopProductDetail() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [detailData, setDetailData] = useState<any>(null)
  const [childrenExistCard, setChildrenExistCard] = useState<any>({})
  let params = useParams();



  const AddProductToCart = async (product: any, children: any) => {
    product['slug'] = params.id ;
    let cart = await AddToCart(product, children);
    getShopCard(product.shop.id)
  }


  const pluseProductToCart = async (shopId: any, product_slug: any) => {
    let cart = await PlusItem(shopId, product_slug);
    getShopCard(shopId)
  }

  const deleteProductFromCart = async (shopId: any, product_slug: any) => {
    let cart = await DeleteProduct(shopId, product_slug);
    getShopCard(shopId)
  }

  const minuseProductFromCart = async (shopId: any, product_slug: any) => {
    let cart = await Minusetem(shopId, product_slug);
    getShopCard(shopId)
  }


  const viewDetail = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}shops/products/${params.id}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setDetailData(req.data);
      getShopCard(req.data.shop.id)
      Ctx.setHeadTitle(`Product ${req.data.title}`)
    }
  }

  const getShopCard = (shopId: any) => {
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }
    let cart_string = localStorage.getItem('cart');
    if (typeof cart_string === 'string') {
      var cart = JSON.parse(cart_string);
    }
    let data: any = {}
    for (let i of cart) {
      if (i.shop.id == shopId) {
        for (let j of i.shopCart) {
          data[j.product_slug] = j.count;
        }
        setChildrenExistCard(data)
      }
    }

  }

  useEffect(() => {
    Ctx.setHeadTitle(`Product `)
    viewDetail()
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      {detailData != null &&
        <div className=" w-full  flex flex-col   ">
          <div className=" w-full h-[60px] flex justify-between items-center">
            <Link to={`/panel/product/duplicate/${detailData.id}`}>
              <button className="  px-[10px] h-[40px] text-center bg-glasses-head-50 rounded-xl text-white flex justify-center items-center">Copy Product</button>
            </Link>
            {/* <button className=" min-w-[100px] px-[10px] h-[40px] text-center bg-[#FF3A44] rounded-xl text-white">
              Add To Card
            </button> */}
          </div>
          <div className=" w-full flex flex-wrap mt-[20px]">
            <div className=" w-full md:w-8/12  flex flex-col">

              <div className='w-full flex flex-col'>
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Chilrens</h3>
                {detailData.children && detailData.children.map((item: any, index: any) =>
                  <div className=" w-full p-[10px] sm:p-[20px] mb-[10px] bg-white rounded-xl shadow flex flex-col md:flex-row items-center ">
                    <img src={item.images[0]} alt="cover" className=' h-[120px]  rounded' />
                    <div className=' w-full md:w-[calc(100%-345px)] pl-[10px]  flex flex-col items-center md:items-start'>
                      {/* <span className="text-glasses-text-50 font-bold">{item.name}</span> */}
                      <div className=' flex items-center mt-[5px]'>
                        <span className=" text-[#836c77] text-sm  md:text-lg">{item.stock} </span>
                      </div>
                      <img src={item.color} alt="cover" className=' mt-[10px] w-[30px] h-[30px] rounded-full' />
                      <span className='text-[#FFA500] text-sm md:text-xl mt-[10px]'>{item.show_price.USD} - {item.show_price.AED}</span>
                    </div>

                    {childrenExistCard[item.slug] == undefined ?
                      <div onClick={() => AddProductToCart(detailData, item)} className=' w-[105px] h-[35px] rounded text-white flex items-center justify-center cursor-pointer mt-[10px] md:mt-[0]  bg-[#FFA500]'>
                        Add to card
                      </div>
                      :
                      <div className=' flex items-center mt-[10px] md:mt-[0]  border'>
                        {childrenExistCard[item.slug] != 1 ?
                          <div onClick={() => minuseProductFromCart(detailData.shop.id, item.slug)} className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-xl  cursor-pointer'>-</div>
                          :
                          <div onClick={() => deleteProductFromCart(detailData.shop.id, item.slug)} className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-xl  cursor-pointer'>
                            <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                              <use xlinkHref="/assets/svg/trash.svg#trash" />
                            </svg>
                          </div>
                        }
                        <div className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-bold text-lg'>
                          {childrenExistCard[item.slug]}
                        </div>
                        <div onClick={() => pluseProductToCart(detailData.shop.id, item.slug)} className=' w-[35px] h-[35px] flex justify-center items-center text-[#FFA500] text-xl cursor-pointer'>+</div>
                      </div>
                    }
                  </div>
                )}
              </div>

              <div className=" w-full flex flex-col ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Product information</h3>
                <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                  <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Product Name</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.title}</span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Product Description</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold" dangerouslySetInnerHTML={{ __html: detailData.description }} />
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Product Frame Width </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.frame_width} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Product Lens Width </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.lens_width} </span>
                  </div>
                </div>
              </div>

              <div className=" w-full flex flex-col md:mt-[50px] mt-[30px] ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Feathers</h3>
                <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                  {Object.entries(detailData.feathers).map((item: any, index) =>
                    <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                      <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                        <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                          <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                        </svg>
                      </button>
                      <span className="mx-[10px] text-[#836c77] font-bold">{item[0]}</span>
                      <span className="mx-[10px] text-glasses-text-50 font-bold">{item[1]} </span>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>

        </div>
      }




    </>
  );
}

export default ShopProductDetail;
