
const CSkeleton = () => {

    return (
        <>
            <div className={` CSkilton`} />
        </>
    )
}

export default CSkeleton;