import { DatePicker } from 'antd';
import { useState, useEffect, useRef, useContext } from 'react';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { SaleDashboardReport } from '../../apis/dashboard-api';
import moment from 'moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import SectionLoading from '../../Loading/SectionLoading';
import { ContextState } from '../../../widget/context/ContextMain'


const ProfitChartCalender = () => {
    const { RangePicker } = DatePicker;
    const [displayLoading, setDisplayLoading] = useState(false)
    const [defualtTime, setDefualtTime] = useState<[string, string]>(['', ''])
    const [chartValue, setChartValue] = useState([])
    const [chartCategory, setChartCategory] = useState([])
    const [chartTime, setChartTime] = useState(null)
    const inputRef: any = useRef()
    const Ctx = useContext(ContextState);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    // forces step size to be 50 units
                    stepSize: 20,
                    offset: true
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Profit chart',
            },
        },
    };

    const labels = chartCategory;

    const data = {
        labels,
        datasets: [
            {
                data: chartValue,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                tension: 0.4,
                fill: false,
            },
        ],
    };

    const onChange = async (value: any, dateString: [string, string] | string,) => {
        if (dateString[0] && dateString[1]) {
            try {
                const res = await SaleDashboardReport('custom', dateString[0], dateString[1])
                setDisplayLoading(false)
                let chartCat: any = [];
                let chartVal: any = [];
                setChartTime(res.data.type)
                Object.entries(res.data.values).map((item: any, index) => {
                    chartCat.push(item[0])
                    chartVal.push(item[1])
                })
                setChartCategory(chartCat)
                setChartValue(chartVal)
            }
            catch {

            }
        }
    }

    useEffect(() => {
        let now = moment().format('YYYY-MM-DD')
        var new_date = moment(now, "YYYY-MM-DD").add(30, 'days').format('YYYY-MM-DD');
        setDefualtTime([now, new_date])
        onChange('', [now, new_date])
    }, [Ctx.shopLogo])

    return (
        <div className=" w-full flex flex-col relative ProfitChartCalender">
            {displayLoading && <SectionLoading />}
            <div className=' w-full flex justify-between items-center px-2 my-3'>
                <div className='  text-lg text-glasses-text-50'>Sale</div>
                <span className='text-lg text-glasses-text-50'>{chartTime != null && chartTime}</span>
                {defualtTime[0] != '' &&
                    <RangePicker defaultValue={[moment(defualtTime[0]), moment(defualtTime[1])]} ref={inputRef} onChange={onChange} />
                }
            </div>
            <Line options={options} data={data} />
        </div>
    )
}
export default ProfitChartCalender