import React,{useEffect, useContext} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { checkisBrand } from '../../../../widget/layout/Nav';
import { ContextState } from '../../../../widget/context/ContextMain'

interface ISelectCategoryProps {
}

const SelectCategory: React.FunctionComponent<ISelectCategoryProps> = ({ }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const Ctx = useContext(ContextState);

    useEffect(() => {
          Ctx.setHeadTitle(`Select Category`)
       
      }, [])
    return (
        <>
            <div className=' w-full flex flex-col'>
                <div className=' w-full flex flex-wrap justify-center items-center my-12'>
                    <Link
                        to={`/panel/product/add?currentCategory=1&page=${searchParams.get('page')}`}>
                        <div onClick={() => { }} className=' mb-4 flex text-white min-w-[220px] bg-glasses-head-50 justify-center items-center text-[28px] cursor-pointer mx-6 p-6 rounded-lg'>
                            Sunglasses
                        </div>
                    </Link>
                    <Link
                        to={`/panel/product/add?currentCategory=2&page=${searchParams.get('page')}`}>
                        <div onClick={() => { }} className=' mb-4 flex text-white min-w-[220px] bg-glasses-head-50 justify-center items-center text-[28px] cursor-pointer mx-6 p-6 rounded-lg'>
                            Eyeglasses
                        </div>
                    </Link> 
                    <Link
                        to={`/panel/product/add/contact-lens?currentCategory=3&page=${searchParams.get('page')}`}>
                        <div onClick={() => { }} className=' mb-4 flex text-white min-w-[220px] bg-glasses-head-50 justify-center items-center text-[28px] cursor-pointer mx-6 p-6 rounded-lg'>
                            Contact Lens
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default SelectCategory;
