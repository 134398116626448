import CModal from "../../widget/CModal/CModal";
import { Badge, Descriptions } from 'antd';


export const ModalRefundDetail = ({ setVisible, visible, selecedOrder }: any) => {




    return (
        <>
            <CModal
                visible={visible}
                setVisible={setVisible}
                uId="detail-order"
                radius={'30px'}
            >
                <div className="w-full flex flex-col p-2">
                    <Descriptions title="Refund Info" bordered>
                        <Descriptions.Item span={3} label="Post codez">{selecedOrder?.receive_postcodez}</Descriptions.Item>
                        <Descriptions.Item span={3} label="Address">{selecedOrder?.receive_address}</Descriptions.Item>
                        <Descriptions.Item span={3} label="Reason">{selecedOrder?.refund_orders?.reason}</Descriptions.Item>
                        <Descriptions.Item span={3} label="Description">{selecedOrder?.refund_orders?.description}</Descriptions.Item>
                    </Descriptions>
                    <button type={'button'} onClick={() => setVisible(false)} className=" w-[120px] h-10 mt-4 border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                        <span>close</span>
                    </button>
                </div>
            </CModal >
        </>
    )
}