import React, { useEffect, useState } from 'react'


export default function BtnLoader() {




  return (
    <div className="loader"></div>
  )
}