import React, { useContext, useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ContextState } from '../context/ContextMain'
import ChatList from './ChatList';
import ChatDetail from './ChatDetail';

function Chat(props: any) {
  const Ctx = useContext(ContextState);



  useEffect(() => {
    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState == "visible") {
        if (Ctx.displayChat == true) {
          Ctx.setPageDetailChatIsOpen(null)
          Ctx.setUpdateChatList(!Ctx.updateChatList)
        }
      }
    })
  }, [])


  return (

    <div className={` w-full md:w-[300px] min-h-screen h-full p-2  fixed z-50 top-0 bottom-0 flex flex-col transition-all ${Ctx.displayChat ? 'right-0' : 'right-[-1000px]'}`}>
      <div className=' w-full h-full bg-white shadow rounded flex flex-col'>
        <div className={` w-full h-[50px] border-b flex shadow-md justify-between items-center px-[10px] transition-all ${Ctx.pageDetailChatIsOpen == null ? 'bg-none' : 'bg-blue-500'} `}>
          {Ctx.pageDetailChatIsOpen == null ?
            <div className='  text-glasses-head-50 rounded-full text-xl font-bold'>Chat</div>
            :
            <div className=' flex'>
              <div onClick={() => { Ctx.setPageDetailChatIsOpen(null) }} className=" w-[20px] h-[20px] rounded-full bg-white cursor-pointer flex justify-center items-center">
                <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-5 h-5  fill-blue-500 transition-all   `}>
                  <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
                </svg>
              </div>
              <span className="text-white ml-3">{Ctx.pageDetailChatIsOpen.display_name} </span>
            </div>
          }
          <div onClick={() => { Ctx.setDisplayChat(false) }} className={` w-[70px] h-[30px] rounded text-gray-500 flex justify-center items-center cursor-pointer transition-all ${Ctx.pageDetailChatIsOpen == null ? 'bg-gray-200 text-gray-500' : 'bg-white text-blue-500'}`}>close</div>
        </div>
        {Ctx.pageDetailChatIsOpen == null ?
          <ChatList  />
          :
          <ChatDetail  />
        }
      </div>

    </div >
  );
}

export default Chat;
