import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import { customApi, baseUrl, ImageUrl } from '../../widget/apis';
import LoadingPage from '../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../widget/Table/TablePage';
import { Popover } from 'antd';
import CModal from '../../widget/CModal/CModal';


function SelectedBrands() {
    const Ctx = useContext(ContextState);
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [listData, setListData] = useState<any[]>([]);


    const col = [
        {
            title: "Logo",
            render: (i: any) => (<>{i.image && i.image != null && <img src={i.image} className="w-[50px] h-[50px] my-3 rounded-full" />}</>)
        },
        {
            title: "Name",
            render: (i: any) => (<>{i.name != null && i.name}</>)
        },
        {
            title: "Sort",
            render: (i: any) => (
                <>
                    {/* <input defaultValue={i?.sort_number ? i.sort_number : null} type='number' onChange={(e) => handelActive(i, 'sort' , +e.target.value)} className='w-[70px] h-[35px] rounded px-1' /> */}
                    <select onChange={(e) => handelActive(i, 'sort' , +e.target.value)} className='w-[70px] h-[35px] rounded px-1 bg-white shadow'>
                        <option value={0}>0</option>
                        {listData.map((item: any, index: number) =>
                            <option selected={i?.sort_number == index+1 ? true : false} value={index + 1}>{index + 1}</option>
                        )}
                    </select>
                </>
            )
        },
        {
            title: <div className=' flex '>
                <div className=' mr-4 '>Change Status</div>
                <Popover placement='bottomLeft' content="Disabling the status of retail shops means they will not be able to see/order from your brand in the platform">
                    <div className=' w-6 h-6 flex items-center justify-center bg-glasses-head-50 text-white rounded'>
                        ?
                    </div>
                </Popover>
            </div>,
            render: (i: any) => (
                <>
                    <div className=' flex items-center '>
                        <label key={i.id} className="table-toggle">
                            <>
                                <input type="checkbox" onChange={() => handelActive(i, 'status', i?.sort_number ? i.sort_number : null)} checked={i.is_selected_brand} />
                                <div className="table-toggle-main" />
                            </>
                        </label>
                    </div>

                </>
            )
        }
    ]

    const handelActive = async (item: any, type: 'status' | 'sort', sort_number: number | null) => {
        setDisplayPage(true)
        let postData: any = {
            "brand_id": item.id,
            "shop_id": Number(localStorage.getItem("shopId")),
            "status": type == 'status' ? !item.is_selected_brand : item.is_selected_brand,
            "sort_number": sort_number

        }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/brands/update` }, { method: "POST", token: true, body: JSON.stringify(postData) })
        setDisplayPage(false)
        if (req.status) {
            getData()
            toast(req.message, { type: "success" })
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const getData = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/brands` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            setListData([...req.data])
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("Selected Brands")
        Ctx.setActiveItemNav({ 'menu': 'selected-brands', 'subMenu': 0 })
        getData()
    }, [])

    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full  flex flex-col   ">
                <TablePage columns={col} data={listData}>
                </TablePage>
            </div>

        </>
    );
}

export default SelectedBrands;
