import CModal from "../../../widget/CModal/CModal";
import { useState, useContext, useRef } from "react";
// import { ReturnOrderApi } from "@/apis/orders-api/address-api";
import { toast } from "react-toastify";
import { baseUrl, customApi } from "../../../widget/apis";
import BtnLoader from "../../../widget/Loading/BtnLoader";


export const ModalReturnOrders = ({ setVisible, visible, selecedOrder, setSelectedOrderReturn }: any) => {
    const formRef: any = useRef()
    const [reasonId, setReasonId] = useState<number>(-1)
    const [displayBtn, setDisplayBtn] = useState<boolean>(false)
    const [step, setStep] = useState<number>(0)
    const [typeRefund, setTypeRefund] = useState<string>('replace')

    const handelNextStep = () => {
        let reason = formRef.current['reason'].value;
        let description = formRef.current['description'].value;
        if (reason && description) {
            setStep(1)
        }
        else {
            toast("reson and comment required", { type: "error" })
        }

    }

    const handelSubmitForm = async (e: any) => {
        e.preventDefault();
        if (selecedOrder == null) return
        setDisplayBtn(true)
        let postData = {
            'status': 6,
            'reason': formRef.current['reason'].value,
            'description': formRef.current['description'].value,
            'type': typeRefund,
        }
        // const res = await ReturnOrderApi(postData, selecedOrder.order_id)

        const res = await customApi({ url: `${baseUrl}${selecedOrder?.shop?.id}/orders/${selecedOrder.order_id}/refund_order` }, { method: 'POST', body: JSON.stringify(postData), token: true })
        setStep(0)
        setTypeRefund('replace')
        if (res.status) {
            setDisplayBtn(false)
            setVisible(false)
            formRef.current['reason'].value = "";
            formRef.current['description'].value = "";
            setSelectedOrderReturn(null)
            toast(res?.message, { type: "success" })
        }
        else {
            formRef.current['description'].value = "";
            formRef.current['reason'].value = "";
            setSelectedOrderReturn(null)
            setVisible(false)
            toast(res.message, { type: "error" })
            setDisplayBtn(false)
        }
    }


    return (
        <>
            <CModal
                visible={visible}
                setVisible={setVisible}
                uId="return-order"
                radius={'30px'}
                accessClose={true}
                onScap={()=>{setStep(0) ; setTypeRefund('replace')}}
            >
                <form id='return-form' ref={formRef} className="w-full flex flex-col p-3" onSubmit={handelSubmitForm}>

                    <div className={`w-full flex-col ${step == 0 ? 'flex' : 'hidden'}`}>
                        <span>Why are you returning this? <span className="text-red-600">*</span></span>
                        <select name='reason' className="mt-2 w-full h-[40px] rounded-md bg-transparent border px-2" required={true}>
                            <option className="text-gray-400" value="" disabled selected>Select your option</option>
                            <option value={"Product doesn't meet expectations"}>Product doesn't meet expectations</option>
                            <option value={'Product damages, but shipping box OK'}>Product damages, but shipping box OK</option>
                            <option value={'item arrived too late'}>item arrived too late</option>
                            <option value={'Missing or broken parts'}>Missing or broken parts</option>
                            <option value={'Product and shipping box both damaged'}>Product and shipping box both damaged</option>
                            <option value={'Wrong item was sent'}>Wrong item was sent</option>
                            <option value={'Other'}>Other</option>
                        </select>
                    </div>

                    <div className={`w-full flex flex-col mt-5 ${step == 0 ? 'flex' : 'hidden'}`}>
                        <span>Comments <span className="text-red-600">*</span></span>
                        <textarea name='description' className="mt-2 w-full h-[100px] rounded-md bg-transparent border p-2 focus:outline-none" required={true} />
                    </div>

                    <div className={`w-full flex-col ${step == 1 ? 'flex' : 'hidden'}`}>
                        <span className="my-3 text-base">How can we make it right? </span>
                        <div onClick={() => { setTypeRefund('replace') }} className="w-full flex items-center  cursor-pointer mt-3 pb-3 border-b">
                            <div className="w-[30px]">
                                <div className={`w-[20px] h-[20px] rounded-full border flex items-center justify-center ${typeRefund == 'replace' ? 'border-gr-300' : 'border-gray-400'}`}>
                                    {typeRefund == 'replace' &&
                                        <div className="w-[15px] h-[15px] bg-gr-300 rounded-full" />
                                    }
                                </div>
                            </div>
                            <div className="w-[calc(100%-30px)] flex flex-col text-sm">
                                <span className="font-bold">1-Replacing with the same exact item </span>
                                <span className="text-gray-600 mt-1">We’ll ship the you the replacement of the new item you ordered once the once the original item is received, at no extra charge.  </span>
                            </div>
                        </div>
                        <div onClick={() => { setTypeRefund('refund') }} className="w-full flex items-center my-3 cursor-pointer">
                            <div className="w-[30px]">
                                <div className={`w-[20px] h-[20px] rounded-full border flex items-center justify-center ${typeRefund == 'refund' ? 'border-gr-300' : 'border-gray-400'}`}>
                                    {typeRefund == 'refund' &&
                                        <div className="w-[15px] h-[15px] bg-gr-300 rounded-full" />
                                    }
                                </div>
                            </div>
                            <div className="w-[calc(100%-30px)] flex flex-col text-sm">
                                <span className="font-bold">2-Refund to your original payment method </span>
                                <span className="text-gray-600 mt-1">Estimated refund timing within 5-7 working days of receiving the item. </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-around mt-[20px]">

                        {step == 0 ?
                            <>
                                {displayBtn == true && visible == true ?
                                    <button type={'button'} className=" w-[120px] h-10 border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                        <span>Cancel</span>
                                    </button>
                                    :
                                    <button type={'button'} onClick={() => setVisible(false)} className=" w-[120px] h-10 border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                        <span>Cancel</span>
                                    </button>
                                }
                            </>
                            :
                            <button type={'button'} onClick={() => setStep(0)} className=" w-[120px] h-10 border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                <span>back</span>
                            </button>
                        }
                        {step == 1 ?
                            <>
                                {displayBtn == true && visible == true ?
                                    <button type={'button'} className=" w-[120px] h-10 bg-gr-300 flex items-center justify-center rounded-[30px] text-white " disabled>
                                        <BtnLoader />
                                    </button>
                                    :
                                    <button type={'submit'} className=" w-[120px] h-10 bg-gr-300 flex items-center justify-center rounded-[30px] text-white">
                                        <span>submit</span>
                                    </button>
                                }
                            </>
                            :
                            <button onClick={handelNextStep} type={'button'} className=" w-[120px] h-10 bg-gr-300 flex items-center justify-center rounded-[30px] text-white">
                                <span>next</span>
                            </button>
                        }
                    </div>

                </form>
            </CModal >
        </>
    )
}