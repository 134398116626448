import { customApi, baseUrl } from ".."

export const GetBrandAPi = async () => {
    const data = await customApi({ url: `${baseUrl}settings/brand` }, { method: "GET", token: true })
    return data;
}


export const AddCouponAPi = async (e: any, isAll: any) => {

    const payload = {
        for_all: isAll ? 1 : 0,
        discount_code: e.target[0].value,
        discount: +e.target[1].value.split('%').join(""),
        count: +e.target[2].value,
        start: e.target[3].value,
        expire: e.target[4].value

    }

    const data = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/coupons` }, {
        method: "POST",
        body: JSON.stringify(payload),
        token: true
    })
    return data;
}