import { useState, useContext, useEffect, useRef } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import CModal from '../../../widget/CModal/CModal';
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import ProductAddWidget from './widget/ProductAddWidget'
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { checkisBrand, checkPermission } from '../../../widget/layout/Nav'
import { ProductsPermissions } from '../../../widget/rolePermissions';
import SelectCategory from './widget/SelectCategory.Comp';
import { ReplaceProductFromDraftMethod } from './widget/product-method'


function ProductAdd() {
  const Ctx = useContext(ContextState);
  const formRef: any = useRef()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayBtn, setDisplayBtn] = useState<any>([])
  const [displayPage, setDisplayPage] = useState<any>([])
  const [visibleAddItem, setVisibleAddItem] = useState<boolean>(false)
  const [listData, setListData] = useState<any>(null)
  const [listCategory, setListCategory] = useState<any>([])
  const [galleryImages, setGalleryImages] = useState<any>([])
  const [lensFieldKeeper, setLensFieldKeeper] = useState<any>()
  const [selectedId, setSelectedId] = useState<any>(null)
  const [is_draft, setIs_draft] = useState<boolean>(false)
  const [keepType, setKeepType] = useState<any>(null)
  const [keepBaseOrChild, setKeepBaseOrChild] = useState<any>(null)
  const [stockList, setStockList] = useState<any>([]);
  const [SelectedCat, setSelectedCat] = useState<any>(null);
  const [settingData, setSettingData] = useState<any>(null)
  const [childList, setChildList] = useState<any>([{
    'color': null,
    'color_id': 0,
    'stock': 0,
    'price': 0,
    'discount': 0,
    'suggestion_price': 0,
    'min_price': 0,
    'ar_top': null,
    'top': null,
    'ar_top_url': null,
    'top_url': null,
    'ar_left': null,
    'left': null,
    'ar_left_url': null,
    'left_url': null,
    'ar_right': null,
    'right': null,
    'ar_right_url': null,
    'right_url': null,
    'lens_color': null,
    'lens_type': null,
    'images': [],
    'gltf_dir': null,
    'stock_type': null
  }])

  const handelReplaceFormDraft = () => {
    ReplaceProductFromDraftMethod(formRef, setChildList, setStockList)
  }

  const onChangeCat = (category: any) => {
    setSelectedCat(+category)
    if (+category == 3) {
      navigate("/panel/product/add/contact-lens")
      if (!listData.base['contact_lens_type']) {
        let data = { ...listData }
        data.base['contact_lens_type'] = lensFieldKeeper.contact_lens_type;
        setListData(data)
      }
    }
    else {
      if (listData.base['contact_lens_type']) {
        let data = { ...listData }
        delete data.base['contact_lens_type']
        setListData(data)
      }
    }
    if (+category != 2) {
      if (!listData.base['lens_material']) {
        let data = { ...listData }
        data.base['lens_material'] = lensFieldKeeper.lens_material;
        data['children'] = lensFieldKeeper.children;
        setListData(data)
      }
    }
    else {
      if (listData.base['lens_material']) {
        let data = { ...listData }
        delete data.base['lens_material']
        delete data['children']
        setListData(data)
      }
    }
  }

  useEffect(() => {
    const cC: any = searchParams.get('currentCategory')
    if (+cC) {
      getData(+cC)
      Ctx.setHeadTitle(`Add Product (${returnCatName(+cC)})`)
    }
    if (listData) {
      if (+cC) {
        onChangeCat(+cC)
      }
    }
  }, [searchParams.get('currentCategory')])

  useEffect(() => {
    const cC: any = searchParams.get('currentCategory')
    if (listData) {
      if (+cC) {
        onChangeCat(+cC)
      }
    }
  }, [searchParams.get('currentCategory'), listData])

  const clearData = async () => {
    setKeepType(null);
    setVisibleAddItem(false)
    const form: any = document.getElementById("formNewItem")
    form?.reset()
    let allSelect: any = document.getElementsByClassName("add-form-sel")
    for (let i of allSelect) {
      if (i.value == -1) {
        i.value = ''
      }
    }
  }

  type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';

  const checkValidation = (): { status: boolean, message: string, messType: TypeOptions } => {
    if (childList.length == 0) return { status: false, message: "please select child", messType: "info" }
    else {
      for (let i of childList) {
        if (!i.min_price) return { status: false, message: " Min Price is Required", messType: "info" }
        if (!i.price) return { status: false, message: " Price is Required", messType: "info" }
        if (!i.color_code) return { status: false, message: "Color code is Required", messType: "info" }
        if (!i.color_id) return { status: false, message: " Frame color is Required", messType: "info" }
        if (i.right == null) return { status: false, message: " select image angle view is required", messType: "info" }
        if (i.left == null) {
          delete i.left
        }
        if (i.top == null) {
          delete i.top
          // toast("select image front view is required", { type: "info" })
          // return false
        }
        if (i.color_id == null || i.color_id == 0) return { status: false, message: " fill all child color input ", messType: "info" }

      }
      return { status: true, message: " Validation is Passed ", messType: "success" }
    }
  }

  const addStockToListANdCheckValid = async () => {
    let allChild: any = [...childList]
    if (stockList.length == 0) {
      toast("It is necessary to select the stock and frame for each pair of glasses", { type: "info" })
      return false
    }
    for (let [i, child] of allChild.entries()) {
      child['frame_sizes'] = [];
      for (let j of stockList) {
        if (j.index == i) {
          child['frame_sizes'].push(j)
        }
      }
    }
    for (let i of childList) {
      if (i.frame_sizes?.length == 0) {
        toast("It is necessary to select the stock and frame for each pair of glasses", { type: "info" })
        return false
      }
    }
    return true
  }

  const returnCatName = (catNum: number) => {
    if (+catNum === 1) return "sunglasses"
    if (+catNum === 2) return "eyeglasses"
    if (+catNum === 3) return "contact_lenses"
  }

  const onFinishAdd = async (e: any) => {
    e.preventDefault()
    if (!checkValidation().status) return toast(checkValidation().message, { type: checkValidation().messType })
    if (await addStockToListANdCheckValid() == false) {
      toast("add stock required", { type: "warning" })
      return
    }
    if (!listData?.children) {
      for (var i in childList) {
        delete childList[i]["lens_color"]
        delete childList[i]["lens_type"]
      }
    }
    var newChildPostData: any[] = []
    for (var i in childList) {
      for (var x in childList[i].frame_sizes) {
        var NewO = { ...childList[i], ...childList[i].frame_sizes[x] }
        delete NewO.frame_sizes
        newChildPostData.push(NewO)
      }
    }

    let postData: any = await {
      "name": formRef.current['name'].value,
      "frame_width": formRef.current['frame_width'].value,
      "lens_width": formRef.current['lens_width'].value,
      "delivery_day": formRef.current['delivery_day'].value,
      "weight": formRef.current['weight'].value,
      "variants": newChildPostData,
    }



    setDisplayPage(true)
    if (formRef.current['made'].value !== "-1") postData["made"] = formRef.current['made'].value
    if (formRef.current['gender'].value !== "-1") postData["gender"] = formRef.current['gender'].value
    if (formRef.current['lens_material'] && Boolean(Object(listData?.children))) {
      if (formRef.current['lens_material'].value !== -1) postData["lens_material"] = formRef.current['lens_material'].value
    }

    if (formRef.current['frame_material'].value !== "-1") postData["frame_material"] = formRef.current['frame_material'].value
    if (formRef.current['frame_shape'].value !== "-1") postData["frame_shape"] = formRef.current['frame_shape'].value
    if (formRef.current['weight_group'].value !== "-1") postData["weight_group"] = formRef.current['weight_group'].value
    if (formRef.current['contact_lens_type']) {
      if (formRef.current['contact_lens_type'].value !== "-1") postData["contact_lens_type"] = formRef.current['contact_lens_type'].value
    }
    await sessionStorage.setItem('draft', JSON.stringify(postData))
    await sessionStorage.setItem('stockList', JSON.stringify(stockList))
    await sessionStorage.setItem('currentCategory', String(searchParams.get('currentCategory')))
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${returnCatName(SelectedCat)}` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status == true) {
      sessionStorage.removeItem('draft')
      sessionStorage.removeItem('stockList')
      toast(req.message, { type: "success" })
      if (searchParams.get('page') != null) {
        navigate(`/panel/product/list?page=${searchParams.get('page')}`);
      }
      else {
        navigate('/panel/product/list');
      }
    }
    else {
      toast(req.message, { type: "error" })
    }
  }

  const handelAddItem = async (type: string, value: any, baseOrchild: string) => {
    if (value == -1) {
      setKeepType(type)
      setKeepBaseOrChild(baseOrchild)
      setVisibleAddItem(true)
      await setSelectedId(`select${type}`)
    }
  }

  const onFinishAddItem = async (e: any) => {
    e.preventDefault()
    setDisplayPage(true)
    let postData = {
      'name': e.target[0].value,
      'group': keepType,
    }
    let data: any = { ...listData };
    data[keepBaseOrChild][keepType].push(e.target[0].value)
    const req = await customApi({ url: `${baseUrl}filters` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status) {
      setListData(data)
      toast(req.message, { type: "success" })
    }
    else {
      toast(req.message, { type: "error" })
    }
    clearData()
  }

  // const getCategory = async () => {
  //   const req = await customApi({ url: `${baseUrl}categories/dropdown` }, { method: "GET", token: true })
  //   if (req.status == true) {
  //     setListCategory(req.data)
  //   }
  // }


  const getData = async (currentCat: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}filters/${returnCatName(+currentCat)}/dropdown` }, { method: "GET", token: true })
    if (req.status == true) {
      setDisplayPage(false)
      setLensFieldKeeper({
        contact_lens_type: req.data.base.contact_lens_type,
        lens_material: req.data.base.lens_material,
        children: req.data.children
      })
      delete req.data.base["contact_lens_type"]
      setListData(req.data)
    }
  }

  const getSettingData = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/settings` }, { method: "GET", token: true })
    if (req.status) {

      setSettingData(req.data)
    } else {
      toast(req.message, { type: "error" })
    }
  }

  useEffect(() => {
    Ctx.setActiveItemNav({ 'menu': 'products', 'subMenu': 0 })
    // getCategory()
    checkisBrand() && getSettingData()
  }, [])

  useEffect(() => {
    if (!checkPermission(ProductsPermissions.add) || !checkisBrand()) {
      navigate('/panel/product/list');
    }
  }, [])

  useEffect(() => {
    if (
      sessionStorage.getItem('draft') &&
      sessionStorage.getItem('draft') != null &&
      sessionStorage.getItem('currentCategory') == searchParams.get('currentCategory')
    ) {
      setIs_draft(true)
    }
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full md:w-6/12 lg:w-6/12 xl:w-4/12  flex flex-col   ">
        {is_draft &&
          <div className='w-full  my-2 border rounded p-2 flex flex-col'>
            <span>
              Do you have a draft product. Do you want it to be posted?
            </span>
            <button type='button' onClick={handelReplaceFormDraft} className='my-2 w-[120px] rounded h-[30px] bg-yellow-600 text-white'>Placement</button>
          </div>
        }
        <form ref={formRef} onSubmit={onFinishAdd} className=" w-full">

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">Name</span>
            <input name={'name'} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
          </div>

          {/* <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">category</span>
            <select onChange={(e: any) => onChangeCat(e.target.value)} name={'category'} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
              <option value={''} selected disabled hidden>select category</option>
              {listCategory.map((itemCat: any, index: any) =>
                <option key={index} value={itemCat.id}>{itemCat.name}</option>
              )}
            </select>
          </div> */}

          {listData != null &&
            <>
              {Object.entries(listData.base).map((Pitem: any, Pindex) =>
                <div key={Pindex} className=" w-full flex flex-col mt-5">
                  <span className=" text-glasses-text-50 text-sm">{Pitem[0]}</span>
                  <select name={Pitem[0]} id={`select${Pitem[0]}`} onChange={(e) => handelAddItem(Pitem[0], e.target.value, 'base')} className=" add-form-sel w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" >
                    <option value={''} className=" text-gray-400" selected disabled hidden>{`select ${Pitem[0]}`}</option>
                    {Pitem[1].map((item: any, index: number) =>
                      <option key={index} value={item}>{item}</option>
                    )}
                    <option key={-1} value={-1}>+ Add custom item</option>
                  </select>
                </div>
              )}
            </>
          }


          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">frame width</span>
            <input name={'frame_width'} type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50" />
          </div>

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">lens width</span>
            <input name={'lens_width'} type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
          </div>

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">Delivery Day</span>
            <input name={'delivery_day'} type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
          </div>

          <div className=" w-full flex flex-col mt-5">
            <span className=" text-glasses-text-50 text-sm">weight (g)</span>
            <input name={'weight'} defaultValue={500} type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
          </div>

          <ProductAddWidget
            brandSetting={settingData}
            childrenDropDown={listData?.children ? listData?.children : null}
            childList={childList}
            setChildList={setChildList}
            stockList={stockList}
            setStockList={setStockList}
            handelAddItem={handelAddItem}
            SelectedCat={SelectedCat}
          />

          <div className='w-full flex items-center'>
            <button type='submit' className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Add Product</button>
            <Link to={searchParams.get('page') != null ? `/panel/product/list?page=${searchParams.get('page')}` : `/panel/product/list`} >
              <button type='button' className=" text-white rounded-lg border border-glasses-head-50 text-glasses-head-50 ml-2 w-[120px] h-[40px] mt-9 font-bold ">Cancel</button>
            </Link>
          </div>

        </form>

        <CModal onScap={clearData} visible={visibleAddItem} setVisible={setVisibleAddItem} title="AddIstem" uId='AddIstem'>
          <form onSubmit={onFinishAddItem} id="formNewItem">
            <input type="text" className=" w-full h-10 border rounded my-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Your custom item"} required />
            {displayBtn == false ?
              <button type="submit" className=" w-full h-10 bg-glasses-head-50 text-white rounded" >save</button>
              :
              <button type="button" disabled><BtnLoader /></button>
            }
          </form>
        </CModal>

      </div>
    </>
  );
}

export default ProductAdd;
