import {  useState } from "react"
import { toast } from "react-toastify";
import { Popconfirm } from 'antd';
import { ConvertToReceivedApi } from "../../../../widget/apis/orders";

export declare type ConvertProps = {
    order_id: any,
}

const ButtonConvertToReceived = ({order_id}:ConvertProps) => {
    const [displayBtn, setDisplayBtn] = useState<any>(false)


    const handelRequest = async () => {
        setDisplayBtn(true)
        try {
            const res: any = await ConvertToReceivedApi(order_id)
            if (res.status == true) {
                toast(res.message, { type: "success" })
                setDisplayBtn(false)
            }
            else {
                toast(res.message, { type: "error" })
                setDisplayBtn(false)
            }
        }
        catch {
            setDisplayBtn(false)
        }
    }

    return (
        <>
            <Popconfirm
                title="Convert to order received?"
                onConfirm={handelRequest}
                disabled={displayBtn}
            >
                <button className=" px-[10px] h-[40px] mr-3 bg-glasses-head-50 rounded-xl text-white">Convert to order received</button>
            </Popconfirm>
        </>
    )
}

export default ButtonConvertToReceived;