import React, { useState, useContext, useEffect } from 'react';
import CModal from '../../../widget/CModal/CModal';
import { toast } from "react-toastify";


function EditProfileDescription(props: any) {
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<number>(-1)
  const [editItem, setEditItem] = useState<number>(-1)
  const [formValue, setFormValue] = useState<any>(null)

  const clearData = () => {
    setFormValue(null)
  }

  const onFinishDelete = () => {
    let data = { ...props.detailData };
    for (let i in data.order_description) {
      if (+i == deleteItem) {
        data.order_description.splice(i, 1)
      }
    }
    setVisibleDelete(false)
    clearData()
    props.setDetailData(data)
  }

  const changeInput = (value: any) => {
    let data = { ...formValue };
    data = value;
    setFormValue(data)
  }

  const onFinishAdd = () => {
    if (formValue != null && formValue != "") {
      let data = { ...props.detailData };
      data.order_description.push(formValue)
      setVisibleAdd(false)
      clearData()
      props.setDetailData(data)
      clearData()
    }
    else {
      toast("The description field is required", { type: "error" })
    }
  }

  const onFinishEdit = () => {
    if (formValue != null && formValue != "") {
      let data = { ...props.detailData };
      for (let i in props.detailData.order_description) {
        if (+i == editItem) {
          props.detailData.order_description[i] = formValue;
        }
      }
      props.setDetailData(data)
      setVisibleEdit(false)
      clearData()
    }
    else {
      toast("The description field is required", { type: "error" })
    }
  }

  return (

    <>
      <div className=' w-full flex flex-col mt-10'>
        <div className='flex justify-between mb-2'>
          <span className=' ml-3 text-glasses-text-50 text-xl font-bold'> Order Description</span>
          <button onClick={() => { setVisibleAdd(true) }} type="button" className=' px-[10px] border border-[#FFA500] min-h-[35px] rounded text-[#FFA500]' >+ new description</button>
        </div>
        <div className='w-full p-[10px] sm:p-[20px] mb-2 bg-white rounded-xl shadow flex flex-col'>
          {props.detailData.order_description && props.detailData.order_description.map((item: any, index: number) =>
            <div className=" w-full  mb-[10px] flex justify-between items-center flex-wrap border-b pb-2 ">
              <div className='flex items-center'>
                <button type='button' className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                  <svg x="0px" y="0px" viewBox="0 0 490.1 490.1" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                    <use xlinkHref="/assets/svg/user.svg#user" />
                  </svg>
                </button>
                <span className="mx-[10px] text-glasses-text-50 font-bold">{item} </span>
              </div>
              <div className=' flex items-center'>
                <button onClick={() => { setVisibleDelete(true); setDeleteItem(index) }} type='button' className=" w-[35px] h-[35px] flex justify-center items-center rounded border border-[#FFA500]">
                  <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                    <use xlinkHref="/assets/svg/trash.svg#trash" />
                  </svg>
                </button>
                <button onClick={() => { setEditItem(index); setFormValue(item); setVisibleEdit(true) }} type='button' className=" w-[35px] h-[35px] ml-[5px] flex justify-center items-center rounded border border-[#FFA500]">
                  <svg x="0px" y="0px" viewBox="0 0 469.331 469.331" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                    <use xlinkHref="/assets/svg/edit.svg#edit" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <CModal onScap={clearData} visible={visibleAdd} setVisible={setVisibleAdd} title="Add Description" uId='AddDescription'>
        <div className=" w-full flex flex-col">

          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Description </span>
            <input value={formValue} onChange={(e) => changeInput(e.target.value)} type="text" className=" border h-[35px] w-full rounded px-2" placeholder="Description" />
          </div>
          <button onClick={onFinishAdd} type='button' className=" w-full h-[35px] border text-white bg-glasses-head-50 rounded mt-[15px]">Add </button>
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleDelete} setVisible={setVisibleDelete} title="DeleteTime" uId='DeleteTime'>
        <p>Are youe shoure delete this item ?</p>
        <div className="CModel-Bottom">
          <button type='button' onClick={onFinishDelete} className="CModel-Bottom-btn-ok" >Ok </button>
          <button type='button' className="CModel-Bottom-btn-cancel" onClick={() => { setVisibleDelete(false) }}>Cansel</button>
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleEdit} setVisible={setVisibleEdit} title="EditDescription" uId='EditDescription'>
        <div className=" w-full flex flex-col">
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Description</span>
            <input value={formValue} onChange={(e) => changeInput(e.target.value)} type="text" className=" border h-[35px] w-full rounded px-2" placeholder="Description" />
          </div>
          <button onClick={onFinishEdit} type='button' className=" w-full h-[35px] border text-white bg-glasses-head-50 rounded mt-[15px]">Update </button>
        </div>
      </CModal>

    </>
  );
}

export default EditProfileDescription;
