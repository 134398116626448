
import React, { useState, useEffect, useContext } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import { baseUrl, customApi } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import TablePage from '../../../widget/Table/TablePage';
import CModal from '../../../widget/CModal/CModal';
import { toast } from 'react-toastify';
import { checkPermission } from '../../../widget/layout/Nav';
import { OperatorsPermissions } from '../../../widget/rolePermissions';

interface IRolesOperatorsProps {
}

const RolesOperators: React.FunctionComponent<IRolesOperatorsProps> = ({ }) => {
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [listData, setListData] = useState<any[]>([]);
    const [permissionsData, setPermissionsData] = useState<any[]>([]);
    const [selectedOperator, setSelectedOperator] = useState<any>({})
    const [selectedPermission, setSelectedPermission] = useState<any[]>([])
    const [isRolePermissionsModal, setIsRolePermissionsModal] = React.useState<boolean>(false);
    const [isAddUserModal, setIsAddUserModal] = React.useState<boolean>(false);
    const [isEditRoleModal, setIsEditRoleModal] = React.useState<boolean>(false);
    const [selectedRole, setSelectedRole] = React.useState<any>();

    const Ctx = useContext(ContextState);

    const col = [
        {
            title: "Name",
            render: (i: any) => (<>{i.name != null && <>{i.name}</>}</>)
        },
        {
            title: "Display Name",
            render: (i: any) => (<>{i.display_name != null && i.display_name}</>)
        },
        {
            title: "Permissions",
            render: (i: any) => (
                <div onClick={() => {
                    setSelectedPermission(i.permissions)
                    setIsRolePermissionsModal(true)
                    setSelectedOperator(i)
                }} className=" h-[35px] px-3 border border-glasses-head-50 flex items-center justify-center rounded cursor-pointer relative group">
                    <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/eye.svg#eye" />
                    </svg>

                </div>
            )
        },
        {
            title: "Edit",
            render: (i: any) => (
                <>
                    {i.shop_id !== null &&
                        <div onClick={() => { editRole(i) }} className=" h-[35px] px-3 border border-glasses-head-50 flex items-center justify-center rounded cursor-pointer">
                            <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
                                <use xlinkHref="/assets/svg/edit.svg#edit" />
                            </svg>
                        </div>
                    }
                </>
            )
        },
        {
            title: "Delete",
            render: (i: any) => (
                <>
                    {i.shop_id !== null && checkPermission(OperatorsPermissions.remove) &&
                        <div onClick={() => { deleteUser(i.id) }} className=" h-[35px] px-3 border border-glasses-head-50 flex items-center justify-center rounded cursor-pointer">
                            <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
                                <use xlinkHref="/assets/svg/trash.svg#trash" />
                            </svg>
                        </div>
                    }
                </>
            )
        },

    ]

    const getData = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/roles` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.data.length !== 0) {
            setListData(req.data)
            setIsAddUserModal(false)
            setIsRolePermissionsModal(false)
        } else {
            toast("The role list is empty", { type: "error" })
        }
    }

    function editRole(item: any) {
        var allPermissions = [...permissionsData]
        var itemsPermissions = [...item.permissions]
        for (var i in allPermissions) {
            allPermissions[i]["isSelected"] = false
        }
        for (var i in allPermissions) {
            for (var x in itemsPermissions) {
                if (allPermissions[i].id === itemsPermissions[x].id) {
                    allPermissions[i]["isSelected"] = true
                }
            }
        }
        setPermissionsData([...allPermissions])
        setSelectedRole(item)
        setIsEditRoleModal(true)
    }


    const getPermissions = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/roles/permissions` }, { method: "GET", token: true })
        if (req.status) {
            setDisplayPage(false)
            const l = req.data.map((item: any) => ({ ...item, isSelected: false }))
            setPermissionsData([...l])
        } else {
            toast(req.message, { type: "error" })
            setDisplayPage(false)
        }
    }



    const deleteUser = async (operatorId: string) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/roles/${operatorId}` }, { method: "DELETE", token: true })
        if (req.status == true) {
            setDisplayPage(false)
            setSelectedPermission(req.data.permissions)
            getData()
        } else setDisplayPage(false)
    }

    const addRole = async (e: any) => {
        e.preventDefault()
        if (displayPage) return
        setDisplayPage(true)
        var selectedPer = permissionsData.filter(item => item.isSelected)
        var selectedPerId: any = selectedPer.map((item: any) => item.id)
        if (selectedPer.length === 0) return toast("choose one permission", { type: "warning" })
        const postData = {
            display_name: e.target[0].value,
            permissions: selectedPerId
        }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/roles` }, { method: "POST", body: JSON.stringify(postData), token: true })
        if (req.status) {
            toast(req.message, { type: "success" })
            setDisplayPage(false)
            const form: any = document.getElementById("addUserForm")
            getData()
            form?.reset()
            setIsAddUserModal(false)
        } else {
            setDisplayPage(false)
            toast(req.message, { type: "warning" })
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("Operators")
        Ctx.setActiveItemNav({ 'menu': 'operators', 'subMenu': 0 })
        getData()
        getPermissions()
    }, [])

    function selectPermission(item: any) {
        var lastArr = [...permissionsData]
        var targetIndex = lastArr.findIndex(e => e.id === item.id)
        lastArr[targetIndex]["isSelected"] = !lastArr[targetIndex].isSelected
        setPermissionsData(lastArr)
    }


    const editRoleSingle = async (e: any) => {
        e.preventDefault()
        if (displayPage) return
        setDisplayPage(true)
        var selectedPer = permissionsData.filter(item => item.isSelected)
        var selectedPerId: any = selectedPer.map((item: any) => item.id)
        if (selectedPer.length === 0) return toast("choose one permission", { type: "warning" })
        const postData = {
            display_name: e.target[0].value,
            permissions: selectedPerId
        }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/roles/${selectedRole.id}` }, { method: "PUT", body: JSON.stringify(postData), token: true })
        if (req.status) {
            toast(req.message, { type: "success" })
            setDisplayPage(false)
            const form: any = document.getElementById("editUserForm")
            getData()
            form?.reset()
            setIsAddUserModal(false)
        } else {
            setDisplayPage(false)
            toast(req.message, { type: "warning" })
        }
    }

    function unselectAllPer() {
        var lastArr = [...permissionsData]
        for (var i in lastArr) {
            lastArr[i]["isSelected"] = false
        }
        setPermissionsData([...lastArr])
        setIsAddUserModal(true)
    }

    return (
        <>
            {checkPermission(OperatorsPermissions.add) &&
                <div onClick={() => unselectAllPer()} className=" w-full h-[60px] flex items-center my-4">
                    <a className=" px-[10px] py-3 bg-glasses-head-50 hover:text-white rounded-xl text-white"> + Add New Role</a>
                </div>
            }
            <div className=" w-full flex flex-col ">
                {displayPage && <LoadingPage />}
                <TablePage columns={col} data={listData} />
            </div>

            <CModal title={`${selectedOperator.name} Permissions`} setVisible={setIsRolePermissionsModal} visible={isRolePermissionsModal} uId='permissionOfUserModal' >
                <>
                    <div className={` flex flex-wrap w-full bg-gray-200 px-2 py-2 rounded-lg `}>
                        {Boolean(selectedPermission?.length) && selectedPermission?.map((item: any, index: number) =>
                            <span key={index} className=' px-2 py-1 bg-blue-100 text-xs mx-1 mb-2 rounded-lg shadow-md'>{item.display_name}</span>
                        )}
                    </div>
                </>
            </CModal>

            <CModal title={`Add Role`} setVisible={setIsAddUserModal} visible={isAddUserModal} uId='addUserModal' >
                <>
                    <div className=" w-full flex flex-col   ">
                        <form id="addRoleForm" name='AddRoleModal' onSubmit={addRole} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>

                            <div className=" flex flex-col my-5 w-full">
                                <span className=" text-glasses-text-50 text-sm">Role Name</span>
                                <input placeholder='Insert role name' className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                            </div>

                            <div className=" flex flex-col my-5 w-full">
                                <span className=" text-glasses-text-50 text-sm mb-2">Select Permissions</span>
                                <div className={` flex flex-wrap w-full bg-gray-100 px-2 py-2 rounded-lg `}>
                                    {Boolean(permissionsData.length) && permissionsData?.map((item: any, index: number) =>
                                        <span onClick={() => { selectPermission(item) }}
                                            key={index} className={` ${item.isSelected ? "bg-blue-700 text-white " : "bg-blue-100  text-black "} 
                                          px-2 text-center cursor-pointer py-1 min-w-[70px]  text-xs mx-1 mb-2 rounded-lg shadow-md`}>
                                            {item.display_name}
                                        </span>
                                    )}
                                </div>
                            </div>




                            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Create </button>
                        </form>
                    </div>
                </>
            </CModal>

            <CModal title={`Edit Role`} setVisible={setIsEditRoleModal} visible={isEditRoleModal} uId='editRoleModal' >
                <>
                    <div className=" w-full flex flex-col   ">
                        <form id="editUserForm" name='editUserForm' onSubmit={editRoleSingle} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>

                            <div className=" flex flex-col my-5 w-full">
                                <span className=" text-glasses-text-50 text-sm">Role Name</span>
                                <input defaultValue={selectedRole?.display_name} placeholder='Insert role name' className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                            </div>

                            <div className=" flex flex-col my-5 w-full">
                                <span className=" text-glasses-text-50 text-sm mb-2">Select Permissions</span>
                                <div className={` flex flex-wrap w-full bg-gray-100 px-2 py-2 rounded-lg `}>
                                    {Boolean(permissionsData.length) && permissionsData?.map((item: any, index: number) =>
                                        <span onClick={() => { selectPermission(item) }}
                                            key={index} className={` ${item.isSelected ? "bg-blue-700 text-white " : "bg-blue-100  text-black "} 
                                          px-2 text-center cursor-pointer py-1 min-w-[70px]  text-xs mx-1 mb-2 rounded-lg shadow-md`}>
                                            {item.display_name}
                                        </span>
                                    )}
                                </div>
                            </div>




                            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Update </button>
                        </form>
                    </div>
                </>
            </CModal>

        </>
    );
};

export default RolesOperators;

