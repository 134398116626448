import { customApi, baseUrl } from ".."

export const GetBrandAPi = async () => {
    const data = await customApi({ url: `${baseUrl}settings/brand` }, { method: "GET", token: true })
    return data;
}


export const UpdateBrandAPi = async (e: any, imagePath?: string) => {
    let reqBody = new FormData()
    reqBody.append('name', e.target[0].value);
    imagePath && reqBody.append('image', imagePath);
    const data = await customApi({ url: `${baseUrl}25/settings/brand` }, {
        method: "POST",
        body: reqBody,
        formData: true,
        token: true
    })
    return data;
}
