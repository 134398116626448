import CModal from "../../widget/CModal/CModal";
import { useState, useContext, useRef } from "react";
// import { ReturnOrderApi } from "@/apis/orders-api/address-api";
import { toast } from "react-toastify";
import { baseUrl, customApi } from "../../widget/apis";
import BtnLoader from "../../widget/Loading/BtnLoader";


export const ModalRefundChangeStatus = ({ setVisible, visible, selecedOrder, setSelectedOrder , currentPage , getData}: any) => {
    const formRef: any = useRef()
    const [displayBtn, setDisplayBtn] = useState<boolean>(false)


    const handelSubmitForm = async (e: any) => {
        e.preventDefault();
        if (selecedOrder == null) return
        setDisplayBtn(true)
        let postData = {
            'status': formRef.current['status'].value,
        }
        const res = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/${selecedOrder.order_id}/refund_order/update_status` }, { method: 'POST', body: JSON.stringify(postData), token: true })
        if (res.status) {
            setDisplayBtn(false)
            setVisible(false)
            formRef.current['status'].value = "";
            setSelectedOrder(null)
            getData(currentPage)
            toast(res?.message, { type: "success" })
        }
        else {
            formRef.current['status'].value = "";
            setSelectedOrder(null)
            setVisible(false)
            toast(res.message, { type: "error" })
            setDisplayBtn(false)
        }
    }


    return (
        <>
            <CModal
                visible={visible}
                setVisible={setVisible}
                uId="return-order"
                radius={'30px'}
                accessClose={true}
            >
                <form id='return-form' ref={formRef} className="w-full flex flex-col p-3" onSubmit={handelSubmitForm}>

                    <div className={`w-full flex-col flex my-3 `}>
                        <span>Change status <span className="text-red-600">*</span></span>
                        <select name='status' className="mt-2 w-full h-[40px] rounded-md bg-transparent border px-2" required={true}>
                            <option className="text-gray-400" value="" disabled selected>Select your option</option>
                            <option value={"approved"}>Approved</option>
                            <option value={'declined'}>Declined</option>
                        </select>
                    </div>

                    <div className="flex justify-around mt-[20px]">

                        {displayBtn == true && visible == true ?
                            <button type={'button'} className=" w-[120px] h-10 border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                <span>Cancel</span>
                            </button>
                            :
                            <button type={'button'} onClick={() => setVisible(false)} className=" w-[120px] h-10 border border-gr-300 flex items-center justify-center rounded-[30px] text-gr-300">
                                <span>Cancel</span>
                            </button>
                        }

                        {displayBtn == true && visible == true ?
                            <button type={'button'} className=" w-[120px] h-10 bg-gr-300 flex items-center justify-center rounded-[30px] text-white " disabled>
                                <BtnLoader />
                            </button>
                            :
                            <button type={'submit'} className=" w-[120px] h-10 bg-gr-300 flex items-center justify-center rounded-[30px] text-white">
                                <span>submit</span>
                            </button>
                        }
                    </div>

                </form>
            </CModal >
        </>
    )
}