import { customApi, baseUrl } from ".."

export const PickupOrderApi = async (orderId: any, formRef: any) => {
    let postData = {
        'pickup_date': formRef.current['pickup_date'].value,
        'pickup_time': formRef.current['pickup_time'].value,
    }
    const data: any = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/${orderId}/pickup_request` }, { method: "POST", token: true , body:JSON.stringify(postData) })
    return data;
}


export const GetInvoiceApi = async (orderId: any) => {
    const data: any = await customApi({ url: `${baseUrl}orders/${orderId}/invoice` }, { method: "GET", token: true })
    return data;
}


export const TrackOrderApi = async (trackId:any) => {
    const data: any = await customApi({ url: `${baseUrl}/orders/pickup_request_tracking?pickup_request_no=${trackId}` }, { method: "GET", token: true  })
    return data;
}


export const ConvertToReceivedApi = async (orderId: any) => {
    const data: any = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/${orderId}/convert_to_order_received` }, { method: "POST", token: true  })
    return data;
}
