import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import BtnLoader from '../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../widget/apis';
import LoadingPage from '../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import UploadWP from '../../widget/uploadWithProgress';
import { ChangePasswordAPi, UpdateProfileAPi } from '../../widget/apis/profile-api';


function EditProfile() {
  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<boolean>(false)
  const [detailData, setDetailData] = useState<any>({})
  const [selectedPermission, setSelectedPermission] = useState<any>({})
  const [selectedImages, setSelectedImages] = useState<{ path: any, url: string }>()
  const [display, setDisplay] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState({
    'first': false,
    'secound': false,
  })
  const [textError, setTextError] = useState('');
  const [displayFormError, setDisplayFormError] = useState(false);
  const updataProfile = async (e: any) => {
    e.preventDefault()
    setDisplayPage(true)
    try {
      const res = await UpdateProfileAPi(e, selectedImages?.path)
      toast("Updated successfully", { type: "success" })
      setDisplayPage(false)
    }
    catch {
      setDisplayPage(false)
    }
  }

  const getData = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}profile` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setSelectedImages(req.data.avatar)
      setDetailData(req.data)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("Update Profile")
    Ctx.setActiveItemNav({ 'menu': 'profile', 'subMenu': 0 })
    getData()
  }, [])


  const onChangeImage = async (response: any) => {
    setSelectedImages(response)
  };

  const displayPassword = (type: string) => {
    let data: any = { ...showPassword };
    data[type] = !data[type]
    setShowPassword(data)
  }

  const handelFormError = (textError: string) => {
    setDisplayFormError(true)
    setTextError(textError)
    toast(textError, { type: "error" })
    return
  }

  const updataPass = async (e: any) => {
    e.preventDefault();
    if (e.target[1].value.length < 8) { return handelFormError("password not match") }
    setDisplayFormError(false)
    if (e.target[1].value != e.target[2].value) { return handelFormError("password not match") }
    setDisplay(true)
    try {
      const res = await ChangePasswordAPi(e)
      toast(res.message, { type: `${res.status ? "success" : "error"}` })
      setDisplay(false)
      if (res.status) {
        window.location.href = "/panel/dashboard"
      }
    }
    catch (err: any) {
      handelFormError(err?.message)
      setDisplay(false)
    }
  }


  return (
    <div className=' w-full flex flex-wrap   '>
      {displayPage && <LoadingPage />}
      {/* {detailData != null && */}
      <div className=' w-full md:w-5/12 flex flex-col p-4 bg-white h-fit mt-6 mx-1 md:ml-6 rounded-lg '>
        <h1 className=' text-lg font-bold '>Your Profile</h1>
        <form onSubmit={updataProfile} className=' w-full flex flex-wrap justify-between md:w-5/12   flex-col'>
          <div className=" w-full">

            <div className=" w-full flex flex-col mt-5 mb-10">
              <span className=" text-glasses-text-50 text-sm">Email</span>
              <input value={detailData.email} type="email" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
            </div>

            <div className=" w-full flex flex-col mt-5">
              <span className=" text-glasses-text-50 text-sm">Name</span>
              <input defaultValue={detailData.name} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
            </div>

            <div className=" w-full flex flex-col mt-5">
              <span className=" text-glasses-text-50 text-sm">Phone</span>
              <input defaultValue={detailData.phone} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
            </div>

            <div className=" w-full flex flex-col mt-5 mx-1">
              <span className=" text-glasses-text-50 text-sm mb-2">Avatar</span>
              <UploadWP imageRatio={1} currentIMG={selectedImages?.url} setResponse={(res: any) => onChangeImage(res)}
                url={`profile/image`} fileCount={1} />
            </div>

            <button className=" text-white rounded-lg bg-glasses-head-50 h-[40px] mt-9 font-bold px-4">Update Profile </button>
          </div>

        </form>
      </div>

      <div className=' w-full md:w-5/12 flex flex-col p-4 bg-white h-fit mt-6 mx-1 md:ml-6 rounded-lg '>
        <h1 className=' text-lg font-bold '>Your access</h1>
        {detailData?.roles?.map((item: any, index: number) =>
          <>
            <div key={index} onClick={() => { setSelectedPermission(item) }} className={` w-full flex-wrap flex items-center bg-gray-100 rounded-lg px-3 py-2 mb-2`}>
              <div className={` w-full flex items-center justify-between cursor-pointer ${selectedPermission?.shop?.id === item.shop.id ? "mb-2" : "mb-0"}`}>
                <div className='flex '>
                  <img className=' w-[35px] rounded-lg h-[35px] mr-2 ' src={item.shop.logo} />
                  <span className=' mr-4'>Shop: {item.shop.name}</span>
                  <span>Name: {item.display_name}</span>
                </div>
                <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all ${selectedPermission?.shop?.id === item.shop.id ? " rotate-90" : "rotate-[-90deg]"} `}>
                  <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
                </svg>
              </div>
              <div className={` flex flex-wrap w-full bg-gray-200 px-2 py-2 rounded-lg ${selectedPermission?.shop?.id === item.shop.id ? "visible opacity-100" : "invisible opacity-0 h-0 py-0"}`}>
                <h5 className=' w-full '>permission:</h5>
                {item.permissions.map((item: any, index: number) =>
                  <span className=' px-2 py-1 bg-blue-100 text-xs mx-1 mb-2 rounded-lg shadow-md'>{item.display_name}</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className=' w-full md:w-5/12 flex flex-col p-4 bg-white h-fit mt-6 mx-1 md:ml-6 rounded-lg '>
        <h1 className=' text-lg font-bold '>Change Password</h1>
        <form onSubmit={updataPass} className=" w-full flex flex-wrap">
          <div className="  w-full flex flex-col p-1">
            <div className="w-full flex flex-col mt-1">
              <span className=" text-xs text-gray-500">Old Password</span>
              <input type="password" className=" w-full h-9 border-b-2 border-glasses-head-50  pl-1 mb-5 text-sm focus:outline-none" required />
            </div>
            <div className="w-full flex flex-col my-1">
              <span className=" text-xs text-gray-500">New Password</span>
              <div className="w-full flex items-center relative mb-3">
                <input type={showPassword.first ? 'text' : 'password'} className=" w-full h-9 border-b-2 border-glasses-head-50 bg-inherit pl-1 text-sm focus:outline-none " required />
                <div onClick={() => displayPassword('first')} className=" w-[30px] h-[30px] absolute right-0 bottom-0 flex justify-center items-center cursor-pointer">
                  <img src="/assets/svg/eye.png" alt="eye" className=" w-[20px]" />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col my-1">
              <span className=" text-xs text-gray-500">Confirm Password</span>
              <div className="w-full flex items-center relative mb-3">
                <input type={showPassword.secound ? 'text' : 'password'} className=" w-full h-9 border-b-2 border-glasses-head-50 bg-inherit pl-1 text-sm focus:outline-none " required />
                <div onClick={() => displayPassword('secound')} className=" w-[30px] h-[30px] absolute right-0 bottom-0 flex justify-center items-center cursor-pointer">
                  <img src="/assets/svg/eye.png" alt="eye" className=" w-[20px]" />
                </div>
              </div>
            </div>
            <div className={` w-full border border-red-500 rounded-[10px] mt-[20px] flex items-center transition-all overflow-hidden ${displayFormError ? 'visible opacity-100 p-3 h-auto' : 'invisible opacity-0 p-0 h-0'}`}>
              <li className="text-sm text-red-500 ml-[20px]">{textError}</li>
            </div>
          </div>
          <div className="  w-full sm:w-6/12 flex flex-col p-1">

          </div>
          <div className="  w-full">
            {!display ?
              <button type="submit" className="text-white rounded-lg bg-glasses-head-50 h-[40px] mt-9 font-bold px-4 ">Update Password</button>
              :
              <button type="button" className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold  flex justify-center items-center relative" disabled>
                <BtnLoader />
              </button>
            }
          </div>
        </form>
      </div >
      {/* } */}
    </div>
  );
}

export default EditProfile;
