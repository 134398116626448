import * as React from 'react';
import CModal from '../../widget/CModal/CModal';
import { Alert } from 'antd';

interface IEditSingleInventoryModalProps {
    setVisible: CallableFunction
    visible: boolean
    selectedInv: any
    onFinish: CallableFunction | any
    changePrice: CallableFunction
    stockData: any
    selectedInvIndex: any
    suggestedPrice: number
    minimumPrice: number
    setSuggestedPrice: CallableFunction
    setMinimumPrice: CallableFunction
}

const EditSingleInventoryModal: React.FunctionComponent<IEditSingleInventoryModalProps> = ({ changePrice, onFinish, selectedInv, setVisible, stockData, visible, selectedInvIndex, minimumPrice, setMinimumPrice, setSuggestedPrice, suggestedPrice }) => {

    return (
        <>
            <CModal title='Edit Pricing' setVisible={setVisible} visible={visible} uId='editInventoryModal' >
                <>
                    {selectedInv &&
                        <form onSubmit={onFinish} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>
                            <Alert
                                className=' w-full'
                                description="Insert optional inputs and then click adjust"
                                type="info"
                                showIcon
                            />

                            <div className=" my-5 mb-5 w-full">
                                <div className=' flex items-center mb-4 justify-between w-full '>
                                    <div className=' flex justify-center items-center flex-wrap  w-[150px] '>
                                        <span className=' w-full flex items-center text-xs justify-center text-gray-400 mb-2 '>Insert minimum price</span>
                                        <input value={minimumPrice} onChange={(e) => { setMinimumPrice(+e.target.value) }} defaultValue={minimumPrice} type="number" className=" w-[calc(100%-70px)] text-center max-w-[150px] h-[30px] rounded-xl bg-white shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                                    </div>
                                    <div className=' flex justify-center items-center flex-wrap  w-[150px] '>
                                        <span className=' w-full flex items-center text-xs justify-center text-gray-400 mb-2 '>Insert suggested price</span>
                                        <input value={suggestedPrice} onChange={(e) => { setSuggestedPrice(+e.target.value) }} defaultValue={suggestedPrice} type="number" className=" w-[calc(100%-70px)] text-center max-w-[150px] h-[30px] rounded-xl bg-white shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                                    </div>
                                    <div className=' flex justify-center items-center flex-wrap  w-[120px] '>
                                        <span className=' w-full flex items-center text-xs justify-center text-gray-400 mb-2 '>Click to apply</span>
                                        <span onClick={() => changePrice(selectedInv)} className={`  rounded w-fit px-4 py-1 flex justify-center items-center cursor-pointer bg-glasses-head-50 text-white`}> Adjust </span>
                                    </div>
                                </div>
                            </div>

                            <div className={` ${selectedInv.isSelected ? "border-l-4 border-l-green-500 " : " border-l-0 "} w-full p-3 flex items-center justify-between bg-gray-50 border-b duration-300 transition-all`}>
                                <div className={`flex items-center w-full `}>
                                    {selectedInv.name}
                                </div>
                                <div className="border flex flex-col justify-center text-center w-[100px] items-center px-2 rounded-xl text-xs bg-white">
                                    <small className="">price</small>
                                    {selectedInv.stock && <>{selectedInv.price ? selectedInv.price : "not set"}</>}
                                </div>
                                <div className="border flex flex-col justify-center text-center w-[220px] items-center px-2 ml-2 rounded-xl text-xs bg-white">
                                    <small className="">Minimum price</small>
                                    {selectedInv.stock && <>{selectedInv.min_price ? selectedInv.min_price : "not set"}</>}
                                </div>

                                <div className="border  flex flex-col justify-center text-center w-[220px] items-center px-2 ml-2 rounded-xl text-xs bg-white">
                                    <small className="">Suggested Price</small>
                                    {selectedInv?.currentStock && <>{selectedInv.suggested_price ? selectedInv.suggested_price : "not set"}</>}
                                </div>

                                <div className=" flex justify-center items-center flex-col">
                                    {selectedInv.suggestion_price ?
                                        <div className=" flex flex-col justify-center items-center bg-white border px-2 py-1  rounded-xl">
                                            <span className=" text-[8px]">Suggested retail price</span>
                                            <span className=" text-xs">{selectedInv.suggestion_price}<span className=" text-[10px] ml-1">AED</span></span>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>


                            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold "> Update </button>

                        </form>
                    }
                </>
            </CModal>

        </>
    );
};

export default EditSingleInventoryModal;
