import React, { useState, useContext, useEffect, useRef } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import ProductEditAsShopWidget from './widget/ProductEditAsShopWidget';
import { checkPermission } from '../../../widget/layout/Nav';
import { ProductsPermissions } from '../../../widget/rolePermissions';

function ProductEditAsShop() {
    const Ctx = useContext(ContextState);
    const [searchParams] = useSearchParams();
    const formRef: any = useRef()
    const params = useParams()
    const navigate = useNavigate();
    const [displayPage, setDisplayPage] = useState<boolean>(false)
    const [childList, setChildList] = useState<any>([])
    const [productSelected, setProductSelected] = useState<number>(0)
    const [productDetail, setProductDetail] = useState<any>(0)


    const returnCatName = (catNum: any) => {
        if (+catNum === 1) return "sunglasses"
        if (+catNum === 2) return "eyeglasses"
        if (+catNum === 3) return "contact_lenses"
    }


    const getProductDetail = async () => {
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${returnCatName(searchParams.get('categoryId'))}/${params.id}` }, {
            method: "GET",
            token: true
        })
        if (req.status) {
            var newChildren: any = []
            var serverNewStructChildren: any[] = Object.values(req.data.children)
            for (var i in serverNewStructChildren) {
                var childStocks: any[] = []
                var canEdit: boolean = true
                for (var x in serverNewStructChildren[i]) {
                    if (!serverNewStructChildren[i][x].can_edit) canEdit = false
                    childStocks.push({
                        frame: serverNewStructChildren[i][x].frame,
                        stock: serverNewStructChildren[i][x].stock,
                        variant_id: serverNewStructChildren[i][x].variant_id,
                        id: serverNewStructChildren[i][x].id
                    })
                }
                var u = serverNewStructChildren[i][0]
                delete u.variant_id
                newChildren.push({ ...u, frame_sizes: childStocks, can_edit: canEdit })
            }
            setProductDetail({ ...req.data, children: newChildren })
            setChildList(newChildren)
            setProductSelected(req.data.glass_id)
        }
    }

    const onFinishAdd = async (e: any) => {
        e.preventDefault()

        let postData = {
            delivery_day: +formRef.current['delivery_day'].value,
        }
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${params.id}/update_shop` }, { method: "POST", body: JSON.stringify(postData), token: true })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            getProductDetail()
        }
        else {
            toast(req.message, { type: "error" })
        }
    }


    useEffect(() => {
        const cC: any = searchParams.get('categoryId')
        Ctx.setHeadTitle(
            <div className='flex items-center '>
                <Link to={searchParams.get('page') != null ? `/panel/product/list?page=${searchParams.get('page')}` : `/panel/product/list`} >
                    <span className='mr-6 text-base cursor-pointer flex items-center text-glasses-head-50 '>
                        <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-6 h-6 fill-glasses-head-50 transition-all rotate-180 `}>
                            <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
                        </svg>back
                    </span>
                </Link>
                Edit Product ({returnCatName(+cC)}, PI: {params.id})
            </div>
        )
        Ctx.setActiveItemNav({ 'menu': 'products', 'subMenu': 0 })
        getProductDetail()
    }, [])

    useEffect(() => {
        if (!checkPermission(ProductsPermissions.update)) {
            navigate('/panel/product/list');
        }
    }, [])
    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full md:w-6/12 lg:w-4/12  flex flex-col   ">
                <form ref={formRef} onSubmit={onFinishAdd} className=" w-full mb-8">
                    {productDetail ?
                        <div className=" w-full flex flex-col mt-5 add-product-as-shop-form">
                            <h1 className=" text-glasses-text-50 mb-2 text-2xl">
                                {productDetail?.title}
                                <span className=' ml-2 text-lg '>({productDetail?.brand?.name})</span>
                            </h1>
                        </div> : ""
                    }

                    <div className=" w-full flex flex-col mt-5 mb-2">
                        <span className=" text-glasses-text-50 text-sm">Delivery Day</span>
                        <input type="number" name='delivery_day' defaultValue={productDetail.delivery_day} className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>


                    <div className='w-full flex items-center'>
                        <button type='submit'
                            className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-2 font-bold ">Update
                            Product
                        </button>
                        <Link
                            to={searchParams.get('page') != null ? `/panel/product/list?page=${searchParams.get('page')}` : `/panel/product/list`}>
                            <button type='button'
                                className=" text-white rounded-lg border border-glasses-head-50 text-glasses-head-50 ml-2 w-[120px] h-[40px] mt-2 font-bold ">Cancel
                            </button>
                        </Link>
                    </div>

                </form>

                <ProductEditAsShopWidget
                    setProductDetail={setProductDetail}
                    productDetail={productDetail}
                    childList={childList}
                    setChildList={setChildList}
                    setProductSelected={setProductSelected}
                    updateData={() => getProductDetail()}
                />
            </div>
        </>
    );
}

export default ProductEditAsShop;
