import { Alert } from 'antd';
import React, { useEffect, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../widget/apis';
import CModal from '../../widget/CModal/CModal';
import { ContextState } from '../../widget/context/ContextMain'
import { checkisBrand } from '../../widget/layout/Nav';
import LoadingPage from '../../widget/Loading/LoadingPage';
import TablePage from '../../widget/Table/TablePage';
import EditLIstInventoryModal from './EditListInventoryModal';
import EditSingleInventoryModal from './EditSingleInventoryModal';
import FilterInventoryModal from './filterInventoryModal';

interface IStockReportProps {

}

const StockReport: React.FunctionComponent<IStockReportProps> = (props) => {
    const Ctx = useContext(ContextState);
    const params = useParams()
    const [displayPage, setDisplayPage] = useState<boolean>(false)
    const [stockData, setStockData] = useState<any[]>([])
    const [stockTypes, setStockTypes] = useState<any[]>([])
    const [totalStock, setTotalStock] = useState<any>(0)
    const [visible, setVisible] = React.useState<boolean>(false);
    const [visibleStock, setVisibleStock] = React.useState<boolean>(false);
    const [selectedInv, setSelectedInv] = React.useState<any>();
    const [selectedInvIndex, setSelectedInvIndex] = React.useState<number>(0);
    const [selectModeForEditList, setSelectModeForEditList] = React.useState<"add" | "set">("add");
    const [numForEditList, setNumForEditList] = React.useState<number>(0);
    const [listBrand, setListBrand] = useState<any>([])
    const [listCategory, setListCategory] = useState<any>([])
    const [selectedCat, setSelectedCat] = useState<any>(null)
    const [selectedBrand, setSelectedBrand] = useState<any>(null)
    const [currentSortKey, setCurrentSortKey] = useState<any>(null)
    const [currentSortType, setCurrentSortType] = useState<any>(null)
    const [isFilterModal, setIsFilterModal] = React.useState<boolean>(false);
    const [isUseFilterStatus, setIsUseFilterStatus] = useState<boolean>(false)


    var col: any = [
        {
            title: "Color code",
            render: (i: any) => (<>{i.color_code && i.color_code}</>)
        },
        {
            title: "Name",
            render: (i: any) => (<>{i.name != null && i.name}</>)
        },
        {
            title: "Frame color",
            render: (i: any) => (<>{i.frame_color != null && i.frame_color}</>)
        },
        {
            title: "Lens color",
            render: (i: any) => (<>{i.lens_color != null && i.lens_color}</>)
        },
        {
            title: "Frame size",
            render: (i: any) => (<>{i.frame_size != null && i.frame_size}</>)
        },
        {
            title: "Price",
            render: (i: any) => (<>{i.price != null && i.price}</>),
            sort: true,
            sortKey: "price"
        },
        {
            title: "SPH",
            render: (i: any) => (<>{i.sph != null && i.sph}</>)
        },
        {
            title: "Stock",
            render: (i: any) => (<>{i.stock != null && i.stock}</>),
            sort: true,
            sortKey: "stock"
        },
        {
            title: "Edit",
            //   display: checkPermission(ProductsPermissions.update) ? true : false,
            render: (i: any, index: number) => (
                <>
                    {/* <Link to={`/panel/stock-report/edit/${i.id}`}> */}
                    <button onClick={() => {
                        setVisible(true)
                        setSelectedInv(i)
                        setSelectedInvIndex(index)
                    }} className=" h-[35px] px-3 text-white rounded-xl bg-glasses-head-50 flex items-center justify-center">
                        <svg x="0px" y="0px" viewBox="0 0 469.331 469.331" className={` w-3 h-3 fill-white transition-all mr-2  `}>
                            <use xlinkHref="/assets/svg/edit.svg#edit" />
                        </svg>
                        <span>Edit</span>
                    </button>
                    {/* </Link> */}
                </>
            )
        },
    ]

    const getData = async (name?: string, colorCode?: string, category?: any, brand?: any, status?: any, sort?: any) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/inventories?tax=""${name ? `&name=${name}` : ""}${colorCode ? `&color_code=${colorCode}` : ""}${category ? `&category=${category}` : ""}${!checkisBrand() ? `${brand ? `&brand=["${brand}"]` : ""}` : " "}${isUseFilterStatus ? `${status ? `&status=${status}` : ""}` : ""}${sort ? `&${sort}` : ""}` }, { method: "GET", token: true })

        if (req.status) {
            var TS: number = 0
            var unitArr: any[] = []
            var titlesArr: any[] = []
            for (var i in req.data) {
                for (var x in req.data[i]) {
                    TS += req.data[i][x].stock
                    req.data[i][x]["title"] = [i][0]
                    unitArr.push({ ...req.data[i][x], currentStock: req.data[i][x].stock })
                }
                titlesArr.push({ stock: req.data[i][0], isActive: false, quantity: req.data[i].length })
            }
            setTotalStock(TS)
            setDisplayPage(false)
            setIsFilterModal(false)
            setVisibleStock(false)
            setStockData(unitArr)
            setStockTypes(titlesArr)
        } else {
            toast(req.message, { type: "error" })
        }
    }


    const getBrands = async () => {
        const req = await customApi({ url: `${baseUrl}brands/dropdown` }, { method: "GET", token: true })
        if (req.status == true) {
            setListBrand(req.data)
        }
    }

    const getCategory = async () => {
        const req = await customApi({ url: `${baseUrl}categories/dropdown` }, { method: "GET", token: true })
        if (req.status == true) {
            setListCategory(req.data)
        }
    }


    useEffect(() => {
        Ctx.setHeadTitle("Inventory")
        Ctx.setActiveItemNav({ 'menu': 'stock-report', 'subMenu': 0 })
        getData()
        getBrands()
        getCategory()
    }, [])


    const onFinish = async (e: any) => {
        e.preventDefault()
        var FinalSelectedList = stockData.filter((e: any) => e.isSelected)
        var postBody: any[] = []
        for (var i in FinalSelectedList) {
            postBody.push({
                "id": FinalSelectedList[i].id,
                "stock": FinalSelectedList[i].currentStock,
            })
        }
        setDisplayPage(true)
        let payload = { variants: postBody }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/inventories` }, { method: "POST", token: true, body: JSON.stringify(payload) })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            setVisible(false)
            getData()
            // setTimeout(() => window.location.reload(), 1500)
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const onFinishSingle = async (e: any) => {
        e.preventDefault()
        var postBody: any[] = [{
            "id": selectedInv.id,
            "stock": selectedInv.stock,
            "resone": ""
        }]

        setDisplayPage(true)
        let payload = { variants: postBody }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/inventories` }, { method: "POST", token: true, body: JSON.stringify(payload) })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            setVisible(false)
            getData()
            // setTimeout(() => window.location.reload(), 1500)
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const changeStock = (type: "add" | "minus", item: any) => {
        var lastSelectedItem = { ...item }
        const indexInOriginList = stockData.findIndex((e: any) => e.id === item.id)
        setSelectedInvIndex(indexInOriginList)
        if (type === "add") {
            if (checkisBrand()) {
                setSelectedInv({ ...lastSelectedItem, stock: lastSelectedItem.stock + 1 })
            } else {
                item.stock < stockData[indexInOriginList].stock && setSelectedInv({ ...lastSelectedItem, stock: lastSelectedItem.stock + 1 })
            }
        } else if (type === "minus") {
            item.stock > 0 && setSelectedInv({ ...lastSelectedItem, stock: lastSelectedItem.stock - 1 })
        }
    }

    const setActiveTab = (item: any) => {
        var lastArr = [...stockTypes]
        var FI = lastArr.findIndex(e => e.stock.title === item.stock.title)
        lastArr[FI].isActive = !item.isActive
        setStockTypes([...lastArr])
    }

    const editListOfStock = () => {
        var lastArr = [...stockData]
        if (selectModeForEditList === "add") {
            for (var i in lastArr) {
                if (lastArr[i].isSelected) {
                    lastArr[i].currentStock = numForEditList + lastArr[i].stock
                }
            }
        } else if (selectModeForEditList === "set") {
            for (var i in lastArr) {
                if (lastArr[i].isSelected) {
                    lastArr[i].currentStock = numForEditList

                }
            }
        }
        setStockData([...lastArr])
    }


    function filterInModal(e: any) {
        e.preventDefault()
        getData(e.target[0].value, e.target[1].value, selectedCat, !checkisBrand() && selectedBrand, isUseFilterStatus && e.target[3]?.checked ? 2 : 1)
        const form: any = document.getElementById("filterProductFormIN")
        form?.reset()
    }

    const filterColDependedOnCat = (catType: any, col: any[]) => {
        var colArr = [...col]
        if (catType === "SunGlass") {
            colArr.splice(6, 1)
        } else if (catType === "EyeGlass") {
            colArr.splice(3, 1)
            colArr.splice(5, 1)
        } else if (catType === "Contact Lens") {
            colArr.splice(0, 1)
            colArr.splice(1,3)
        }
        return colArr
    }

    return (
        <>
            {displayPage && <LoadingPage />}
            <div onClick={() => setIsFilterModal(!isFilterModal)} className=' flex w-[100px] justify-center absolute top-6 right-5 items-center mt-3 px-4 py-2 text-sm mx-2 rounded-lg bg-glasses-head-50 cursor-pointer text-white'>
                Filter
            </div>
            <div className={`  w-full h-[70px] flex items-center justify-end py-4 ${stockData.filter((e: any) => e.isSelected).length !== 0 ? " max-h-[500px] opacity-100 visible " : " max-h-[0] opacity-0 invisible"} transition-all duration-1000`}>
                <button onClick={() => { checkisBrand() ? setVisibleStock(true) : setVisible(true) }} className=" px-[10px] h-[40px] bg-glasses-head-50 rounded-xl text-white"> Edit quantity </button>
            </div>
       
            <div className={` w-full flex flex-col `}>
                {stockTypes.length !== 0 && stockTypes.map((items, index) =>
                    <>
                        <div key={index} className={` w-full flex flex-col bg-gray-100 pl-3 rounded-xl ${items.isActive ? " max-h-[3000px] " : " "}  overflow-hidden duration-1000 transition-all py-4 mb-2`}>
                            <div className=' w-full items-center justify-between '>
                                <div className=' flex items-center cursor-pointer ' onClick={() => setActiveTab(items)}>
                                    <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 mr-4 fill-glasses-head-50 transition-all ${items?.isActive ? " rotate-90" : "rotate-[-90deg]"} `}>
                                        <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
                                    </svg>
                                    {items.stock?.title}
                                    <span className=' ml-3 text-xs'>({items.quantity}) items </span>
                                </div>
                            </div>
                            <div className={` w-full  flex flex-col   ${items.isActive ? " max-h-[3000px] opacity-100 visible mt-4 " : " max-h-0  opacity-0 invisible "} `}>
                                <TablePage
                                    TypeOfFilter={"title"}
                                    keyOfFilter={items.stock?.title}
                                    isSelective={checkisBrand()}
                                    columns={filterColDependedOnCat(items.stock?.title, col)}
                                    data={stockData}
                                    setData={setStockData}
                                    isCol
                                    isSortMode={{
                                        status: true,
                                        keyOfServerDataFetch: "o_",
                                        getData: getData,
                                        currentSortKey: currentSortKey,
                                        currentSortType: currentSortType,
                                        setCurrentSortKey: setCurrentSortKey,
                                        setCurrentSortType: setCurrentSortType
                                    }}
                                >
                                </TablePage>
                            </div>
                        </div>
                    </>
                )}

            </div>

            <div className=' fixed bottom-7 w-fit mx-auto right-0 left-0 shadow-lg rounded-lg justify-center flex items-center p-4 py-2 bg-gray-300 text-gray-600 text-2xl '>
                Total Stock : {totalStock}
            </div>



            {/*  ------------ edit single inventory modal ------------  */}
            <EditSingleInventoryModal
                changeStock={changeStock}
                onFinish={onFinishSingle}
                selectedInv={selectedInv}
                selectedInvIndex={selectedInvIndex}
                setVisible={setVisible}
                stockData={stockData}
                visible={visible}
            />

            {/*  ------------ edit selected list modal ------------  */}

            <EditLIstInventoryModal
                editListOfStock={editListOfStock}
                numForEditList={numForEditList}
                onFinish={onFinish}
                selectModeForEditList={selectModeForEditList}
                setNumForEditList={setNumForEditList}
                setSelectModeForEditList={setSelectModeForEditList}
                setVisibleStock={setVisibleStock}
                stockData={stockData}
                visibleStock={visibleStock}
            />

            {/*  ------------  filter modal  ------------  */}

            <FilterInventoryModal
                filterInModal={filterInModal}
                isFilterModal={isFilterModal}
                listBrand={listBrand}
                listCategory={listCategory}
                setIsFilterModal={setIsFilterModal}
                setSelectedBrand={setSelectedBrand}
                setSelectedCat={setSelectedCat}
                isUseFilterStatus={isUseFilterStatus}
                setIsUseFilterStatus={setIsUseFilterStatus}
            />

        </>
    );
};

export default StockReport;
