import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../../widget/apis';
import CModal from '../../../widget/CModal/CModal';

interface IGroupFilterModalProps {
    setIsGroupFilterModal: CallableFunction,
    isGroupFilterModal: boolean,
    getData: CallableFunction
}

const GroupFilterModal: React.FunctionComponent<IGroupFilterModalProps> = ({ isGroupFilterModal, setIsGroupFilterModal, getData }) => {
    const [productItems, setProductItems] = React.useState<any[]>([])
    const [selectedStatus, setSelectedStatus] = React.useState<boolean>(false)
    const [isReq, setIsReq] = React.useState<boolean>(false)
    const [isSelectAll, setIsSelectAll] = React.useState<boolean>(false)

    const getPRoductDropdown = async () => {
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/drop` }, { method: "GET", token: true })
        if (req.status == true) {
            for (var i in req.data) {
                req.data[i]["isSelected"] = false
            }
            setProductItems(req.data)
        }
    }

    useEffect(() => {
        setIsSelectAll(false)
        if (isGroupFilterModal) {
            if (productItems.length === 0) getPRoductDropdown()
            else {
                var lastArr = [...productItems]
                for (var i in lastArr) {
                    lastArr[i]["isSelected"] = false
                }
                setProductItems([...lastArr])
            }
        }
    }, [isGroupFilterModal])

    function insertInList(item: any) {
        var lastArr = [...productItems]
        let indexOfItem = lastArr.findIndex(e => e.id === item.id)
        lastArr[indexOfItem].isSelected = !item.isSelected
        setProductItems([...lastArr])
    }

    async function submitChanges() {
        setIsReq(true)
        if (isReq) return
        var lastArr = [...productItems]
        var selectedArr = []
        for (var i in lastArr) {
            if (lastArr[i].isSelected) {
                selectedArr.push(lastArr[i].id)
            }
        }
        if (selectedArr.length == 0) {
            setIsReq(false)
            return toast(" no item selected", { type: "warning" })
        }
        const postBody = {
            "ids": selectedArr,
            "status": selectedStatus
        }

        const res = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/multi_change_status` }, { method: "POST", body: JSON.stringify(postBody), token: true })
        if (res.status) {
            getData()
            toast(res.message, { type: "success" })
            setIsGroupFilterModal(false)
            setIsReq(false)
        } else {
            toast(res.message, { type: "error" })
            setIsReq(false)
        }
    }

    function selectUnselectAll() {
        var lastArr = [...productItems]
        for (var i in lastArr) {
            lastArr[i]["isSelected"] = !isSelectAll
        }
        setIsSelectAll(!isSelectAll)
    }

    return (
        <>
            <CModal title='  Group Filter options' setVisible={setIsGroupFilterModal} visible={isGroupFilterModal} uId='filterGroupProductModal' >
                <>
                    <div className=' w-full flex items-center py-2'>
                        <span onClick={() => selectUnselectAll()} className=' rounded-xl text-xs p-2 px-4 cursor-pointer bg-gray-100 '>
                            {isSelectAll ? " Unselect All " : " Select All "}
                        </span>
                    </div>
                    <div className={` h-[480px] overflow-y-auto flex flex-col ${productItems.length > 0 ? " justify-start " : " justify-center "} items-center`}>
                        {productItems.length > 0 ? productItems?.map((item: any, index: any) =>
                            <div key={index} className={` ${item.isSelected ? "border-l-4 border-l-green-500 " : " border-l-0 "} w-full p-3 flex items-center justify-start bg-gray-50 border-b duration-300 transition-all`}>
                                <span className=' mr-6 w-4'>{index + 1}</span>
                                <div className={`flex items-center `}>
                                    <div onClick={() => insertInList(item)} className=" border border-green-400 w-[15px] h-[15px] flex justify-center cursor-pointer items-center mr-2 rounded-sm">
                                        {item.isSelected ? <div className=" bg-green-400 w-[10px] h-[10px] rounded-sm" /> : ""}
                                    </div>
                                    <div className=" flex ml-2 justify-center items-center" >
                                        <span className="ml-1  ">{item.title}</span>
                                    </div>

                                </div>
                            </div>
                        ) :
                            <span className=' w-full flex justify-center items-center py-20'>
                                loading ...
                            </span>
                        }
                    </div>
                </>

                <div className=' w-full flex items-center justify-between pt-4 px-4'>
                    <div className=' flex flex-col'>
                        <span>Choose Status</span>
                        <select onChange={(e: any) => setSelectedStatus(JSON.parse(e.target.value))} className=" bg-[#ddd] rounded-xl focus:outline-none p-2">
                            <option className=' ' value={"false"}>set items de-active</option>
                            <option className=' ' value={"true"}>set items active</option>
                        </select>
                    </div>
                </div>
                <div className=' w-full flex items-center justify-end pt-4'>
                    <button onClick={() => submitChanges()} className=" w-[120px] h-10 m-3 bg-gr-300 flex items-center justify-center rounded-[30px] text-white">
                        {isReq ?
                            <span>Wait ...</span>
                            :
                            <span>Submit Changes</span>
                        }
                    </button>
                </div>

            </CModal>
        </>
    );
};

export default GroupFilterModal;
