import { useContext, useEffect, createRef, useState } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import { GetInvoiceApi } from '../../widget/apis/orders';
import CSkeleton from '../../widget/CSkeleton/CSkeleton';
import { useParams } from 'react-router-dom';
// @ts-ignore
import { useScreenshot, createFileName } from 'use-react-screenshot'



function Index() {
  const ref: any = createRef();
  const Ctx = useContext(ContextState);
  const params = useParams()

  const [invoice, setInvoice] = useState<any>({})
  const [isReq, setIsReq] = useState<boolean>(true)

  const [image, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1
  })

  const getImage = () => takeScreenshot(ref.current).then(download)
  const download = (image: any, { name = `invoice-${invoice.table_information?.order_information.order_number}`, extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };


  const getInvoice = async (id: any) => {
    setIsReq(true)
    try {
      const res: any = await GetInvoiceApi(id)
      setInvoice(res.data)
      setIsReq(false)
    }
    catch {
      setIsReq(false)
    }
  };

  useEffect(() => {
    Ctx.setHeadTitle("Invoice")
    getInvoice(params.id)
  }, [params.id])

  return (
    <>
      {isReq ?
        <div ref={ref} className=' w-full max-w-screen-lg mx-auto flex flex-col items-center p-6 bg-slate-50 rounded-lg my-10 overflow-hidden min-h-[600px]'>
          <CSkeleton />
        </div>
        :
        <div ref={ref} className=' w-full max-w-screen-lg mx-auto flex flex-col items-center p-6 bg-slate-50 rounded-lg my-10'>
          <div className=' w-full flex justify-end mb-5'>
            {/* <Pdf targetRef={ref}
                            filename={`invoice-${invoice.table_information?.order_information.order_number}`}
                            scale={0.79}> */}
            {/* {({ toPdf }: any) => */}
            <button className=' rounded-lg bg-blue-400 text-yellow-50 px-4 py-2'
              onClick={() => getImage()}>Download Invoice</button>
            {/* </Pdf> */}
          </div>
          <div className=' w-full flex justify-between mb-4'>
            <img className=' w-full max-w-[150px]' alt='logo' src='/assets/about-logo.png' />
            <h3 className=' text-[26px]'>
              TAX INVOICE
            </h3>
          </div>

          <div className=' w-full justify-between flex'>
            <div className=' flex flex-col w-full max-w-[calc(50%-1rem)] mx-2'>
              <h4 className=' text-[16px] font-bold mb-4'>CUSTOMER INFORMATION</h4>
              <div className=' flex flex-col '>
                <p className=' text-sm mb-2'>NAME: <strong>{invoice.customer_information?.name}</strong></p>
                <p className=' text-sm mb-2'>ADDRESS: <strong>{invoice.customer_information?.address}</strong></p>
                <p className=' text-sm mb-2'>MOBILE NO: <strong>{invoice.customer_information?.mobile}</strong></p>
              </div>
            </div>
            <div className=' flex flex-col w-full max-w-[calc(50%-1rem)] mx-2 mb-6'>
              <p className=' '>Sold By: <strong>{invoice.sold_information?.sold_by}</strong></p>
              <p className=' text-sm'>VAT#: <strong>{invoice.sold_information?.vat}</strong></p>
              <div className=' border-b-[1px] border-b-gray-600 mt-4' />
              <p className=' text-sm mb-2'>TAX Invoice Issue Date: <strong>{invoice.sold_information?.tax_date}</strong></p>
              <p className=' text-sm mb-2'>TAX Invoice Number: <strong>{invoice.sold_information?.tax_id}</strong></p>
              <p className=' text-sm mb-2'>Total Payable: <strong>-----</strong></p>
            </div>
          </div>

          <p className=' w-full mb-3'>For questions about your order, visit the website:<a href={"https://optics4less.com/"} target={'_blank'} rel="noreferrer" className=' ml-3'><strong>https://www.optics4less.com</strong></a></p>
          <div className=' w-full flex flex-col border-2 border-gray-600'>
            <div className=' w-full flex flex-wrap'>
              <div className=' w-full md:w-4/12 border-r-gray-600 flex flex-col border-2 p-2'>
                <p className=' font-bold text-sm'>BILLING ADDRESS: </p>
                {invoice.table_information?.billing_address}
              </div>
              <div className=' w-full md:w-4/12 border-r-gray-600 flex flex-col border-2 p-2'>
                <p className=' font-bold text-sm'>DELIVERY ADDRESS: </p>
                {invoice.table_information?.delivery_address}
              </div>
              <div className=' w-full md:w-4/12 flex flex-col p-2'>
                <p className=' font-bold text-sm'>SOLD BY: </p>

                <h5 className=' font-bold text-lg mt-4'>{invoice.table_information?.sold_by.name}</h5>
                <small className=' text-[10px]'>
                  {invoice.table_information?.sold_by.address}<br />
                  Commercial License No: {invoice.table_information?.sold_by.licence}<br />
                  VAT #: {invoice.table_information?.sold_by.vat}
                </small>
              </div>
            </div>
            <div className=' w-full border-t-gray-600 border-2 p-2'>
              <p className=' font-bold text-sm'>ORDER INFORMATION:</p>
              <p className=' text-sm mb-2 mt-6'>Order Date: <strong>{invoice.table_information?.order_information.order_date}</strong></p>
              <p className=' text-sm mb-2'>Order Number:<strong>{invoice.table_information?.order_information.order_number}</strong></p>
            </div>
          </div>

          <div className=' w-full mt-8'>
            <div className=' w-full bg-gray-200 p-2 font-bold'>NVOICE DETAILS</div>
            <table className=' w-full invoice-table'>
              <tr>
                <th className=' min-w-[200px]'>Item Code</th>
                <th className=' min-w-[200px]'>DESCRIPTION</th>
                <th>QTY</th>
                <th><p>UNIT PRICE </p><small className=' text-[12px] font-thin'>(excl. VAT)</small></th>
                <th>VAT RATE</th>
                <th>UNIT VAT AMOUNT</th>
                <th><p>UNIT PRICE </p><small className=' text-[12px] font-thin'>(incl. VAT)</small></th>
                <th><p>Item Subtotal </p><small className=' text-[12px] font-thin'>(incl. VAT)</small>
                </th>
              </tr>
              {invoice.invoices_information.map((item: any, index: number) =>
                <tr key={index}>
                  <td>{item.slug}</td>
                  <td>{item.description}</td>
                  <td>{item.count}</td>
                  <td>{item.unit_price}</td>
                  <td>{item.vat_rate}</td>
                  <td>{item.unit_vat_price}</td>
                  <td>{item.unit_with_vat_price}</td>
                  <td>{item.unit_with_vat_sum_price}</td>
                </tr>
              )}

              <tr>
                <td className=' bg-gray-200 text-center '>------</td>
                <td className=' bg-gray-200 text-center '>------ </td>
                <td className=' bg-gray-200 text-center '>------</td>
                <td className=' bg-gray-200 text-center '>------ </td>
                <td className=' bg-gray-200 text-center '>------</td>
                <td className=' bg-gray-200 text-center '>------</td>
                <td className=' '></td>
              </tr>
            </table>
            <div className=' w-full bg-gray-100 border border-t-0 p-2 font-bold flex'>
              <div className=' flex w-[calc(100%-150px)] justify-between'>
                <p></p>
                <h3 className=' text-lg'>TOTAL</h3>
              </div>
            </div>
          </div>

          <div className=' w-full mt-8'>
            <table className=' w-full invoice-table'>
              <tr className=' bg-gray-200'>
                <th className=" w-3/12">VAT SUMMARY</th>
                <th className=" w-3/12">VAT RATE </th>
                <th className=" w-3/12"><p>SUBTOTAL</p><small className=' text-[12px] font-thin'>(excl. VAT)</small></th>
                <th className=" w-3/12">VAT</th>
              </tr>

              <tr>
                <td> </td>
                <td>5%</td>
                <td></td>
                <td> </td>
              </tr>
              <tr>
                <td><p className=' font-bold'>TOTAL</p></td>
                <td></td>
                <td></td>
                <td> </td>
              </tr>
            </table>
          </div>

          <div className=' flex flex-col border-t border-t-gray-600 mt-12 w-full'>
            <h1 className=' text-xl'>
              NICE OPTICS
            </h1>
            <small>Deira City Centre, Dubai UAE</small>
            <small>Commercial License No:</small>
            <small>VAT #</small>
            <div className=' flex justify-end w-full'>
              <small>
                Please note that this invoice is not a demand for payment
              </small>
            </div>
          </div>
        </div>
      }

    </>
  );
}

export default Index;
