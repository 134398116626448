import React from 'react';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { baseUrl } from './../apis/index';
import { useState } from 'react';
import ImgCrop from 'antd-img-crop';

export declare type UploadListType = 'text' | 'picture' | 'picture-card';

interface IUploadWPProps {
    url: string,
    fileCount: number,
    setResponse: CallableFunction,
    currentIMG: any,
    imageRatio: number,
    customText?: string,
    listType?: UploadListType,
    showCart?: boolean,
    width?: number,
    height?: number,
}

const UploadWP: React.FunctionComponent<IUploadWPProps> = ({
     url,
      fileCount,
       setResponse,
        currentIMG,
         imageRatio, 
         customText = "Upload", listType = "picture-card",
          showCart = true ,
          width =  150,
          height
        }) => {
    const [fileListLength, setFileListLength] = useState<number>(0)

    const uploadProps: UploadProps = {
        name: 'image',
        listType: listType,
        action: `${baseUrl}${url}`,
        headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onChange(info) {

            if (info.file.status === 'uploading') {
                setFileListLength(fileCount)
            }
            if (info.file.status === 'done') {
                if (info.fileList.length !== 0) {
                    setFileListLength(info.fileList.length);
                    setResponse(info.file.response.data)
                }
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                setFileListLength(0)
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 4,
            format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        onRemove(file) {
            setFileListLength(0)
        },
    };


    return (

        <>
            < ImgCrop aspect={imageRatio}>
                <Upload {...uploadProps}>
                    {fileListLength < fileCount &&
                        <div style={{width: width, height: height}} className={` bg-cover relative flex justify-center items-center flex-col p-2 bg-gray-300 rounded-xl`}>
                            <img src={currentIMG ? currentIMG : "/assets/svg/empty-cart.svg"} className="w-full absolute rounded-xl" />
                            <div className=" w-full h-full border border-dashed flex flex-col justify-center items-center rounded-xl backdrop-blur-[1px] bg-gray-100/40">
                                <h3 className="text-gray-800 z-10 text-xs">
                                    {customText}
                                </h3>
                                <svg x="0px" y="0px" viewBox="0 0 218.166 218.166" className={` w-6 h-6  fill-gray-800 transition-all `}>
                                    <use xlinkHref="/assets/svg/photo.svg#photo" />
                                </svg>
                            </div>
                        </div>
                    }
                </Upload>
            </ImgCrop>
        </>
    );
};

export default UploadWP;
