import React from 'react';


async function AddToCart(product: any, children: any) {
    if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
    }
    let cart_string = localStorage.getItem('cart');
    if (typeof cart_string === 'string') {
        var cart = JSON.parse(cart_string);
    }
    for (let item of cart) {
        if (item.shop.id == product.shop.id) {
            for (let pro of item.shopCart) {
                if (pro.product_slug == children.slug) {
                    pro.count++;
                    await TotalCard(cart)
                    localStorage.setItem('cart', JSON.stringify(cart))
                    let cardLength = await CountProductOfCard(cart);
                    // toast("Success add to cart", { type: "success" })
                    return [cart, cardLength];
                }
            }
            item.shopCart.push({
                'product_slug': children.slug,
                'count': 1,
                'price': children.price,
                'title': product.title,
                'image': children.images[0],
                'show_price': children.show_price,
                'product_parent_id':product.id ,
                'product_parent_slug':product.slug ,
            })
            await TotalCard(cart)
            let cardLength = await CountProductOfCard(cart);
            localStorage.setItem('cart', JSON.stringify(cart))
            // toast("Success add to cart", { type: "success" })
            return [cart, cardLength];
        }
    }
    cart.push({
        'shop': {
            'id': product.shop.id,
            'name': product.shop.name,
            'logo': product.shop.logo,
            'feathers': product.feathers,
            'shipping_price': product.shop.shipping_price,
            'benefits': product.shop.benefits,
        },
        'shopCart': [{
            'product_slug': children.slug,
            'count': 1,
            'price': children.price,
            'title': product.title,
            'image': children.images[0],
            'show_price': children.show_price,
            'product_parent_id': product.id,
            'product_parent_slug': product.slug,
        }]
    })
    await TotalCard(cart)
    let cardLength = await CountProductOfCard(cart);
    localStorage.setItem('cart', JSON.stringify(cart))
    return [cart, cardLength];
}

export { AddToCart };




async function DeleteProduct(shopID: string, product_slug: string) {
    if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
    }
    let cart_string = localStorage.getItem('cart');
    if (typeof cart_string === 'string') {
        var cart = JSON.parse(cart_string);
    }
    for (let singleCart of cart) {
        if (singleCart.shop.id == shopID) {
            for (let pro in singleCart.shopCart) {
                if (singleCart.shopCart[pro].product_slug == product_slug) {
                    singleCart.shopCart.splice(pro, 1)
                    await TotalCard(cart)
                    let cardLength = await CountProductOfCard(cart);
                    localStorage.setItem('cart', JSON.stringify(cart))
                    return [cart, cardLength];
                }
            }
        }
    }
    return cart;
}
export { DeleteProduct };




async function PlusItem(shopID: string, product_slug: string) {
    if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
    }
    let cart_string = localStorage.getItem('cart');
    if (typeof cart_string === 'string') {
        var cart = JSON.parse(cart_string);
    }
    for (let singleCart of cart) {
        if (singleCart.shop.id == shopID) {
            for (let pro of singleCart.shopCart) {
                if (pro.product_slug == product_slug) {
                    pro.count++;
                    await TotalCard(cart)
                    let cardLength = await CountProductOfCard(cart);
                    localStorage.setItem('cart', JSON.stringify(cart))
                    return [cart, cardLength];
                }
            }
        }
    }
    return cart;
}

export { PlusItem };



async function Minusetem(shopID: string, product_slug: string) {
    if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
    }
    let cart_string = localStorage.getItem('cart');
    if (typeof cart_string === 'string') {
        var cart = JSON.parse(cart_string);
    }
    for (let singleCart of cart) {
        if (singleCart.shop.id == shopID) {
            for (let pro of singleCart.shopCart) {
                if (pro.product_slug == product_slug && pro.count > 1) {
                    pro.count--;
                    await TotalCard(cart)
                    let cardLength = await CountProductOfCard(cart);
                    localStorage.setItem('cart', JSON.stringify(cart))
                    return [cart, cardLength];
                }
            }
        }
    }
    return cart;
}

export { Minusetem };




async function DeleteCart(shopID: string) {
    if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
    }
    let cart_string = localStorage.getItem('cart');
    if (typeof cart_string === 'string') {
        var cart = JSON.parse(cart_string);
    }
    for (let singleCart in cart) {
        if (cart[singleCart].shop.id == shopID) {
            cart.splice(singleCart, 1);
            let cardLength = await CountProductOfCard(cart);
            localStorage.setItem('cart', JSON.stringify(cart))
            return [cart, cardLength];
        }
    }
    return cart;
}
export { DeleteCart };



function CountProductOfCard(card: any) {
    let count = 0;
    for (let i of card) {
        for (let j of i.shopCart) {
            count++;
        }
    }
    return count;

}
export { CountProductOfCard };



async function TotalCard(cards: any) {

    for (let card of cards) {
        let sum = 0;
        for (let p of card.shopCart) {
            sum = sum + (p.count * p.price);
            card.total = sum;
        }
    }
    
    return cards;

}
export { TotalCard };



