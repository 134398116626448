import { useState } from "react";
import CModal from "../../../../widget/CModal/CModal"
import { baseUrl, customApi } from "../../../../widget/apis";
import { toast } from "react-toastify";
import BtnLoader from "../../../../widget/Loading/BtnLoader";

const ModalMangeTag = ({ visible, setVisible, selectedTags, setSelectedTags }: any) => {
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const handelAddTag = async(e: any) => {
        e.preventDefault()
        await setSelectedTags((prevState: any) => ({
            ...prevState,
            tags: [...prevState.tags, e.target[0].value]
        }));
        e.target.reset()
    }

    const handelDeleteTag = (index: number) => {
        let list = [...selectedTags.tags];
        list.splice(index, 1)
        setSelectedTags((prevState: any) => ({
            ...prevState,
            tags: list
        }))
    }

    const handelSubmitForm = async () => {
        setLoadingBtn(true)

        let list = [];
        for (let i of selectedTags.tags) {
            list.push(i.replace(/ /g, "_"))
        }
        let postData = {
            'product_id': selectedTags.product_id,
            'tags': list,
        }
        const req = await customApi({ url: `${baseUrl}attach_tags` }, { method: "POST", body: JSON.stringify(postData), token: true })
        setLoadingBtn(false)
        if (req.message == "Tags attached successfully!") {
            toast("Updated successfully", { type: "success" })
            setVisible(false)
            setSelectedTags({
                'product_id': 0,
                'tags': [],
            })
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    return (
        <>
            <CModal visible={visible} setVisible={setVisible} title={'Manage Tags'} uId="managetag">
                <div className="w-full flex flex-col">
                    <form onSubmit={handelAddTag} className="w-full flex items-center justify-between">
                        <input className="w-[calc(100%-45px)] h-[40px] rounded-md border px-2 focus:outline-none" placeholder="Enter Your Tags" required/>
                        <button className="w-[40px] h-[40px] rounded-md flex items-center justify-center bg-blue-500 text-white text-[30px]">+</button>
                    </form>
                    {selectedTags.tags.length == 0 ?
                        <div className="w-full h-[100px] flex items-center justify-center text-center border p-3 rounded-md my-3">There are no tags</div>
                        :
                        <div className="w-full flex flex-col">
                            <div className="w-full  flex flex-wrap border p-2 rounded-md my-3">
                                {selectedTags.tags.map((item: any, index: number) =>
                                    <div key={index} className="px-2 py-1 bg-gr-300 text-white rounded-md m-1 flex items-center">
                                        <button onClick={() => handelDeleteTag(index)} type="button" className="w-[20px] h-[20px] rounded-lg bg-white text-gr-300 flex items-center justify-center mr-2 pb-[3px]">x</button>
                                        <span>{item}</span>
                                    </div>
                                )}
                            </div>
                            {loadingBtn ?
                                <button className="w-full h-[40px] bg-blue-400 rounded-md text-white flex items-center justify-center relative"><BtnLoader /></button>
                                :
                                <button onClick={handelSubmitForm} className="w-full h-[40px] bg-blue-400 rounded-md text-white">Apply</button>
                            }
                        </div>
                    }
                </div>
            </CModal>
        </>
    )
}

export default ModalMangeTag;