import React, { useContext } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import RouteData from './RouteData';
import { ContextState } from '../context/ContextMain'
import { ProtectedRoute } from '../apis/PrivateRoute';

// product
import ProductAdd from '../../page/product/product-add/ProductAdd';
import ProductList from '../../page/product/product-list/ProductList';
import ProductEdit from '../../page/product/product-edit/ProductEdit';
import ProductDetail from '../../page/product/product-detail/ProductDetail';
import ProductEditAsShop from '../../page/product/product-edit-as-shop/ProductEditAsShop';
import ProductDuplicate from '../../page/product/product-duplicate/ProductDuplicate';

// orders
import OrderList from '../../page/order/order-list/OrderList';
import OrderDetail from '../../page/order/order-detail/OrderDetail'


//Shops
import ShopList from '../../page/shop/shop-list/ShopList';
import ShopDetail from '../../page/shop/shop-detail/ShopDetail';
import ShopProductDetail from '../../page/shop/shop-product-detail/ShopProductDetail';

// Profile
import EditProfile from '../../page/profile/EditProfile';
import EditShop from '../../page/profile/EditShop';

// Card
import CardList from '../../page/Card/card-list/CardList';

// Pdf
import Invoice from '../../page/invoice/Invoice';

// Order Buy
import OrderBuyList from '../../page/order-buy/order-buy-list/OrderBuyList'
import OrderBuyDetail from '../../page/order-buy/order-buy-detail/OrderBuyDetail';

// Benefits
import BenefitsList from '../../page/Benefits/benefits-list/Benefits-List';
import Settings from '../../page/setting/Settings';
import Dashboard from './../../page/dashboard/Dashboard';
import MyWallet from '../../page/wallet';
import Brand from '../../page/brand';
import Coupon from '../../page/coupon';
import AddNewCoupon from '../../page/coupon/addNewCoupon';
import MyOrder from '../../page/my-order/MyOrder';
import MyOrderRecived from '../../page/my-order/MyOrderRecived';
import Credits from '../../page/credits';

// Statement
import Statement from '../../page/statement';

// Statement
import {Refund} from '../../page/refund';

import AddBrand from '../../page/addBrand';
import Eprator from '../../page/operator';
import RolesOperators from '../../page/operator/roles';
import StockReport from '../../page/stock-report';
import PriceController from '../../page/price-controller';
import NotificationPage from '../../page/notification';
import ProductAddContactLens from '../../page/product/product-add/ProductAddContactLens';
import SelectCategory from '../../page/product/product-add/widget/SelectCategory.Comp';
import ProductEditContactLens from '../../page/product/product-edit/contact_lens/ProductEditContactLens';
import ProductEditAsShopWidget from '../../page/product/product-edit-as-shop/widget/ProductEditAsShopWidget';
import ProductEditAsShopWidgetContactLenses from '../../page/product/product-edit-as-shop/contactLens/ProductEditeAdShopContactLensWidget';
import ProductEditContactLensesAsShop from '../../page/product/product-edit-as-shop/contactLens/ProductEditContactLensAsShop';
import SelectedBrands from '../../page/selected-brand';


function Count(props: any) {
  const Ctx = useContext(ContextState);

  return (

    <div className=" w-full md:w-[calc(100%-90px)]  relative  flex flex-col py-0 md:py-[20px] px-0 md:px-[10px] transition-all">

      <div className={` w-full h-[60px] bg-glasses-head-50 rounded-b-2xl flex items-center justify-between px-[20px] md:hidden transition-all absolute ${props.displayNavMobile ? 'top-[-60px]' : 'top-[0px]'}`}>
        <img src={'/assets/image/logo.png'} alt="logo" className=" h-[40px]  " />
        <svg x="0px" y="0px" onClick={() => { props.setDisplayNavMobile(!props.displayNavMobile) }} viewBox="0 0 330 330" className={` w-5 h-5  fill-[#fff] cursor-pointer  `}>
          <use xlinkHref="/assets/svg/list.svg#list" />
        </svg>
      </div>
      <div className={` w-full px-[10px] md:px-0 mt-[70px] md:mt-[0px]`}>
        <div className=" w-full  bg-glasses-bg-section-50 rounded-2xl flex flex-col p-[20px]" >
          {props.children}
          <div className=" w-full h-11 flex it1ems-center border-b text-glasses-head-50 text-2xl font-bold">{Ctx.headTitle}</div>
          <Routes>
            <Route path="dashboard" element={<ProtectedRoute ><Dashboard /></ProtectedRoute>} />

            <Route path="product/add" element={<ProtectedRoute ><ProductAdd /></ProtectedRoute>} />
            <Route path="product/add/contact-lens" element={<ProtectedRoute ><ProductAddContactLens /></ProtectedRoute>} />
            <Route path="product/edit-as-shop/:id" element={<ProtectedRoute ><ProductEditAsShop /></ProtectedRoute>} />
            <Route path="product/edit-as-shop/contact-lenses/:id" element={<ProtectedRoute ><ProductEditContactLensesAsShop /></ProtectedRoute>} />
            <Route path="product/list" element={<ProtectedRoute ><ProductList /></ProtectedRoute>} />
            <Route path="product/detail/:id" element={<ProtectedRoute ><ProductDetail /></ProtectedRoute>} />
            <Route path="product/edit/:id" element={<ProtectedRoute ><ProductEdit /></ProtectedRoute>} />
            <Route path="product/edit/contact-lenses/:id" element={<ProtectedRoute ><ProductEditContactLens /></ProtectedRoute>} />
            <Route path="product/duplicate/:id" element={<ProtectedRoute ><ProductDuplicate /></ProtectedRoute>} />
            <Route path="product/add/select-category" element={<ProtectedRoute ><SelectCategory /></ProtectedRoute>} />


            <Route path="order/list" element={<ProtectedRoute ><OrderList /></ProtectedRoute>} />
            <Route path="order/detail/:id" element={<ProtectedRoute ><OrderDetail /></ProtectedRoute>} />
            <Route path="my-order" element={<ProtectedRoute ><MyOrder /></ProtectedRoute>} />
            <Route path="my-order-recived" element={<ProtectedRoute ><MyOrderRecived /></ProtectedRoute>} />

            <Route path="shop/list" element={<ProtectedRoute ><ShopList /></ProtectedRoute>} />
            <Route path="shop/detail/:id" element={<ProtectedRoute ><ShopDetail /></ProtectedRoute>} />
            <Route path="shop/product/:id" element={<ProtectedRoute ><ShopProductDetail /></ProtectedRoute>} />

            <Route path="profile" element={<ProtectedRoute ><EditProfile /></ProtectedRoute>} />
            <Route path="setting" element={<ProtectedRoute ><Settings /></ProtectedRoute>} />
            <Route path="brand" element={<ProtectedRoute ><Brand /></ProtectedRoute>} />
            <Route path="coupon" element={<ProtectedRoute ><Coupon /></ProtectedRoute>} />
            <Route path="coupon/add" element={<ProtectedRoute ><AddNewCoupon /></ProtectedRoute>} />
            <Route path="credits" element={<ProtectedRoute ><Credits /></ProtectedRoute>} />
            <Route path="selected-brands" element={<ProtectedRoute ><SelectedBrands /></ProtectedRoute>} />
            <Route path="shop-edit" element={<ProtectedRoute ><EditShop /></ProtectedRoute>} />

            <Route path="card/list" element={<ProtectedRoute ><CardList /></ProtectedRoute>} />

            <Route path="order-buy/list" element={<ProtectedRoute ><OrderBuyList /></ProtectedRoute>} />
            <Route path="order-buy/detail/:id" element={<ProtectedRoute ><OrderBuyDetail /></ProtectedRoute>} />
            <Route path="benefits/list" element={<ProtectedRoute ><BenefitsList /></ProtectedRoute>} />
            <Route path="my-wallet" element={<ProtectedRoute ><MyWallet /></ProtectedRoute>} />

            <Route path="invoice/:id" element={<ProtectedRoute ><Invoice /></ProtectedRoute>} />

            <Route path="refund" element={<ProtectedRoute ><Refund /></ProtectedRoute>} />

            <Route path="statement" element={<ProtectedRoute ><Statement /></ProtectedRoute>} />
            <Route path="add-brand" element={<ProtectedRoute ><AddBrand /></ProtectedRoute>} />
            <Route path="operator-user" element={<ProtectedRoute ><Eprator /></ProtectedRoute>} />
            <Route path="operator-roles" element={<ProtectedRoute ><RolesOperators /></ProtectedRoute>} />
            <Route path="stock-report" element={<ProtectedRoute ><StockReport /></ProtectedRoute>} />
            <Route path="price-controller" element={<ProtectedRoute ><PriceController /></ProtectedRoute>} />
            <Route path="notification" element={<ProtectedRoute ><NotificationPage /></ProtectedRoute>} />



            {/* {RouteData.map((item: any, index: any) =>
                <Route path={item.path} element={item.component} key={index} />
              )} */}
          </Routes>
        </div>
      </div>
      <div className='  fixed bottom-[20px] right-[20px]'>
        <img onClick={() => { Ctx.setDisplayChat(true) }} src='/assets/image/chat.png' alt="chat" className=' w-[50px] h-[50px] rounded-full cursor-pointer' />
      </div>
    </div >
  );
}

export default Count;
