import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import CModal from '../../../widget/CModal/CModal';

interface IsStatusRenderProps {
  status: any,
  isAbsolute?: boolean
}

export const OrderStatusList = [
  {
    name: "preparing",
    status: 1
  },
  {
    name: "completed",
    status: 5
  },
  {
    name: "returned",
    status: 6
  },
]

export const StatusRender: React.FunctionComponent<IsStatusRenderProps> = ({ status, isAbsolute }) => {

  return (
    <>
      {status === -1 && <span className={`text-white rounded-lg px-2 py-1 bg-red-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>Order has been canceled</span>}
      {status === 0 && <span className={`text-white rounded-lg px-2 py-1 bg-slate-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>waiting</span>}
      {status === 1 && <span className={`text-white rounded-lg px-2 py-1 bg-blue-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>Pending for delivery</span>}
      {status === 2 && <span className={`text-white rounded-lg px-2 py-1 bg-stone-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>sending</span>}
      {status === 3 && <span className={`text-white rounded-lg px-2 py-1 bg-purple-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>receiving</span>}
      {status === 4 && <span className={`text-white rounded-lg px-2 py-1 bg-green-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>Delivered</span>}
      {status === 5 && <span className={`text-white rounded-lg px-2 py-1 bg-green-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>Delivered</span>}
      {status === 6 && <span className={`text-white rounded-lg px-2 py-1 bg-red-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>returned</span>}
      {status === 7 && <span className={`text-white rounded-lg px-2 py-1 bg-orange-400 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>reported</span>}
      {status === 8 && <span className={`text-white rounded-lg px-2 py-1 bg-pink-300 text-xs ${isAbsolute && "absolute top-10 right-5"}`}>Pending order</span>}
    </>
  );
};

function OrderList() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [isAllowReq, setIsAllowReq] = React.useState<boolean>(true);
  const [isFilterModal, setIsFilterModal] = React.useState<boolean>(false);
  const [filterItems, setFilterItems] = React.useState<{ user_name?: string, status?: any }>({});



  const col = [
    {
      title: "Order ID",
      render: (i: any) => (<>{i.order_id && i.order_id}</>)
    },
    {
      title: "User Name",
      render: (i: any) => (
        <div className=' flex relative  justify-center items-center'>
          <span className=''>
            <img src={i.client.avatar ? i.client.avatar : "/assets/svg/person.svg"} className=" w-4 h-4 mr-3 rounded-full" />
          </span>
          <span>
            {i.client.name && i.client.name}
          </span>
        </div>
      )
    },
    {
      title: "Phone number",
      render: (i: any) => (<>{i.receive_phone && i.receive_phone}</>)
    },
    {
      title: "Status",
      render: (i: any) => (<StatusRender status={i.status} />)
    },
    {
      title: "Detail",
      render: (i: any) => (
        <Link to={`/panel/order/detail/${i.id}`}>
          <div className=" h-[35px] px-3 border border-glasses-head-50 flex items-center justify-center rounded">
            <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
              <use xlinkHref="/assets/svg/eye.svg#eye" />
            </svg>
          </div>
        </Link>
      )
    },

  ]

  const changePage = (status: any) => {
    if (status) {
      if (lastPage >= currentPage + 1) {
        getData(currentPage + 1)
      }
    }
    else {
      if (currentPage > 1) {
        getData(currentPage - 1)
      }
    }
  }

  const getData = async (current: any, searchKey?: any, user_name?: any, status?: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders?page=${current}${searchKey ? `&search=${searchKey}` : ""}${user_name ? `&user_name=${user_name}` : ""}${status ? `&status=${status}` : ""}` },
      { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status) {
      setListData(req.data.data)
      setCurrentPage(req.data.meta.current_page)
      setLastPage(req.data.meta.last_page)
      setIsFilterModal(false)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("List Orders")
    Ctx.setActiveItemNav({ 'menu': 'orders', 'subMenu': 0 })
    getData(currentPage)
  }, [])

  async function searchWithDelay() {
    if (isAllowReq) {
      const stockInput: any = document.getElementById(`searchInput`)
      setIsAllowReq(false)
      setTimeout(async () => {
        // if (!stockInput.value) return setIsAllowReq(true)
        getData(currentPage, stockInput.value)
        setIsAllowReq(true)
      }, 1500)
    }
  }


  function filterInModal(e: any) {
    e.preventDefault()
    setFilterItems({ user_name: e.target[0].value, status: e.target[1].value })
    getData(currentPage, null, e.target[0].value, e.target[1].value)
    const form: any = document.getElementById("filterOrdersForm")
    form?.reset()
  }


  function filterCancel({ user, status }: any) {
    if (status) {
      setFilterItems((prev) => ({ ...prev, status: "" }))
      getData(currentPage, null, filterItems.user_name, null)
    }
    if (user) {
      setFilterItems((prev) => ({ ...prev, user_name: "" }))
      getData(currentPage, null, null, filterItems.status)
    }
  }

  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full  flex flex-col   ">
        <div className=' w-full flex flex-wrap pt-6 justify-center md:justify-between items-center'>
          <div className=' flex items-center justify-center bg-gray-200 pr-4 overflow-hidden rounded-lg group'>
            <input id='searchInput' className='bg-gray-200 px-4 rounded-tl-lg rounded-bl-lg h-12' placeholder='Search by order id' type={"text"}
              onChange={(e: any) => {
                searchWithDelay()
              }} />
            <svg x="0px" y="0px" viewBox="0 0 53.627 53.627" className={` w-5 fill-gray-500 h-12 bg-gray-200 rounded-tr-lg rounded-br-lg transition-all group-hover:fill-glasses-head-50 `}>
              <use xlinkHref="/assets/svg/search.svg#search" />
            </svg>
          </div>
          <div className=' flex '>
            {filterItems.user_name &&
              <div onClick={() => filterCancel({ user: true })} className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600'>
                <span className=' cursor-pointer mr-2'>x</span>  {filterItems.user_name}
              </div>
            }
            {filterItems.status &&
              <div onClick={() => filterCancel({ status: true })} className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600'>
                <span className=' cursor-pointer mr-2'>x</span> <StatusRender status={parseInt(filterItems.status)} />
              </div>
            }
            <div onClick={() => setIsFilterModal(!isFilterModal)} className=' flex justify-center items-center px-4 py-2 text-sm rounded-lg bg-glasses-head-50 cursor-pointer text-white'>
              Filter
            </div>
          </div>
        </div>
        <TablePage columns={col} data={listData}>
          {currentPage != 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(false)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
              </svg>
            </div>
          }
          <div className="table-counter-main-item">{currentPage} </div>
          {lastPage >= currentPage + 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(true)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
              </svg>
            </div>
          }
        </TablePage>
      </div>

      <CModal title='filter options' setVisible={setIsFilterModal} visible={isFilterModal} uId='filterModalOrders' >
        <>
          <form id="filterOrdersForm" name='filterMOdal' onSubmit={filterInModal} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>
            <div className=" w-10/12 flex flex-col my-5">
              <span className=" text-glasses-text-50 text-sm">User Name</span>
              <input type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
            </div>
            <div className=" w-10/12 flex flex-col my-5">
              <span className=" text-glasses-text-50 text-sm">Status</span>
              <select className=" min-w-[100px] px-[10px] h-[40px] text-sm bg-[#ddd]  rounded-xl  focus:outline-none">
                <option className=' ' value={''} selected hidden>all</option>
                {OrderStatusList.map((item) => <option className=' ' value={item.status}>{item.name}</option>)}
              </select>
            </div>

            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Filter </button>
          </form>
        </>
      </CModal>
    </>
  );
}

export default OrderList;
