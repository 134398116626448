import React, { useState, useContext, useEffect } from 'react';
import CModal from '../../../widget/CModal/CModal';
import { toast } from "react-toastify";


function EditProfileTimes(props: any) {
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<number>(-1)
  const [editItem, setEditItem] = useState<number>(-1)
  const [formValue, setFormValue] = useState<any>({
    'day': null,
    'start_date': null,
    'end_date': null,
  })

  const clearData = () => {
    setFormValue({
      'day': null,
      'start_date': null,
      'end_date': null,
    })
  }

  const onFinishDelete = () => {
    let data = { ...props.detailData };
    for (let i in data.times) {
      if (+i == deleteItem) {
        data.times.splice(i, 1)
      }
    }
    setVisibleDelete(false)
    clearData()
    props.setDetailData(data)
  }

  const changeInput = (value: any, type: string) => {
    let data = { ...formValue };
    data[type] = value;
    setFormValue(data)
  }

  const onFinishAdd = () => {
    if (formValue.day != null && formValue.day != "" && formValue.start_date != null && formValue.start_date != "" && formValue.end_date != null && formValue.end_date != "") {
      let data = { ...props.detailData };
      data.times.push(formValue)
      setVisibleAdd(false)
      clearData()
      props.setDetailData(data)
      clearData()
    }
    else {
      toast("Please fill in all the fields", { type: "error" })
    }
  }

  const onFinishEdit = () => {
    if (formValue.day != null && formValue.day != "" && formValue.start_date != null && formValue.start_date != "" && formValue.end_date != null && formValue.end_date != "") {
      let data = { ...props.detailData };
      for (let i in props.detailData.times) {
        if (+i == editItem) {
          props.detailData.times[i].day = formValue.day;
          props.detailData.times[i].start_date = formValue.start_date;
          props.detailData.times[i].end_date = formValue.end_date;
        }
      }
      props.setDetailData(data)
      setVisibleEdit(false)
      clearData()
    }
    else {
      toast("Please fill in all the fields", { type: "error" })
    }
  }

  return (

    <>
      <div className=' w-full flex flex-col mt-10'>
        <div className='flex justify-between mb-2'>
          <span className=' ml-3 text-glasses-text-50 text-xl font-bold'> Times</span>
          <button onClick={() => { setVisibleAdd(true) }} type="button" className=' px-[30px] border border-[#FFA500] h-[35px] rounded text-[#FFA500]' >+ new time</button>
        </div>
        {props.detailData.times && props.detailData.times.map((item: any, index: number) =>

          <div className='w-full p-[10px] sm:p-[20px] mb-2 bg-white rounded-xl shadow flex flex-col'>
            <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
              <button type='button' className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                <svg x="0px" y="0px" viewBox="0 0 612 612" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                  <use xlinkHref="/assets/svg/clock.svg#clock" />
                </svg>
              </button>
              <span className="mx-[10px] text-[#836c77] font-bold">Day</span>
              <span className="mx-[10px] text-glasses-text-50 font-bold">{item.day} </span>
            </div>
            <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
              <button type='button' className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                <svg x="0px" y="0px" viewBox="0 0 612 612" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                  <use xlinkHref="/assets/svg/clock.svg#clock" />
                </svg>
              </button>
              <span className="mx-[10px] text-[#836c77] font-bold">Start Time </span>
              <span className="mx-[10px] text-glasses-text-50 font-bold">{item.start_date} </span>
            </div>
            <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
              <button type='button' className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                <svg x="0px" y="0px" viewBox="0 0 612 612" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                  <use xlinkHref="/assets/svg/clock.svg#clock" />
                </svg>
              </button>
              <span className="mx-[10px] text-[#836c77] font-bold">End Time </span>
              <span className="mx-[10px] text-glasses-text-50 font-bold">{item.end_date} </span>
            </div>
            <div className=" w-full  flex items-center border-t pt-[10px]">
              <button onClick={() => { setVisibleDelete(true); setDeleteItem(index) }} type='button' className=" w-[35px] h-[35px] flex justify-center items-center rounded border border-[#FFA500]">
                <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                  <use xlinkHref="/assets/svg/trash.svg#trash" />
                </svg>
              </button>
              <button onClick={() => { setEditItem(index); setFormValue(item); setVisibleEdit(true) }} type='button' className=" w-[35px] h-[35px] ml-[5px] flex justify-center items-center rounded border border-[#FFA500]">
                <svg x="0px" y="0px" viewBox="0 0 469.331 469.331" className={` w-4 h-4 fill-[#FFA500] transition-all   `}>
                  <use xlinkHref="/assets/svg/edit.svg#edit" />
                </svg>
              </button>
            </div>
          </div>

        )}
      </div>

      <CModal onScap={clearData} visible={visibleAdd} setVisible={setVisibleAdd} title="AddTimes" uId='AddTimes'>
        <div className=" w-full flex flex-col">
          <div className=" w-full flex flex-col mt-[10px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Day</span>
            <select className=" border h-[35px] w-full rounded px-2 bg-white" value={formValue.day} onChange={(e) => changeInput(e.target.value, 'day')}>
              <option value="" disabled selected>select day</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Start Time</span>
            <input value={formValue.start_date} onChange={(e) => changeInput(e.target.value, 'start_date')} type="time" className=" border h-[35px] w-full rounded px-2" placeholder="Start Time" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">End Time</span>
            <input value={formValue.end_date} onChange={(e) => changeInput(e.target.value, 'end_date')} type="time" className=" border h-[35px] w-full rounded px-2" placeholder="End Time" />
          </div>
          <button onClick={onFinishAdd} type='button' className=" w-full h-[35px] border text-white bg-glasses-head-50 rounded mt-[15px]">Add </button>
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleDelete} setVisible={setVisibleDelete} title="DeleteTime" uId='DeleteTime'>
        <p>Are youe shoure delete this item ?</p>
        <div className="CModel-Bottom">
          <button type='button' onClick={onFinishDelete} className="CModel-Bottom-btn-ok" >Ok </button>
          <button type='button' className="CModel-Bottom-btn-cancel" onClick={() => { setVisibleDelete(false) }}>Cansel</button>
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleEdit} setVisible={setVisibleEdit} title="EditTime" uId='EditTime'>
        <div className=" w-full flex flex-col">
          <div className=" w-full flex flex-col mt-[10px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Day</span>
            <select className=" border h-[35px] w-full rounded px-2 bg-white" value={formValue.day} onChange={(e) => changeInput(e.target.value, 'day')}>
              <option value="" disabled selected>select day</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">Start Time</span>
            <input value={formValue.start_date} onChange={(e) => changeInput(e.target.value, 'start_date')} type="time" className=" border h-[35px] w-full rounded px-2" placeholder="Start Time" />
          </div>
          <div className=" w-full flex flex-col mt-[15px]">
            <span className=" text-glasses-text-50 text-sm mx-1 mb-1">End Time</span>
            <input value={formValue.end_date} onChange={(e) => changeInput(e.target.value, 'end_date')} type="time" className=" border h-[35px] w-full rounded px-2" placeholder="End Time" />
          </div>
          <button onClick={onFinishEdit} type='button' className=" w-full h-[35px] border text-white bg-glasses-head-50 rounded mt-[15px]">Update </button>
        </div>
      </CModal>

    </>
  );
}

export default EditProfileTimes;
