import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

function ProductDetail() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [detailData, setDetailData] = useState<any>(null)
  let params = useParams();



  const viewDetail = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${params.id}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setDetailData(req.data);
      Ctx.setHeadTitle(`Product ${req.data.title}`)
    }
  }



  useEffect(() => {
    Ctx.setHeadTitle(`Product `)
    Ctx.setActiveItemNav({'menu':'products' , 'subMenu':0})
    viewDetail()
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      {detailData != null &&
        <div className=" w-full  flex flex-col   ">
          <div className=" w-full flex flex-wrap mt-[20px]">
            <div className=" w-full md:w-8/12  flex flex-col">

              <div className='w-full flex flex-col'>
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Chilrens</h3>
                {detailData.children && detailData.children.map((item: any, index: any) =>
                  <div className=" w-full p-[10px] sm:p-[20px] mb-[10px] bg-white rounded-xl shadow flex flex-col md:flex-row items-center ">
                    <img src={item.top.url} alt="cover" className=' h-[120px]  rounded' />
                    <div className=' w-full md:w-[calc(100%-120px)] pl-[10px]  flex flex-col items-center md:items-start'>
                      {/* <span className="text-glasses-text-50 font-bold">{item.name}</span> */}
                      <div className=' flex items-center mt-[5px]'>
                        <span className=" text-[#836c77] text-sm  md:text-lg">{item.stock} </span>
                      </div>
                      <img src={item.color.image} alt="cover" className=' mt-[10px] w-[30px] h-[30px] rounded-full' />
                      <span className='text-[#FFA500] text-sm md:text-xl mt-[10px]'>{item.price}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className=" w-full flex flex-col ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Product information</h3>
                <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                  <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Product Name</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.title}</span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Product Description</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.description} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Frame Width </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.frame_width} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Lens Width </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.lens_width} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Frame Color </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.frame_color} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Frame Color </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.lens_color} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Lens Type</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.lens_type} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Lens Material</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.lens_material} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Frame Shape</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.frame_shape} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Brand</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.brand} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Frame Size</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.frame_size} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Frame Material</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.frame_material} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Weight Group</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.weight_group} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Genders</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.genders} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Made</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.made} </span>
                  </div>
                </div>
              </div>

              {/* <div className=" w-full flex flex-col md:mt-[50px] mt-[30px] ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Feathers</h3>
                <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                  {Object.entries(detailData.feathers).map((item: any, index) =>
                    <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                      <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                        <svg x="0px" y="0px" viewBox="0 0 293.246 293.246" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                          <use xlinkHref="/assets/svg/glasses.svg#glasses" />
                        </svg>
                      </button>
                      <span className="mx-[10px] text-[#836c77] font-bold">{item[0]}</span>
                      <span className="mx-[10px] text-glasses-text-50 font-bold">{item[1]} </span>
                    </div>
                  )}
                </div>
              </div> */}

            </div>
          </div>

        </div>
      }




    </>
  );
}

export default ProductDetail;
