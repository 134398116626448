import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import CModal from '../../../widget/CModal/CModal';
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';

function BenefitsList() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<boolean>(false)
  const [displayPage, setDisplayPage] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [iconFile, setIconFile] = useState<any>({
    'path': null,
    'url': null
  })
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [selectItem, setSelectItem] = useState<any>(null)


  const clearData = () => {
    setIconFile({
      'path': null,
      'url': null
    })
    setSelectItem(null)
    const formAdd: any = document.getElementById("add-benefits-form");
    formAdd?.reset()
  }

  const selectIcon = async (image: any) => {
    setDisplayPage(true)
    let formData = new FormData()
    formData.append("image", image);
    const req = await customApi({ url: `${baseUrl}benefits/image` }, { method: "POST", token: true, formData: true, body: formData })
    setDisplayPage(false);
    if (req.status == true) {
      let data = { ...iconFile };
      data.path = req.data.path;
      data.url = req.data.url;
      setIconFile(data)
    }
  }

  const onFinishAdd = async (e: any) => {
    e.preventDefault()
    setDisplayPage(true)
    let postData = {
      'title': e.target[0].value,
      'description': e.target[1].value,
      'icon': iconFile.path,
    }
    const req = await customApi({ url: `${baseUrl}benefits` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setVisibleAdd(false)
      clearData()
      getData()
    }

  }

  const removeSelectImage = () => {
    let data = { ...selectItem }
    data.icon = null;
    setSelectItem(data)
  }


  const getData = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}benefits` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListData(req.data)
      // setCurrentPage(req.data.meta.current_page)
      // setLastPage(req.data.meta.last_page)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("List Benefits")
    getData()
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full  flex flex-col   ">
        <div className=" w-full h-[60px] flex items-center">
          <button onClick={() => { setVisibleAdd(true) }} className=" px-[10px] h-[40px] bg-glasses-head-50 rounded-xl text-white"> + Add New Benefit</button>
        </div>
        <div className=' w-full py-[20px]   flex flex-wrap'>
          {listData.map((item: any, index: any) =>
            <div className=' lg:w-3/12 sm:w-4/12 w-full p-[10px]  flex flex-col items-center relative  transition-all hover:mt-[-3px] overflow-hidden'>
              <div className=' w-[80px] h-[80px] ct-bg-1 z-10 rounded-full flex justify-center items-center p-[2px] mb-[-40px]'>
                <div className=' w-full h-full bg-white rounded-full p-[2px]'>
                  <img src={item.icon} alt='logo' className='w-full h-full rounded-full p-[5px]' />
                </div>
              </div>
              <div className='w-full pt-[50px] pb-[20px] bg-white shadow rounded-xl flex flex-col items-center overflow-hidden border-t-2 border-[#ffc804]'>
                <div className=' w-full flex flex-col items-center justify-center'>
                  {/* <svg x="0px" y="0px" viewBox="0 0 489.4 489.4" className={` w-5 h-5 fill-gray-500 transition-all `}>
                    <use xlinkHref="/assets/svg/shop.svg#shop" />
                  </svg> */}
                  <span className=' text-gray-500 ml-[10px] text-lg'>{item.title}</span>
                  <span className=' text-gray-400 ml-[10px]'>{item.description}</span>
                </div>
                <div className=' w-full flex justify-center'>
                  {/* <button onClick={() => { setVisibleEdit(true); setSelectItem(item) }} className='mt-[20px]  h-[40px] border rounded-md border-[#ffc804] text-[#ffc804] px-[10px]'>
                    <svg x="0px" y="0px" viewBox="0 0 469.331 469.331" className={` w-5 h-5 fill-[#ffc804] transition-all `}>
                      <use xlinkHref="/assets/svg/edit.svg#edit" />
                    </svg>
                  </button> */}
                  {/* <Link key={index} to={`/panel/shop/detail/${item.id}`}>
                    <button className='mt-[20px] mx-[4px] h-[40px] border rounded-md border-[#ffc804] text-[#ffc804] px-[10px]'>
                      <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-5 h-5 fill-[#ffc804] transition-all `}>
                        <use xlinkHref="/assets/svg/trash.svg#trash" />
                      </svg>
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className=' w-full  flex items-center justify-center'>
          {currentPage != 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(false)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
              </svg>
            </div>
          }
          <div className="table-counter-main-item">{currentPage} </div>
          {lastPage >= currentPage + 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(true)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
              </svg>
            </div>
          }
        </div> */}
      </div>

      {/* Add Modal */}
      <CModal onScap={clearData} visible={visibleAdd} setVisible={setVisibleAdd} title={"Benefits"} uId="Benefits">
        <form onSubmit={onFinishAdd} id="add-benefits-form">
          <div className=" flex flex-col w-full">
            <span className=" text-gray-500">Title :</span>
            <input type="text" className=" w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"title"} required />
          </div>
          <div className=" flex flex-col w-full">
            <span className=" text-gray-500">Description :</span>
            <input type="text" className=" w-full h-[80px] border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Description"} required />
          </div>
          <div className=" flex justify-center w-full mb-[15px]">
            {iconFile.path == null ?
              <label>
                <div className=' w-[100px] h-[100px] shadow  p-1 rounded'>
                  <div className=' w-full h-full cursor-pointer border border-dashed border-[#ffcb10] text-[#ffcb10] rounded flex justify-center items-center'>
                    + image icon
                  </div>
                </div>
                <input onChange={(e: any) => { selectIcon(e.target.files[0]) }} type="file" className="hidden" required />
              </label>
              :
              <div className=' w-[100px] h-[100px] relative '>
                <div onClick={() => { setIconFile({ 'path': null, 'url': null }) }} className='absolute left-1 top-1 w-[20px] h-[20px] bg-red-500 rounded-full cursor-pointer flex justify-center items-center z-10 text-white'>X</div>
                <img src={iconFile.url} alt="icon" className=' w-full h-full rounded border border-gray-700' />
              </div>
            }
          </div>
          {displayBtn == false ?
            <button type="submit" className=" w-full h-10 bg-glasses-head-50 text-white rounded" >Add</button>
            :
            <button type="button" className=" w-full h-10 bg-glasses-head-50 flex justify-center items-center text-white rounded relative" disabled><BtnLoader /></button>
          }
        </form>
      </CModal>

      {/* Edit Modal */}
      <CModal onScap={clearData} visible={visibleEdit} setVisible={setVisibleEdit} title={'Edit Benefits'} uId="Edit-Benefits">
        <>
          {selectItem != null &&
            <form  >
              <div className=" flex flex-col w-full">
                <span className=" text-gray-500">Title :</span>
                <input value={selectItem?.title} type="text" className=" w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"title"} required />
              </div>
              <div className=" flex flex-col w-full">
                <span className=" text-gray-500">Description :</span>
                <input value={selectItem?.description} type="text" className=" w-full h-[80px] border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Description"} required />
              </div>
              <div className=" flex justify-center w-full mb-[15px]">
                {selectItem.icon == null ?
                  <label>
                    <div className=' w-[100px] h-[100px] shadow  p-1 rounded'>
                      <div className=' w-full h-full cursor-pointer border border-dashed border-[#ffcb10] text-[#ffcb10] rounded flex justify-center items-center'>
                        + image icon
                      </div>
                    </div>
                    <input type="file" className="hidden" required />
                  </label>
                  :
                  <div className=' w-[100px] h-[100px] relative '>
                    <div onClick={removeSelectImage} className='absolute left-1 top-1 w-[20px] h-[20px] bg-red-500 rounded-full cursor-pointer flex justify-center items-center z-10 text-white'>X</div>
                    <img src={selectItem.icon} alt="icon" className=' w-full h-full rounded border border-gray-700' />
                  </div>
                }
              </div>
              {displayBtn == false ?
                <button type="submit" className=" w-full h-10 bg-glasses-head-50 text-white rounded" >Update</button>
                :
                <button type="button" className=" w-full h-10 bg-glasses-head-50 flex justify-center items-center text-white rounded relative" disabled><BtnLoader /></button>
              }
            </form>
          }
        </>
      </CModal>
    </>
  );
}

export default BenefitsList;
