
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom"

import './tableStyle.css';


type ITablePage = {
    data: any[],
    setData?: CallableFunction,
    isSelective?: boolean,
    isCol?: boolean,
    columns: any[],
    children?: JSX.Element | JSX.Element[] | React.ReactElement | React.ReactElement[] | any,
    keyOfFilter?: any,
    TypeOfFilter?: any,
    key?: any,
    isSortMode?: {
        status: boolean,
        keyOfServerDataFetch: any,
        getData: CallableFunction,
        currentSortKey: string
        setCurrentSortKey: CallableFunction
        currentSortType: string
        setCurrentSortType: CallableFunction
    }
}


// if you want to active solective ability you should set the setData atribute 

const TablePage = ({ columns, data, children, isCol, isSelective, keyOfFilter = null, setData, TypeOfFilter, isSortMode }: ITablePage) => {
    const [listData, setListData] = useState<any[]>([]);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // const sortList = async (sort: any) => {
    //     await setSortState(!sortState)
    //     if (sortState) {
    //         await setListData(listData.sort((a, b) => (a[sort] > b[sort]) ? 1 : -1))
    //         await setSortState(false)
    //     }
    //     else {
    //         await setListData(listData.sort((a, b) => (a[sort] < b[sort]) ? 1 : -1))
    //         await setSortState(true)
    //     }
    // }

    useEffect(() => {
        if (data) {
            for (var i in data) {
                if (data[i].isSelected === undefined || data[i].isSelected === null) data[i].isSelected = false
            }
            setListData(data)
        }
    }, [data?.length, data]);

    const selectAnItem = (item: any) => {
        var lastArr = [...listData]
        var findTI = lastArr.findIndex(e => e.id === item.id)
        lastArr[findTI]["isSelected"] = !item.isSelected
        // setListData([...lastArr])
        setData?.([...lastArr])
    }

    const selectAndUnselectAll = () => {
        var lastArr = [...listData]
        for (var i in lastArr) {
            if (keyOfFilter !== null) {
                if (keyOfFilter === lastArr[i][TypeOfFilter]) {
                    lastArr[i].isSelected = !isSelectAll
                }
            } else {
                lastArr[i].isSelected = !isSelectAll
            }
        }
        setIsSelectAll(!isSelectAll)
        setData?.([...lastArr])
    }

    const sortTable = (colItems: any) => {
        var lastArr = [...columns]
        var urlParm = searchParams.get(`${isSortMode?.keyOfServerDataFetch}${colItems.sortKey}`)
        var findTI = lastArr.findIndex(e => e.title === colItems.title)
        if (urlParm == "ASC") {
            lastArr[findTI][`${isSortMode?.keyOfServerDataFetch}${colItems.title}`] = "DESC"
            navigate(`?${isSortMode?.keyOfServerDataFetch}${colItems.sortKey}=DESC`)
        } else {
            lastArr[findTI][`${isSortMode?.keyOfServerDataFetch}${colItems.title}`] = "ASC"
            navigate(`?${isSortMode?.keyOfServerDataFetch}${colItems.sortKey}=ASC`)
        }
        isSortMode?.getData(null, null, null, null, null, `${isSortMode?.keyOfServerDataFetch}${colItems.sortKey}=${lastArr[findTI][`${isSortMode?.keyOfServerDataFetch}${colItems.title}`]}`)
    }

    return (
        <div className="table">
            <div className="table-head sticky top-0 z-50 backdrop-blur-md mb-4">
                {isSelective &&
                    <div className=" flex flex-col justify-center items-center min-w-[90px]" >
                        <p className=" text-xs text-gray-500">{isSelectAll ? "Unselect all" : " Select all"}</p>
                        <div className=" flex justify-center items-center min-w-[90px]">
                            <div onClick={() => selectAndUnselectAll()}
                                className={` w-[20px] h-[20px] flex justify-center items-center border rounded-md cursor-pointer ${isSelectAll && "border-glasses-head-50"} hover:border-glasses-head-50 `}>
                                {isSelectAll && <span className=" bg-glasses-head-50 w-[16px] h-[16px] rounded-md" />}
                            </div>
                        </div>
                    </div>
                }
                {isCol &&
                    <div className=" flex justify-center items-center min-w-[30px]" />
                }
                {columns.map((item: any) =>
                    <>
                        {item.display != false &&
                            <div className="table-head-item text-center">
                                <span className=" flex w-full text-gray-600 text-center justify-center">
                                    {item.sort && isSortMode?.status &&
                                        <img onClick={() => sortTable(item)} className=" w-5 cursor-pointer mr-2" src="/assets/svg/sort.svg" />
                                    }
                                    {item.title}
                                </span>
                            </div>
                        }
                    </>
                )}
            </div>
            {listData.filter((e) => {
                if (keyOfFilter !== null) {
                    if (keyOfFilter === e[TypeOfFilter]) return e
                } else {
                    return e
                }
            }).map((item, index) =>
                <div className="table-row">
                    {isSelective &&
                        <div className=" flex justify-center items-center min-w-[90px]">
                            <div onClick={() => selectAnItem(item)}
                                className={` w-[20px] h-[20px] flex justify-center items-center border rounded-md cursor-pointer ${item.isSelected && "border-glasses-head-50"} hover:border-glasses-head-50 `}>
                                {item.isSelected && <span className=" bg-glasses-head-50 w-[16px] h-[16px] rounded-md" />}
                            </div>
                        </div>
                    }
                    {isCol &&
                        <div className=" flex border-x  justify-center items-center min-w-[30px]">
                            <span className=" ">{index + 1}</span>
                        </div>
                    }
                    {columns.map((items: any) =>
                        <>
                            {items.display != false &&
                                <div key={JSON.stringify(item)} className="table-row-item">
                                    {item[items.dataIndex]}
                                    {item[items.dataIndex] == undefined && items.render(item, index)}
                                </div>
                            }
                        </>
                    )}
                </div>
            )}
            <div className="table-counter">
                <div className="table-counter-main">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default TablePage;