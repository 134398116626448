import { toast } from "react-toastify";
import { baseUrl, customApi } from "../../../../../widget/apis";

const formInputs = (
    inputName: string,
    value: any,
    parentType: "usual-parent" | "zero-parent",
    isParentChangedState: any,
    setIsParentChangedFunc: CallableFunction,
    formDataState: any,
    setFormDataFunc: CallableFunction,
) => {
    let lastParentChanged = { ...isParentChangedState }
    let data: any = { ...formDataState };
    if (inputName === "discount" || inputName === "discount_zero") {
        if (value <= 100) data[inputName] = value;
    } else data[inputName] = value
    if (parentType === "usual-parent") lastParentChanged["usual-parent"] = true
    if (parentType === "zero-parent") lastParentChanged["zero-parent"] = true
    setIsParentChangedFunc({ ...lastParentChanged })
    setFormDataFunc({ ...data })
}

const updateMulti = async (
    formData: any,
    isParentChangedState: any,
    parentType: "usual-parent" | "zero-parent",
    setDisplayPage: CallableFunction,
    SingleProduct: any,
    updateData: CallableFunction,
    currentCategory: any,
    variants: any[],
    isParentChanged: any,
    setIsParentChanged: CallableFunction
) => {
    if (parentType === "zero-parent" && !isParentChangedState["zero-parent"]) return toast("isParentChanged state has problem", { type: "error" })
    if (parentType === "usual-parent" && !isParentChangedState["usual-parent"]) return toast("isParentChanged state has problem", { type: "error" })

    var variantsIdList = []
    for (var t in variants) {
        if (variants[t].variant_id) variantsIdList.push(variants[t].variant_id)
    }
    const postBody: any = {
        "product_id": SingleProduct.id,
        "variants_id": variantsIdList
    }

    postBody["discount"] = parentType === "zero-parent" ? formData.discount_zero : formData.discount
    postBody["min_price"] = parentType === "zero-parent" ? formData.min_price_zero : formData.min_price
    postBody["suggestion_price"] = parentType === "zero-parent" ? formData.suggestion_price_zero : formData.suggestion_price
    postBody["price"] = parentType === "zero-parent" ? formData.price_zero : formData.price
    postBody["stock_type"] = parentType === "zero-parent" ? formData.stock_type_zero : formData.stock_type
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/${currentCategory}/update_multi` }, { method: "POST", body: JSON.stringify(postBody), token: true })
    if (req.status) {
        const lastIsParentChanged = {...isParentChanged}
        await updateData()
        lastIsParentChanged[parentType] = false
        setIsParentChanged({...lastIsParentChanged})
    } else {
        toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
}

const fallBackInformation = (
    formInputs: any,
    setFormData: CallableFunction,
    formInputsTerm: any,
    setFromDataTerm: CallableFunction,
    parentType: "usual-parent" | "zero-parent",
    isParentChanged: any,
    setIsParentChanged: CallableFunction
) => {
    let isParentChangedIn: any = { ...isParentChanged }
    let lastFormInputsTerm: any = { ...formInputsTerm }
    let formInputsIn = { ...formInputs };
    if (parentType === "usual-parent") {
        formInputsIn['price'] = formInputsTerm['price']
        formInputsIn['discount'] = formInputsTerm['discount']
        formInputsIn['suggestion_price'] = formInputsTerm['suggestion_price']
        formInputsIn['min_price'] = formInputsTerm['min_price']
        isParentChangedIn["usual-parent"] = false
        setIsParentChanged()
    } else if (parentType === "zero-parent") {
        formInputsIn['price_zero'] = formInputsTerm['price_zero']
        formInputsIn['discount_zero'] = formInputsTerm['discount_zero']
        formInputsIn['suggestion_price_zero'] = formInputsTerm['suggestion_price_zero']
        formInputsIn['min_price_zero'] = formInputsTerm['min_price_zero']
        isParentChangedIn["zero-parent"] = false
    }
    setIsParentChanged({ ...isParentChangedIn })
    setFormData({ ...formInputsIn })

}

export { formInputs, updateMulti, fallBackInformation }