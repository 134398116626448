import React, { useEffect, useContext } from 'react';
import * as firebase from "firebase";
import app from 'firebase/app';
import { baseUrl, customApi } from '../apis';
import { ContextState } from '../context/ContextMain'
import { toast } from "react-toastify";


function FireBaseConfig(props: any) {
  const Ctx = useContext(ContextState);

  const showNotification = async (dataString: any) => {
    let data: any = await JSON.parse(dataString.data.chat)
    let message: any = await JSON.parse(dataString.data.message)
    toast(data.last_message, { type: "info" })
    if (Ctx.pageDetailChatIsOpen != null && data.id == Ctx.pageDetailChatIsOpen.id) {
      let newMessage: any = {}
      if (message.type == 'message') {
        newMessage = {
          "type": "message",
          "content": message.content,
          "is_admin": false,
        }
      }
      else {
        newMessage = {
          "type": "file",
          "content": message.content,
          "is_admin": false,
        }
      }
      let update = [...Ctx.chatDetailListData];
      update.unshift(newMessage)
      Ctx.setChatDetailListData(update)
    }
    if (Ctx.pageDetailChatIsOpen == null) {
      Ctx.setUpdateChatList(!Ctx.updateChatList)
    }

  }

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const config = {
        apiKey: "AIzaSyCA4ylNBAIX_HqLCDrB5we0mK1EvK43doI",
        authDomain: "javaproject-251506.firebaseapp.com",
        projectId: "javaproject-251506",
        storageBucket: "javaproject-251506.appspot.com",
        messagingSenderId: "742253838312",
        appId: "1:742253838312:web:57672758e271fda5a9ee5d",
        measurementId: "G-G50VDG2CM2"
      };
      if (firebase.apps.length === 0) {
        app.initializeApp(config);
      }
      const messaging = firebase.messaging();
      Notification.requestPermission()
        .then(function () {
          return messaging.getToken();
        })
        .then(async function (tokenFire) {
          let postData = JSON.stringify({
            'g_token': tokenFire,
          })
          try {
            const req = await customApi({ url: `${baseUrl}profile/firebase` }, { method: "POST", body: postData, token: true })
          }
          catch (e: any) {

          }
        })
      messaging.onMessage((payload) => {
        Ctx.setUpdateChatList(!Ctx.updateChatList)
        // showNotification(payload)

      });
    }
  }, [Ctx.updateChatList]);


  return (<></>
  );
}

export default FireBaseConfig;
