import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import BtnLoader from '../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../widget/apis';
import LoadingPage from '../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditProfileAddress from './widget/EditProfileAddress';
import EditProfileTimes from './widget/EditProfileTimes';
import EditProfileDescription from './widget/EditProfileDescription';

function EditShop() {
  const Ctx = useContext(ContextState);
  const navigate = useNavigate();
  const [displayBtn, setDisplayBtn] = useState<boolean>(false)
  const [displayPage, setDisplayPage] = useState<boolean>(false)
  const [detailData, setDetailData] = useState<any>(null)

  const updateProfile = async (e: any) => {
    e.preventDefault()
    setDisplayPage(true)
    let data = { ...detailData };
    delete data['cover_url'];
    delete data['logo_url']
    delete data['benefits']
    const req = await customApi({ url: `${baseUrl}profile` }, { method: "PUT", body: JSON.stringify(data), token: true })
    setDisplayPage(false)
    if (req.status == true) {
      await getData()
      toast(req.message, { type: "success" })
      // navigate('/panel/product/list');
    }
    else {
      toast(req.message, { type: "error" })
    }
  }

  const getData = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}profile` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      let data = req.data;
      data['logo_url'] = req.data.logo.url;
      data['cover_url'] = req.data.cover.url;
      data['cover'] = req.data.cover.path;
      data['logo'] = req.data.logo.path;
      setDetailData(data)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("Update Shop")
    Ctx.setActiveItemNav({'menu':'profile' , 'subMenu':0})
    getData()
  }, [])



  return (
    <>
      {displayPage && <LoadingPage />}
      {detailData != null &&
        <form onSubmit={updateProfile} className=' w-full flex flex-wrap justify-between'>
          <div className=" w-full md:w-6/12 pl-0 md:pl-5  flex flex-col order-[-2] md:order-2  ">
            <EditProfileAddress detailData={detailData} setDetailData={setDetailData} />
            <EditProfileTimes detailData={detailData} setDetailData={setDetailData} />
            <EditProfileDescription detailData={detailData} setDetailData={setDetailData} />
            <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Update Shop </button>
          </div>
        </form>
      }
    </>
  );
}

export default EditShop;
