import React, { useContext, useEffect, useState } from 'react';
import BtnLoader from '../../widget/Loading/BtnLoader';
import { customApi, baseUrl } from '../../widget/apis';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../../widget/Loading/LoadingPage';
import { ContextState } from '../../widget/context/ContextMain';


function Auth() {
  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<boolean>(false)
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPass, setDisplayPass] = useState<any>(false)
  const navigate = useNavigate();

  const authLogin = async (e: any) => {
    e.preventDefault()
    let postData = JSON.stringify({
      'email': e.target[0].value,
      'password': e.target[1].value,
    })
    setDisplayBtn(true)
    const req = await customApi({ url: `${baseUrl}auth/login` }, { method: "POST", body: postData })
    if (req.status == true) {
      localStorage.setItem('token', req.data.token)
      toast('Logged in successfully', { type: "success" })
      navigate('/panel/dashboard');
    }
    else {
      setDisplayBtn(false)
      toast(req.message, { type: "error" })
    }
  }

  async function checkToken() {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}accounts` }, { method: "GET", token: true })
    if (req.status) {
      navigate('/panel/dashboard');
      // setDisplayPage(false)
    }
    else if (req.status == false && req.message == 'Unauthenticated') {
      navigate('/');
      localStorage.clear()
      setDisplayPage(false)
      toast("Your Token is Expired, Try Login", { type: "info" })
    }
  }

  useEffect(() => {
    const isToken = window.localStorage.getItem("token")
    if (isToken) {
      checkToken()
    }
  }, [])

  return (
    <div className="auth" >
      {displayPage && <LoadingPage />}
      <div className="auth-image">
        <img src={'/assets/svg/cover.svg'} />
      </div>

      <div className="auth-main">
        <div className="auth-main-inner">
          <img className="auth-main-inner-logo" src={`/assets/image/logo.png`} alt="logo" />
          <p>Welcome back to your account.</p>
          <form onSubmit={authLogin} >

            <div className="auth-main-inner-form-item">
              <div className="auth-main-inner-form-item-icon">
                <svg x="0px" y="0px" viewBox="0 0 75.294 75.294" className={` w-5 fill-[#afacac]`}>
                  <use xlinkHref={`/assets/svg/email.svg#email`} />
                </svg>
              </div>
              <input type="emial" placeholder="Email Address" required />
            </div>

            <div className="auth-main-inner-form-item">
              <div className="auth-main-inner-form-item-icon">
                <svg x="0px" y="0px" viewBox="0 0 574.922 574.922" className={` w-5 fill-[#afacac]`}>
                  <use xlinkHref={`/assets/svg/lock.svg#lock`} />
                </svg>
              </div>
              <input type={displayPass ? 'text' : 'password'} placeholder="Password" required />
              <svg onClick={() => { setDisplayPass(!displayPass) }} x="0px" y="0px" viewBox="0 0 512 512" className={` w-5 fill-[#afacac] cursor-pointer`}>
                <use xlinkHref={`/assets/svg/eye.svg#eye`} />
              </svg>
            </div>

            <div className="auth-main-inner-form-bottom">
              {displayBtn == false ?
                <button type='submit' className="auth-main-inner-form-bottom-btn">Login</button>
                :
                <button type='button' className="auth-main-inner-form-bottom-btn cursor-not-allowed" disabled><BtnLoader /></button>
              }
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default Auth;
