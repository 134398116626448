import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import BtnLoader from '../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../widget/apis';
import LoadingPage from '../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../widget/Table/TablePage';
import { Link, useNavigate } from 'react-router-dom';
import CModal from '../../widget/CModal/CModal';
import Cleave from 'cleave.js/react';
import { AddCouponAPi } from '../../widget/apis/coupon';
import { CouponsPermissions } from '../../widget/rolePermissions';
import { checkPermission } from '../../widget/layout/Nav';
import { Modal, Popconfirm } from 'antd';

function Coupon() {
    const Ctx = useContext(ContextState);
    const navigator = useNavigate()
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [listData, setListData] = useState([]);
    const [lastPage, setLastPage] = useState(0);
    const [isAllowReq, setIsAllowReq] = React.useState<boolean>(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedToDeleted, setSelectedToDeleted] = useState<any>();
    const [isAddCouponModal, setIsAddCouponModal] = React.useState<boolean>(false);
    const [filterItems, setFilterItems] = React.useState<{ name?: string, price?: any }>({});
    const [isBrand, setIsBrand] = useState(false);

    const [col, setCol] = useState([
        {
            title: "Discount Code",
            render: (i: any) => (<>{i.discount_code != null && i.discount_code}</>)
        },
        {
            title: "Discount",
            render: (i: any) => (<>{i.discount != null && <>{i.discount} %</>}</>)
        },
        {
            title: "Count",
            render: (i: any) => (<>{i.count != null && i.count}</>)
        },
        {
            title: "Start date",
            render: (i: any) => (<>{i.start_time != null && i.start_time}</>)
        },

        {
            title: "End date",
            render: (i: any) => (<>{i.expire_time != null && i.expire_time}</>)
        },
        {
            title: "Status",
            render: (i: any) => (
                <>
                    {i.status && <span className=" text-[#16BB83] font-bold">Enabled</span>}
                    {!i.status && <span className=" text-[#FF3A44] font-bold">Disabled</span>}
                </>
            )
        },
        {
            title: "Delete",
            render: (i: any) => (
                <>
                    <Popconfirm placement="top" title={`Are you sure to delete ?`} onConfirm={() => handelStartDelete(i)} okText="Yes" cancelText="No">
                        <button className=' mx-[4px] h-[35px] border rounded-md border-glasses-head-50 text-glasses-head-50 px-[10px]'>
                            <svg x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-5 h-5 fill-glasses-head-50 transition-all `}>
                                <use xlinkHref="/assets/svg/trash.svg#trash" />
                            </svg>
                        </button>
                    </Popconfirm>
                </>
            )
        },
    ])

    const handelStartDelete=(i:any)=>{
        setSelectedToDeleted(i)
        if(Ctx.accountsList.length > 1){
            return setOpenModal(true) ;
        }
        else {
            return deleteCoupon(i.id, false)
        }
    }

    const handelActive = async (id: number) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/coupons/${id}/change_status` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            getData(currentPage)
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const deleteCoupon = async (id: number, isAll: boolean) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/coupons/${id}?for_all=${isAll ? 1 : 0}` }, { method: "DELETE", token: true })
        if (req.status == true) {
            toast(req.message, { type: "success" })
            getData(currentPage)
            setOpenModal(false)
        }
        else {
            toast(req.message, { type: "error" })
            setOpenModal(false)
        }
        setDisplayPage(false)
    }



    const changePage = (status: any) => {
        if (status) {
            if (lastPage >= currentPage + 1) {
                getData(currentPage + 1)
            }
        }
        else {
            if (currentPage > 1) {
                getData(currentPage - 1)
            }
        }
    }


    const getData = async (current: any, searchKey?: any, name?: any, price?: any) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/coupons?page=${current}${searchKey ? `&search=${searchKey}` : ""}${name ? `&name=${name}` : ""}${price ? `&price=${price}` : ""}` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            setListData(req.data.data)
            setCurrentPage(req.data.meta.current_page)
            setLastPage(req.data.meta.last_page)
            setIsAddCouponModal(false)
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("Coupon")
        Ctx.setActiveItemNav({ 'menu': 'coupon', 'subMenu': 0 })
        getData(currentPage)
    }, [])

    async function searchWithDelay() {
        if (isAllowReq) {
            const Input: any = document.getElementById(`searchInputProduct`)
            setIsAllowReq(false)
            setTimeout(async () => {
                // if (!stockInput.value) return setIsAllowReq(true)
                getData(currentPage, Input.value)
                setIsAllowReq(true)
            }, 1500)
        }
    }

    // async function addCoupon(e: any) {
    //     e.preventDefault()
    //     if (displayPage) return
    //     setDisplayPage(true)
    //     const form: any = document.getElementById("AddCouponForm")
    //     try {
    //         const req = await AddCouponAPi(e)
    //         if (req.status) {
    //             toast(req.message, { type: "success" })
    //             setDisplayPage(false)
    //             getData(currentPage)
    //             form?.reset()
    //             setIsAddCouponModal(false)
    //         } else {
    //             setDisplayPage(false)
    //             toast(req.message)
    //         }
    //     }
    //     catch {
    //         setDisplayPage(false)
    //     }
    // }

    useEffect(() => {
        if (checkPermission(CouponsPermissions.update)) {
            let colData = [...col];
            colData.push({
                title: "Change Status",
                render: (i: any) => (
                    <>
                        {i != 0 ?
                            <label className="table-toggle">
                                <>
                                    <input type="checkbox" onChange={() => handelActive(i.id)} checked={i.status == 1 && true} />
                                    <div className="table-toggle-main" />
                                </>
                            </label>
                            :
                            <span ></span>
                        }
                    </>
                )
            })
            setCol(colData)
        }
    }, [])


    useEffect(() => {
        if (!checkPermission(CouponsPermissions.view)) {
            navigator('/panel/dashboard')
        }
    }, [])

    const okAllShop = () => {
        deleteCoupon(selectedToDeleted.id, true)
    };

    const noJustMyShop = () => {
        deleteCoupon(selectedToDeleted.id, false)
    };

    useEffect(()=>{
        if(localStorage.getItem('isBrand')){
            setIsBrand(Boolean(localStorage.getItem('isBrand')))
        }
    },[])

    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full  flex flex-col   ">
                {checkPermission(CouponsPermissions.add) &&
                    <div className=" w-full h-[60px] flex items-center">
                        <Link to={"/panel/coupon/add"}>
                            <a className=" px-[10px] py-3 bg-glasses-head-50 hover:text-white rounded-xl text-white"> + Add New Coupon</a>
                        </Link>
                    </div>
                }
                {/* <div className=' w-full flex flex-wrap pt-4 justify-center md:justify-between items-center'> */}
                {/* <div className=' flex items-center justify-center bg-gray-200 pr-4 overflow-hidden rounded-lg group'>
                        <input id='searchInputProduct' className='bg-gray-200 px-4 rounded-tl-lg rounded-bl-lg h-12' placeholder='Search' type={"text"}
                            onChange={(e: any) => {
                                searchWithDelay()
                            }} />
                        <svg x="0px" y="0px" viewBox="0 0 53.627 53.627" className={` w-5 fill-gray-500 h-12 bg-gray-200 rounded-tr-lg rounded-br-lg transition-all group-hover:fill-glasses-head-50 `}>
                            <use xlinkHref="/assets/svg/search.svg#search" />
                        </svg>
                    </div> */}

                {/* <div className=' flex '>
                        {filterItems.name &&
                            <div onClick={() => filterCancel({ name: true })} className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600'>
                                <span className=' cursor-pointer mr-2'>x</span>  {filterItems.name}
                            </div>
                        }
                        {filterItems.price &&
                            <div onClick={() => filterCancel({ price: true })} className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600'>
                                <span className=' cursor-pointer mr-2'>x</span> {filterItems.price}
                            </div>
                        }
                        <div onClick={() => setIsAddCouponModal(!isAddCouponModal)} className=' flex justify-center items-center px-8 py-2 text-sm rounded-lg bg-blue-400 hover:bg-blue-600 cursor-pointer text-white'>
                            Filter
                        </div>
                    </div> */}
                {/* </div> */}
                <TablePage columns={col} data={listData}>
                    {currentPage != 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(false)} >
                            <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
                            </svg>
                        </div>
                    }
                    <div className="table-counter-main-item">{currentPage} </div>
                    {lastPage >= currentPage + 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(true)} >
                            <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
                            </svg>
                        </div>
                    }
                </TablePage>
            </div>
            <Modal
                visible={openModal}
                footer={false}
                onCancel={() => { setOpenModal(false) }}
            >
                <p className=' text-lg pr-5'>
                    {`Do you want this coupon to be applied to all of your ${isBrand ? 'brands' : 'shops'}  ?`}
                </p>
                <div className=' w-full items-center mt-3'>
                    <button onClick={okAllShop} className=" text-white rounded-lg bg-glasses-head-50 mr-2 w-[120px] h-[40px]  font-bold ">Yes </button>
                    <button onClick={noJustMyShop} className=" text-white rounded-lg bg-glasses-head-50 mr-2 w-[120px] h-[40px]  font-bold ">No </button>
                </div>

            </Modal>

        </>
    );
}

export default Coupon;
